import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Button, Card, Modal } from "@mui/material";
import PhoneInput from "react-phone-input-2";
import { styled } from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";
import FormatNumber from "../../../components/formatData/formatPhone";
import { Phone, Smartphone } from "@mui/icons-material";
import { RestaurantContext } from "../../../contexts/restaurantContext";

const useStyles = makeStyles(() => ({
  modal: {
    display: "flex",
    border: "2px solid #000",
    padding: 20,
    boxShadow: 5,
    backgroundColor: "rgb(0 0 0 / 62%)",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
    maxHeight: "100vh",
  },
  paper: {
    width: "100%",
    borderRadius: 8,
  },
}));

const BpIcon = styled("span")(({}) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  boxShadow:
    "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: "#f5f8fa",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background: "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#137cbd",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&::before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#106ba3",
  },
});

const ReferencePhoneModal = ({
  referencePhoneModal,
  setReferencePhoneModal,
  currentTask,
}) => {
  const classes = useStyles();
  const [customPhoneNumber, setCustomPhoneNumber] = useState("");
  const [selectedPhoneValue, setSelectedPhoneValue] = useState(null);
  const { sendReferenceSmsFunc } = useContext(RestaurantContext);

  function BpCheckbox(props) {
    return (
      <Checkbox
        sx={{ "&:hover": { bgcolor: "transparent" } }}
        disableRipple
        color="default"
        checkedIcon={<BpCheckedIcon />}
        icon={<BpIcon />}
        inputProps={{ "aria-label": "Checkbox demo" }}
        {...props}
      />
    );
  }

  return (
    <Modal
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      open={referencePhoneModal}
      className={classes.modal}
      onClose={() => {
        setReferencePhoneModal(false);
      }}
    >
      <Card
        style={{
          width: "30%",
          display: "flex",
          flexDirection: "column",
          maxHeight: "30%",
          overflow: "auto",
          minHeight: "30%",
          padding: 30,
        }}
      >
        <div style={{ fontFamily: "SgBd", fontSize: 22 }}>
          Referans linki gönderilecek telefon numarasını seçiniz
        </div>
        <div style={{ display: "flex", marginTop: 30 }}>
          <BpCheckbox
            checked={selectedPhoneValue === 1 ? true : false}
            onChange={(e) => {
              if (selectedPhoneValue === 1) {
                setSelectedPhoneValue(null);
              } else {
                setSelectedPhoneValue(1);
              }
            }}
          />
          <div
            style={{
              display: "flex",
              borderRadius: 4,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div>
              <Smartphone />
            </div>
            <div
              style={{
                marginLeft: 5,
                letterSpacing: 0.8,
                fontFamily: "segoeuiRg",
                fontSize: 18,
              }}
            >
              {FormatNumber(currentTask?.restofficial?.phone, "")}
            </div>
          </div>
        </div>
        <div style={{ display: "flex", marginTop: 15 }}>
          <BpCheckbox
            checked={selectedPhoneValue === 2 ? true : false}
            onChange={(e) => {
              if (selectedPhoneValue === 2) {
                setSelectedPhoneValue(null);
              } else {
                setSelectedPhoneValue(2);
              }
            }}
          />
          <div
            style={{
              display: "flex",
              borderRadius: 4,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div>
              <Phone />
            </div>
            <div
              style={{
                marginLeft: 5,
                letterSpacing: 0.8,
                fontFamily: "segoeuiRg",
                fontSize: 18,
              }}
            >
              {FormatNumber(currentTask?.restofficial?.constant_phone, "")}
            </div>
          </div>
        </div>
        <div style={{ display: "flex", marginTop: 15 }}>
          <BpCheckbox
            checked={selectedPhoneValue === 3 ? true : false}
            onChange={(e) => {
              if (selectedPhoneValue === 3) {
                setSelectedPhoneValue(null);
              } else {
                setSelectedPhoneValue(3);
              }
            }}
          />
          <PhoneInput
            countryCodeEditable={false}
            country="tr"
            onlyCountries={["tr"]}
            localization={{ tr: "Türkiye" }}
            value={customPhoneNumber}
            containerStyle={{ height: 40 }}
            inputStyle={{
              height: 40,
              width: 200,
              fontSize: 15,
              fontWeight: "400",
            }}
            onChange={(phone) => {
              setCustomPhoneNumber(phone);
            }}
          />
        </div>
        <Button
          style={{
            color: "white",
            backgroundColor: "#f53b62",
            padding: "2px 20px",
            textTransform: "capitalize",
            fontFamily: "SgBd",
            fontSize: 18,
            marginTop: 10,
          }}
          onClick={() => {
            let data;
            if (selectedPhoneValue === 1) {
              data = {
                sendObj: {
                  phone_number: currentTask?.restofficial?.phone,
                  restofficial_id: currentTask?.restofficial?.user_id,
                },
              };
            } else if (selectedPhoneValue === 2) {
              data = {
                sendObj: {
                  phone_number: currentTask?.restofficial?.constant_phone,
                  restofficial_id: currentTask?.restofficial?.user_id,
                },
              };
            } else {
              data = {
                sendObj: {
                  phone_number: customPhoneNumber,
                  restofficial_id: currentTask?.restofficial?.user_id,
                },
              };
            }

            sendReferenceSmsFunc(data);
            setReferencePhoneModal(false);
          }}
        >
          Sms'i Gönder
        </Button>
      </Card>
    </Modal>
  );
};

export default ReferencePhoneModal;
