import React, { useEffect, useState } from "react";

import { Avatar, Card, IconButton, Modal, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { Forward } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    padding: 20,
    border: "2px solid #000",
    boxShadow: 5,
    backgroundColor: "rgb(0 0 0 / 62%)",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
    maxHeight: "100vh",
  },
}));

const TaskTypeStatsModal = ({
  taskTypeStatsModal,
  setTaskTypeStatsModal,
  startDate,
  endDate,
  ratingTaskTypeStatsFunc,
  ratingTaskTypeStats,
  cdrRatingTaskTypeCreateFunc,
}) => {
  const classes = useStyles();
  useEffect(() => {
    if (taskTypeStatsModal.showModal) {
      ratingTaskTypeStatsFunc({
        sendObj: {
          start_date: moment(startDate).format("YYYY-MM-DD"),
          end_date: moment(endDate).format("YYYY-MM-DD"),
          officeuser:
            taskTypeStatsModal.selectedOfficeuser?.officeuser?.user_id,
        },
      });
    }
  }, [taskTypeStatsModal.showModal]);

  return (
    <>
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={taskTypeStatsModal.showModal}
        className={classes.modal}
        onClose={() => {
          setTaskTypeStatsModal({
            showModal: false,
            selectedOfficeuser: null,
          });
        }}
      >
        <Card
          style={{
            width: "80%",
            display: "flex",
            alignItems: "center",
            padding: 30,
            flexDirection: "column",
            maxHeight: "80vh",
            overflow: "auto",
          }}
        >
          <div
            style={{
              width: "100%",
              height: 40,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#fff",
              marginBottom: 20,
              fontFamily: "segoeuiBd",
              fontSize: 22,
              fontVariant: "all-petite-caps",
              letterSpacing: 3.5,
            }}
          >
            TASKLARA GÖRE PUANTAJI -{" "}
            {taskTypeStatsModal.selectedOfficeuser?.officeuser?.full_name}{" "}
          </div>
          <div
            style={{
              width: "90%",
              display: "flex",
              background: "gray",
              borderRadius: 2,
              backgroundColor: "#494343",
              flexWrap: "wrap",
              gap: "10px",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              padding: 20,
              height: "90%",
              overflow: "auto",
              maxHeight: "75vh",
            }}
          >
            {ratingTaskTypeStats?.map((item, index) => {
              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "#332e2d",
                    borderRadius: 4,
                    padding: 4,
                  }}
                >
                  <div
                    style={{
                      color: "#fff",
                      margin: "0px 5px",
                      fontFamily: "segoeuiRg",
                    }}
                  >
                    {item?.task_type?.name}
                  </div>

                  <Paper
                    style={{
                      backgroundColor: "#403836",
                      marginLeft: 10,
                      width: 80,
                      color: "#fff",
                      padding: 1,
                      display: "flex",
                      justifyContent: "center",
                      height: 30,
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        fontSize: 10,
                        fontFamily: "segoeuiRg",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        padding: 2,
                      }}
                    >
                      Yeni
                      <div
                        style={{
                          fontSize: 13,
                          textAlign: "center",
                        }}
                      >
                        {item?.new_count}
                      </div>
                    </div>
                  </Paper>
                  <Paper
                    style={{
                      backgroundColor: "#403836",
                      marginLeft: 10,
                      width: 80,
                      color: "#fff",
                      padding: 1,
                      display: "flex",
                      justifyContent: "center",
                      height: 30,
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        fontSize: 10,
                        fontFamily: "segoeuiRg",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        padding: 2,
                      }}
                    >
                      Eğitim
                      <div
                        style={{
                          fontSize: 13,
                          textAlign: "center",
                        }}
                      >
                        {item?.education_count}
                      </div>
                    </div>
                  </Paper>
                  <Paper
                    style={{
                      backgroundColor: "#403836",
                      marginLeft: 10,
                      width: 80,
                      color: "#fff",
                      padding: 1,
                      display: "flex",
                      justifyContent: "center",
                      height: 30,
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        fontSize: 10,
                        fontFamily: "segoeuiRg",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        padding: 2,
                      }}
                    >
                      Tamamlanan
                      <div
                        style={{
                          fontSize: 13,
                          textAlign: "center",
                        }}
                      >
                        {item?.completed_count}
                      </div>
                    </div>
                  </Paper>
                  <Paper
                    style={{
                      backgroundColor: "#403836",
                      marginLeft: 10,
                      width: 80,
                      color: "#fff",
                      padding: 1,
                      display: "flex",
                      justifyContent: "center",
                      height: 30,
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        fontSize: 10,
                        fontFamily: "segoeuiRg",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        padding: 2,
                      }}
                    >
                      Puan Ort.
                      <div
                        style={{
                          fontSize: 13,
                          textAlign: "center",
                        }}
                      >
                        %{item?.average_percentage?.toFixed(0)}
                      </div>
                    </div>
                  </Paper>
                  <IconButton
                    style={{ marginLeft: 2 }}
                    onClick={() => {
                      let data = {
                        sendObj: {
                          officeuser:
                            taskTypeStatsModal.selectedOfficeuser?.officeuser
                              ?.user_id,
                          task_type: item?.task_type?.id,
                        },
                      };
                      cdrRatingTaskTypeCreateFunc(data);
                    }}
                  >
                    <Forward style={{ color: "#fff" }} />
                  </IconButton>
                </div>
              );
            })}
          </div>
        </Card>
      </Modal>
    </>
  );
};

export default TaskTypeStatsModal;
