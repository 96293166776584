import React, { useContext, useEffect, useState } from "react";

import CardFlip from "react-card-flip";
import MainScreen from "./components/mainScreen";
import { ManagementContext } from "../../../contexts/managementContext";
import { Button, Tooltip } from "@mui/material";
import DetailScreen from "./components/detailScreen";
import DatePicker from "react-datepicker";
import CustomDatePickerInput from "../../../components/datePickerInput";
import BasicSpeedDial from "./components/speedDial";
import moment from "moment";
import { RestaurantContext } from "../../../contexts/restaurantContext";
import Select from "react-select";
import { selectDefaultStyles } from "../../../components/selectStyles/stylev2";
import LottieMain from "../../../components/lottie";
import SummaryScreen from "./components/summaryScreen";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import AddOfficeUserModal from "./addOfficeUserModal";
import SantralScreen from "./components/santralScreen";
import PuantajScreen from "./components/puantajScreen";
import { GreenSwitch } from "../../../components/switch/greenSwitch";
import MainTechScreen from "./components/mainTechScreen";
import RenewealSummaryScreen from "./components/renewalSummaryScreen";

const UserDashboard = () => {
  const [callerFlipped, setCallerFlipped] = useState(false);
  const {
    dashboardOfficeuser,
    dashboardOfficeuserList,
    dashboardOfficeuserDetailActiveStatus,
    dashboardOfficeuserDetail,
    dashboardOfficeuserDetailFunc,
    dashboardOfficeuserTech,
    dashboardLoader,
    dashboardOfficeuserTechDetailFunc,
    stateHistoryPastListFunc,
    stateHistoryPastList,
    dashboardOfficeuserDetailTaskLock,
    showAddUserModal,
    setShowAddUserModal,
  } = useContext(ManagementContext);
  const [dashboardUserInfo, setDashboardUserInfo] = useState(null);
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [startDate, endDate] = dateRange;
  const { getOfficeUserListWithRoleCollector, backofficeCollectorList } =
    useContext(RestaurantContext);
  const [selectedUser, setSelectedUser] = useState(null);
  const [backofficeCollectorListWithAll, setBackofficeCollectorListWithAll] =
    useState([]);
  const [dashboardType, setDashboardType] = useState("collector");
  const [openToolTip, setOpenToolTip] = useState(false);

  useEffect(() => {
    if (
      dashboardType !== "renewal_summary" &&
      dashboardType !== "summary" &&
      dashboardType !== "santral"
    ) {
      if (dashboardType === "collector") {
        dashboardOfficeuser();
      } else {
        dashboardOfficeuserTech();
      }
    }
    getOfficeUserListWithRoleCollector({
      role:
        dashboardType === "summary" ||
        dashboardType === "renewal_summary" ||
        dashboardType === "santral"
          ? "collector"
          : dashboardType,
      task_types: "",
    });
  }, [dashboardType]);

  useEffect(() => {
    if (backofficeCollectorList) {
      const newUserList = [...backofficeCollectorList];
      newUserList.unshift({ full_name: "Hepsi", user_id: 0 });
      setBackofficeCollectorListWithAll(newUserList);
    }
  }, [backofficeCollectorList]);

  useEffect(() => {
    if (dashboardOfficeuserList !== null) {
      dashboardOfficeuserList.map((row, i) => {
        if (!row.hasOwnProperty("current_date")) {
          row.current_date = moment();
        }
      });
    }
  }, [dashboardOfficeuserList]);

  useEffect(() => {
    if (selectedUser && startDate && endDate && callerFlipped) {
      setDashboardUserInfo(selectedUser);
      let data = {
        user_id: selectedUser?.user_id,
        startDate: moment(startDate).format("YYYY-MM-DD"),
        endDate: moment(endDate).format("YYYY-MM-DD"),
      };
      if (dashboardType === "collector") {
        dashboardOfficeuserDetailFunc(data);
      } else {
        dashboardOfficeuserTechDetailFunc(data);
      }
    }
  }, [selectedUser, startDate, endDate]);

  const handleChangeUserStatus = async () => {
    let data = {
      user_id: dashboardUserInfo.user_id,
      obj: {
        active: !dashboardOfficeuserDetail.user_active,
      },
    };

    let data2 = {
      user_id: dashboardUserInfo?.user_id,
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
    };
    dashboardOfficeuserDetailActiveStatus(data, data2);
  };

  const handleChangeDiscountPermission = async () => {
    let data = {
      user_id: dashboardUserInfo.user_id,
      obj: {
        price_update_allowed: !dashboardOfficeuserDetail.price_update_allowed,
      },
    };

    let data2 = {
      user_id: dashboardUserInfo?.user_id,
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
    };
    dashboardOfficeuserDetailTaskLock(data, data2);
  };

  const handleChangeShowSummaryPermission = async () => {
    let data = {
      user_id: dashboardUserInfo.user_id,
      obj: {
        show_summary: !dashboardOfficeuserDetail.show_summary,
      },
    };

    let data2 = {
      user_id: dashboardUserInfo?.user_id,
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
    };
    dashboardOfficeuserDetailTaskLock(data, data2);
  };

  const handleChangeIqcellVerimor = async () => {
    let data = {
      user_id: dashboardUserInfo.user_id,
      obj: {
        iqcell_verimor: !dashboardOfficeuserDetail.iqcell_verimor,
      },
    };

    let data2 = {
      user_id: dashboardUserInfo?.user_id,
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
    };
    dashboardOfficeuserDetailTaskLock(data, data2);
  };

  return (
    <div
      style={{ minHeight: "100%", backgroundColor: "#000", overflow: "auto" }}
    >
      {dashboardLoader ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 650,
          }}
        >
          <LottieMain />
        </div>
      ) : (
        <>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: "1px solid rgba(255,255,255,0.4)",
              marginBottom: 5,
              paddingTop: 5,
              paddingBottom: 5,
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Button
                style={{
                  border: "1px solid rgba(255,255,255,0.5)",
                  color: "#fff",
                  marginRight: 10,
                  background:
                    dashboardType === "collector" ? "darkslategray" : "",
                  textTransform: "capitalize",
                }}
                onClick={() => {
                  setDashboardType("collector");
                  setCallerFlipped(false);
                }}
              >
                Tahsilat Liste
              </Button>

              <Button
                style={{
                  border: "1px solid rgba(255,255,255,0.5)",
                  color: "#fff",
                  background: dashboardType === "tech" ? "darkslategray" : "",
                  marginRight: 10,
                  textTransform: "capitalize",
                }}
                onClick={() => {
                  setDashboardType("tech");
                  setCallerFlipped(false);
                }}
              >
                Teknik Liste
              </Button>

              <Button
                style={{
                  border: "1px solid rgba(255,255,255,0.5)",
                  color: "#fff",
                  background:
                    dashboardType === "summary" ? "darkslategray" : "",
                  marginRight: 10,
                  textTransform: "capitalize",
                }}
                onClick={() => {
                  setDashboardType("summary");
                  setCallerFlipped(false);
                }}
              >
                Tahsilat Liste
              </Button>
              <Button
                style={{
                  border: "1px solid rgba(255,255,255,0.5)",
                  color: "#fff",
                  background:
                    dashboardType === "renewal_summary" ? "darkslategray" : "",
                  textTransform: "capitalize",
                }}
                onClick={() => {
                  setDashboardType("renewal_summary");
                  setCallerFlipped(false);
                }}
              >
                Söz Yenileme Liste
              </Button>

              {callerFlipped && (
                <>
                  <div style={{ marginLeft: 10, marginRight: 10 }}>
                    <DatePicker
                      dateFormat="yyyy-MM-dd"
                      fixedHeight="35px"
                      selectsRange={true}
                      startDate={startDate}
                      endDate={endDate}
                      maxDate={new Date()}
                      onChange={(update) => {
                        setDateRange(update);
                      }}
                      customInput={
                        <CustomDatePickerInput
                          selectWidth={230}
                          color={"#393737"}
                        />
                      }
                      locale="tr"
                    />
                  </div>

                  <Select
                    styles={{
                      ...selectDefaultStyles,
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                    options={backofficeCollectorListWithAll}
                    darkMode={true}
                    responsiveHeight={"auto"}
                    selectFontSize={14}
                    menuColor={"#f4f4f4"}
                    hoverColor={"#f53b62"}
                    isSearchable={false}
                    selectWidth={"150px"}
                    inputColor={"#f4f4f4"}
                    textColor={"black"}
                    placeHolderColor={"#f53b62"}
                    inputFontSize={14}
                    dropdownColor={"#f53b62"}
                    isClearable={false}
                    closeMenuOnSelect={true}
                    placeholder="Kişi"
                    getOptionLabel={(option) => option.full_name}
                    getOptionValue={(option) => option.user_id}
                    value={selectedUser}
                    onChange={(e) => setSelectedUser(e)}
                  />
                </>
              )}
              <Button
                style={{
                  border: "1px solid rgba(255,255,255,0.5)",
                  color: "#fff",
                  background:
                    dashboardType === "santral" ? "darkslategray" : "",
                  marginLeft: 10,
                  textTransform: "capitalize",
                }}
                onClick={() => {
                  setDashboardType("santral");
                  setCallerFlipped(false);
                }}
              >
                Santral Raporlar
              </Button>
              <Button
                style={{
                  border: "1px solid rgba(255,255,255,0.5)",
                  color: "#fff",
                  background:
                    dashboardType === "puantaj" ? "darkslategray" : "",
                  marginLeft: 10,
                  textTransform: "capitalize",
                }}
                onClick={() => {
                  setDashboardType("puantaj");
                  setCallerFlipped(false);
                }}
              >
                Puantaj Cetveli
              </Button>
            </div>

            {!callerFlipped && (
              <div style={{ marginRight: 10, display: "flex" }}>
                <Tooltip title="Kullanıcı Ekle">
                  <PersonAddAltIcon
                    style={{
                      color: "#fff",
                      padding: 5,
                      borderRadius: "9999px",
                      border: "1px solid rgba(255,255,255,0.5)",
                      cursor: "pointer",
                      boxShadow: "0px 0px 6px 1px",
                    }}
                    onClick={() => setShowAddUserModal(true)}
                  />
                </Tooltip>
              </div>
            )}

            {callerFlipped && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <BasicSpeedDial
                  dashboardOfficeuserDetail={dashboardOfficeuserDetail}
                  setCallerFlipped={setCallerFlipped}
                  startDate={startDate}
                  endDate={endDate}
                  backofficeCollectorList={backofficeCollectorList}
                />
                <Tooltip title="Gsm Aç/Kapat">
                  <GreenSwitch
                    checked={!dashboardOfficeuserDetail?.iqcell_verimor}
                    onChange={handleChangeIqcellVerimor}
                  />
                </Tooltip>
                <Tooltip title="Özet Aç/Kapat">
                  <GreenSwitch
                    checked={dashboardOfficeuserDetail?.show_summary}
                    onChange={handleChangeShowSummaryPermission}
                  />
                </Tooltip>
                <Tooltip title="İndirimi Kendisi Yapsın">
                  <GreenSwitch
                    checked={dashboardOfficeuserDetail?.price_update_allowed}
                    onChange={handleChangeDiscountPermission}
                  />
                </Tooltip>
                <Tooltip title="Aktiflik Durumu">
                  <GreenSwitch
                    checked={dashboardOfficeuserDetail?.user_active}
                    onChange={handleChangeUserStatus}
                  />
                </Tooltip>
              </div>
            )}
          </div>
          {dashboardType === "collector" || dashboardType === "tech" ? (
            <CardFlip
              isFlipped={callerFlipped}
              containerStyle={{
                width: "100%",
                height: "calc(96vh - 120px)",
                overflow: "auto",
              }}
            >
              <div>
                {dashboardType === "collector" ? (
                  <MainScreen
                    setCallerFlipped={setCallerFlipped}
                    dashboardOfficeuserList={dashboardOfficeuserList}
                    setDashboardUserInfo={setDashboardUserInfo}
                    setSelectedUser={setSelectedUser}
                    dashboardType={dashboardType}
                    stateHistoryPastListFunc={stateHistoryPastListFunc}
                    openToolTip={openToolTip}
                    setOpenToolTip={setOpenToolTip}
                  />
                ) : (
                  <MainTechScreen
                    setCallerFlipped={setCallerFlipped}
                    dashboardOfficeuserList={dashboardOfficeuserList}
                    setDashboardUserInfo={setDashboardUserInfo}
                    setSelectedUser={setSelectedUser}
                    dashboardType={dashboardType}
                    stateHistoryPastListFunc={stateHistoryPastListFunc}
                    openToolTip={openToolTip}
                    setOpenToolTip={setOpenToolTip}
                  />
                )}
              </div>

              <div>
                <DetailScreen
                  dashboardUserInfo={dashboardUserInfo}
                  setCallerFlipped={setCallerFlipped}
                  startDate={startDate}
                  endDate={endDate}
                  dashboardOfficeuserDetail={dashboardOfficeuserDetail}
                  setDateRange={setDateRange}
                  selectedUser={selectedUser}
                  dashboardType={dashboardType}
                  stateHistoryPastListFunc={stateHistoryPastListFunc}
                  stateHistoryPastList={stateHistoryPastList}
                />
              </div>
            </CardFlip>
          ) : dashboardType === "summary" ? (
            <SummaryScreen
              dashboardType={dashboardType}
              backofficeCollectorListWithAll={backofficeCollectorListWithAll}
            />
          ) : dashboardType === "santral" ? (
            <SantralScreen
              dashboardType={dashboardType}
              backofficeCollectorListWithAll={backofficeCollectorListWithAll}
            />
          ) : dashboardType === "puantaj" ? (
            <PuantajScreen />
          ) : (
            <RenewealSummaryScreen
              dashboardType={dashboardType}
              backofficeCollectorListWithAll={backofficeCollectorListWithAll}
            />
          )}
        </>
      )}

      {showAddUserModal && (
        <AddOfficeUserModal
          showAddUserModal={showAddUserModal}
          setShowAddUserModal={setShowAddUserModal}
        />
      )}
    </div>
  );
};

export default UserDashboard;
