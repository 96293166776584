import React, { useContext, useEffect, useState } from "react";
import { RestaurantContext } from "../../../contexts/restaurantContext";
import Select from "react-select";
import { selectDefaultStyles } from "../../../components/selectStyles/stylev3";
import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
} from "@mui/material";
import { offerCount } from "../../../utils/constants";
import { Notifications } from "@mui/icons-material";
import TaskReminder from "../../../components/modals/taskReminder";

const TaskDashboard = () => {
  const {
    getApplicationTransferList,
    backofficeList,
    taskDashboardFunc,
    taskDashboardData,
    taskDashboardPatchFunc,
    setTaskDashboardData,
    changeOfficeUserProducts,
  } = useContext(RestaurantContext);
  const [selectedBackofficeUser, setSelectedBackofficeUser] = useState(null);
  const [reminderModal, setReminderModal] = useState(false);
  const [reminderRow, setReminderRow] = useState(null);

  useEffect(() => {
    setTaskDashboardData(null);
    getApplicationTransferList();
  }, []);

  useEffect(() => {
    if (selectedBackofficeUser !== null) {
      taskDashboardFunc({
        user_id: selectedBackofficeUser?.user_id,
      });
    }
  }, [selectedBackofficeUser]);

  useEffect(() => {
    if (backofficeList != null) {
      backofficeList.unshift({ full_name: "-", user_id: 0 });
    }
  }, [backofficeList]);

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <div style={{ width: "98%", height: "98%" }}>
          <div>
            <span
              style={{
                fontSize: 20,
                marginBottom: 10,
                display: "block",
                fontWeight: "600",
                letterSpacing: 0.8,
              }}
            >
              Kişiler
            </span>
            <Select
              options={backofficeList}
              styles={{ ...selectDefaultStyles }}
              isSearchable={true}
              hoverColor={"black"}
              menuColor={"#f53b62"}
              selectWidth={250}
              placeholder={"-"}
              value={selectedBackofficeUser}
              getOptionValue={(option) => option.user_id}
              onChange={(e) => {
                setSelectedBackofficeUser(e);
              }}
              getOptionLabel={(option) => option.full_name}
            />
          </div>

          {selectedBackofficeUser && (
            <div style={{ margin: "20px 0px" }}>
              <span
                style={{
                  fontSize: 20,
                  marginBottom: 10,
                  display: "block",
                  fontWeight: "600",
                  letterSpacing: 0.8,
                }}
              >
                Ürünler
              </span>

              <div
                style={{
                  display: "flex",
                  overflow: "auto",
                  paddingBottom: 5,
                }}
              >
                {taskDashboardData?.officeuser_product_applications?.map(
                  (product, i) => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: 5,
                        borderRadius: 5,
                        backgroundColor: product.active ? "#f53b62" : "#fff",
                        color: product.active ? "white" : "#f53b62",
                        fontWeight: "600",
                        letterSpacing: 0.8,
                        textAlign: "center",
                        padding: 5,
                        textTransform: "capitalize",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        const taskDashboardDataCopy = [
                          ...taskDashboardData.officeuser_product_applications,
                        ];
                        taskDashboardDataCopy[i].active = !product.active;
                        setTaskDashboardData({
                          ...taskDashboardData,
                          officeuser_product_applications:
                            taskDashboardDataCopy,
                        });
                      }}
                    >
                      {product.key}
                    </div>
                  )
                )}
              </div>

              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  variant="contained"
                  size="small"
                  style={{
                    fontFamily: "segoeuiB",
                    width: 160,
                    fontWeight: 700,
                    backgroundColor: "#0FBD20",
                  }}
                  onClick={() => {
                    const productApplications =
                      taskDashboardData?.officeuser_product_applications.filter(
                        (product) => product.active
                      );

                    const filteredArr = productApplications.map((p) => p.id);
                    const data = {
                      product_applications: filteredArr,
                    };
                    changeOfficeUserProducts(
                      selectedBackofficeUser?.user_id,
                      data
                    );
                  }}
                >
                  Kaydet
                </Button>
              </div>
            </div>
          )}

          <div style={{ marginTop: 10 }}>
            {taskDashboardData !== null && (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Görev Tipi</TableCell>
                      <TableCell align="center">Aktiflik</TableCell>
                      <TableCell align="center">Adet</TableCell>
                      <TableCell align="center">Yönlendir</TableCell>
                      <TableCell align="center">Öncelik</TableCell>
                      <TableCell align="center">Takvim Max Gün</TableCell>
                      <TableCell align="center">Hatırlatıcı</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {taskDashboardData?.officeuser_task_types?.map(
                      (row, ind) => {
                        return (
                          <TableRow key={ind}>
                            <TableCell
                              align="center"
                              style={{
                                backgroundColor:
                                  (row?.task_type?.name ===
                                    "Farklı Fiyat Talebi" ||
                                    row?.task_type?.name === "Bloke Talebi" ||
                                    row?.task_type?.name === "Bloke" ||
                                    row?.task_type?.name ===
                                      "Sözleşme Yenileme") &&
                                  "#cfcf52",
                              }}
                            >
                              {row?.task_type?.name}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                textAlign: "-webkit-center",
                                backgroundColor:
                                  (row?.task_type?.name ===
                                    "Farklı Fiyat Talebi" ||
                                    row?.task_type?.name === "Bloke Talebi" ||
                                    row?.task_type?.name === "Bloke" ||
                                    row?.task_type?.name ===
                                      "Sözleşme Yenileme") &&
                                  "#cfcf52",
                              }}
                            >
                              <Select
                                options={[
                                  { value: true, label: "Aktif" },
                                  { value: false, label: "Pasif" },
                                ]}
                                styles={{ ...selectDefaultStyles }}
                                isSearchable={true}
                                hoverColor={"black"}
                                menuColor={"#f53b62"}
                                selectWidth={120}
                                placeholder={"-"}
                                value={{
                                  value: row?.active,
                                  label: row?.active ? "Aktif" : "Pasif",
                                }}
                                onChange={(e) => {
                                  taskDashboardPatchFunc({
                                    user_id: selectedBackofficeUser?.user_id,
                                    task_type_id: row?.id,
                                    sendObj: { active: e.value },
                                  });
                                }}
                                getOptionValue={(option) => option.value}
                                getOptionLabel={(option) => option.label}
                              />
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                backgroundColor:
                                  (row?.task_type?.name ===
                                    "Farklı Fiyat Talebi" ||
                                    row?.task_type?.name === "Bloke Talebi" ||
                                    row?.task_type?.name === "Bloke" ||
                                    row?.task_type?.name ===
                                      "Sözleşme Yenileme") &&
                                  "#cfcf52",
                              }}
                            >
                              {row?.count}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                textAlign: "-webkit-center",
                                backgroundColor:
                                  (row?.task_type?.name ===
                                    "Farklı Fiyat Talebi" ||
                                    row?.task_type?.name === "Bloke Talebi" ||
                                    row?.task_type?.name === "Bloke" ||
                                    row?.task_type?.name ===
                                      "Sözleşme Yenileme") &&
                                  "#cfcf52",
                              }}
                            >
                              <Select
                                options={backofficeList}
                                styles={{ ...selectDefaultStyles }}
                                isSearchable={true}
                                hoverColor={"black"}
                                menuColor={"#f53b62"}
                                selectWidth={240}
                                placeholder={"-"}
                                value={row?.transfer}
                                onChange={(e) => {
                                  taskDashboardPatchFunc({
                                    user_id: selectedBackofficeUser?.user_id,
                                    task_type_id: row?.id,
                                    sendObj: { transfer: e.user_id },
                                  });
                                }}
                                getOptionValue={(option) => option.user_id}
                                getOptionLabel={(option) => option.full_name}
                              />
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                textAlign: "-webkit-center",
                                backgroundColor:
                                  (row?.task_type?.name ===
                                    "Farklı Fiyat Talebi" ||
                                    row?.task_type?.name === "Bloke Talebi" ||
                                    row?.task_type?.name === "Bloke" ||
                                    row?.task_type?.name ===
                                      "Sözleşme Yenileme") &&
                                  "#cfcf52",
                              }}
                            >
                              <Select
                                options={offerCount}
                                styles={{ ...selectDefaultStyles }}
                                isSearchable={true}
                                hoverColor={"black"}
                                menuColor={"#f53b62"}
                                selectWidth={80}
                                placeholder={"-"}
                                value={{
                                  value: row?.priority,
                                  label: row?.priority,
                                }}
                                onChange={(e) => {
                                  taskDashboardPatchFunc({
                                    user_id: selectedBackofficeUser?.user_id,
                                    task_type_id: row?.id,
                                    sendObj: { priority: e.value },
                                  });
                                }}
                                getOptionValue={(option) => option.value}
                                getOptionLabel={(option) => option.label}
                              />
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                textAlign: "-webkit-center",
                                backgroundColor:
                                  (row?.task_type?.name ===
                                    "Farklı Fiyat Talebi" ||
                                    row?.task_type?.name === "Bloke Talebi" ||
                                    row?.task_type?.name === "Bloke" ||
                                    row?.task_type?.name ===
                                      "Sözleşme Yenileme") &&
                                  "#cfcf52",
                              }}
                            >
                              <Select
                                options={offerCount}
                                styles={{ ...selectDefaultStyles }}
                                isSearchable={true}
                                hoverColor={"black"}
                                menuColor={"#f53b62"}
                                selectWidth={80}
                                placeholder={"-"}
                                menuPlacement="top"
                                value={{
                                  value: row?.max_allowed_days,
                                  label: row?.max_allowed_days,
                                }}
                                onChange={(e) => {
                                  taskDashboardPatchFunc({
                                    user_id: selectedBackofficeUser?.user_id,
                                    task_type_id: row?.id,
                                    sendObj: { max_allowed_days: e.value },
                                  });
                                }}
                                getOptionValue={(option) => option.value}
                                getOptionLabel={(option) => option.label}
                              />
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                textAlign: "-webkit-center",
                                backgroundColor:
                                  (row?.task_type?.name ===
                                    "Farklı Fiyat Talebi" ||
                                    row?.task_type?.name === "Bloke Talebi" ||
                                    row?.task_type?.name === "Bloke" ||
                                    row?.task_type?.name ===
                                      "Sözleşme Yenileme") &&
                                  "#cfcf52",
                              }}
                            >
                              <Notifications
                                style={{
                                  cursor: "pointer",
                                  color:
                                    row?.reminders?.length !== 0
                                      ? row?.reminders?.some((row1) => {
                                          return row1?.is_read === false;
                                        })
                                        ? "red"
                                        : "green"
                                      : "black",
                                }}
                                onClick={() => {
                                  setReminderRow(row);
                                  setReminderModal(true);
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      }
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>
        </div>
      </div>
      <TaskReminder
        reminderModal={reminderModal}
        setReminderModal={setReminderModal}
        reminderRow={reminderRow}
        setReminderRow={setReminderRow}
        fetchData={selectedBackofficeUser}
      />
    </>
  );
};

export default TaskDashboard;
