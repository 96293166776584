import React, { useContext, useEffect, useState } from "react";
import { ManagementContext } from "../../../../contexts/managementContext";
import {
  Button,
  Divider,
  InputBase,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import managementService from "../../../../services/managementService";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { GreenSwitch } from "../../../../components/switch/greenSwitch";
import { RestaurantContext } from "../../../../contexts/restaurantContext";
import Select from "react-select";
import { selectDefaultStyles } from "../../../../components/selectStyles/stylev3";
import { useToast } from "../../../../components/snackbar";

const totalBundleCount = (products) => {
  const options = [];
  for (let i = 2; i <= products.length; i++) {
    options.push({
      key: i,
      label: i,
    });
  }
  return options;
};

export default function PrimSettingsV2() {
  const { success, alert } = useToast();
  const {
    getProductPrimSettings,
    selectedProductPrimSettings,
    setSelectedProductPrimSettings,
    sendPrimSettings,
    getExtraPrimSettings,
    extraPrimSettings,
    setExtraPrimSettings,
    addExtraPrimSettings,
  } = useContext(ManagementContext);
  const { getApplicationTransferList, backofficeList } =
    useContext(RestaurantContext);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [productsList, setProductsList] = useState([]);
  const [blokePrimLimit, setBlokePrimLimit] = useState(0);
  const [selectedUser, setSelectedUser] = useState({
    user_id: 0,
    full_name: "Tümü",
  });
  const [usersSetupPrims, setUsersSetupPrims] = useState([]);
  const [setupSettings, setSetupSettings] = useState([]);
  const [negativeTreshold, setNegativeTreshold] = useState(0);
  const [selectedBundleProducts, setSelectedBundleProducts] = useState([]);
  const [bundlePrimSettings, setBundlePrimSettings] = useState([]);
  const [selectedBundleCount, setSelectedBundleCount] = useState(null);
  const [selectedBundlePrimSettings, setSelectedBundlePrimSettings] = useState(
    []
  );

  const getUsersSetupPrim = async () => {
    const res = await managementService.get_users_setup_prim();
    setUsersSetupPrims(res);
  };

  useEffect(() => {
    (async () => {
      const res = await managementService.get_product_list();
      setProductsList(res);
    })();

    getUsersSetupPrim();
    getExtraPrimSettings();
    getApplicationTransferList();
    getBundleProducts();
    getBundlePrimSettings();
  }, []);

  useEffect(() => {
    backofficeList.unshift({
      user_id: 0,
      full_name: "Tümü",
    });
  }, [backofficeList]);

  useEffect(() => {
    if (selectedProductPrimSettings?.length === 0) {
      setSelectedProductPrimSettings([
        {
          prim_percent_range: [],
          contract_renewal_percent_range: [],
        },
      ]);
    }
  }, [selectedProductPrimSettings]);

  useEffect(() => {
    if (extraPrimSettings) {
      setBlokePrimLimit(extraPrimSettings.bloke_prim_limit);
    }

    if (extraPrimSettings?.setup_prim) {
      setSetupSettings(extraPrimSettings.setup_prim);
    }
  }, [extraPrimSettings]);

  const handleAddNewPrim = () => {
    const newObj = {
      start: "",
      end: "",
      prim: "",
    };
    const newPrimSettings = [...selectedProductPrimSettings];
    newPrimSettings[0].prim_percent_range.push(newObj);
    setSelectedProductPrimSettings(newPrimSettings);
  };

  const handleAddNewRenewalPrim = () => {
    const newObj = {
      start: "",
      end: "",
      prim: "",
    };
    const newPrimSettings = [...selectedProductPrimSettings];
    newPrimSettings[0].contract_renewal_percent_range.push(newObj);
    setSelectedProductPrimSettings(newPrimSettings);
  };

  const handleAddNewExtraPrim = () => {
    const newObj = {
      start: "",
      end: "",
      prim: "",
    };
    const newPrimSettings = { ...extraPrimSettings };
    newPrimSettings.extra_prim.push(newObj);
    setExtraPrimSettings(newPrimSettings);
  };

  const handleAddNewSetupPrim = () => {
    const newObj = {
      start: "",
      end: "",
      prim: "",
    };
    if (!selectedUser) {
      const newPrimSettings = { ...extraPrimSettings };
      newPrimSettings.setup_prim.push(newObj);
      setExtraPrimSettings(newPrimSettings);
    } else {
      const newSetupSettings = [...setupSettings];
      newSetupSettings.push(newObj);
      setSetupSettings(newSetupSettings);
    }
  };

  const handleSavePrimSettings = () => {
    let data = {
      product: selectedProduct.id,
      prim_percent_range: selectedProductPrimSettings[0].prim_percent_range,
      contract_renewal_percent_range:
        selectedProductPrimSettings[0].contract_renewal_percent_range,
      officeusers: [],
    };
    if (selectedProductPrimSettings[0].id) {
      data = {
        ...data,
        id: selectedProductPrimSettings[0].id,
      };
    }
    sendPrimSettings(data);
  };

  const handleSaveExtraPrimSettings = async () => {
    if (selectedUser.user_id === 0) {
      const data = {
        bloke_prim: extraPrimSettings.bloke_prim
          ? extraPrimSettings.bloke_prim
          : false,
        bloke_prim_limit: blokePrimLimit ? blokePrimLimit : 0,
        extra_prim: extraPrimSettings.extra_prim
          ? extraPrimSettings.extra_prim
          : [],
        setup_prim: setupSettings ? setupSettings : [],
      };
      addExtraPrimSettings(data);
    } else {
      const data = {
        officeuser: selectedUser.user_id,
        setup_prim: setupSettings,
        negative_transaction_treshold: negativeTreshold,
      };
      try {
        await managementService.add_user_setup_prim(data);
        success("Primler başarıyla kaydedildi.");
        getUsersSetupPrim();
        getExtraPrimSettings();
        setSelectedUser({
          user_id: 0,
          full_name: "Tümü",
        });
      } catch (error) {
        alert(error?.response?.data?.message);
      }
    }
  };

  const getUserPrimSettings = (e) => {
    if (e.user_id === 0) {
      setSetupSettings(extraPrimSettings?.setup_prim);
    } else {
      const user = usersSetupPrims.find(
        (user) => user.officeuser_id === e.user_id
      );
      if (user) {
        setSetupSettings(user?.setup_prim);
        setNegativeTreshold(user?.negative_transaction_treshold);
      } else {
        setSetupSettings([]);
        setNegativeTreshold(0);
      }
    }
  };

  const handleSaveBundleProducts = async () => {
    try {
      const data = {
        product_ids: selectedBundleProducts,
      };
      await managementService.set_bundle_products(data);
      getBundleProducts();
      success("Bundle ürünleri başarıyla kaydedildi.");
    } catch (error) {
      alert(error?.response?.data?.message);
    }
  };

  const getBundleProducts = async () => {
    try {
      const res = await managementService.get_bundle_products();
      const products = res.map((product) => product.id);
      setSelectedBundleProducts(products);
    } catch (error) {
      alert(error?.response?.data?.message);
    }
  };

  const getBundlePrimSettings = async () => {
    try {
      const res = await managementService.get_bundle_prim_settings();
      setBundlePrimSettings(res);
    } catch (error) {
      alert(error?.response?.data?.message);
    }
  };

  const handleAddNewBundlePrim = () => {
    const newObj = {
      start: "",
      end: "",
      prim: "",
    };
    const newPrimSettings = [...selectedBundlePrimSettings];
    newPrimSettings.push(newObj);
    setSelectedBundlePrimSettings(newPrimSettings);
  };

  const handleSaveBundlePrimSettings = async () => {
    const data = {
      count: selectedBundleCount.key,
      extra_prim: selectedBundlePrimSettings,
    };

    try {
      await managementService.add_bundle_prim_settings(data);
      success("Bundle primleri başarıyla kaydedildi.");
      getBundlePrimSettings();
    } catch (error) {
      alert(error?.response?.data?.message);
    }
  };

  return (
    <div>
      <Paper
        style={{ padding: 20, borderRadius: 8, marginBottom: 20 }}
        elevation={3}
      >
        <div
          style={{
            display: "flex",
            padding: 10,
            borderRadius: 4,
          }}
        >
          <div style={{ width: "50%", marginRight: 30 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h3>Ekstra Primler</h3>
              <Tooltip title="Ekstra Prim Ekle">
                <AddCircleOutlineIcon
                  style={{ color: "#f53b62", fontSize: 34, cursor: "pointer" }}
                  onClick={handleAddNewExtraPrim}
                />
              </Tooltip>
            </div>

            <div>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Başlangıç</TableCell>
                      <TableCell align="center">Bitiş</TableCell>
                      <TableCell align="center">Prim %</TableCell>
                      <TableCell align="center">Aksiyon</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {extraPrimSettings?.extra_prim?.map((prim, ind) => {
                      return (
                        <TableRow key={ind}>
                          <TableCell align="center">
                            <InputBase
                              variant="filled"
                              type="number"
                              style={{
                                width: 90,
                                color: "black",
                                fontSize: 15,
                                fontWeight: "400",
                                fontFamily: "segoeuiRg",
                                height: 40,
                                backgroundColor: "#f4f4f4",
                                textAlignLast: "center",
                              }}
                              value={prim.start}
                              onChange={(e) => {
                                const newPrimSettings = {
                                  ...extraPrimSettings,
                                };
                                newPrimSettings.extra_prim[ind].start =
                                  e.target.value;
                                setExtraPrimSettings(newPrimSettings);
                              }}
                            />
                          </TableCell>
                          <TableCell align="center">
                            <InputBase
                              variant="filled"
                              type="number"
                              style={{
                                width: 90,
                                color: "black",
                                fontSize: 15,
                                fontWeight: "400",
                                fontFamily: "segoeuiRg",
                                height: 40,
                                backgroundColor: "#f4f4f4",
                                textAlignLast: "center",
                              }}
                              value={prim.end}
                              onChange={(e) => {
                                const newPrimSettings = {
                                  ...extraPrimSettings,
                                };
                                newPrimSettings.extra_prim[ind].end =
                                  e.target.value;
                                setExtraPrimSettings(newPrimSettings);
                              }}
                            />
                          </TableCell>
                          <TableCell align="center">
                            <InputBase
                              variant="filled"
                              type="number"
                              style={{
                                width: 90,
                                color: "black",
                                fontSize: 15,
                                fontWeight: "400",
                                fontFamily: "segoeuiRg",
                                height: 40,
                                backgroundColor: "#f4f4f4",
                                textAlignLast: "center",
                              }}
                              value={prim.prim}
                              onChange={(e) => {
                                const newPrimSettings = {
                                  ...extraPrimSettings,
                                };
                                newPrimSettings.extra_prim[ind].prim =
                                  e.target.value;
                                setExtraPrimSettings(newPrimSettings);
                              }}
                            />
                          </TableCell>
                          <TableCell align="center">
                            <Tooltip title="Sil">
                              <DeleteForeverIcon
                                style={{
                                  color: "#f53b62",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  const newPrimSettings = {
                                    ...extraPrimSettings,
                                  };
                                  newPrimSettings.extra_prim.splice(ind, 1);
                                  setExtraPrimSettings(newPrimSettings);
                                }}
                              />
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>

          <div style={{ width: "50%", marginRight: 30 }}>
            <h3>Kurulum Primler</h3>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 20,
              }}
            >
              <div>
                <span
                  style={{
                    fontSize: 16,
                    marginBottom: 5,
                    display: "block",
                    fontWeight: "600",
                  }}
                >
                  Kişi Seç
                </span>
                <Select
                  menuPosition="fixed"
                  menuPortalTarget={document.body}
                  responsiveHeight={44}
                  selectFontSize={16}
                  selectWidth={200}
                  options={backofficeList}
                  styles={selectDefaultStyles}
                  isSearchable={false}
                  zIndex={90}
                  hoverColor={"#f53b62"}
                  menuColor={"#f53b62"}
                  placeholder={"Kişi"}
                  value={selectedUser}
                  getOptionValue={(option) => option.id}
                  onChange={(e) => {
                    setSelectedUser(e);
                    getUserPrimSettings(e);
                  }}
                  getOptionLabel={(e) => (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {e.full_name}
                    </div>
                  )}
                />
              </div>

              <Tooltip title="Kurulum Prim Ekle">
                <AddCircleOutlineIcon
                  style={{ color: "#f53b62", fontSize: 34, cursor: "pointer" }}
                  onClick={handleAddNewSetupPrim}
                />
              </Tooltip>
            </div>

            {selectedUser.user_id !== 0 && (
              <div style={{ marginBottom: 10 }}>
                <span
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 5,
                    fontWeight: 600,
                  }}
                >
                  Muaf Tutulacak Tahsilat Limiti
                </span>
                <InputBase
                  variant="filled"
                  type="number"
                  style={{
                    width: 150,
                    color: "black",
                    fontSize: 15,
                    fontWeight: "400",
                    fontFamily: "segoeuiRg",
                    height: 40,
                    backgroundColor: "#fff",
                    padding: 5,
                  }}
                  value={negativeTreshold}
                  onChange={(e) => {
                    setNegativeTreshold(e.target.value);
                  }}
                />
              </div>
            )}

            <div>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Başlangıç</TableCell>
                      <TableCell align="center">Bitiş</TableCell>
                      <TableCell align="center">Prim %</TableCell>
                      <TableCell align="center">Aksiyon</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {setupSettings?.map((prim, ind) => {
                      return (
                        <TableRow key={ind}>
                          <TableCell align="center">
                            <InputBase
                              variant="filled"
                              type="number"
                              style={{
                                width: 90,
                                color: "black",
                                fontSize: 15,
                                fontWeight: "400",
                                fontFamily: "segoeuiRg",
                                height: 40,
                                backgroundColor: "#f4f4f4",
                                textAlignLast: "center",
                              }}
                              value={prim.start}
                              onChange={(e) => {
                                const newPrimSettings = [...setupSettings];
                                newPrimSettings[ind].start = e.target.value;
                                setSetupSettings(newPrimSettings);
                              }}
                            />
                          </TableCell>
                          <TableCell align="center">
                            <InputBase
                              variant="filled"
                              type="number"
                              style={{
                                width: 90,
                                color: "black",
                                fontSize: 15,
                                fontWeight: "400",
                                fontFamily: "segoeuiRg",
                                height: 40,
                                backgroundColor: "#f4f4f4",
                                textAlignLast: "center",
                              }}
                              value={prim.end}
                              onChange={(e) => {
                                const newPrimSettings = [...setupSettings];
                                newPrimSettings[ind].end = e.target.value;
                                setSetupSettings(newPrimSettings);
                              }}
                            />
                          </TableCell>
                          <TableCell align="center">
                            <InputBase
                              variant="filled"
                              type="number"
                              style={{
                                width: 90,
                                color: "black",
                                fontSize: 15,
                                fontWeight: "400",
                                fontFamily: "segoeuiRg",
                                height: 40,
                                backgroundColor: "#f4f4f4",
                                textAlignLast: "center",
                              }}
                              value={prim.prim}
                              onChange={(e) => {
                                const newPrimSettings = [...setupSettings];
                                newPrimSettings[ind].prim = e.target.value;
                                setSetupSettings(newPrimSettings);
                              }}
                            />
                          </TableCell>
                          <TableCell align="center">
                            <Tooltip title="Sil">
                              <DeleteForeverIcon
                                style={{
                                  color: "#f53b62",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  const newPrimSettings = [...setupSettings];
                                  newPrimSettings.splice(ind, 1);
                                  setSetupSettings(newPrimSettings);
                                }}
                              />
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              marginBottom: 10,
            }}
          >
            <div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <h3>Prim Bloklama</h3>
                <GreenSwitch
                  onClick={() => {
                    const data = {
                      ...extraPrimSettings,
                      bloke_prim: !extraPrimSettings.bloke_prim,
                    };
                    addExtraPrimSettings(data);
                  }}
                  checked={extraPrimSettings?.bloke_prim}
                />
              </div>
              <div>
                <span
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 5,
                    fontWeight: 600,
                  }}
                >
                  Prim Bloklama Limiti
                </span>
              </div>
              <InputBase
                disabled={!extraPrimSettings?.bloke_prim}
                variant="filled"
                type="number"
                style={{
                  width: 150,
                  color: "black",
                  fontSize: 15,
                  fontWeight: "400",
                  fontFamily: "segoeuiRg",
                  height: 40,
                  backgroundColor: "#fff",
                  padding: 5,
                }}
                value={blokePrimLimit}
                onChange={(e) => {
                  setBlokePrimLimit(e.target.value);
                }}
              />
            </div>

            <Button
              color="success"
              variant="contained"
              size="large"
              style={{
                alignSelf: "end",
                width: "100%",
                marginTop: 20,
              }}
              onClick={handleSaveExtraPrimSettings}
            >
              Kaydet
            </Button>
          </div>
        </div>
      </Paper>

      <Paper
        style={{ padding: 20, borderRadius: 8, marginBottom: 20 }}
        elevation={3}
      >
        <h3>Bundle Prim Ayarları</h3>

        <p style={{ color: "#f53b62", fontWeight: 600 }}>
          Bundle'da kullanmak istenilen ürünleri seçiniz
        </p>

        <div style={{ display: "flex", overflow: "auto", paddingBottom: 10 }}>
          {productsList?.map((product, i) => {
            return (
              <Tooltip title={product.name} key={product.id}>
                <Paper
                  elevation={2}
                  style={{
                    cursor: "pointer",
                    color: selectedBundleProducts.includes(product.id)
                      ? "white"
                      : "black",
                    fontFamily: "segoeuiRg",
                    background: selectedBundleProducts.includes(product.id)
                      ? "#f53b62"
                      : "",
                    padding: 5,
                    marginRight: 20,
                    width: 200,
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    minWidth: 100,
                    textAlign: "center",
                  }}
                  onClick={(e) => {
                    if (selectedBundleProducts.includes(product.id)) {
                      setSelectedBundleProducts(
                        selectedBundleProducts.filter((id) => id !== product.id)
                      );
                    } else {
                      setSelectedBundleProducts([
                        ...selectedBundleProducts,
                        product.id,
                      ]);
                    }
                  }}
                >
                  {product.name}
                </Paper>
              </Tooltip>
            );
          })}
        </div>

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            color="success"
            variant="contained"
            size="large"
            style={{
              alignSelf: "end",
            }}
            onClick={handleSaveBundleProducts}
          >
            Kaydet
          </Button>
        </div>

        <Divider style={{ marginTop: 20 }} />

        <div style={{ width: "50%", marginRight: 30 }}>
          <h3>Bundle Primler</h3>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 10,
            }}
          >
            <div>
              <span
                style={{
                  fontSize: 16,
                  marginBottom: 5,
                  display: "block",
                  fontWeight: "600",
                }}
              >
                Adet Seç
              </span>
              <Select
                menuPosition="fixed"
                menuPortalTarget={document.body}
                responsiveHeight={44}
                selectFontSize={16}
                selectWidth={200}
                options={totalBundleCount(selectedBundleProducts)}
                styles={selectDefaultStyles}
                isSearchable={false}
                zIndex={90}
                hoverColor={"#f53b62"}
                menuColor={"#f53b62"}
                placeholder={"Adet"}
                value={selectedBundleCount}
                getOptionValue={(option) => option.key}
                onChange={(e) => {
                  setSelectedBundleCount(e);

                  const selectedBundle = bundlePrimSettings.find(
                    (prim) => prim.count === e.key
                  );
                  if (selectedBundle) {
                    setSelectedBundlePrimSettings(selectedBundle.extra_prim);
                  } else {
                    setSelectedBundlePrimSettings([]);
                  }
                }}
                getOptionLabel={(e) => (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {e.label}
                  </div>
                )}
              />
            </div>

            {selectedBundleCount && (
              <Tooltip title="Bundle Ekle">
                <AddCircleOutlineIcon
                  style={{
                    color: "#f53b62",
                    fontSize: 34,
                    cursor: "pointer",
                  }}
                  onClick={handleAddNewBundlePrim}
                />
              </Tooltip>
            )}
          </div>

          {selectedBundleCount && (
            <>
              <div>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Başlangıç</TableCell>
                        <TableCell align="center">Bitiş</TableCell>
                        <TableCell align="center">Prim TL</TableCell>
                        <TableCell align="center">Aksiyon</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {selectedBundlePrimSettings?.map((prim, ind) => {
                        return (
                          <TableRow key={ind}>
                            <TableCell align="center">
                              <InputBase
                                variant="filled"
                                type="number"
                                style={{
                                  width: 90,
                                  color: "black",
                                  fontSize: 15,
                                  fontWeight: "400",
                                  fontFamily: "segoeuiRg",
                                  height: 40,
                                  backgroundColor: "#f4f4f4",
                                  textAlignLast: "center",
                                }}
                                value={prim.start}
                                onChange={(e) => {
                                  const newPrimSettings = [
                                    ...selectedBundlePrimSettings,
                                  ];
                                  newPrimSettings[ind].start = e.target.value;
                                  setSelectedBundlePrimSettings(
                                    newPrimSettings
                                  );
                                }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <InputBase
                                variant="filled"
                                type="number"
                                style={{
                                  width: 90,
                                  color: "black",
                                  fontSize: 15,
                                  fontWeight: "400",
                                  fontFamily: "segoeuiRg",
                                  height: 40,
                                  backgroundColor: "#f4f4f4",
                                  textAlignLast: "center",
                                }}
                                value={prim.end}
                                onChange={(e) => {
                                  const newPrimSettings = [
                                    ...selectedBundlePrimSettings,
                                  ];
                                  newPrimSettings[ind].end = e.target.value;
                                  setSelectedBundlePrimSettings(
                                    newPrimSettings
                                  );
                                }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <InputBase
                                variant="filled"
                                type="number"
                                style={{
                                  width: 90,
                                  color: "black",
                                  fontSize: 15,
                                  fontWeight: "400",
                                  fontFamily: "segoeuiRg",
                                  height: 40,
                                  backgroundColor: "#f4f4f4",
                                  textAlignLast: "center",
                                }}
                                value={prim.prim}
                                onChange={(e) => {
                                  const newPrimSettings = [
                                    ...selectedBundlePrimSettings,
                                  ];
                                  newPrimSettings[ind].prim = e.target.value;
                                  setSelectedBundlePrimSettings(
                                    newPrimSettings
                                  );
                                }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <Tooltip title="Sil">
                                <DeleteForeverIcon
                                  style={{
                                    color: "#f53b62",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    const newPrimSettings = [
                                      ...selectedBundlePrimSettings,
                                    ];
                                    newPrimSettings.splice(ind, 1);
                                    setSelectedBundlePrimSettings(
                                      newPrimSettings
                                    );
                                  }}
                                />
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>

              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  color="success"
                  variant="contained"
                  size="large"
                  style={{
                    alignSelf: "end",
                    marginTop: 20,
                  }}
                  onClick={handleSaveBundlePrimSettings}
                >
                  Kaydet
                </Button>
              </div>
            </>
          )}
        </div>
      </Paper>

      <Paper
        style={{ padding: 20, borderRadius: 8, marginBottom: 20 }}
        elevation={3}
      >
        <h2
          style={{
            textAlign: "center",
            marginTop: 20,
            paddingTop: 20,
            borderTop: "1px solid rgba(233,233,233)",
          }}
        >
          Ürünler
        </h2>

        <div style={{ display: "flex", overflow: "auto", paddingBottom: 10 }}>
          {productsList?.map((product) => {
            return (
              <Tooltip title={product.name} key={product.id}>
                <Paper
                  elevation={2}
                  style={{
                    cursor: "pointer",
                    color:
                      selectedProduct?.id === product.id ? "white" : "black",
                    fontFamily: "segoeuiRg",
                    background:
                      selectedProduct?.id === product.id ? "#f53b62" : "",
                    padding: 5,
                    marginRight: 20,
                    width: 200,
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    minWidth: 100,
                    textAlign: "center",
                  }}
                  onClick={() => {
                    getProductPrimSettings(product.id);
                    setSelectedProduct(product);
                  }}
                >
                  {product.name}
                </Paper>
              </Tooltip>
            );
          })}
        </div>

        {selectedProductPrimSettings && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 20,
            }}
          >
            <div
              style={{
                width: "50%",
                paddingRight: 20,
                marginRight: 20,
                borderRight: "1px solid rgba(200,200,200,0.1)",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h3>
                  {selectedProduct?.name} ({selectedProduct?.price} ₺) Satış
                  Primleri
                </h3>
                <Tooltip title="Yeni Prim Ekle">
                  <AddCircleOutlineIcon
                    style={{
                      color: "#f53b62",
                      fontSize: 34,
                      cursor: "pointer",
                    }}
                    onClick={handleAddNewPrim}
                  />
                </Tooltip>
              </div>

              <div>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Min İndirim Oranı</TableCell>
                        <TableCell align="center">Max İndirim Oranı</TableCell>
                        <TableCell align="center">Prim %</TableCell>
                        <TableCell align="center">Aksiyon</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {selectedProductPrimSettings[0]?.prim_percent_range?.map(
                        (prim, ind) => {
                          return (
                            <TableRow key={ind}>
                              <TableCell align="center">
                                <InputBase
                                  variant="filled"
                                  type="number"
                                  style={{
                                    width: 90,
                                    color: "black",
                                    fontSize: 15,
                                    fontWeight: "400",
                                    fontFamily: "segoeuiRg",
                                    height: 40,
                                    backgroundColor: "#f4f4f4",
                                    textAlignLast: "center",
                                  }}
                                  value={prim.start}
                                  onChange={(e) => {
                                    const newPrimSettings = [
                                      ...selectedProductPrimSettings,
                                    ];
                                    newPrimSettings[0].prim_percent_range[
                                      ind
                                    ].start = e.target.value;
                                    setSelectedProductPrimSettings(
                                      newPrimSettings
                                    );
                                  }}
                                />
                              </TableCell>
                              <TableCell align="center">
                                <InputBase
                                  variant="filled"
                                  type="number"
                                  style={{
                                    width: 90,
                                    color: "black",
                                    fontSize: 15,
                                    fontWeight: "400",
                                    fontFamily: "segoeuiRg",
                                    height: 40,
                                    backgroundColor: "#f4f4f4",
                                    textAlignLast: "center",
                                  }}
                                  value={prim.end}
                                  onChange={(e) => {
                                    const newPrimSettings = [
                                      ...selectedProductPrimSettings,
                                    ];
                                    newPrimSettings[0].prim_percent_range[
                                      ind
                                    ].end = e.target.value;
                                    setSelectedProductPrimSettings(
                                      newPrimSettings
                                    );
                                  }}
                                />
                              </TableCell>
                              <TableCell align="center">
                                <InputBase
                                  variant="filled"
                                  type="number"
                                  style={{
                                    width: 90,
                                    color: "black",
                                    fontSize: 15,
                                    fontWeight: "400",
                                    fontFamily: "segoeuiRg",
                                    height: 40,
                                    backgroundColor: "#f4f4f4",
                                    textAlignLast: "center",
                                  }}
                                  value={prim.prim}
                                  onChange={(e) => {
                                    const newPrimSettings = [
                                      ...selectedProductPrimSettings,
                                    ];
                                    newPrimSettings[0].prim_percent_range[
                                      ind
                                    ].prim = e.target.value;
                                    setSelectedProductPrimSettings(
                                      newPrimSettings
                                    );
                                  }}
                                />
                              </TableCell>
                              <TableCell align="center">
                                <Tooltip title="Sil">
                                  <DeleteForeverIcon
                                    style={{
                                      color: "#f53b62",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      const newPrimSettings = [
                                        ...selectedProductPrimSettings,
                                      ];
                                      newPrimSettings[0].prim_percent_range.splice(
                                        ind,
                                        1
                                      );
                                      setSelectedProductPrimSettings(
                                        newPrimSettings
                                      );
                                    }}
                                  />
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          );
                        }
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>

            <div style={{ width: "50%" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h3>Sözleşme Uzatma Primleri</h3>
                <Tooltip title="Yeni Prim Ekle">
                  <AddCircleOutlineIcon
                    style={{
                      color: "#f53b62",
                      fontSize: 34,
                      cursor: "pointer",
                    }}
                    onClick={handleAddNewRenewalPrim}
                  />
                </Tooltip>
              </div>

              <div>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Min Zam Oranı</TableCell>
                        <TableCell align="center">Max Zam Oranı</TableCell>
                        <TableCell align="center">Prim %</TableCell>
                        <TableCell align="center">Aksiyon</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {selectedProductPrimSettings[0]?.contract_renewal_percent_range?.map(
                        (prim, ind) => {
                          return (
                            <TableRow key={ind}>
                              <TableCell align="center">
                                <InputBase
                                  variant="filled"
                                  type="number"
                                  style={{
                                    width: 90,
                                    color: "black",
                                    fontSize: 15,
                                    fontWeight: "400",
                                    fontFamily: "segoeuiRg",
                                    height: 40,
                                    backgroundColor: "#f4f4f4",
                                    textAlignLast: "center",
                                  }}
                                  value={prim.start}
                                  onChange={(e) => {
                                    const newPrimSettings = [
                                      ...selectedProductPrimSettings,
                                    ];
                                    newPrimSettings[0].contract_renewal_percent_range[
                                      ind
                                    ].start = e.target.value;
                                    setSelectedProductPrimSettings(
                                      newPrimSettings
                                    );
                                  }}
                                />
                              </TableCell>
                              <TableCell align="center">
                                <InputBase
                                  variant="filled"
                                  type="number"
                                  style={{
                                    width: 90,
                                    color: "black",
                                    fontSize: 15,
                                    fontWeight: "400",
                                    fontFamily: "segoeuiRg",
                                    height: 40,
                                    backgroundColor: "#f4f4f4",
                                    textAlignLast: "center",
                                  }}
                                  value={prim.end}
                                  onChange={(e) => {
                                    const newPrimSettings = [
                                      ...selectedProductPrimSettings,
                                    ];
                                    newPrimSettings[0].contract_renewal_percent_range[
                                      ind
                                    ].end = e.target.value;
                                    setSelectedProductPrimSettings(
                                      newPrimSettings
                                    );
                                  }}
                                />
                              </TableCell>
                              <TableCell align="center">
                                <InputBase
                                  variant="filled"
                                  type="number"
                                  style={{
                                    width: 90,
                                    color: "black",
                                    fontSize: 15,
                                    fontWeight: "400",
                                    fontFamily: "segoeuiRg",
                                    height: 40,
                                    backgroundColor: "#f4f4f4",
                                    textAlignLast: "center",
                                  }}
                                  value={prim.prim}
                                  onChange={(e) => {
                                    const newPrimSettings = [
                                      ...selectedProductPrimSettings,
                                    ];
                                    newPrimSettings[0].contract_renewal_percent_range[
                                      ind
                                    ].prim = e.target.value;
                                    setSelectedProductPrimSettings(
                                      newPrimSettings
                                    );
                                  }}
                                />
                              </TableCell>
                              <TableCell align="center">
                                <Tooltip title="Sil">
                                  <DeleteForeverIcon
                                    style={{
                                      color: "#f53b62",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      const newPrimSettings = [
                                        ...selectedProductPrimSettings,
                                      ];
                                      newPrimSettings[0].contract_renewal_percent_range.splice(
                                        ind,
                                        1
                                      );
                                      setSelectedProductPrimSettings(
                                        newPrimSettings
                                      );
                                    }}
                                  />
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          );
                        }
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>

                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    color="success"
                    variant="contained"
                    size="large"
                    style={{
                      alignSelf: "end",
                    }}
                    onClick={handleSavePrimSettings}
                  >
                    Kaydet
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </Paper>
    </div>
  );
}
