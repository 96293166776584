import React, { useState } from "react";

import { Card, Modal, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { CheckBox } from "@mui/icons-material";
import { GreenSwitch } from "../../../../../../components/switch/greenSwitch";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    padding: 20,
    border: "2px solid #000",
    boxShadow: 5,
    backgroundColor: "rgb(0 0 0 / 62%)",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
    maxHeight: "100vh",
  },
}));

const SettingsHeaderSelectModal = ({
  settingsHeaderSelectModal,
  setSettingsHeaderSelectModal,
  cdrRatingHeaderList,
  ratingSettingsHeaderFunc,
}) => {
  const classes = useStyles();

  const handleSwitchClick = (row) => {
    // Seçili ayarları al
    const selectedSettings = settingsHeaderSelectModal.selectedTaskType;

    // `action` değerini ve `headers` dizisini belirle
    const isHeaderPresent = selectedSettings.headers.some(
      (header) => header.id === row.id
    );

    const updatedHeaders = isHeaderPresent
      ? selectedSettings.headers.filter((header) => header.id !== row.id) // Sil
      : [...selectedSettings.headers, row]; // `row` nesnesini ekle

    const action = isHeaderPresent ? "remove" : "add";

    // API'ye gönderilecek nesne
    const data = {
      id: selectedSettings.id,
      sendObj: {
        action,
        headers: [row.id], // Sadece işlem yapılan id'yi gönder
      },
    };

    // `ratingSettingsHeaderFunc` ile API çağrısı yapılabilir
    ratingSettingsHeaderFunc(data);

    // Local state güncellemesi
    const updatedSettings = {
      ...selectedSettings,
      headers: updatedHeaders, // Headers'ı doğru şekilde güncelle
    };

    setSettingsHeaderSelectModal((prev) => ({
      ...prev,
      selectedTaskType: updatedSettings, // selectedTaskType'i güncelle
    }));
  };

  return (
    <>
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={settingsHeaderSelectModal.showModal}
        className={classes.modal}
        onClose={() => {
          setSettingsHeaderSelectModal({
            showModal: false,
            selectedTaskType: null,
            index: null,
          });
        }}
      >
        <Card
          style={{
            width: "45%",
            display: "flex",
            alignItems: "center",
            padding: 30,
            flexDirection: "column",
            maxHeight: "80vh",
            overflow: "auto",
          }}
        >
          <div
            style={{
              width: "100%",
              height: 40,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#fff",
              marginBottom: 20,
              fontFamily: "segoeuiBd",
              fontSize: 22,
              fontVariant: "all-petite-caps",
              letterSpacing: 3.5,
            }}
          >
            TASK TİPİ İÇİN BAŞLIK EKLE
          </div>
          {settingsHeaderSelectModal.showModal &&
            cdrRatingHeaderList?.map((row) => {
              return (
                <Paper
                  elevation={1}
                  style={{
                    backgroundColor: "#f4f4f4",
                    width: "90%",
                    padding: 10,
                    margin: 10,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div>{row?.title}</div>
                  <GreenSwitch
                    onClick={() => {
                      handleSwitchClick(row);
                    }}
                    checked={settingsHeaderSelectModal.selectedTaskType?.headers.some(
                      (header) => header.id === row.id
                    )}
                  />
                </Paper>
              );
            })}
        </Card>
      </Modal>
    </>
  );
};

export default SettingsHeaderSelectModal;
