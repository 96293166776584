import React, { useState, useRef } from "react";
import { Box, Slider, IconButton, Tooltip } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import SpeedIcon from "@mui/icons-material/Speed";

const AudioPlayer = ({ audioRef, src }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(50); // Varsayılan ses seviyesi
  const [playbackRate, setPlaybackRate] = useState(1); // Varsayılan hız 1x
  const [tooltipOpenVolume, setTooltipOpenVolume] = useState(false);
  const [tooltipOpenRate, setTooltipOpenRate] = useState(false);

  const togglePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleSliderChange = (event, newValue) => {
    audioRef.current.currentTime = newValue;
    setCurrentTime(newValue);
  };

  const handleVolumeChange = (event, newValue) => {
    setVolume(newValue);
    audioRef.current.volume = newValue / 100;
  };

  const handleSpeedChange = (event, newValue) => {
    setPlaybackRate(newValue);
    audioRef.current.playbackRate = newValue;
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60)
      .toString()
      .padStart(2, "0");
    return `${minutes}:${seconds}`;
  };

  const handleLoadedMetadata = () => {
    setDuration(audioRef.current.duration);
  };

  return (
    <>
      {/* Ses oynatıcı element */}
      <audio
        ref={audioRef}
        src={src} // Ses dosyanızın yolunu buraya ekleyin
        onLoadedMetadata={handleLoadedMetadata}
        onTimeUpdate={() => setCurrentTime(audioRef.current.currentTime)}
      />

      {/* Progress Bar */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 1,
        }}
      >
        <span style={{ fontSize: "12px", color: "#fff" }}>
          {formatTime(currentTime)}
        </span>
        <Slider
          size="small"
          value={currentTime}
          max={duration}
          onChange={handleSliderChange}
          sx={{
            color: "#fff",
            height: 4,
            "& .MuiSlider-thumb": {
              width: 10,
              height: 10,
              backgroundColor: "#fff",
            },
          }}
        />
        <span style={{ fontSize: "12px" }}>{formatTime(duration)}</span>
      </Box>

      {/* Controls */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <IconButton
          sx={{
            backgroundColor: "#fff",
            color: "#121212",
            width: 40,
            height: 40,
            "&:hover": { backgroundColor: "#e0e0e0" },
          }}
          onClick={togglePlayPause}
        >
          {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
        </IconButton>

        {/* Volume Control with Tooltip */}
        <Tooltip
          title={
            <Box sx={{ padding: 1 }}>
              <Slider
                orientation="vertical"
                value={volume}
                onChange={handleVolumeChange}
                sx={{
                  color: "#fff",
                  height: 100,
                  "& .MuiSlider-thumb": {
                    width: 10,
                    height: 10,
                    backgroundColor: "#fff",
                  },
                }}
              />
            </Box>
          }
          placement="top"
          arrow
          open={tooltipOpenVolume}
          onClose={() => {
            setTooltipOpenRate(false);
            setTooltipOpenVolume(false);
          }}
          disableFocusListener
          disableHoverListener
          componentsProps={{
            tooltip: {
              sx: {
                maxWidth: 50, // Tooltip'in genişliğini sınırla
                backgroundColor: "#333", // Tooltip arka planı
                color: "#fff", // Tooltip içeriği
              },
            },
          }}
        >
          <IconButton
            onClick={() => {
              setTooltipOpenRate(false);
              setTooltipOpenVolume(!tooltipOpenVolume);
            }}
          >
            <VolumeUpIcon sx={{ color: "#fff" }} />
          </IconButton>
        </Tooltip>

        {/* Speed Control with Tooltip */}
        <Tooltip
          title={
            <Box sx={{ width: 50 }}>
              <Slider
                orientation="vertical"
                value={playbackRate}
                min={0.5}
                max={2}
                step={0.5}
                onChange={handleSpeedChange}
                marks={[
                  { value: 0.5, label: "0.5x" },
                  { value: 1, label: "1x" },
                  { value: 1.5, label: "1.5x" },
                  { value: 2, label: "2x" },
                ]}
                sx={{
                  color: "#fff",
                  height: 100,
                  "& .MuiSlider-thumb": {
                    width: 10,
                    height: 10,
                    backgroundColor: "#fff",
                  },
                  "& .MuiSlider-markLabel": {
                    color: "#ffffff",
                    fontSize: "11px",
                    transform: "translateX(-10px) translateY(5px)",
                  },
                  "& .MuiSlider-mark": {
                    backgroundColor: "#fff",
                    width: 6,
                    height: 6,
                    borderRadius: "50%",
                  },
                }}
              />
            </Box>
          }
          placement="top"
          arrow
          open={tooltipOpenRate}
          onClose={() => {
            setTooltipOpenVolume(false);
            setTooltipOpenRate(false);
          }}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          componentsProps={{
            tooltip: {
              sx: {
                maxWidth: 50, // Tooltip'in genişliğini sınırla
                backgroundColor: "#333", // Tooltip arka planı
                color: "#fff", // Tooltip içeriği
                padding: 2,
              },
            },
          }}
        >
          <IconButton
            onClick={() => {
              setTooltipOpenVolume(false);
              setTooltipOpenRate(!tooltipOpenRate);
            }}
          >
            <SpeedIcon sx={{ color: "#fff" }} />
          </IconButton>
        </Tooltip>
      </Box>
    </>
  );
};

export default AudioPlayer;
