import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import restaurantService from "../services/restaurantService";
import { useToast } from "../components/snackbar";
import { Paths, data } from "../utils/constants";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import moment from "moment";
import managementService from "../services/managementService";
import UserActivity from "../components/modals/userActivity";
import useMouseInactivity from "../components/mouseTracker/MouseTracker";
import soundPlayF from "../components/soundPlay";
import resTicket from "../utils/assets/res-ticket.png";
import { Button } from "@mui/material";
import TechResTicket from "../components/modals/techResTicket";
import TicketBounce from "../components/modals/ticketBounce";

import callAuth from "../components/call/func/auth";
import { CallContext } from "./callContext";

export const RestaurantContext = createContext();

let keyTicket;

const RestaurantProvider = (props) => {
  let history = useHistory();
  const {
    success,
    info,
    alert,
    infoPersist,
    closeSnack,
    alertOrderStatus,
    successV2,
    alertUrgent,
  } = useToast();
  const [restaurantID, setRestaurantID] = useState(null);
  const [currentIndex, setCurrentIndex] = useState("tabela");
  const [courierLoading, setCourierLoading] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [loader, setLoader] = React.useState(false);
  const [restOfficialData, setRestOfficialData] = useState(null);
  const [formData, setFormData] = useState({});
  const [cities, setCities] = useState([]);
  const [town, setTown] = useState([]);
  const [neigh, setNeigh] = useState([]);
  const [applicationStatuList, setApplicationStatuList] = useState(null);
  const [dealarSidebarPage, setDealarSidebarPage] = useState(0);
  const [preDealarSidebarPage, setPreDealarSidebarPage] = useState(0);
  const [operationSidebarPage, setOperationSidebarPage] = useState(0);
  const [financeSidebarPage, setFinanceSidebarPage] = useState(0);
  const [managmentSidebarPage, setManagementSidebarPage] = useState(0);
  const [sellerSidebarPage, setSellerSidebarPage] = useState(0);
  const [platformList, setPlatformList] = useState(null);
  const [restofficialPlatforms, setRestofficialPlatforms] = useState([]);
  const [restaurantInfoData, setRestaurantInfoData] = useState({});
  const [myResmtaurantsData, setMyRestaurantsData] = useState(null);
  const [myResmtaurantsTagReport, setMyRestaurantsTagReport] = useState();
  const [foodCategory, setFoodCategory] = useState([]);
  const [restaurantCourier, setRestaurantCourier] = useState([]);
  const [restaurantPrinter, setRestaurantPrinter] = useState([]);
  const [restaurantModules, setRestaurantModules] = useState(null);
  const [restaurantWorkingHours, setRestaurantWorkingHours] = useState([]);
  const [restaurantPaymentTypes, setRestaurantPaymentTypes] = useState([]);
  const [paymentTypeList, setpaymentTypeList] = useState([]);
  const [reportData, setReportData] = useState(null);
  const [restaurantOffer, setRestaurantOffer] = useState(null);
  const [restaurantActiveOffer, setRestaurantActiveOffer] = useState(null);
  const [paymentTracker, setPaymentTracker] = useState([]);
  const [restaurantMenuList, setRestaurantMenuList] = useState([]);
  const [backofficeList, setBackofficeList] = useState([]);
  const [applicationNoteList, setApplicationNoteList] = useState([]);
  const [applicationNotes, setApplicationNotes] = useState(null);
  const [blockedReasons, setBlockedReasons] = useState(null);
  const [blockedReasonsv2, setBlockedReasonsv2] = useState(null);
  const [trackerNotes, setTrackerNotes] = useState(null);
  const [operationPrim, setOperationPrim] = useState(null);
  const [sellerMyPrim, setSellerMyPrim] = useState(null);
  const [sellerMyPrimHistory, setSellerMyPrimHistory] = useState(null);
  const [eftList, setEftList] = useState(null);
  const [orderRepeats, setOrderRepeats] = useState(null);
  const [countApplication, setCountApplication] = useState({});
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [platforms, setPlatforms] = useState(null);
  const [applicationList, setApplicationsList] = useState(null);
  const [productHeaderList, setProductHeaderList] = useState(null);
  const [productList, setProductList] = useState(null);
  const [optProductList, setOptProductList] = useState(null);
  const [menuDetailObj, setMenuDetailObj] = useState(null);
  const [resDetailInfo, setResDetailInfo] = useState(null);
  const [restaurantRatingList, setRestaurantRatingList] = useState(null);
  const [offerProductList, setOfferProductList] = useState(null);
  const [offerProducts, setOfferProducts] = useState(null);
  const [sectorList, setSectorList] = useState(null);
  const [offerHistoriesList, setOfferHistoriesList] = useState(null);
  const [offerHistoryDetail, setOfferHistoryDetail] = useState(null);
  const [enduserDetail, setEnduserDetail] = useState(null);
  const [enduserOrders, setEnduserOrders] = useState(null);
  const [sepetPayAmound, setSepetPayAmound] = useState(0);
  const [restaurantEnduserAppList, setRestaurantEnduserAppList] =
    useState(null);
  const [outboundCount, setOutboundCount] = useState(0);
  const [outboundReports, setOutbountReports] = useState(null);
  const [primOutbound, setPrimOutbound] = useState(null);
  const [techSupportList, setTechSupportList] = useState(null);
  const [notificationList, setNotificationList] = useState(null);
  const [callCenterRestList, setCallCenterRestList] = useState(null);
  const [backofficeListWithRole, setBackofficeListWithRole] = useState(null);
  const [backofficeCollectorList, setBackofficeCollectorList] = useState(null);
  const [totalAmounts, setTotalAmounts] = useState(null);
  const [ticketTypes, setTicketTypes] = useState(null);
  const [activeTicketList, setActiveTicketList] = useState(null);
  const [ivrDataInfo, setIvrDataInfo] = useState(null);
  const [sentTickets, setSentTickets] = useState(null);
  const [completedTickets, setCompletedTickets] = useState(null);
  const [stateHistory, setStateHistory] = useState(null);
  const [stateHistoryDaily, setStateHistoryDaily] = useState(null);
  const [collectorRestaurantList, setCollectorRestaurantList] = useState(null);
  const [collectorRestaurantOffers, setCollectorRestaurantOffers] =
    useState(null);
  const [collectorRestaurantOrders, setCollectorRestaurantOrders] =
    useState(null);
  const [courierTrackerStatusList, setCourierTrackerStatusList] =
    useState(null);
  const [taskList, setTaskList] = useState(null);
  const [calendarList, setCalendarList] = useState(null);
  const [personalTemplateNoteList, setPersonalTemplateNoteList] =
    useState(null);
  const [calendarAllList, setCalendarAllList] = useState(null);
  const [restPrevOfferHistory, setRestPrevOfferHistory] = useState(null);
  const [taskDashboardData, setTaskDashboardData] = useState(null);
  const [restofficialTaskActionList, setRestofficialTaskActionList] =
    useState(null);
  const [restofficialRefs, setRestofficialRefs] = useState(null);
  const [userStatus, setUserStatus] = useState(null);
  const [userActiveModal, setuserActiveModal] = useState(false);
  const [isMouseInactive, setisMouseInactive] = useMouseInactivity(
    165000,
    userStatus
  );
  const [statusModal, setStatusModal] = useState(false);
  const [activeTasks, setActiveTasks] = useState([]);

  const [applicationCounts, setApplicationCounts] = useState(null);
  const [applicationDeletedCount, setApplicationDeletedCount] = useState(null);
  const [applicationHistoryLog, setApplicationHistoryLog] = useState(null);
  const [ysRemoteIdList, setYsRemoteIdList] = useState(null);
  const [secretKeyPlatformSummary, setSecretKeyPlatformSummary] =
    useState(null);
  const [secretKeyPlatformInfo, setSecretKeyPlatformInfo] = useState(null);
  const [sepetAppDemoDetail, setSepetAppDemoDetail] = useState(null);
  const [techResTicketModal, setTechResTicketModal] = useState(false);
  const [ticketBounceModal, setTicketBounceModal] = useState(false);
  const [currentBounce, setCurrentBounce] = useState(null);
  const [appointmentList, setAppointmentList] = useState(null);
  const [appointmentQcdList, setAppointmentQcdList] = useState(null);
  const [appointmentOrderList, setAppointmentOrderList] = useState(null);
  const [productHeaderPictureTemplate, setProductHeaderPictureTemplate] =
    useState(null);
  const [productPictureTemplate, setProductPictureTemplate] = useState(null);

  const [offerDetail, setOfferDetail] = useState(null);
  const [openAddHeaderModal, setOpenAddHeaderModal] = useState(false);
  const [openProductModal, setOpenProductModal] = useState(false);
  const [primTable, setPrimTable] = useState(null);
  const [lifetimeStats, setLifetimeStats] = useState(null);
  const [swingAnim, setSwingAnim] = useState(false);
  const [partialPaymentInfo, setPartialPaymentInfo] = useState(null);
  const [renewalOrderCounterList, setRenewalOrderCounterList] = useState(null);
  const [renewalPreviousOffersList, setRenewalPreviousOffersList] =
    useState(null);
  const [summaryRestDetailInfo, setSummaryRestDetailInfo] = useState(null);
  const [backofficeListRenewal, setBackofficeListRenewal] = useState(null);
  const [productProfitData, setProductProfitData] = useState(null);
  const [productProfitAvailabilityList, setProductProfitAvailabilityList] =
    useState(null);

  //*renewal-summary
  const [renewalSummaryListNew, setRenewalSummaryListNew] = useState(null);
  const [renewalSummaryListProgress, setRenewalSummaryListProgress] =
    useState(null);
  const [renewalSummaryListCompleted, setRenewalSummaryListCompleted] =
    useState(null);
  const [renewalOfficeusersList, setRenewalOfficeusersList] = useState(null);
  const [renewerList, setRenewerList] = useState(null);
  const [renewerIncreaseRateDetail, setRenewerIncreaseRateDetail] =
    useState(null);
  //*

  const { cdrHangupFunc, cdrAnswerFunc, callkey, cdrMissedCountFunc } =
    useContext(CallContext);

  useEffect(() => {
    if (isMouseInactive) {
      setuserActiveModal(true);
    }
  }, [isMouseInactive]);

  useEffect(() => {
    resTechPendingFunc();
    // ticketBounceListFunc();
    if (
      JSON.parse(localStorage.getItem("st-officeuser-info"))
        ?.iqcell_username !== null
    ) {
      callAuth(
        enqueueSnackbar,
        closeSnackbar,
        cdrHangupFunc,
        cdrAnswerFunc,
        callkey,
        cdrMissedCountFunc,
        history
      );
    }
  }, []);

  const getRestaurantInfo = (id) => {
    restaurantService
      .get_office_user(id)
      .then((response) => {
        setRestOfficialData(response);
      })
      .catch((error) => {
        alert("Bir Hata Oluştu. Şehirler getirilemedi");
      });
  };
  const getOfficeuserDetail = (id) => {
    restaurantService
      .get_office_user(id)
      .then((response) => {
        getCity();
        getTown(response.city?.id);
        getNeighborhood(response.city?.id, response.town?.id);
        getFoodCategory();
        setRestOfficialData(response);
        setFormData({
          city: response.city?.id,
          town: response.town?.id,
          neighborhood: response.neighborhood?.id,
          state_working: response.state_working,
          first_name: response.first_name,
          last_name: response.last_name,
          name: response.name,
          email: response.user ? response.user.username : "",
          address: response.address,
          phone: response.phone,
          constant_phone: response.constant_phone,
          door_number: response.door_number ? response.door_number : "",
          is_active: response.is_active,
          auto_accept: response.auto_accept,
          platforms: response.platforms,
          latitude: response.latitude,
          longitude: response.longitude,
          category: response.category?.id, // fodo
        });

        success("Restoran detayı getirildi");
      })
      .catch((error) => {
        alert("Bir Hata oluştu");
      });
  };

  const patchOfficeuserDetail = (id, data) => {
    restaurantService
      .patch_office_user(id, data)
      .then((response) => {
        getOfficeuserDetail(id);
        success(`Restoran bilgileri güncellendi`);
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  };

  const patchLimitedUseTask = (id, data) => {
    restaurantService
      .patch_office_user(id, data)
      .then((response) => {
        getTaskList();
        success(`Kısıtlama durumu değiştirildi.`);
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  };

  const getSectorListFunc = () => {
    restaurantService
      .get_application_sector()
      .then((response) => {
        setSectorList(response);
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  };

  const getCity = () => {
    restaurantService
      .get_city()
      .then((response) => {
        if (response) {
          setCities(response);
        }
      })
      .catch((error) => {
        alert("Bir Hata Oluştu. Şehirler getirilemedi");
      });
  };

  const getTown = (city_id) => {
    restaurantService
      .get_town(city_id)
      .then((response) => {
        setTown(response);
      })
      .catch((error) => {
        alert("Bir Hata Oluştu. İlçeler getirilemedi");
      });
  };

  const getNeighborhood = (city_id, town_id) => {
    restaurantService
      .get_neighborhood(city_id, town_id)
      .then((response) => {
        setNeigh(response);
      })
      .catch((error) => {
        alert("Bir Hata Oluştu. Mahalleler getirilemedi");
      });
  };

  /* ------------------------------------------- Platform Secret Key ----------------------------------------------------- */

  const postSecretKey = (data, r_id) => {
    restaurantService
      .post_secret_key(data)
      .then(() => {
        getRestofficialPlatforms(r_id);
        getOfficeuserDetail(r_id);
        success("Secret key kayıt edildi");
        secretKeyPlatformSummaryFunc({ rest_id: r_id });
        secretKeyPlatformInfoFunc({ id: r_id, platform_id: data?.platform });
      })
      .catch((err) => {
        alertOrderStatus(err?.response?.data?.message);
      });
  };

  const changeSecretKey = (data) => {
    restaurantService
      .change_secret_key(data)
      .then((response) => {
        success("Secret key aktif edildi");
        secretKeyPlatformInfoFunc({
          id: data.r_id,
          platform_id: data?.platform,
        });
      })
      .catch((err) => {
        alertOrderStatus(err?.response?.data?.message);
      });
  };

  /* ------------------------------------------- Platform Secret Key ----------------------------------------------------- */
  const getApplicationStatuList = (searchValue, type, page, activeSms) => {
    restaurantService
      .get_application_statu_list(searchValue, type, page, activeSms)
      .then((response) => {
        setApplicationStatuList(response);
        success("Restoran başvuruları getirildi");
      })
      .catch((error) => {
        setLoader(false);
      });
  };

  const getApplicationStatuListStayed = (searchValue, type, page) => {
    restaurantService
      .get_application_statu_list_stayed(searchValue, type, page)
      .then((response) => {
        setApplicationStatuList(response);
        success("Restoran başvuruları getirildi");
      })
      .catch((error) => {
        setLoader(false);
      });
  };

  const getApplicationStatuListDeleted = (data, type, page) => {
    restaurantService
      .get_application_statu_list_deleted(data, type, page)
      .then((response) => {
        setApplicationStatuList(response);
        success("Silinen başvurular getirildi");
      })
      .catch((error) => {
        setLoader(false);
      });
  };

  const patchApplicationStatu = (id, statu, pageStatus, page) => {
    restaurantService
      .patch_application_statu(id, statu)
      .then((response) => {
        if (pageStatus === "home") {
          getApplicationStatuList("", "", page);
        } else if (pageStatus === "deleted") {
          let data = {
            searchTerm: "",
            selectedDeleteReason: "",
          };
          getApplicationStatuListDeleted(data, "", page);
        } else if (pageStatus === "waiting") {
          getApplicationStatuListStayed("", "", page);
        }

        enqueueSnackbar(`Başarıyla Onay gerçekleşti`, {
          variant: "success",
          preventDuplicate: true,
          autoHideDuration: 1500,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const complatedApplicationStatu = (id, statu) => {
    restaurantService
      .patch_application_statu(id, statu)
      .then((response) => {
        getOfficeuserDetail(id);
        success(`Başarıyla Kurulum Tamamlandı`);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getRestaurantInfoData = (id) => {
    restaurantService
      .get_restaurant_info_data(id)
      .then((response) => {
        setRestaurantInfoData(response);
        getCity();
        getFoodCategory();
        info(`Restoran bilgisi getirildi`);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getFoodCategory = () => {
    restaurantService.get_food_category().then((response) => {
      setFoodCategory(response);
    });
  };

  const updateRestaurantInfoData = (id, data, pageStatus, page) => {
    restaurantService
      .updade_application_statu(id, data)
      .then((response) => {
        success(`Başarıyla Onay gerçekleşti`);
        if (pageStatus === "home") {
          getApplicationStatuList("", "", page);
        } else if (pageStatus === "deleted") {
          let data = {
            searchTerm: "",
            selectedDeleteReason: "",
          };
          getApplicationStatuListDeleted(data, "", page);
        } else if (pageStatus === "waiting") {
          getApplicationStatuListStayed("", "", page);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const updatePlatformsWorkstating = (id, data) => {
    restaurantService
      .update_platforms_workstating(id, data)
      .then((response) => {
        getOfficeuserDetail(restaurantID);

        success(`Başarıyla guncelendi`);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getPlatformList = (id) => {
    restaurantService
      .get_platform_list(id)
      .then((response) => {
        setPlatformList(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getRestofficialPlatforms = (id) => {
    restaurantService
      .get_restofficial_platforms(id)
      .then((response) => {
        setRestofficialPlatforms(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const patchRestofficialPlatforms = (id, data) => {
    restaurantService
      .patch_restofficial_platforms(id, data)
      .then((response) => {
        if (response.response) {
          success(`Başarıyla Güncelendi`);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const deleteRestofficialPlatforms = (user_id, id, platform) => {
    restaurantService
      .delete_restofficial_platforms(user_id, id)
      .then((response) => {
        getRestofficialPlatforms(user_id);
        info(`Başarıyla Kaldırıldı`);
        secretKeyPlatformSummaryFunc({ rest_id: user_id });
        secretKeyPlatformInfoFunc({ id: user_id, platform_id: platform });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getmyRestaurantTableDataList = (data) => {
    restaurantService
      .get_myrestaurant_table_data_list(data)
      .then((response) => {
        setMyRestaurantsData(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getmyRestaurantTableDataListTech = (data) => {
    restaurantService
      .get_myrestaurant_table_data_list_tech(data)
      .then((response) => {
        setMyRestaurantsData(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getmyRestaurantTagReport = () => {
    restaurantService
      .get_my_restaurant_tag_report()
      .then((response) => {
        setMyRestaurantsTagReport(response);
        // info(`Restoran raporları getirildi`);
      })
      .catch((error) => {
        alert("Restoran raporları getirillirken hata oluştu");
      });
  };
  const getRestaurantCourierData = (resID, page) => {
    setCourierLoading(true);
    restaurantService
      .get_restaurant_courier_data(resID, page)
      .then((response) => {
        setRestaurantCourier(response);
        setCourierLoading(false);
      })
      .catch((error) => {
        alert(`Hata oluştu`);
        setCourierLoading(false);
      });
  };
  const patchRestaurantCourierData = (resID, data, page = 1) => {
    setCourierLoading(true);
    restaurantService
      .patch_restaurant_courier_data(resID, data)
      .then((response) => {
        getRestaurantCourierData(resID, page);
        setCourierLoading(false);
      })
      .catch((error) => {
        alert(`Hata oluştu`);
        setCourierLoading(false);
      });
  };

  const postRestaurantCourierData = (resID, page, data) => {
    setCourierLoading(true);
    restaurantService
      .post_restaurant_courier_data(resID, data)
      .then((response) => {
        getRestaurantCourierData(resID, page);
        setCourierLoading(false);
      })
      .catch((error) => {
        alert(`Hata oluştu`);
        setCourierLoading(false);
      });
  };
  const getRestaurantPrinterData = (resID) => {
    restaurantService
      .get_restaurant_printer_data(resID)
      .then((response) => {
        setRestaurantPrinter(response);
      })
      .catch((error) => {
        alert(`Hata oluştu`);
      });
  };
  const patchRestaurantPrinterData = (resID, data) => {
    restaurantService
      .patch_restaurant_printer_data(resID, data)
      .then((response) => {
        getRestaurantPrinterData(resID);
      })
      .catch((error) => {
        alert(`Hata oluştu`);
      });
  };
  const deleteRestaurantPrinterData = (resID, data) => {
    restaurantService
      .delete_restaurant_printer_data(resID, data)
      .then((response) => {
        getRestaurantPrinterData(resID);
      })
      .catch((error) => {
        alert(`Hata oluştu`);
      });
  };

  const getRestaurantModulesData = (resID) => {
    restaurantService
      .get_restaurant_modules_data(resID)
      .then((response) => {
        setRestaurantModules(response);
      })
      .catch((error) => {
        alert(`Hata oluştu`);
      });
  };
  const patchRestaurantModulesData = (resID, data) => {
    restaurantService
      .patch_restaurant_modules_data(resID, data)
      .then((response) => {
        getRestaurantModulesData(resID);
      })
      .catch((error) => {
        alert(`Hata oluştu`);
      });
  };
  const getRestaurantWorkingHours = (resID, p_id) => {
    restaurantService
      .get_restaurant_working_hours_data(resID, p_id)
      .then((response) => {
        setRestaurantWorkingHours(response);
      })
      .catch((error) => {
        alert(`Hata oluştu`);
      });
  };

  const postRestaurantWorkingHours = (resID, p_id, data) => {
    restaurantService
      .post_restaurant_working_hours_data(resID, data)
      .then((response) => {
        getRestaurantWorkingHours(resID, p_id);
      })
      .catch((error) => {
        alert(`Hata oluştu`);
      });
  };
  const patchRestaurantWorkingHours = (resID, p_id, data) => {
    restaurantService
      .patch_restaurant_working_hours_data(resID, data)
      .then((response) => {
        getRestaurantWorkingHours(resID, p_id);
      })
      .catch((error) => {
        alert(`Hata oluştu`);
      });
  };

  const getRestaurantPaymentTypeList = () => {
    restaurantService
      .get_restaurant_payment_type_list()
      .then((response) => {
        setpaymentTypeList(response);
      })
      .catch((error) => {
        alert(`Hata oluştu`);
      });
  };
  const getRestaurantPaymentTypesData = (resID, p_id) => {
    restaurantService
      .get_restaurant_payment_types_data(resID, p_id)
      .then((response) => {
        setRestaurantPaymentTypes(response);
      })
      .catch((error) => {
        alert(`Hata oluştu`);
      });
  };
  const postRestaurantPaymentTypesData = (resID, p_id, data) => {
    restaurantService
      .post_restaurant_payment_types_data(resID, data)
      .then((response) => {
        getRestaurantPaymentTypesData(resID, p_id);
      })
      .catch((error) => {
        alert(`Hata oluştu`);
      });
  };

  const postRestaurantRegister = (data) => {
    restaurantService
      .post_restaurant_register(data)
      .then((response) => {
        success(`Başarıyla Oluşturuldu`);
        history.push(Paths.tasks);
        setOperationSidebarPage(0);
        getApplicationStatuList();
      })
      .catch((error) => {
        if (error.response.data.message) {
          alertUrgent(error.response.data.message);
        } else {
          alert(`Hata oluştu`);
        }
      });
  };

  const postRestaurantRegisterTech = (data) => {
    restaurantService
      .post_restaurant_register_tech(data)
      .then((response) => {
        success(`Başarıyla Oluşturuldu`);
      })
      .catch((error) => {
        if (error.response.data.message) {
          alertUrgent(error.response.data.message);
        } else {
          alert(`Hata oluştu`);
        }
      });
  };

  const postSellerRestaurantRegister = (data) => {
    restaurantService
      .post_seller_restaurant_register(data)
      .then((response) => {
        success(`Başarıyla Oluşturuldu`);
        history.push(Paths.sellerMyPrim);
        setSellerSidebarPage(1);
      })
      .catch((error) => {
        if (error.response.data.message) {
          alert(error.response.data.message);
        } else {
          alert(`Hata oluştu`);
        }
      });
  };

  const getReportsData = (resID, data) => {
    restaurantService
      .get_reports_data(resID, data)
      .then((response) => {
        setReportData(response);
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          setTimeout(() => {
            getReportsData(data);
          }, 2500);
        } else {
          alert(err?.response?.data?.message);
        }
      });
  };

  const getRestaurantActiveOffer = (resID) => {
    restaurantService
      .get_restaurant_active_offer(resID)
      .then((response) => {
        setRestaurantActiveOffer(response);
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          setTimeout(() => {
            getRestaurantActiveOffer(resID);
          }, 2500);
        } else {
          alert(err?.response?.data?.message);
        }
      });
  };
  const getRestaurantOfferData = (resID) => {
    restaurantService
      .get_restaurant_offer_data(resID)
      .then((response) => {
        setRestaurantOffer(response);
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          setTimeout(() => {
            getRestaurantOfferData(resID);
          }, 2500);
        } else {
          alert(err?.response?.data?.message);
        }
      });
  };
  const patchRestaurantOfferData = (resID, data) => {
    restaurantService
      .patch_restaurant_offer(resID, data)
      .then((response) => {
        getRestaurantOfferData(resID);
        getRestaurantActiveOffer(resID);
      })
      .catch((error) => {
        alert(`Hata oluştu`);
      });
  };

  const getPaymentTracker = (data) => {
    restaurantService
      .get_payment_tracker(data)
      .then((response) => {
        setPaymentTracker(response);
      })
      .catch((err) => {
        if (err.response.data.message == "Invalid page.") {
          data.setPage(0);
          getPaymentTracker({
            searchTerm: data.searchTerm,
            page: 1,
            stayed: data.stayed,
          });
        } else if (err?.response?.status === 401) {
          setTimeout(() => {
            let data = {
              searchValue: "",
              startDate: "",
              endDate: "",
              stayed: "home",
              processed_by: "",
            };
            getPaymentTracker(data);
          }, 2500);
        } else {
          alert(err?.response?.data?.message);
        }
      });
  };

  const getPaymentTrackerV2 = (data) => {
    restaurantService
      .get_payment_tracker_v2(data)
      .then((response) => {
        setPaymentTracker(response);
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          setTimeout(() => {
            getPaymentTrackerV2(data);
          }, 2500);
        } else {
          alert(err?.response?.data?.message);
        }
      });
  };

  const getPaymentTrackerWithStayed = (searchValue, page) => {
    restaurantService
      .get_payment_tracker_with_stayed(searchValue, page)
      .then((response) => {
        setPaymentTracker(response);
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          setTimeout(() => {
            getPaymentTrackerWithStayed("", 1);
          }, 2500);
        } else {
          alert(err?.response?.data?.message);
        }
      });
  };

  const patchPaymentTracker = (resID, data) => {
    restaurantService
      .patch_payment_tracker(resID, data)
      .then((response) => {
        if (data.note === "pasif") {
          success("Teklif kaldırıldı");
        }
        let v1 = {
          searchValue: "",
          startDate: "",
          endDate: "",
          stayed: "home",
          processed_by: "",
        };
        getPaymentTrackerV2(v1);
      })
      .catch((error) => {
        alert(`Hata oluştu`);
      });
  };
  const postResetPassword = (resID) => {
    restaurantService
      .post_reset_password(resID)
      .then((response) => {
        info("Şifre yenileme linki gönderildi");
      })
      .catch((error) => {
        alert(`Şifre yenilemede bir hata oluştu`);
      });
  };
  const postRestaurantBlock = (id, resID, data) => {
    restaurantService
      .post_restaurant_block(resID, data)
      .then((response) => {
        if (id == "tracker") {
          let data = {
            searchValue: "",
            startDate: "",
            endDate: "",
            stayed: "home",
            processed_by: "",
          };
          getPaymentTrackerV2(data);
        } else {
          getOfficeuserDetail(resID);
        }

        info("Bloke edildi");
      })
      .catch((error) => {
        alert(`Hata oluştu tekrar deneyiniz`);
      });
  };

  const restaurantBlockV2 = (data) => {
    restaurantService
      .post_restaurant_block_v2(data)
      .then((response) => {
        info("Bloke edildi");
      })
      .catch((error) => {
        alert(`Hata oluştu tekrar deneyiniz`);
      });
  };

  const getRestaurantMenuList = (resID, secret_key) => {
    restaurantService
      .get_restaurant_menu_list(resID, secret_key)
      .then((response) => {
        setRestaurantMenuList(response);
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          setTimeout(() => {
            getRestaurantMenuList(resID, secret_key);
          }, 2500);
        } else {
          alert(err?.response?.data?.message);
        }
      });
  };

  const postRestaurantMenuProductStatu = (resID, data) => {
    let keyurun = infoPersist("Ürün durumu değiştiriliyor.");
    data.setBulkDisable(true);
    restaurantService
      .post_restaurant_menu_product_status(resID, data.statusData)
      .then((response) => {
        if (data.platform == "Gofody") {
          if (data.statusData.is_active) {
            success(`${data.prod_name} adlı ürün Gofody platformunda açıldı.`);
          } else {
            success(
              `${data.prod_name} adlı ürün Gofody platformunda kapatıldı.`
            );
          }
        } else if (data.platform == "Trendyol") {
          if (data.statusData.is_active) {
            success(
              `${data.prod_name} adlı ürün Trendyol platformunda açıldı.`
            );
          } else {
            success(
              `${data.prod_name} adlı ürün Trendyol platformunda kapatıldı.`
            );
          }
        } else if (data.platform == "Getir") {
          if (data.statusData.is_active) {
            success(`${data.prod_name} adlı ürün Getir platformunda açıldı.`);
          } else {
            success(
              `${data.prod_name} adlı ürün Getir platformunda kapatıldı.`
            );
          }
        }

        closeSnack(keyurun);

        getRestaurantMenuList(resID, data.statusData?.secret_key);
      })
      .catch((error) => {
        closeSnack(keyurun);
        if (error?.response?.status === 401) {
          setTimeout(() => {
            postRestaurantMenuProductStatu(resID, data);
          }, 2500);
        } else {
          alert(error?.response?.data?.message);
        }
      });
  };
  const getApplicationTransferList = () => {
    restaurantService
      .officeuser_list()
      .then((response) => {
        setBackofficeList(response);
      })
      .catch((err) => {
        alert(err?.response?.data?.message);
      });
  };

  const getOfficeUserListWithRole = (data) => {
    restaurantService
      .officeuser_list_with_role(data)
      .then((response) => {
        setBackofficeListWithRole(response);
      })
      .catch((err) => {
        alert(err?.response?.data?.message);
      });
  };

  const getOfficeUserListWithRoleCollector = (data) => {
    restaurantService
      .officeuser_list_with_role(data)
      .then((response) => {
        setBackofficeCollectorList(response);
      })
      .catch((err) => {
        alert(err?.response?.data?.message);
      });
  };

  const getOfficeUserListRenewal = (data) => {
    restaurantService
      .officeuser_list_renewal(data)
      .then((response) => {
        setBackofficeListRenewal(response);
      })
      .catch((err) => {
        alert(err?.response?.data?.message);
      });
  };

  const patchApplicationTransfer = (id, data) => {
    restaurantService
      .patch_aplication_transfer(id, data)
      .then((response) => {
        getApplicationStatuList();
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };
  const getApplicationNoteList = (id) => {
    restaurantService
      .get_application_note_list(id)
      .then((response) => {
        setApplicationNoteList(response);
        info("Yeni Note listesi getirildi");
      })
      .catch((err) => {
        alert(err?.response?.data?.message);
      });
  };

  const postApplicationNote = (id, data) => {
    restaurantService
      .post_application_note(id, data)
      .then((response) => {
        getApplicationNoteList(id);
        success(`Yeni not eklendi`);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const applicationNotesFunc = (data) => {
    restaurantService
      .application_notes(data)
      .then((response) => {
        setApplicationNotes(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const applicationNotesCreateFunc = (data) => {
    restaurantService
      .application_notes_create(data)
      .then((response) => {
        applicationNotesFunc(data);
        data.setNote("");
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const blockedStatusReasonsFunc = () => {
    restaurantService
      .blocked_status_reasons()
      .then((response) => {
        setBlockedReasons(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const blockedStatusReasonsFuncv2 = (data) => {
    restaurantService
      .blocked_status_reasons_v2(data)
      .then((response) => {
        setBlockedReasonsv2(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const blockedReasonFunc = (data) => {
    restaurantService
      .blocked_reason(data)
      .then((response) => {
        getApplicationStatuList();
        data.setApplicationActionsModal(false);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const stayTimeFunc = (data) => {
    restaurantService
      .stay_time(data)
      .then((response) => {
        if (data.pageStatus == "home") {
          if (data.table_type == "application") {
            getApplicationStatuList();
          } else {
            if (
              JSON.parse(localStorage.getItem("st-officeuser-info")).role ===
              "setup"
            ) {
              let data = {
                searchTerm: "",
                page: 1,
                stayed: "home",
              };
              getPaymentTracker(data);
            } else {
              let data = {
                searchValue: "",
                startDate: "",
                endDate: "",
                stayed: "home",
                processed_by: "",
              };
              getPaymentTrackerV2(data);
            }
          }
        } else {
          if (data.table_type == "application") {
            getApplicationStatuListStayed("");
          } else {
            if (
              JSON.parse(localStorage.getItem("st-officeuser-info")).role ===
              "setup"
            ) {
              let data = {
                searchTerm: "",
                page: 1,
                stayed: "stay",
              };
              getPaymentTracker(data);
            } else {
              let data = {
                searchValue: "",
                startDate: "",
                endDate: "",
                stayed: "stay",
                processed_by: "",
              };
              getPaymentTrackerV2(data);
            }
          }
        }
        data.setApplicationActionsModal(false);
        data.setSelectedActionType("normal");
        data.setNote("");
        data.setSelectedDeleteReason("null");
        data.setStartDate(
          data.setHours(
            setMinutes(new Date(), moment().format("mm")),
            moment().format("HH")
          )
        );
        data.setApplicationId(null);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const getTrackerNotes = (data) => {
    restaurantService
      .get_tracker_notes(data)
      .then((response) => {
        response?.creators?.unshift({
          user_id: "",
          full_name: "Tümü",
          note_count: "-",
        });
        setTrackerNotes(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const postTrackerNotes = (data) => {
    restaurantService
      .post_tracker_notes(data)
      .then((response) => {
        getTrackerNotes(data);
        data.setNote("");
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const operationPrimFunc = () => {
    restaurantService
      .operation_prim()
      .then((response) => {
        setOperationPrim(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const sellerMyPrimFunc = () => {
    restaurantService
      .seller_my_prim()
      .then((response) => {
        setSellerMyPrim(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const sellerMyPrimHistoryFunc = (data) => {
    restaurantService
      .seller_my_prim_history(data)
      .then((response) => {
        setSellerMyPrimHistory(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const eftCreateFunc = (data) => {
    restaurantService
      .eft_create(data)
      .then((response) => {
        data.setPayedModal(false);
        data.setFormData(false);
        getOfferBasketFunc({
          id: data.r_id,
        });
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const eftListFunc = (data) => {
    restaurantService
      .eft_list(data)
      .then((response) => {
        setEftList(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const eftChangeFunc = (data) => {
    restaurantService
      .eft_change(data)
      .then((response) => {
        eftListFunc({ page: 1 });
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const getOrderRepeats = async (id) => {
    try {
      const result = await restaurantService.get_order_repeats(id);

      setOrderRepeats(result);
    } catch (error) {
      alert(error?.response?.data?.message);
    }
  };

  const patchRestaurantTransfer = async (id, data) => {
    try {
      await restaurantService.patch_restaurant_transfer(id, data);
      history.push("/operation/restaurants");
    } catch (error) {
      alert(error?.response?.data?.message);
    }
  };

  const getPaymentTypes = async () => {
    try {
      const response = await restaurantService.get_all_officeuser_payments();
      setPaymentTypes(response);
    } catch (error) {
      alert(error?.response?.data?.message);
    }
  };

  const getPlatforms = async () => {
    try {
      const response = await restaurantService.get_platform_list();
      setPlatforms(response);
    } catch (error) {
      alert(error?.response?.data?.message);
    }
  };

  const getApplicationsStatusList = async () => {
    try {
      const response = await restaurantService.get_applications_status_list();
      setApplicationsList(response);
    } catch (error) {
      alert(error?.response?.data?.message);
    }
  };

  const getProductHeaderList = (restofficial_id) => {
    restaurantService
      .get_product_header(restofficial_id)
      .then((response) => {
        setProductHeaderList(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };
  const getProductList = (restofficial_id) => {
    restaurantService
      .get_product_list(restofficial_id)
      .then((response) => {
        setProductList(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };
  const menuProductCreateFunc = (data) => {
    restaurantService
      .menu_product_create(data)
      .then((response) => {
        getProductHeaderList(data?.sendingObj?.restofficial_id);
        data.setIsFlipped(false);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const optProductListFunc = (id) => {
    restaurantService
      .opt_product_list(id)
      .then((response) => {
        setOptProductList(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const menuDetailFunc = (data) => {
    restaurantService
      .menu_detail(data)
      .then((response) => {
        setMenuDetailObj(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const menuDetailChangeFunc = (data) => {
    restaurantService
      .menu_detail_change(data)
      .then((response) => {
        success("menü güncellendi");
        getProductHeaderList(data?.restofficial_id);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const menuProductUpdateFunc = (data) => {
    restaurantService
      .menu_product_update(data)
      .then((response) => {
        success("Ürün Güncellendi.");
        data?.setIsFlipped(false);
        getProductHeaderList(data?.menuObj?.restofficial_id);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const menuBulkUpdateFunc = (data) => {
    restaurantService
      .menu_bulk_update(data)
      .then((response) => {
        getProductHeaderList(data?.restofficial_id);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const menuHeaderDetailChangeFunc = (data) => {
    restaurantService
      .menu_header_detail_change(data)
      .then((response) => {
        success("Menü Başlığı Güncellendi");
        getProductHeaderList(data?.restofficial_id);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const restaurantEnduserAppListFunc = (data) => {
    restaurantService
      .restaurant_enduser_app_list(data)
      .then((response) => {
        setRestaurantEnduserAppList(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };
  const menuHeaderPhotoUploadFunc = (data) => {
    restaurantService
      .menu_header_photo_upload(data)
      .then((response) => {
        getProductHeaderList(data?.restofficial_id);
        success("Fotoğraf güncellendi");
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };
  const menuHeaderSortFunc = (data) => {
    restaurantService
      .menu_header_sort(data)
      .then((response) => {
        getProductHeaderList(data?.id);
        success("Başlıklar sıralandı.");
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          setTimeout(() => {
            menuHeaderSortFunc(data);
          }, 2500);
        } else {
          alert(err?.response?.data?.message);
        }
      });
  };

  const menuProductSortFunc = (data) => {
    restaurantService
      .menu_product_sort(data)
      .then((response) => {
        getProductHeaderList(data?.id);
        success("Ürünler sıralandı.");
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          setTimeout(() => {
            menuProductSortFunc(data);
          }, 2500);
        } else {
          alert(err?.response?.data?.message);
        }
      });
  };

  const menuPhotoUploadFunc = (data) => {
    restaurantService
      .menu_photo_upload(data)
      .then((response) => {
        getProductHeaderList(data?.restofficial_id);
        success("Fotoğraf güncellendi");
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };
  const sendPushNotificationFunc = (data) => {
    restaurantService
      .send_push_notification(data)
      .then(() => {
        success("Bildirim Gönderildi.");
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const menuBulkUpdatePriceFunc = (data) => {
    restaurantService
      .menu_bulk_update_price(data)
      .then((response) => {
        success("Ürünler Güncellendi.");
        getProductHeaderList(data?.restofficial_id);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const resDetailInfoFunc = (restofficial_id) => {
    restaurantService
      .res_detail_info(restofficial_id)
      .then((res) => {
        setResDetailInfo(res);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };
  const resChangeDetailFunc = (data) => {
    restaurantService
      .res_detail(data)
      .then((response) => {
        if (data.hasOwnProperty("payment_types")) {
          resDetailInfoFunc(data.restofficial_id);
          success(`Ödeme seçenekleri değiştirildi.`);
        } else if (data.hasOwnProperty("working_hours")) {
          resDetailInfoFunc(data.restofficial_id);
          success(`Çalışma saatleri güncellendi.`);
        } else if (data.hasOwnProperty("service_area")) {
          resDetailInfoFunc(data.restofficial_id);
          success(`Teslimat bölgesi ayarlandı.`);
        } else if (data.hasOwnProperty("show_rating")) {
          resDetailInfoFunc(data.restofficial_id);
          success(`Yorum ve Puan durumu değiştirildi.`);
        } else if (data.hasOwnProperty("order_discount_percent")) {
          resDetailInfoFunc(data.restofficial_id);
          success(`Tüm ürünlere indirim oranı ayarlandı.`);
        } else if (data.hasOwnProperty("first_order_discount_amount")) {
          resDetailInfoFunc(data.restofficial_id);
          success(`İlk siparişe özel kupon tutarı ayarlandı.`);
        } else if (data.hasOwnProperty("cashback_percent")) {
          resDetailInfoFunc(data.restofficial_id);
          success(`Her siparişe verilen sepetpay oranı ayarlandı.`);
        } else if (data.hasOwnProperty("first_order_discount_min_price")) {
          resDetailInfoFunc(data.restofficial_id);
          success(`İlk siparişe indirim minimum tutarı değiştirildi.`);
        }
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };
  const menuHeaderCreateFunc = (data) => {
    restaurantService
      .menu_header_create(data)
      .then((response) => {
        getProductHeaderList(data?.restofficial_id);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };
  const orderRatingListFunc = (data) => {
    restaurantService
      .order_rating_list(data)
      .then((response) => {
        setRestaurantRatingList(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const orderRatingChangeFunc = (data) => {
    restaurantService
      .order_rating_change(data)
      .then((response) => {
        success("Yorum Cevaplandı.");
        orderRatingListFunc({
          page: data.page,
          pageType: data.pageType,
          restofficial_id: data.restofficial_id,
        });
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const getOfferBasketFunc = (data) => {
    restaurantService
      .get_offer_basket(data)
      .then((response) => {
        setOfferProducts(response);
      })
      .catch((error) => {
        if (error?.response?.data?.message == "Sepet bulunamadı!") {
          setOfferProducts([]);
        } else {
          alert(error?.response?.data?.message);
        }
      });
  };

  const offerBasketFunc = (data) => {
    restaurantService
      .offer_basket(data)
      .then((response) => {
        getOfferBasketFunc({
          id: data.id,
        });
        offerProductListFunc({
          id: data.id,
        });
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const restaurantEnduserDetailFunc = (user_id, id) => {
    restaurantService
      .restaurant_enduser_detail(user_id, id)
      .then((response) => {
        setEnduserDetail(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const offerBasketChangeFunc = (data) => {
    restaurantService
      .offer_basket_change(data)
      .then((response) => {
        if (data?.sendObj.hasOwnProperty("is_selected")) {
          offerProductListFunc({
            id: data.id,
          });
        }
        getOfferBasketFunc({
          id: data.id,
        });
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const restaurantEnduserOrdersFunc = (data) => {
    restaurantService
      .restaurant_enduser_orders(data)
      .then((response) => {
        setEnduserOrders(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const offerProductListFunc = (data) => {
    restaurantService
      .offer_product_list(data)
      .then((response) => {
        setOfferProductList(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const restaurantEnduserCashBackUptFunc = (data) => {
    restaurantService
      .restaurant_enduser_cashback(data)
      .then((response) => {
        success("Sepetpay tutarı başarıyla eklendi");
        restaurantEnduserDetailFunc(data?.enduser_id);
        setSepetPayAmound(0);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const createOfferFunc = (data) => {
    restaurantService
      .create_offer(data)
      .then((response) => {
        getOfferBasketFunc({
          id: data.id,
        });
        offerProductListFunc({
          id: data.id,
        });
        success("Teklif oluşturuldu.");
      })
      .catch((error) => {
        if (data.sendObj.free_days === null) {
          alert("Bedava gün sayısı giriniz.");
        } else if (data.sendObj.trial_days === null) {
          alert("Deneme Süresi giriniz.");
        } else {
          alert(error?.response?.data?.message);
        }
      });
  };

  const sendOfferPopupFunc = (userId, data, page, taskId) => {
    restaurantService
      .change_offer_time(userId, data)
      .then((response) => {
        if (page === "task") {
          taskListDetailFunc({ task_id: taskId });
        } else {
          getOfferBasketFunc({
            id: userId,
          });
        }
        success("Popup gönderildi.");
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const changeOfferTime = (userId, data) => {
    restaurantService
      .change_offer_time(userId, data)
      .then((response) => {
        success("Teklif süresi değiştirildi.");
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const offerHistoriesListFunc = (data) => {
    restaurantService
      .offer_histories_list(data)
      .then((response) => {
        setOfferHistoriesList(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const sepetAppDemoDetailFunc = (data) => {
    restaurantService
      .sepet_app_demo_detail(data)
      .then((response) => {
        setSepetAppDemoDetail(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const sepetAppDemo = (data) => {
    restaurantService
      .sepet_app_demo(data)
      .then((res) => {
        sepetAppDemoDetailFunc({ rest_id: data.restofficial });
        success("Demo Açıldı.");
      })
      .catch((error) => {
        sepetAppDemoDetailFunc({ rest_id: data.restofficial });
        alert(error?.response?.data?.message);
      });
  };

  const sepetAppDemoDelete = (data) => {
    restaurantService
      .sepet_app_demo_delete(data)
      .then((res) => {
        sepetAppDemoDetailFunc({ rest_id: data.restofficial });
        success("Demo Kaldırıldı.");
      })
      .catch((error) => {
        sepetAppDemoDetailFunc({ rest_id: data.restofficial });
        alert(error?.response?.data?.message);
      });
  };

  const offerHistoryDetailFunc = (data) => {
    restaurantService
      .offer_history_detail(data)
      .then((response) => {
        setOfferHistoryDetail(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const specialOfferPatch = (id, data) => {
    restaurantService
      .special_offer_patch(id, data)
      .then((res) => {
        getOfferBasketFunc({ id: data?.id });
        success("Özel Teklif onaya gönderildi.");
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const getOutboundAmount = () => {
    restaurantService
      .get_outbound_amount()
      .then((res) => {
        setOutboundCount(res);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const getOutboundReports = (data) => {
    restaurantService
      .get_reports_outbound(data)
      .then((res) => {
        setOutbountReports(res);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const calculatePrimOutbound = () => {
    restaurantService
      .calculate_prim_outbound(data)
      .then((res) => {
        setPrimOutbound(res);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const techSupportListFunc = (data) => {
    restaurantService
      .tech_support_list(data)
      .then((res) => {
        setTechSupportList(res);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const techSupportDetail = (data) => {
    restaurantService
      .tech_support_detail(data)
      .then((res) => {
        techSupportListFunc({
          stayed: data.stayed,
          searchTerm: data.searchTerm,
        });
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const notificationListFunc = () => {
    restaurantService
      .notification_list()
      .then((res) => {
        setNotificationList(res);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const notificationDetailFunc = (data) => {
    restaurantService
      .notification_detail(data)
      .then((res) => {
        notificationListFunc();
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const callCenterRestSearchFunc = (data) => {
    restaurantService
      .call_center_rest_search(data)
      .then((res) => {
        setCallCenterRestList(res);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const patchOfficeuserDetailFunc = (data) => {
    restaurantService
      .patch_officeuser_detail(data)
      .then((res) => {
        success("İşlem başarılı.");
        if (data.sendObj.hasOwnProperty("accept_support")) {
          getOfficeUserListWithRole({
            role: "tech",
          });
          data.setSelectedTechStatus(!data.selectedTechStatus);
        }
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const patchOfficeuserDetailFuncCollector = (data) => {
    restaurantService
      .patch_officeuser_detail_collector(data)
      .then((res) => {
        success("İşlem başarılı.");
        if (data.sendObj.hasOwnProperty("accept_data")) {
          getOfficeUserListWithRoleCollector({
            role: "collector",
          });
          data.setSelectedCollectorStatus(!data.selectedCollectorStatus);
        }
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const getTotalAmounts = (id) => {
    restaurantService
      .get_total_amounts(id)
      .then((res) => {
        setTotalAmounts(res);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const sendSmsToCustomer = (data) => {
    restaurantService
      .send_sms_to_customer(data)
      .then((res) => {
        success("Sms Gönderildi.");
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const sendPaymentToCollector = (data) => {
    restaurantService
      .send_payment_to_collector(data)
      .then((res) => {
        data.setStayTimeModal(false);
        data.stayFuncReRender();
        data.setStartDate(
          setHours(
            setMinutes(new Date(), moment().format("mm")),
            moment().format("HH")
          )
        );
        data.setStayNote("");
        success("Tahsilat birimine aktarıldı");
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const getTicketTypes = (userRole) => {
    restaurantService
      .get_ticket_types(userRole)
      .then((res) => {
        setTicketTypes(res);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const sendTicket = (data, data2) => {
    restaurantService
      .send_ticket(data)
      .then((res) => {
        success("Ticket Gönderildi.");
        if (data2 === "modal") return;
        getTrackerNotes(data2);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const getActiveTicketList = (stayStatus, searchText, start, end) => {
    restaurantService
      .get_active_ticket_list(stayStatus, searchText, start, end)
      .then((res) => {
        setActiveTicketList(res);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const changeTicketStatus = (data, stayStatus, searchText, start, end) => {
    restaurantService
      .change_ticket_status(data, start, end)
      .then((res) => {
        getActiveTicketList(stayStatus, searchText, start, end);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const ticketDetailChange = (data, stayStatus, searchText, start, end) => {
    restaurantService
      .ticket_detail_change(data)
      .then((res) => {
        getActiveTicketList(stayStatus, searchText, start, end);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const getSentTickets = (searchText, start, end, page) => {
    restaurantService
      .get_sent_tickets(searchText, start, end, page)
      .then((res) => {
        setSentTickets(res);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const getCompletedTickets = (searchText, start, end, page) => {
    restaurantService
      .get_completed_tickets(searchText, start, end, page)
      .then((res) => {
        setCompletedTickets(res);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  // * ivr data exel

  const ivrExelUploadFunc = (formData) => {
    restaurantService
      .ivr_exel_upload(formData)
      .then((res) => {
        success("Başarılı");
        ivrDataInfoFunc();
      })
      .catch((error) => {
        alert(error?.response?.data);
      });
  };

  const zeroDataExelUploadFunc = (formData) => {
    restaurantService
      .zero_data_exel_upload(formData)
      .then((res) => {
        success("Başarılı");
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const ivrSendIvrFunc = (data) => {
    restaurantService
      .ivr_send_ivr(data)
      .then((res) => {
        ivrDataInfoFunc();
        success(
          `${data.sendObj.count} adet ivr datası ${data.workerSelect.full_name} aktarıldı.`
        );
        data.setCount(0);
        data.setWorkerSelect(null);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const ivrDataInfoFunc = () => {
    restaurantService
      .ivr_data_info()
      .then((response) => {
        setIvrDataInfo(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  // * Urgent Setup

  const sendUrgentSetupFunc = (data) => {
    restaurantService
      .send_urgent_setup(data)
      .then((response) => {
        getApplicationStatuList("", "", 1);
        success("Acil kurulum isteği gönderildi.");
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const acceptUrgentSetupFunc = (data) => {
    restaurantService
      .accept_urgent_setup(data)
      .then((response) => {
        getApplicationStatuList("", "", 1);
        success("Acil kurulum kabul edildi.");
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const getStateHistory = (data) => {
    restaurantService
      .get_state_history(data)
      .then((response) => {
        setStateHistory(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const sendUrgentCollectorFunc = (data, setStayTimeModal, stayNote) => {
    restaurantService
      .send_urgent_to_collector(data, stayNote)
      .then((response) => {
        success("Acil tahsilat isteği gönderildi.");
        let data = {
          searchTerm: "",
          page: 1,
          stayed: "home",
        };
        getPaymentTracker(data);
        setStayTimeModal(false);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const acceptUrgentCollectorFunc = (data) => {
    restaurantService
      .accept_urgent_collector(data)
      .then((response) => {
        let v1 = {
          searchValue: "",
          startDate: "",
          endDate: "",
          stayed: "home",
          processed_by: "",
        };
        getPaymentTrackerV2(v1);
        success("Acil tahsilat kabul edildi.");
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const getStateHistoryDaily = () => {
    restaurantService
      .get_state_history_daily(data)
      .then((response) => {
        setStateHistoryDaily(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const collectorRestaurantListFunc = (data) => {
    restaurantService
      .collector_restaurant_list(data)
      .then((response) => {
        setCollectorRestaurantList(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const collectorRestaurantOffersFunc = (data) => {
    restaurantService
      .collector_restaurant_offers(data)
      .then((response) => {
        setCollectorRestaurantOffers(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const collectorRestaurantOrdersFunc = (data) => {
    restaurantService
      .collector_restaurant_orders(data)
      .then((response) => {
        setCollectorRestaurantOrders(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const courierTrackerDemoStatusListFunc = (data) => {
    restaurantService
      .courier_tracker_demo_status_list(data)
      .then((response) => {
        setCourierTrackerStatusList(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const courierTrackerDemoChangeFunc = (data) => {
    restaurantService
      .courier_tracker_demo_change(data)
      .then((response) => {
        success("Kurye Takip Demo Aktifleştirildi..");
        window.location.reload();
      })
      .catch((error) => {
        window.location.reload();
        alert(error?.response?.data?.message);
      });
  };

  const courierTrackerDemoDeleteFunc = (data) => {
    restaurantService
      .courier_tracker_demo_delete(data)
      .then((response) => {
        success("Kurye Takip Demo Kaldırıldı.");
        window.location.reload();
      })
      .catch((error) => {
        window.location.reload();
        alert(error?.response?.data?.message);
      });
  };

  const testOrderFunc = (data) => {
    restaurantService
      .courier_tracker_demo_test_order(data)
      .then((response) => {
        success("Test Siparişi Gönderildi.");
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const getTaskList = () => {
    restaurantService
      .get_task_list()
      .then((response) => {
        setTaskList(response);
      })
      .catch((error) => {
        if (error?.response?.data?.error_code === "no_task") {
          newTaskCreateFunc();
        } else {
          alert(error?.response?.data?.message);
        }
      });
  };

  const taskListDetailFunc = (data) => {
    restaurantService
      .task_list_detail(data)
      .then((response) => {
        setTaskList(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const newTaskCreateFunc = () => {
    restaurantService
      .new_task_create()
      .then((response) => {
        setTaskList(response);
        if (
          JSON.parse(localStorage.getItem("st-officeuser-info")).role === "tech"
        ) {
          getActiveTasks();
          resTechPendingFunc();
        }
        (async () => {
          const res = await managementService.get_current_state();
          if (res?.state === "out_call" || res?.state === "setup") {
            let data = {
              sendObj: {
                state: "active",
              },
            };

            await restaurantService.change_state_history(data);
            setUserStatus("active");
          }
        })();
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const calendarListFunc = () => {
    restaurantService
      .calendar_list()
      .then((response) => {
        setCalendarList(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const personalTemplateNoteListFunc = () => {
    restaurantService
      .personal_template_note_list()
      .then((response) => {
        setPersonalTemplateNoteList(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const personalTemplateNoteCreateFunc = (data) => {
    restaurantService
      .personal_template_note_create(data)
      .then((response) => {
        personalTemplateNoteListFunc();
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const personalTemplateNoteDeleteFunc = (data) => {
    restaurantService
      .personal_template_note_delete(data)
      .then((response) => {
        personalTemplateNoteListFunc();
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const personalTemplateNoteSortFunc = (data) => {
    restaurantService
      .personal_template_note_sort(data)
      .then((response) => {
        personalTemplateNoteListFunc();
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const calendarAllListFunc = () => {
    restaurantService
      .calendar_all_list()
      .then((response) => {
        setCalendarAllList(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const restOfficialTaskActionListFunc = (data) => {
    restaurantService
      .restofficial_task_action_list(data)
      .then((response) => {
        setRestofficialTaskActionList(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const restOfficialTaskActionFunc = (data) => {
    restaurantService
      .restofficial_task_action(data)
      .then((response) => {
        getTrackerNotes({
          id: 1,
          app_id: data?.rest_id,
        });
        calendarAllListFunc();
        successV2("İşlem başarılı.", 5000);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const taskActionFunc = (data) => {
    restaurantService
      .task_action(data)
      .then((response) => {
        getTaskList();
        calendarListFunc();
        calendarAllListFunc();
        if (response?.restofficial_id) {
          let data = {
            sendObj: {
              state: "setup",
            },
          };

          restaurantService.change_state_history(data);
          setUserStatus("setup");
          history.push(`/restaurants/${response?.restofficial_id}`);
        } else {
          if (
            JSON.parse(localStorage.getItem("st-officeuser-info")).role !==
            "tech"
          ) {
            newTaskCreateFunc();
          } else {
            getActiveTasks();
          }
        }
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const taskRevertFunc = (data) => {
    restaurantService
      .task_revert(data)
      .then((response) => {
        getTaskList();
        calendarListFunc();
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const applicationDataUpdateFunc = (id, data) => {
    restaurantService
      .application_data_update(id, data)
      .then((response) => {
        successV2("Güncelleme gerçekleşti", 5000);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const patchRestOfficialFunc = (data, id) => {
    restaurantService
      .patch_restofficial(data)
      .then((response) => {
        // getTaskList();
        taskListDetailFunc({ task_id: id });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const taskApplicationUpdateFunc = (data) => {
    restaurantService
      .task_application_update(data)
      .then((response) => {
        getTaskList();
        successV2("Güncelleme başarılı.", 5000);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const restPrevOfferHistoryFunc = (data) => {
    restaurantService
      .rest_prev_offer_history(data)
      .then((response) => {
        setRestPrevOfferHistory(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const taskDashboardFunc = (data) => {
    restaurantService
      .task_dashboard(data)
      .then((response) => {
        setTaskDashboardData(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const taskDashboardPatchFunc = (data) => {
    restaurantService
      .task_dashboard_patch(data)
      .then((response) => {
        taskDashboardFunc({ user_id: data.user_id });
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const taskPhoneSearchFunc = (data) => {
    restaurantService
      .task_phone_search(data)
      .then((response) => {
        (async () => {
          const res = await managementService.get_current_state();
          if (res?.state === "out_call") {
            let data = {
              sendObj: {
                state: "active",
              },
            };

            await restaurantService.change_state_history(data);
            setUserStatus("active");
          }
        })();
        if (response?.restofficial_id) {
          history.push(`/restaurants/${response?.restofficial_id}`);
        } else {
          taskListDetailFunc({
            task_id: response?.task_id,
          });
        }
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const taskResetFunc = (data) => {
    restaurantService
      .task_reset(data)
      .then((response) => {
        getTaskList();
        calendarListFunc();
        successV2("Randevu öne alındı.", 5000);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const restofficialRefsFunc = (data) => {
    restaurantService
      .restofficial_refs(data)
      .then((response) => {
        setRestofficialRefs(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const taskUnlockFunc = (data) => {
    restaurantService
      .task_unlock(data)
      .then((response) => {
        successV2("Kilitli task açıldı.", 2000);
        data.setLockedModal(false);
        data.setPassw("");
        getTaskList();
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
        data.setPassw("");
      });
  };

  const offerUnlockFunc = (data) => {
    restaurantService
      .offer_unlock(data)
      .then((response) => {
        successV2("Kilitli teklif açıldı.", 2000);
        getTaskList();
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
        data.setPassw("");
      });
  };

  const userGoToBreak = (data) => {
    restaurantService.change_state_history(data).then(() => {
      if (data.sendObj.state === "break") {
        setUserStatus("break");
      } else {
        setUserStatus("passive");
      }
    });
  };
  //* application Exel

  const applicationCountsFunc = (zeroProductId, ivrProductId) => {
    restaurantService
      .application_counts(zeroProductId, ivrProductId)
      .then((response) => {
        setApplicationCounts(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const applicationDeletedCountFunc = (data) => {
    restaurantService
      .application_deleted_count(data)
      .then((response) => {
        setApplicationDeletedCount(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const applicationDeletedFunc = (data) => {
    restaurantService
      .application_deleted(data)
      .then((response) => {
        success("İşlem başarılı.");
        applicationHistoryLogFunc(1);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const applicationHistoryLogFunc = (page) => {
    restaurantService
      .application_history_log(page)
      .then((response) => {
        setApplicationHistoryLog(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  // * ys_secret_key_v2

  const ysRemoteIdListFunc = (data) => {
    restaurantService
      .ys_remote_id_list(data)
      .then((response) => {
        setYsRemoteIdList(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const ysRemoteIdCreateFunc = (data) => {
    restaurantService
      .ys_remote_id_create(data)
      .then((response) => {
        success("İşlem başarılı");
        ysRemoteIdListFunc({ rest_id: data.res_id });
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const ysRemoteIdDeleteFunc = (data) => {
    restaurantService
      .ys_remote_id_delete(data)
      .then((response) => {
        success("İşlem başarılı");
        ysRemoteIdListFunc({ rest_id: data.res_id });
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const secretKeyPlatformSummaryFunc = (data) => {
    restaurantService
      .secret_key_platform_summary(data)
      .then((response) => {
        setSecretKeyPlatformSummary(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const secretKeyPlatformInfoFunc = (data) => {
    restaurantService
      .secret_key_platform_info(data)
      .then((response) => {
        setSecretKeyPlatformInfo(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const getActiveTasks = () => {
    restaurantService
      .get_active_tasks()
      .then((response) => {
        setActiveTasks(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const taskFromCalendarFunc = (data) => {
    restaurantService
      .task_from_calendar(data)
      .then((response) => {
        setTaskList(response);
        calendarListFunc();
        getActiveTasks();
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const patchCompanyInfosFunc = (data) => {
    restaurantService
      .patch_company_info(data)
      .then((response) => {
        success("Şirket bilgileri güncelendi.");
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const taskPullFunc = (data) => {
    restaurantService
      .task_pull(data)
      .then((response) => {
        setTaskList(response);
        calendarListFunc();
        getActiveTasks();
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const resTechPendingFunc = (data) => {
    restaurantService
      .res_tech_pending(data)
      .then((response) => {
        if (response?.length !== 0) {
          closeSnackbar(keyTicket);
          soundPlayF(10);
          keyTicket = enqueueSnackbar(
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: 300,
                height: 300,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={resTicket}
                      alt="ticket"
                      style={{ maxWidth: 200, height: "auto" }}
                    />
                    <div
                      style={{
                        fontSize: 20,
                        fontFamily: "SgBd",
                        textAlign: "center",
                      }}
                    >
                      Restoran tarafından teknik talep oluşturuldu.
                    </div>
                  </div>
                </div>

                <Button
                  style={{
                    color: "white",
                    backgroundColor: "#f53b62",
                    padding: "5px 20px",
                    textTransform: "capitalize",
                    fontFamily: "SgBd",
                    fontSize: 18,
                    marginTop: 10,
                  }}
                  onClick={() => {
                    history.push("/operation/tasks");
                    closeSnackbar(keyTicket);
                    newTaskCreateFunc();
                  }}
                >
                  Tamam
                </Button>
              </div>
            </div>,
            {
              preventDuplicate: true,
              persist: true,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
              },
            }
          );
        }
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const ticketBounceListFunc = () => {
    restaurantService
      .ticket_bounce_list()
      .then((response) => {
        if (response?.length !== 0) {
          closeSnackbar(keyTicket);
          setTechResTicketModal(false);
          setTicketBounceModal(true);
          setCurrentBounce(response[0]);
        } else {
          setTicketBounceModal(false);
        }
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const ticketBounceDefendFunc = (data) => {
    restaurantService
      .ticket_bounce_defend(data)
      .then((response) => {
        ticketBounceListFunc();
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const appointmentListFunc = (data) => {
    restaurantService
      .appointment_list(data)
      .then((response) => {
        setAppointmentList(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const appointmentQcdListFunc = (data) => {
    restaurantService
      .appointment_qcd_list(data)
      .then((response) => {
        setAppointmentQcdList(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const appointmentOrderListFunc = (data) => {
    restaurantService
      .appointment_order_list(data)
      .then((response) => {
        setAppointmentOrderList(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const appointmentTaskBackFunc = (data) => {
    restaurantService
      .appointment_task_back(data)
      .then((response) => {
        if (data.setNotPayedModal) {
          data.setNotPayedModal();
        }
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const productImport = (data, restId) => {
    restaurantService
      .product_import(data)
      .then((response) => {
        getProductHeaderList(restId);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const getProductHeaderPictureTemplate = () => {
    restaurantService
      .get_product_header_picture_template()
      .then((response) => {
        setProductHeaderPictureTemplate(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const getProductPictureTemplate = (id) => {
    restaurantService
      .get_product_picture_template(id)
      .then((response) => {
        setProductPictureTemplate(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const saveProductPictureTemplate = (data, restId) => {
    restaurantService
      .save_product_picture_template(data)
      .then((response) => {
        getProductHeaderList(restId);
        success("Ürün resmi başarıyla kaydedildi.");
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const createNewTaskFunc = (data) => {
    restaurantService
      .create_new_task(data)
      .then((response) => {
        success("Task sıraya alındı.");
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const renewalSummaryListFunc = (data) => {
    restaurantService
      .renewal_summary_list(data)
      .then((response) => {
        if (data.sendObj.state === "new") {
          setRenewalSummaryListNew(response);
        } else if (data.sendObj.state === "in_progress") {
          setRenewalSummaryListProgress(response);
        } else {
          setRenewalSummaryListCompleted(response);
        }
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const renewalSummaryOrderCounterFunc = (data) => {
    restaurantService
      .renewal_summary_order_counter(data)
      .then((response) => {
        setRenewalOrderCounterList(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const renewalSummaryPreviousOfferFunc = (data) => {
    restaurantService
      .renewal_summary_previous_offer(data)
      .then((response) => {
        setRenewalPreviousOffersList(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const offerInfoFunc = (data) => {
    restaurantService
      .offer_info(data)
      .then((response) => {
        setOfferDetail(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const deleteProductTemplateHeader = (id) => {
    restaurantService
      .delete_product_template_header(id)
      .then((response) => {
        success("Başlık Silindi.");
        getProductHeaderPictureTemplate();
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const createReminder = (data) => {
    restaurantService
      .create_reminder(data)
      .then((response) => {
        if (data.reminderType === "officeusertasktype") {
          taskDashboardFunc({
            user_id: data.fetchData?.user_id,
          });
        } else if (data.reminderType === "show") {
          getTaskList();
        } else {
          appointmentQcdListFunc({
            user_id:
              data?.fetchData?.selectedUser !== null
                ? data?.fetchData?.selectedUser?.user_id !== 0
                  ? data?.fetchData?.selectedUser?.user_id
                  : ""
                : "",
            type: data?.fetchData?.typeSummary,
          });
        }
        success("Hatırlatıcı Oluşturuldu");
        data.setNote("");
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const deleteProductHeader = (id, headerId) => {
    restaurantService
      .delete_product_template(id)
      .then((response) => {
        success("Ürün Silindi.");
        getProductPictureTemplate(headerId);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const patchReminder = (data) => {
    restaurantService
      .patch_reminder(data)
      .then((response) => {
        if (data.reminderType === "officeusertasktype") {
          taskDashboardFunc({
            user_id: data.fetchData?.user_id,
          });
        } else if (data.reminderType === "show") {
          getTaskList();
        } else {
          appointmentQcdListFunc({
            user_id:
              data?.fetchData?.selectedUser !== null
                ? data?.fetchData?.selectedUser?.user_id !== 0
                  ? data?.fetchData?.selectedUser?.user_id
                  : ""
                : "",
            type: data?.fetchData?.typeSummary,
          });
        }
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const patchReminderHistory = (data) => {
    restaurantService
      .patch_reminder_history(data)
      .then((response) => {
        if (data.reminderType === "officeusertasktype") {
          taskDashboardFunc({
            user_id: data.fetchData?.user_id,
          });
        } else if (data.reminderType === "show") {
          getTaskList();
        } else {
          appointmentQcdListFunc({
            user_id:
              data?.fetchData?.selectedUser !== null
                ? data?.fetchData?.selectedUser?.user_id !== 0
                  ? data?.fetchData?.selectedUser?.user_id
                  : ""
                : "",
            type: data?.fetchData?.typeSummary,
          });
        }
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const createProductTemplateHeader = (data) => {
    restaurantService
      .create_product_template_header(data)
      .then((response) => {
        success("Başlık Eklendi.");
        setOpenAddHeaderModal(false);
        getProductHeaderPictureTemplate();
      })
      .catch((error) => {
        setOpenAddHeaderModal(true);
        alert(error?.response?.data?.message);
      });
  };

  const editProductTemplateHeader = (data, id) => {
    restaurantService
      .edit_product_template_header(data, id)
      .then((response) => {
        success("Başlık Güncellendi");
        setOpenAddHeaderModal(false);
        getProductHeaderPictureTemplate();
      })
      .catch((error) => {
        setOpenAddHeaderModal(true);
        alert(error?.response?.data?.message);
      });
  };

  const editProductTemplate = (data, id, headerId) => {
    restaurantService
      .edit_product_template(data, id)
      .then((response) => {
        setOpenProductModal(false);
        success("Ürün Güncellendi.");
        getProductPictureTemplate(headerId);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const createProductTemplate = (data, headerId) => {
    restaurantService
      .create_product_template(data)
      .then((response) => {
        setOpenProductModal(false);
        success("Ürün Eklendi.");
        getProductPictureTemplate(headerId);
      })
      .catch((error) => {
        setOpenProductModal(true);
        alert(error?.response?.data?.message);
      });
  };

  const createPictureFromTask = (taskId, data) => {
    restaurantService
      .create_picture_from_task(taskId, data)
      .then((response) => {})
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const getPrimTable = (productId, renew) => {
    restaurantService
      .get_prim_table(productId, renew)
      .then((response) => {
        setPrimTable(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const createRestofficialReminder = (data) => {
    restaurantService
      .create_restofficial_reminder(data)
      .then((response) => {
        if (data.reminderType === "show") {
          getTaskList();
        } else {
          if (data.dashboardType === "renewal") {
            summaryRestDetailFunc({
              rest_id: data?.fetchData?.rest_id,
              sendObj: {
                state: "new",
              },
            });
            summaryRestDetailFunc({
              rest_id: data?.fetchData?.rest_id,
              sendObj: {
                state: "in_progress",
              },
            });
          } else {
            appointmentQcdListFunc({
              user_id:
                data?.fetchData?.selectedUser !== null
                  ? data?.fetchData?.selectedUser?.user_id !== 0
                    ? data?.fetchData?.selectedUser?.user_id
                    : ""
                  : "",
              type: data?.fetchData?.typeSummary,
            });
          }
        }
        success("Hatırlatıcı Oluşturuldu");
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const patchRestofficialReminder = (data) => {
    restaurantService
      .patch_restofficial_reminder(data)
      .then((response) => {
        if (data.reminderType === "show") {
          getTaskList();
        } else {
          if (data.dashboardType === "renewal") {
            summaryRestDetailFunc({
              rest_id: data?.fetchData?.rest_id,
              sendObj: {
                state: "new",
              },
            });
            summaryRestDetailFunc({
              rest_id: data?.fetchData?.rest_id,
              sendObj: {
                state: "in_progress",
              },
            });
          } else {
            appointmentQcdListFunc({
              user_id:
                data?.fetchData?.selectedUser !== null
                  ? data?.fetchData?.selectedUser?.user_id !== 0
                    ? data?.fetchData?.selectedUser?.user_id
                    : ""
                  : "",
              type: data?.fetchData?.typeSummary,
            });
          }
        }
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const patchDiscountOffer = (offerId, data, restId) => {
    managementService
      .patch_discount_offer(offerId, data)
      .then((response) => {
        success("İşlem Başarılı");
        if (restId) {
          getOfferBasketFunc({
            id: restId,
          });
        }
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const createTaskReminder = (data) => {
    restaurantService
      .create_task_reminder(data)
      .then((response) => {
        success("Hatırlatıcı Oluşturuldu");
        data.setNote("");
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const getLifetimeStats = (id) => {
    restaurantService
      .get_lifetime_stats(id)
      .then((response) => {
        setLifetimeStats(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const setNewPasswordFunc = (data) => {
    restaurantService
      .set_new_password(data)
      .then((response) => {
        success("Şifre değiştirildi.");
        data.setChangePasswordModal(false);
        data.setStPassword("");
        data.setPatronPassword("");
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const showProductProfitFunc = (data) => {
    restaurantService
      .show_product_profit(data)
      .then((response) => {
        success("Kazanç gösteriliyor.");
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const changeRestaurantPictureFunc = (data) => {
    restaurantService
      .change_restaurant_picture(data)
      .then((response) => {
        resDetailInfoFunc(data.id);
        success("Restoran vitrin resmi değiştirildi.", 4500);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const changeRestaurantVideoFunc = (data) => {
    restaurantService
      .change_restaurant_video(data)
      .then((response) => {
        resDetailInfoFunc(data.id);
        success("Restoran videosu değiştirildi.", 4500);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const partialPaymentCreateFunc = (data) => {
    restaurantService
      .partial_payment_create(data)
      .then((response) => {
        partialPaymentInfoFunc({ offer_id: data?.offer_id });
        success("Parçalı Online Ödeme oluşturuldu.", 4500);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const partialPaymentInfoFunc = (data) => {
    restaurantService
      .partial_payment_info(data)
      .then((response) => {
        setPartialPaymentInfo(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const partialPaymentDeleteFunc = (data) => {
    restaurantService
      .partial_payment_delete(data)
      .then((response) => {
        success("Ön ödeme geri alındı", 4500);
        partialPaymentInfoFunc({ offer_id: data?.offer_id });
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const trackerNotesRecordFunc = (data) => {
    restaurantService
      .cdr_recording(data)
      .then((response) => {
        const trackerNotesObj = Object.assign(
          {},
          JSON.parse(JSON.stringify({ ...trackerNotes }))
        );

        trackerNotesObj.results[data.i].task.recordings[data.index].voice =
          response?.recording_url;
        setTrackerNotes(trackerNotesObj);
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  };

  const summaryRestDetailFunc = (data) => {
    setSummaryRestDetailInfo(null);
    restaurantService
      .summary_restaurant_detail(data)
      .then((response) => {
        setSummaryRestDetailInfo(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const summaryRenewalAssingFunc = (data) => {
    restaurantService
      .summary_renewal_assign(data)
      .then((response) => {
        success("Atama gerçekleşti", 4500);
        renewalSummaryListFunc({
          sendObj: {
            state: "new",
            remaining_day: data.remaining_day,
            officeuser_id: data.officeuser_id,
            restofficial_id: data.restofficial_id,
            passive: data.passive,
          },
        });
        renewalSummaryListFunc({
          sendObj: {
            state: "in_progress",
            officeuser_id: data.officeuser_id,
            restofficial_id: data.restofficial_id,
            passive: data.passive,
          },
        });
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const renewalOfficeusersListFunc = (data) => {
    restaurantService
      .renewal_officeusers(data)
      .then((response) => {
        response.unshift({
          id: "",
          full_name: "Tümü",
        });
        setRenewalOfficeusersList(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const renewerListFunc = () => {
    restaurantService
      .renewer_list()
      .then((response) => {
        setRenewerList(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const renewerIncreaseRateDetailFunc = (data) => {
    restaurantService
      .renewer_increase_rate_detail(data)
      .then((response) => {
        setRenewerIncreaseRateDetail(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const productProfitDataFunc = (data) => {
    restaurantService
      .product_profit_data(data)
      .then((response) => {
        setProductProfitData(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const productProfitAvailabilityFunc = (data) => {
    restaurantService
      .product_profit_availability(data)
      .then((response) => {
        setProductProfitAvailabilityList(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const sendReferenceSmsFunc = (data) => {
    restaurantService
      .send_reference_sms(data)
      .then((response) => {
        success("Sms gönderildi.", 4500);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const changeOfficeUserProducts = (id, data) => {
    restaurantService
      .change_officeuser_products(id, data)
      .then((response) => {
        taskDashboardFunc({ user_id: id });
        success("Ürünler güncellendi.");
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  return (
    <RestaurantContext.Provider
      value={{
        restaurantID,
        setRestaurantID,
        currentIndex,
        setCurrentIndex,
        preDealarSidebarPage,
        setPreDealarSidebarPage,
        dealarSidebarPage,
        setDealarSidebarPage,
        operationSidebarPage,
        setOperationSidebarPage,
        financeSidebarPage,
        setFinanceSidebarPage,
        managmentSidebarPage,
        setManagementSidebarPage,
        formData,
        setFormData,
        restOfficialData,
        getOfficeuserDetail,
        cities,
        getCity,
        town,
        getTown,
        neigh,
        getNeighborhood,

        postSecretKey,
        loader,

        applicationStatuList,
        getApplicationStatuList,
        patchApplicationStatu,
        restaurantInfoData,
        setRestaurantInfoData,
        getRestaurantInfoData,
        updateRestaurantInfoData,
        patchOfficeuserDetail,
        complatedApplicationStatu,

        platformList,
        getPlatformList,
        restofficialPlatforms,
        getRestofficialPlatforms,
        patchRestofficialPlatforms,
        deleteRestofficialPlatforms,
        updatePlatformsWorkstating,

        myResmtaurantsData,
        setMyRestaurantsData,
        getmyRestaurantTableDataList,
        myResmtaurantsTagReport,
        getmyRestaurantTagReport,

        getFoodCategory,
        foodCategory,

        getRestaurantCourierData,
        restaurantCourier,
        courierLoading,
        patchRestaurantCourierData,
        postRestaurantCourierData,

        getRestaurantPrinterData,
        restaurantPrinter,
        patchRestaurantPrinterData,
        deleteRestaurantPrinterData,

        getRestaurantModulesData,
        patchRestaurantModulesData,
        restaurantModules,

        getRestaurantWorkingHours,
        postRestaurantWorkingHours,
        patchRestaurantWorkingHours,
        restaurantWorkingHours,
        setRestaurantWorkingHours,

        getRestaurantPaymentTypeList,
        getRestaurantPaymentTypesData,
        restaurantPaymentTypes,
        setRestaurantPaymentTypes,
        paymentTypeList,
        postRestaurantPaymentTypesData,
        getRestaurantInfo,

        postRestaurantRegister,

        getReportsData,
        reportData,

        getRestaurantOfferData,
        restaurantOffer,
        setRestaurantOffer,
        patchRestaurantOfferData,
        getRestaurantActiveOffer,
        restaurantActiveOffer,

        getPaymentTracker,
        paymentTracker,
        patchPaymentTracker,
        getPaymentTrackerWithStayed,

        postResetPassword,
        postRestaurantBlock,

        getRestaurantMenuList,
        restaurantMenuList,
        postRestaurantMenuProductStatu,

        getApplicationTransferList,
        backofficeList,
        patchApplicationTransfer,

        getApplicationNoteList,
        applicationNoteList,
        postApplicationNote,

        applicationNotesFunc,
        applicationNotes,
        applicationNotesCreateFunc,

        blockedStatusReasonsFunc,
        blockedReasons,
        blockedReasonFunc,

        stayTimeFunc,
        getApplicationStatuListStayed,

        getTrackerNotes,
        trackerNotes,
        postTrackerNotes,

        getApplicationStatuListDeleted,

        operationPrimFunc,
        operationPrim,

        sellerSidebarPage,
        setSellerSidebarPage,
        postSellerRestaurantRegister,
        sellerMyPrimFunc,
        sellerMyPrim,

        sellerMyPrimHistory,
        sellerMyPrimHistoryFunc,

        eftCreateFunc,
        eftListFunc,
        eftList,
        eftChangeFunc,
        getOrderRepeats,
        orderRepeats,
        patchRestaurantTransfer,
        countApplication,
        getPaymentTypes,
        paymentTypes,
        platforms,
        getPlatforms,
        getApplicationsStatusList,
        applicationList,

        productHeaderList,
        getProductHeaderList,
        getProductList,
        productList,
        menuProductCreateFunc,
        setOptProductList,
        optProductList,
        optProductListFunc,
        setMenuDetailObj,
        menuDetailObj,
        menuDetailChangeFunc,
        menuDetailFunc,
        menuProductUpdateFunc,
        menuBulkUpdateFunc,
        menuHeaderDetailChangeFunc,
        restaurantEnduserAppListFunc,
        restaurantEnduserAppList,
        menuHeaderPhotoUploadFunc,
        sendPushNotificationFunc,
        menuPhotoUploadFunc,
        menuBulkUpdatePriceFunc,
        resDetailInfoFunc,
        resDetailInfo,
        resChangeDetailFunc,
        menuHeaderCreateFunc,
        restaurantRatingList,
        orderRatingListFunc,
        orderRatingChangeFunc,

        offerProductListFunc,
        offerProductList,
        offerProducts,

        getSectorListFunc,
        sectorList,
        offerBasketFunc,
        getOfferBasketFunc,
        offerBasketChangeFunc,
        createOfferFunc,
        offerHistoriesListFunc,
        offerHistoriesList,
        sepetAppDemo,
        offerHistoryDetailFunc,
        offerHistoryDetail,
        restaurantEnduserDetailFunc,
        restaurantEnduserOrdersFunc,
        enduserOrders,
        enduserDetail,
        restaurantEnduserCashBackUptFunc,
        setSepetPayAmound,
        sepetPayAmound,
        changeOfferTime,
        specialOfferPatch,
        menuHeaderSortFunc,
        menuProductSortFunc,
        getOutboundAmount,
        outboundCount,
        getOutboundReports,
        outboundReports,
        calculatePrimOutbound,
        primOutbound,
        getPaymentTrackerV2,
        techSupportListFunc,
        techSupportDetail,
        techSupportList,
        notificationListFunc,
        notificationDetailFunc,
        notificationList,
        callCenterRestList,
        callCenterRestSearchFunc,
        getOfficeUserListWithRole,
        backofficeListWithRole,
        patchOfficeuserDetailFunc,
        getTotalAmounts,
        totalAmounts,
        sendSmsToCustomer,
        sendPaymentToCollector,
        getTicketTypes,
        ticketTypes,
        sendTicket,
        getActiveTicketList,
        activeTicketList,
        changeTicketStatus,
        ticketDetailChange,
        ivrExelUploadFunc,
        ivrSendIvrFunc,
        ivrDataInfo,
        ivrDataInfoFunc,
        sendOfferPopupFunc,
        getSentTickets,
        sentTickets,
        getCompletedTickets,
        completedTickets,
        sendUrgentSetupFunc,
        acceptUrgentSetupFunc,
        getStateHistory,
        stateHistory,
        sendUrgentCollectorFunc,
        acceptUrgentCollectorFunc,
        getOfficeUserListWithRoleCollector,
        backofficeCollectorList,
        patchOfficeuserDetailFuncCollector,
        getStateHistoryDaily,
        stateHistoryDaily,
        collectorRestaurantListFunc,
        collectorRestaurantList,
        collectorRestaurantOffersFunc,
        collectorRestaurantOffers,
        collectorRestaurantOrdersFunc,
        collectorRestaurantOrders,
        courierTrackerDemoStatusListFunc,
        courierTrackerStatusList,
        courierTrackerDemoChangeFunc,
        courierTrackerDemoDeleteFunc,
        testOrderFunc,
        getTaskList,
        taskList,
        newTaskCreateFunc,
        taskListDetailFunc,
        calendarListFunc,
        calendarList,
        personalTemplateNoteListFunc,
        personalTemplateNoteList,
        personalTemplateNoteCreateFunc,
        personalTemplateNoteDeleteFunc,
        personalTemplateNoteSortFunc,
        calendarAllListFunc,
        calendarAllList,
        taskActionFunc,
        taskRevertFunc,
        applicationDataUpdateFunc,
        patchRestOfficialFunc,
        taskApplicationUpdateFunc,
        restPrevOfferHistoryFunc,
        restPrevOfferHistory,
        taskDashboardFunc,
        taskDashboardData,
        taskDashboardPatchFunc,
        setTaskDashboardData,
        restOfficialTaskActionListFunc,
        restofficialTaskActionList,
        restOfficialTaskActionFunc,
        restaurantBlockV2,
        taskPhoneSearchFunc,
        taskResetFunc,
        restofficialRefsFunc,
        restofficialRefs,
        taskUnlockFunc,
        userStatus,
        setUserStatus,
        userGoToBreak,
        statusModal,
        setStatusModal,
        setisMouseInactive,
        applicationCountsFunc,
        applicationCounts,
        applicationDeletedCountFunc,
        applicationDeletedCount,
        applicationHistoryLogFunc,
        applicationHistoryLog,
        applicationDeletedFunc,
        ysRemoteIdListFunc,
        ysRemoteIdList,
        ysRemoteIdCreateFunc,
        ysRemoteIdDeleteFunc,
        zeroDataExelUploadFunc,
        postRestaurantRegisterTech,
        getmyRestaurantTableDataListTech,
        secretKeyPlatformSummaryFunc,
        secretKeyPlatformSummary,
        secretKeyPlatformInfoFunc,
        secretKeyPlatformInfo,
        getActiveTasks,
        activeTasks,
        taskFromCalendarFunc,
        sepetAppDemoDetailFunc,
        sepetAppDemoDetail,
        sepetAppDemoDelete,
        patchCompanyInfosFunc,
        taskPullFunc,
        resTechPendingFunc,
        ticketBounceListFunc,
        ticketBounceDefendFunc,
        blockedStatusReasonsFuncv2,
        blockedReasonsv2,
        appointmentListFunc,
        appointmentList,
        appointmentOrderListFunc,
        appointmentOrderList,
        appointmentTaskBackFunc,
        appointmentQcdListFunc,
        appointmentQcdList,
        productImport,
        getProductHeaderPictureTemplate,
        productHeaderPictureTemplate,
        setProductHeaderPictureTemplate,
        getProductPictureTemplate,
        productPictureTemplate,
        setProductPictureTemplate,
        saveProductPictureTemplate,
        createNewTaskFunc,
        renewalSummaryListFunc,
        renewalSummaryListNew,
        renewalSummaryListProgress,
        renewalSummaryListCompleted,
        offerInfoFunc,
        offerDetail,
        createReminder,
        patchReminder,
        patchLimitedUseTask,
        deleteProductTemplateHeader,
        deleteProductHeader,
        createProductTemplateHeader,
        openAddHeaderModal,
        setOpenAddHeaderModal,
        editProductTemplateHeader,
        editProductTemplate,
        createProductTemplate,
        openProductModal,
        setOpenProductModal,
        createPictureFromTask,
        getPrimTable,
        primTable,
        patchReminderHistory,
        createRestofficialReminder,
        patchRestofficialReminder,
        patchDiscountOffer,
        createTaskReminder,
        getLifetimeStats,
        lifetimeStats,
        setLifetimeStats,
        setNewPasswordFunc,
        showProductProfitFunc,
        changeSecretKey,
        swingAnim,
        setSwingAnim,
        changeRestaurantPictureFunc,
        changeRestaurantVideoFunc,
        partialPaymentInfoFunc,
        partialPaymentCreateFunc,
        partialPaymentInfo,
        partialPaymentDeleteFunc,
        trackerNotesRecordFunc,
        renewalSummaryOrderCounterFunc,
        renewalSummaryPreviousOfferFunc,
        renewalOrderCounterList,
        renewalPreviousOffersList,
        summaryRestDetailFunc,
        summaryRestDetailInfo,
        summaryRenewalAssingFunc,
        getOfficeUserListRenewal,
        backofficeListRenewal,
        renewalOfficeusersListFunc,
        renewalOfficeusersList,
        renewerListFunc,
        renewerList,
        renewerIncreaseRateDetailFunc,
        renewerIncreaseRateDetail,
        productProfitDataFunc,
        setProductProfitData,
        productProfitData,
        sendReferenceSmsFunc,
        productProfitAvailabilityList,
        productProfitAvailabilityFunc,
        offerUnlockFunc,
        changeOfficeUserProducts,
      }}
    >
      {props.children}

      <UserActivity
        userActiveModal={userActiveModal}
        setuserActiveModal={setuserActiveModal}
        isMouseInactive={isMouseInactive}
        setisMouseInactive={setisMouseInactive}
        setStatusModal={setStatusModal}
      />
      <TechResTicket
        techResTicketModal={techResTicketModal}
        setTechResTicketModal={setTechResTicketModal}
        resTechPendingFunc={resTechPendingFunc}
      />
      <TicketBounce
        ticketBounceModal={ticketBounceModal}
        currentBounce={currentBounce}
        setCurrentBounce={setCurrentBounce}
        ticketBounceDefendFunc={ticketBounceDefendFunc}
      />
    </RestaurantContext.Provider>
  );
};

export default RestaurantProvider;
