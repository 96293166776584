import React, { useState, useEffect, useContext } from "react";
import { selectDefaultStyles } from "../../../../components/selectStyles/stylev2";
import Select from "react-select";
import RegistrationField from "../../../../components/registrationField";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import validator from "validator";
import { useToast } from "../../../../components/snackbar";
import { RestaurantContext } from "../../../../contexts/restaurantContext";
import restaurantService from "../../../../services/restaurantService";
import RollModal from "./RollModal";

let paymentStatus = [
  {
    id: "new",
    name: "Yeni Ürün",
  },
  {
    id: "renew",
    name: "Yenileme Ürün",
  },
];

const eInvoices = [
  {
    value: true,
    label: "Evet",
  },
  {
    value: false,
    label: "Hayır",
  },
];

const companyTypes = [
  { value: "Şahıs Şirketi", label: "Şahıs Şirketi" },
  { value: "Anonim Şirket", label: "Anonim Şirket" },
  { value: "Limited Şirket", label: "Limited Şirket" },
  { value: "Adi Ortaklık", label: "Adi Ortaklık" },
];

const Company = ({
  payedModal,
  setPayedModal,
  r_id,
  showPayment,
  taskPage,
}) => {
  const theme = createTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const { alert } = useToast();
  const [formData, setFormData] = useState(null);
  const [selectedCompanyType, setselectedCompanyType] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedTown, setSelectedTown] = useState(null);
  const [selectedNeigh, setSelectedNeigh] = useState(null);
  const [isInvoices, setIsInvoices] = useState(null);
  const {
    getCity,
    cities,
    getTown,
    town,
    getNeighborhood,
    neigh,
    eftCreateFunc,
    patchCompanyInfosFunc,
  } = useContext(RestaurantContext);
  const [selectedPaymentType, setselectedPaymentType] = useState("new");
  const [openRollModal, setOpenRollModal] = useState(false);

  useEffect(() => {
    if (payedModal) {
      setFormData({
        company_type: "",
        tax_no: "",
        tax_authority: "",
        identity_number: "",
        company_title: "",
        billing_address: "",
        billing_city: null,
        billing_town: null,
        billing_neighborhood: null,
        billing_phone: "",
        billing_email: "",
        efatura_mukellef: null,
        eft_detail: {},
      });
    }
  }, [payedModal]);

  const getCompanyInfo = async () => {
    const result = await restaurantService.get_company_info(r_id);
    companyTypes.map((type) => {
      if (type.label === result.company_type) {
        setselectedCompanyType(type);
      }
    });
    setSelectedCity(result.billing_city);
    setSelectedTown(result.billing_town);
    setSelectedNeigh(result.billing_neighborhood);
    setFormData({
      ...result,
      billing_city: result?.billing_city?.id,
      billing_town: result?.billing_town?.id,
      billing_neighborhood: result?.billing_neighborhood?.id,
    });
    if (result?.efatura_mukellef) {
      setIsInvoices({
        value: true,
        label: "Evet",
      });
    } else {
      setIsInvoices({
        value: false,
        label: "Hayır",
      });
    }
  };

  useEffect(() => {
    getCity();
    getCompanyInfo();
  }, []);

  useEffect(() => {
    if (selectedCity != null) {
      getTown(selectedCity.id);
    }
  }, [selectedCity]);

  useEffect(() => {
    if (selectedTown != null) {
      getNeighborhood(selectedCity.id, selectedTown?.id);
    }
  }, [selectedTown]);

  const isBlank = (str) => {
    return !str || /^\s*$/.test(str);
  };

  const sendEftCreateFunc = () => {
    if (isBlank(formData?.company_type)) {
      alert("Şirket türü boş bırakılamaz.");
    } else if (
      isBlank(formData?.tax_no) &&
      formData?.company_type !== "Şahıs Şirketi"
    ) {
      alert("Vergi no boş bırakılamaz.");
    } else if (
      isBlank(formData?.identity_number) &&
      formData?.company_type === "Şahıs Şirketi"
    ) {
      alert("Kimlik no boş bırakılamaz.");
    } else if (isBlank(formData?.company_title)) {
      alert("Ticari ünvan boş bırakılamaz.");
    } else if (formData?.billing_city == null) {
      alert("İl seçimi boş bırakılamaz.");
    } else if (formData?.billing_town == null) {
      alert("İlçe seçimi boş bırakılamaz.");
    } else if (formData?.billing_neighborhood == null) {
      alert("Mahalle seçimi boş bırakılamaz.");
    } else if (isBlank(formData?.billing_address)) {
      alert("Adres boş bırakılamaz.");
    } else if (!validator.isEmail(formData?.billing_email)) {
      alert("Uygun bir email giriniz.");
    } else if (!validator.isMobilePhone(formData?.billing_phone, "tr-TR")) {
      alert("Uygun bir cep telefonu giriniz.");
    } else if (formData?.efatura_mukellef === null) {
      alert("E-fatura mükellefi seçimi boş bırakılamaz.");
    } else {
      let data = {
        r_id: r_id,
        companyObj: {
          ...formData,
          renew: selectedPaymentType === "renew" ? true : false,
        },
        setFormData,
        setPayedModal,
      };
      eftCreateFunc(data);
    }
  };

  const customFilter = (option, searchText) => {
    if (option.data.name.toLowerCase().includes(searchText.toLowerCase())) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div
      style={{
        marginTop: matches ? 30 : 0,
        width: "95%",
        backgroundColor: "gray",
        padding: 20,
        borderRadius: 8,
        overflow: "auto",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: matches ? "row" : "column",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            marginRight: 14,
          }}
        >
          <span
            style={{
              color: "#293c51",
              fontFamily: "segoeuiRg",
              fontSize: 15,
              fontWeight: 700,
              marginLeft: 10,
              marginBottom: matches ? 10 : 5,
            }}
          >
            Şirket Türü
          </span>
          <Select
            options={companyTypes}
            styles={selectDefaultStyles}
            isSearchable={false}
            hoverColor={"black"}
            menuColor={"#f4f4f4"}
            placeholder={"Seçiniz"}
            dynamiczIndex={4}
            value={selectedCompanyType || ""}
            getOptionValue={(option) => option.id}
            onChange={(e) => {
              setFormData({
                ...formData,
                company_type: e.value,
                tax_no: e.value === "Şahıs Şirketi" ? "" : formData?.tax_no,
                identity_number:
                  e.value !== "Şahıs Şirketi" ? "" : formData?.identity_number,
              });
              setselectedCompanyType(e);
            }}
            getOptionLabel={(e) => (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span>{e.label}</span>
              </div>
            )}
          />
        </div>
        <div
          style={{
            flex: 1,
          }}
        >
          <RegistrationField
            fieldHeader={"Vergi Dairesi"}
            fieldValue={formData?.tax_authority}
            fieldName={"tax_authority"}
            formData={formData}
            setFormData={setFormData}
          />
        </div>
        <div
          style={{
            flex: 1,
          }}
        >
          <RegistrationField
            fieldHeader={"Vergi No"}
            fieldValue={formData?.tax_no}
            fieldName={"tax_no"}
            formData={formData}
            setFormData={setFormData}
            // inputDisabled={
            //   selectedCompanyType !== null
            //     ? selectedCompanyType?.value !== null
            //       ? selectedCompanyType?.value === "Şahıs Şirketi"
            //         ? true
            //         : false
            //       : true
            //     : true
            // }
          />
        </div>
        <div
          style={{
            flex: 1,
          }}
        >
          <RegistrationField
            fieldHeader={"T.C Kimlik Numarası"}
            fieldValue={formData?.identity_number}
            fieldName={"identity_number"}
            formData={formData}
            setFormData={setFormData}
            // inputDisabled={
            //   selectedCompanyType !== null
            //     ? selectedCompanyType?.value !== null
            //       ? selectedCompanyType?.value !== "Şahıs Şirketi"
            //         ? true
            //         : false
            //       : true
            //     : true
            // }
          />
        </div>
        <div
          style={{
            flex: 1,
          }}
        >
          <RegistrationField
            fieldHeader={"Ticari Ünvan (Fatura Adı)"}
            fieldValue={formData?.company_title}
            fieldName={"company_title"}
            formData={formData}
            setFormData={setFormData}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: matches ? "row" : "column",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            marginRight: 10,
            marginTop: matches ? 20 : 10,
          }}
        >
          <span
            style={{
              color: "#293c51",
              fontFamily: "segoeuiRg",
              fontSize: 15,
              fontWeight: 700,
              marginLeft: 8,
              marginBottom: 10,
            }}
          >
            İl Seçimi
          </span>
          <Select
            options={cities}
            styles={selectDefaultStyles}
            isSearchable={true}
            hoverColor={"black"}
            menuColor={"#f4f4f4"}
            placeholder={"Seçiniz"}
            dynamiczIndex={3}
            value={selectedCity || ""}
            getOptionValue={(option) => option.id}
            onChange={(e) => {
              setSelectedCity(e);
              setFormData({
                ...formData,
                billing_city: e.id,
                billing_town: null,
                billing_neighborhood: null,
              });
              setSelectedTown(null);
              setSelectedNeigh(null);
            }}
            filterOption={customFilter}
            getOptionLabel={(e) => (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span>{e.name}</span>
              </div>
            )}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            marginRight: 10,
            marginTop: matches ? 20 : 10,
          }}
        >
          <span
            style={{
              color: "#293c51",
              fontFamily: "segoeuiRg",
              fontSize: 15,
              fontWeight: 700,
              marginLeft: 8,
              marginBottom: 10,
            }}
          >
            İlçe Seçimi
          </span>
          <Select
            options={town}
            styles={selectDefaultStyles}
            isSearchable={true}
            hoverColor={"black"}
            menuColor={"#f4f4f4"}
            placeholder={"Seçiniz"}
            value={selectedTown || ""}
            dynamiczIndex={2}
            getOptionValue={(option) => option.id}
            filterOption={customFilter}
            onChange={(e) => {
              setFormData({
                ...formData,
                billing_town: e.id,
                billing_neighborhood: null,
              });
              setSelectedTown(e);
              setSelectedNeigh(null);
            }}
            getOptionLabel={(e) => (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span>{e.name}</span>
              </div>
            )}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            marginRight: 10,
            marginTop: matches ? 20 : 10,
          }}
        >
          <span
            style={{
              fontFamily: "segoeuiRg",
              color: "#293c51",
              fontSize: 15,
              fontWeight: 700,
              marginLeft: 8,
              marginBottom: 10,
            }}
          >
            Mahalle Seçimi
          </span>
          <Select
            options={neigh}
            styles={selectDefaultStyles}
            isSearchable={true}
            hoverColor={"black"}
            menuColor={"#f4f4f4"}
            placeholder={"Seçiniz"}
            value={selectedNeigh || ""}
            dynamiczIndex={1}
            getOptionValue={(option) => option.id}
            onChange={(e) => {
              setFormData({ ...formData, billing_neighborhood: e.id });
              setSelectedNeigh(e);
            }}
            filterOption={customFilter}
            getOptionLabel={(e) => (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span>{e.name}</span>
              </div>
            )}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: matches ? "row" : "column",
        }}
      >
        <div
          style={{
            flex: 1,
            marginTop: matches ? 20 : 10,
          }}
        >
          <RegistrationField
            fieldHeader={"Adres"}
            fieldValue={formData?.billing_address}
            fieldName={"billing_address"}
            formData={formData}
            setFormData={setFormData}
          />
        </div>
        <div
          style={{
            flex: 1,
            marginTop: matches ? 20 : 10,
          }}
        >
          <RegistrationField
            fieldHeader={"Email"}
            fieldValue={formData?.billing_email}
            fieldName={"billing_email"}
            formData={formData}
            setFormData={setFormData}
          />
        </div>
        <div style={{ flex: 1, marginTop: matches ? 20 : 5 }}>
          <span
            style={{
              fontFamily: "segoeuiRg",
              color: "#293c51",
              fontSize: 15,
              fontWeight: 700,
              marginLeft: 8,
            }}
          >
            Cep No
          </span>
          <div
            style={{
              flex: 1,
              backgroundColor: "#f4f4f4",
              height: 45,
              marginTop: matches ? 10 : 5,
              borderRadius: 6,
              marginRight: 14,
            }}
          >
            <PhoneInput
              countryCodeEditable={false}
              country="tr"
              onlyCountries={["tr"]}
              localization={{ tr: "Türkiye" }}
              value={formData?.billing_phone || ""}
              containerStyle={{ height: 45 }}
              inputStyle={{
                height: 45,
                width: "100%",
                fontSize: 15,
                fontWeight: "400",
              }}
              onChange={(phone) => {
                setFormData({ ...formData, billing_phone: phone });
              }}
            />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            marginRight: 10,
            marginTop: matches ? 20 : 10,
          }}
        >
          <span
            style={{
              fontFamily: "segoeuiRg",
              color: "#293c51",
              fontSize: 15,
              fontWeight: 700,
              marginLeft: 8,
              marginBottom: 10,
            }}
          >
            E-Fatura Mükellefi mi?
          </span>
          <Select
            options={eInvoices}
            styles={selectDefaultStyles}
            isSearchable={true}
            hoverColor={"black"}
            menuColor={"#f4f4f4"}
            placeholder={"Seçiniz"}
            value={isInvoices}
            dynamiczIndex={1}
            getOptionValue={(option) => option.value}
            onChange={(e) => {
              setFormData({ ...formData, efatura_mukellef: e.value });
              setIsInvoices(e);
            }}
            getOptionLabel={(e) => (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span>{e.label}</span>
              </div>
            )}
          />
        </div>
      </div>
      {!showPayment && (
        <div
          style={{
            display: "flex",
            flexDirection: matches ? "row" : "column",
            marginTop: 10,
          }}
        >
          <div
            style={{
              flex: 1,
            }}
          >
            <RegistrationField
              width={"90%"}
              fieldHeader={"Banka Adı"}
              fieldValue={formData?.bank_name}
              fieldName={"bank_name"}
              formData={formData}
              setFormData={setFormData}
            />
          </div>
          <div
            style={{
              flex: 1,
            }}
          >
            <RegistrationField
              width={"90%"}
              fieldHeader={"Hesap Adı"}
              fieldValue={formData?.account_name}
              fieldName={"account_name"}
              formData={formData}
              setFormData={setFormData}
            />
          </div>
          <div
            style={{
              flex: 1,
            }}
          >
            <RegistrationField
              width={"90%"}
              fieldHeader={"IBAN"}
              fieldValue={formData?.iban}
              fieldName={"iban"}
              formData={formData}
              setFormData={setFormData}
            />
          </div>
        </div>
      )}
      <div
        style={{
          marginTop: 10,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        <div>
          {taskPage && (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  fontSize: 14,
                  color: "#293c51",
                }}
              >
                <div style={{ marginBottom: 10 }}>
                  Yeni Ürün Ön Ödeme : ₺ {formData?.eft_detail?.new_eft_amount}
                </div>
                <div style={{ marginBottom: 10 }}>
                  Yenileme Ürün Ön Ödeme : ₺{" "}
                  {formData?.eft_detail?.renew_eft_amount}
                </div>
                <div>
                  Yeni Ürün Kalan Ödeme : ₺ {formData?.eft_detail?.new_debt}
                </div>
                <div>
                  Yenileme Kalan Ödeme : ₺ {formData?.eft_detail?.renew_debt}
                </div>
              </div>
            </>
          )}

          {showPayment && (
            <RegistrationField
              fieldHeader={"Ön Ödeme"}
              fieldValue={formData?.down_payment}
              fieldName={"down_payment"}
              formData={formData}
              setFormData={setFormData}
            />
          )}
        </div>

        <Button
          onClick={() => {
            setOpenRollModal(true);
          }}
          style={{
            backgroundColor: "#fff",
            color: "#293c51",
            textTransform: "capitalize",
            borderRadius: 13,
            fontFamily: "segoeuiRg",
            fontSize: 18,
            margin: "0px 10px",
          }}
          size="small"
          variant="contained"
        >
          Yuvarla
        </Button>

        {showPayment && (
          <div>
            {paymentStatus.map((item, i) => {
              return (
                <FormControlLabel
                  key={i}
                  control={
                    <Checkbox
                      value={item.id}
                      checked={item.id === selectedPaymentType ? true : false}
                      color="success"
                    />
                  }
                  label={item.name}
                  onChange={(e) => {
                    setselectedPaymentType(e.target.value);
                  }}
                />
              );
            })}
          </div>
        )}
        <Button
          onClick={() => {
            if (showPayment) {
              sendEftCreateFunc();
            } else {
              let data = {
                id: r_id,
                sendObj: formData,
              };
              patchCompanyInfosFunc(data);
            }
          }}
          style={{
            backgroundColor: "#293c51",
            textTransform: "capitalize",
            borderRadius: 13,
            fontFamily: "segoeuiRg",
            width: 180,
            height: 36,
            borderColor: "gray",
            border: "solid",
            fontSize: 18,
          }}
          size="small"
          variant="contained"
        >
          Devam Et
        </Button>
      </div>

      {openRollModal && (
        <RollModal
          openRollModal={openRollModal}
          setOpenRollModal={setOpenRollModal}
          restId={r_id}
        />
      )}
    </div>
  );
};

export default Company;
