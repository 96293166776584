import { http } from "./axios";

function res_platforms() {
  return http.get("/restaurant/platforms");
}

function res_status(res_status) {
  return http.post("/restaurant/state_working", {
    state_working: res_status,
  });
}

function get_city() {
  return http.get(`/officeuser/city`);
}

function get_town(city_id = 10) {
  return http.get(`/officeuser/town?city=${city_id}`);
}

function get_neighborhood(city_id, town_id) {
  return http.get(`/officeuser/neighborhood?city=${city_id}&town=${town_id}`);
}

function res_delete_printers(printer_id) {
  return http.delete(`restaurant/printer/${printer_id}`);
}

function delete_office_user(id = 19) {
  return http.delete(`/officeuser/${id}`);
}

function get_office_user(id) {
  return http.get(`/officeuser/restofficial/detail/${id}`);
}
function patch_office_user(id, data) {
  return http.patch(`/officeuser/restofficial/detail/${id}`, data);
}

function get_platform_list() {
  return http.get(`/officeuser/platforms`);
}

function update_platforms(data) {
  return http.post(`/restofficial/offer/platforms`, data);
}

function get_company_info(id) {
  return http.get(`/officeuser/restofficial/billing/${id}`);
}

function patch_company_info(data) {
  return http.patch(
    `/officeuser/restofficial/billing/${data.id}`,
    data.sendObj
  );
}

function update_platforms_workstating(user_id, data) {
  return http.patch(`/officeuser/restofficial/state_working/${user_id}`, data);
}

function get_restofficial_platforms(id) {
  return http.get(`/officeuser/restofficial/platforms/${id}`);
}

function patch_restofficial_platforms(id, data) {
  return http.patch(`/officeuser/restofficial/platforms/edit/${id}`, data);
}
function delete_restofficial_platforms(user_id, id) {
  return http.delete(
    `/officeuser/restofficial/platforms/${user_id}/delete/${id}`
  );
}

function get_officeuser_restofficial_report(id = 3) {
  return http.get(`/officeuser/restofficial/report/${id}`);
}

function get_all_officeuser_payments() {
  return http.get(`/officeuser/payments`);
}

function get_officeuser_payments(id = 3) {
  return http.get(`/officeuser/restofficial/payments/${id}`);
}

function edit_officeuser_payments(id = 3) {
  return http.patch(`/officeuser/restofficial/payments/${id}`);
}

function get_restofficial_platforms_openclose(user_id = 2) {
  return http.post(`/restofficial/platforms/openclose/user_id=${user_id}`);
}

function edit_officeuser_data(
  user_id,
  userData = {
    mode: "formdata",
    formdata: [
      {
        key: "permissions",
        value: "cc,saha",
        type: "text",
        disabled: true,
      },
      {
        key: "probationary_contract",
        type: "file",
        src: "/C:/Users/kadir/Downloads/test.png",
      },
    ],
  }
) {
  return http.patch(`/officeuser/${user_id}`, userData);
}

/* ------------------------------------------- Platform Secret Key ----------------------------------------------------- */
function post_secret_key(data = {}) {
  return http.post(`/officeuser/restaurant/secret_key`, data);
}

function change_secret_key(data) {
  return http.patch(
    `/officeuser/restaurant/secret_key/${data?.id}`,
    data.sendObj
  );
}

/* ------------------------------------------- ----------------------------------------------------- */

function get_application_statu_list(
  searchValue = "",
  type = "",
  page = 1,
  activeSms
) {
  return http.get(
    `/officeuser/application/status/list?status=lead,setup,zero data&&search=${searchValue}&type=${type}&page=${page}&sms=${activeSms}`
  );
}

function get_application_statu_list_stayed(
  searchValue = "",
  type = "",
  page = 1
) {
  return http.get(
    `/officeuser/application/status/list?status=lead,setup,zero data&&search=${searchValue}&&stayed=true&type=${type}&page=${page}`
  );
}

function get_application_statu_list_deleted(data, type = "", page = 1) {
  return http.get(
    `/officeuser/application/status/list?status=lead,setup,zero data&&search=${
      data.searchTerm
    }&&deleted=true&&deleted_reason=${
      data.selectedDeleteReason !== "" ? data.selectedDeleteReason.id : ""
    }&type=${type}&page=${page}`
  );
}

function patch_application_statu(id, resStatu) {
  return http.patch(`/officeuser/application/change/status/${id}`, {
    status: resStatu,
  });
}
function get_food_category() {
  return http.get(`/officeuser/foodcategory`);
}

function get_restaurant_info_data(id) {
  return http.get(`/officeuser/application/status/${id}`);
}

function updade_application_statu(id, data) {
  return http.patch(`/officeuser/application/status/${id}`, {
    ...data,
  });
}

function get_myrestaurant_table_data_list(data) {
  return http.get(
    `/officeuser/restofficial/list?page=${data.page}${
      data.hasOwnProperty("search") ? `&search=${data.search}` : ""
    }${
      data.hasOwnProperty("setup_uncompleted")
        ? `&setup_uncompleted=${data.setup_uncompleted}`
        : ""
    }`
  );
}

function get_myrestaurant_table_data_list_tech(data) {
  return http.get(
    `/officeuser/restofficial/customerservices/search?page=${data.page}&search=${data.search}`
  );
}

function restofficial_search(data) {
  return http.get(`/officeuser/restofficial/search?search=${data.search}`);
}

function get_my_restaurant_tag_report() {
  return http.get(`/officeuser/restofficial/usage/report`);
}

function get_restaurant_courier_data(resID, page) {
  return http.get(`/officeuser/courier/${resID}?page=${page}`);
}

function post_restaurant_courier_data(resID, data) {
  return http.post(`/officeuser/courier/${resID}`, data);
}

function patch_restaurant_courier_data(resID, data) {
  return http.patch(`/officeuser/courier/${resID}/${data?.id}`, data);
}

function get_restaurant_printer_data(resID) {
  return http.get(`/officeuser/printer/${resID}`);
}
function delete_restaurant_printer_data(resID, data) {
  return http.delete(`/officeuser/printer/${resID}/${data?.id}`, data);
}
function patch_restaurant_printer_data(resID, data) {
  return http.patch(`/officeuser/printer/${resID}/${data?.id}`, data);
}
function get_restaurant_modules_data(resID) {
  return http.get(`/officeuser/restofficial/modules/settings/${resID}`);
}
function patch_restaurant_modules_data(resID, data) {
  return http.patch(`/officeuser/restofficial/modules/settings/${resID}`, data);
}

function get_restaurant_working_hours_data(resID, p_id) {
  return http.get(
    `/officeuser/restofficial/working_hours/${resID}?secret_key=${p_id}`
  );
}
function post_restaurant_working_hours_data(resID, data) {
  return http.post(`/officeuser/restofficial/working_hours/${resID}`, data);
}
function patch_restaurant_working_hours_data(resID, data) {
  return http.patch(`/officeuser/restofficial/modules/settings/${resID}`, data);
}

function get_restaurant_payment_type_list() {
  return http.get(`/officeuser/restofficial/payment_types/list`);
}
function get_restaurant_payment_types_data(resID, p_id) {
  return http.get(
    `/officeuser/restofficial/payment_types/${resID}?secret_key=${p_id}`
  );
}
function get_restaurant_offer_data(resID) {
  return http.get(`/officeuser/restofficial/offer/${resID}`);
}
function get_restaurant_active_offer(resID) {
  return http.get(`/officeuser/restofficial/active_offer/${resID}`);
}

function patch_restaurant_offer(resID, data) {
  return http.patch(`/officeuser/restofficial/offer/${resID}`, data);
}
function get_payment_tracker(data) {
  return http.get(
    `/officeuser/restofficial/payment/tracker?search=${
      data.searchTerm
    }&stayed=${data.stayed === "home" ? "false" : "true"}&page=${data.page}`
  );
}
function get_payment_tracker_with_stayed(searchValue, page) {
  return http.get(
    `/officeuser/restofficial/payment/tracker?search=${searchValue}&&stayed=true&page=${page}`
  );
}

function get_payment_tracker_v2(data) {
  return http.get(
    `/officeuser/restofficial/collector/payment/tracker?search=${
      data.searchValue
    }${data.startDate !== "" ? `&start=${data.startDate}` : ""}${
      data.endDate !== "" ? `&end=${data.endDate}` : ""
    }&stayed=${data.stayed === "home" ? "false" : "true"}&processed_by=${
      data.processed_by
    }`
  );
}

function patch_payment_tracker(id, data) {
  return http.patch(`/officeuser/restofficial/payment/tracker/${id}`, data);
}

function post_restaurant_payment_types_data(resID, data) {
  return http.post(`/officeuser/restofficial/payment_types/${resID}`, data);
}

function post_reset_password(resID) {
  return http.post(`/officeuser/restofficial/reset_password/${resID}`);
}
function post_restaurant_block(resID, data) {
  return http.post(`/officeuser/restofficial/block/${resID}`, data);
}

function post_restaurant_block_v2(data) {
  return http.post(
    `/officeuser/restofficial/block/${data?.res_id}`,
    data.sendObj
  );
}

function post_restaurant_register(data) {
  return http.post(`/officeuser/application`, data);
}

function post_seller_restaurant_register(data) {
  return http.post(`/seller/application`, data);
}

function get_reports_data(resID, data) {
  return http.get(
    `/officeuser/restofficial/report/profitlost/${resID}?${
      data?.payment_type?.id
        ? "payment_type=" + data.payment_type.tag + "&&"
        : ""
    }${
      data?.platform?.id ? "platform=" + data.platform.id + "&&" : ""
    }start_date=${data.startDate}&&end_date=${data.endDate}`
  );
}

function get_restaurant_menu_list(resID, secret_key) {
  return http.get(
    `/officeuser/restofficial/menu/${resID}?secret_key=${secret_key}`
  );
}

function post_restaurant_menu_product_status(resID, data) {
  return http.post(
    `/officeuser/restofficial/menu/product/status/${resID}`,
    data
  );
}

function officeuser_list() {
  return http.get(`/officeuser/list?all=true`);
}

function officeuser_list_with_role(data) {
  return http.get(`/officeuser/list?all=true&&role=${data.role}`);
}

function officeuser_list_renewal(data) {
  return http.get(`/officeuser/list?task_type=${data.task_type}`);
}

function patch_aplication_transfer(id, data) {
  return http.patch(`officeuser/application/transfer/${id}`, data);
}

function get_application_note_list(resID, data) {
  return http.get(`officeuser/application/notes/${resID}`);
}

function post_application_note(resID, data) {
  return http.post(`officeuser/application/notes/${resID}`, data);
}

function application_notes(data) {
  return http.get(
    `/officeuser/application/notes/${data.app_id}?page=${data.id}&&page_size=${
      data.page_size ? data.page_size : 5
    }`
  );
}

function application_notes_create(data) {
  return http.post(`/officeuser/application/notes/${data.app_id}`, {
    note: data.note,
  });
}

function blocked_status_reasons() {
  return http.get(`/officeuser/blocked/reasons`);
}

function blocked_status_reasons_v2(data) {
  return http.get(`/officeuser/restofficial/${data.res_id}/block_reasons`);
}

function blocked_reason(data) {
  return http.patch(`/officeuser/application/change/status/${data.app_id}`, {
    status: data.status,
    deleted_reason: data.deleted_reason.id,
    deleted_reason_note: data.deleted_reason_note,
  });
}

function stay_time(data) {
  return http.post(
    `/officeuser/staynote/create/${data.stay_id}?type=${data.table_type}`,
    {
      note: data.note,
      stay_time: data.stay_time,
    }
  );
}

function get_tracker_notes(data) {
  return http.get(
    `/officeuser/restofficial/notes/${data.app_id}?page=${data.id}&&page_size=${
      data.page_size ? data.page_size : 5
    }&&creator_id=${data.selectedOfficeUser ? data.selectedOfficeUser : ""}`
  );
}

function post_tracker_notes(data) {
  return http.post(`/officeuser/restofficial/notes/${data.app_id}`, {
    note: data.note,
  });
}

function operation_prim() {
  return http.get(`/officeuser/calculate/prim`);
}

function seller_my_prim() {
  return http.get(`/seller/application`);
}

function seller_my_prim_history(data) {
  return http.get(
    `/seller/paymentlist/history?start=${data.start_date}&&end=${data.end_date}`
  );
}

function eft_create(data) {
  return http.post(
    `/officeuser/offer/eft/create/${data.r_id}`,
    data.companyObj
  );
}

function eft_list(data) {
  return http.get(`/officeuser/offer/eft/list?page=${data.page}`);
}

function eft_change(data) {
  return http.patch(`/officeuser/offer/eft/${data.id}`, data.eftObj);
}

function get_order_repeats(id) {
  return http.get(`/officeuser/order/repeats/${id}`);
}

function patch_restaurant_transfer(id, types) {
  return http.patch(`/officeuser/restofficial/transfer/${id}`, types);
}

function get_applications_status_list() {
  return http.get(`/officeuser/application/status/list/summary`);
}

function get_campaign_offer(id) {
  return http.get(`/officeuser/campaign/${id}`);
}

function patch_campaign_offer(id, data) {
  return http.patch(`/officeuser/campaign/${id}`, {
    campaign: data.campaign,
    campaign_id: data.campaign_id,
  });
}

function get_notification_types() {
  return http.get(`/officeuser/campaign/notification/types`);
}

function get_product_header(id) {
  return http.get(`/officeuser/product_header?restofficial_id=${id}`);
}

function post_create_product_header(data) {
  return http.post(`/officeuser/product_header`, data);
}

function get_product_list(id) {
  return http.get(`/officeuser/product/list?restofficial_id=${id}`);
}

function menu_product_create(data) {
  return http.post(`/officeuser/product/create`, data.sendingObj);
}

function opt_product_list(id) {
  return http.get(
    `/officeuser/product/optional_products?restofficial_id=${id}`
  );
}

function menu_detail(data) {
  return http.get(`/officeuser/product/${data.product_id}`);
}

function menu_detail_change(data) {
  return http.patch(`/officeuser/product/${data?.product_id}`, data?.menuObj);
}

function menu_product_update(data) {
  return http.post(`/officeuser/product/update`, data.menuObj);
}

function menu_bulk_update(data) {
  return http.post(`/officeuser/product/bulk_update`, data.menuObj);
}

function menu_header_detail_change(data) {
  return http.patch(
    `/officeuser/product_header/${data?.product_id}`,
    data?.menuObj
  );
}

function restaurant_enduser_app_list(id) {
  return http.get(
    `officeuser/restaurant/enduser/app/list?restofficial_id=${id}`
  );
}
function menu_header_photo_upload(data) {
  const bodyFormData = new FormData();
  bodyFormData.append("name", data.name);
  bodyFormData.append("picture", data.croppedImage);

  return http.patch(
    `/officeuser/product_header/${data.product_id}`,
    bodyFormData,
    {
      "Content-Type": "multipart/form-data",
    }
  );
}

function menu_photo_upload(data) {
  const bodyFormData = new FormData();
  bodyFormData.append("picture", data.croppedImage);

  return http.patch(`/officeuser/product/${data.product_id}`, bodyFormData, {
    "Content-Type": "multipart/form-data",
  });
}

function menu_header_sort(data) {
  return http.post(`/officeuser/product_header/place`, {
    id_list: data.sortObj,
  });
}

function menu_product_sort(data) {
  return http.post(`/officeuser/product/place`, {
    id_list: data.sortObj,
  });
}

function send_push_notification(data) {
  return http.post(`/officeuser/restaurant/enduser/push_notification`, data);
}

function menu_bulk_update_price(data) {
  return http.post(`/officeuser/product/bulk_update/price`, data);
}

function res_detail_info(restofficial_id) {
  return http.get(`/officeuser/restaurant/restofficial/${restofficial_id}`);
}

function res_detail(data) {
  return http.patch(
    `/officeuser/restaurant/restofficial/${data.restofficial_id}`,
    data
  );
}

function menu_header_create(data) {
  return http.post(`/officeuser/product_header`, data);
}

function order_rating_list(data) {
  return http.get(
    `/officeuser/order_rating/list?restofficial_id=${data.restofficial_id}&&?page_size=7&&page=${data.page}&&verification=${data.pageType}`
  );
}

function order_rating_change(data) {
  return http.patch(`/restaurant/order_rating/${data.id}`, data.sendObj);
}
function search_by_customer(data) {
  return http.get(
    `officeuser/restaurant/order/search?restofficial_id=${data.restofficial_id}&search=${data.search}`
  );
}

function restaurant_online_history(id) {
  return http.get(`officeuser/restaurant/online_history?restofficial_id=${id}`);
}

function get_restaurant_online_history(secretKey) {
  return http.get(
    `officeuser/restaurant/state_working_history?secret_key=${secretKey}`
  );
}

function get_offer_basket(data) {
  return http.get(`/officeuser/offer/basket/${data.id}`);
}

function offer_basket(data) {
  return http.post(`/officeuser/offer/basket/${data.id}`, data.sendObj);
}

function offer_basket_change(data) {
  return http.patch(
    `/officeuser/offer/basketdetail/${data.detail_id}`,
    data.sendObj
  );
}

function offer_product_list(data) {
  return http.get(`/officeuser/offer/products/list/${data.id}`);
}

function get_application_sector() {
  return http.get(`/officeuser/application/sectors`);
}

function create_offer(data) {
  return http.post(`/officeuser/create/offer/v2/${data.id}`, data.sendObj);
}

function change_offer_time(userId, data) {
  return http.patch(`/officeuser/create/offer/v2/${userId}`, data);
}

function offer_histories_list(data) {
  return http.get(`/officeuser/offer/v2/${data.id}/histories`);
}

function sepet_app_demo(data) {
  return http.post(`/officeuser/restaurant/sepetapp/demo`, data);
}

function sepet_app_demo_delete(data) {
  return http.delete(`/officeuser/restaurant/sepetapp/demo`, { data: data });
}

function sepet_app_demo_detail(data) {
  return http.get(
    `/officeuser/restaurant/sepetapp/demo?restofficial=${data?.rest_id}`
  );
}

function offer_history_detail(data) {
  return http.get(`/officeuser/offer/v2/${data.id}/${data.offer_id}`);
}

function restaurant_enduser_search(data, id) {
  return http.get(
    `officeuser/enduser/search?search=${data}&restofficial_id=${id}`
  );
}

function restaurant_enduser_detail(user_id, id) {
  return http.get(`/officeuser/enduser/${user_id}?restofficial_id=${id}`);
}
function restaurant_enduser_orders(data) {
  return http.get(
    `/officeuser/enduser/${data.user_id}/orders?page=${data.page}&restofficial_id=${data.restofficial_id}`
  );
}

function restaurant_enduser_cashback(data) {
  return http.post(`/officeuser/restaurant/enduser/cashback`, data);
}

function special_offer_patch(id) {
  return http.patch(`/officeuser/offer/special/basketdetail/${id}`);
}

function get_outbound_amount() {
  return http.get(`/officeuser/outbound/tracker`);
}

function get_reports_outbound(data) {
  return http.get(
    `officeuser/outbound/finance/application?setup_status=${data.setup_status}&start_date=${data.startDate}&end_date=${data.endDate}&page=${data.page}`
  );
}

function calculate_prim_outbound() {
  return http.get(`officeuser/calculate/prim/outbound`);
}

function tech_support_list(data) {
  return http.get(
    `officeuser/restofficial/support?stay=${data.stayed}&&search=${data.searchTerm}`
  );
}

function tech_support_detail(data) {
  return http.patch(
    `officeuser/restofficial/support/${data.support_id}`,
    data.sendObj
  );
}

function notification_list() {
  return http.get(`/officeuser/notifications`);
}

function notification_detail(data) {
  return http.patch(`/officeuser/notification/${data.notif_id}`, data.sendObj);
}

function call_center_rest_search(data) {
  return http.get(
    `/officeuser/cc/restofficial/search?search=${data.searchTerm}`
  );
}

function patch_officeuser_detail(data) {
  return http.patch(`/officeuser/detail/${data.user_id}`, data.sendObj);
}

function patch_officeuser_detail_collector(data) {
  return http.patch(`/officeuser/detail/${data.user_id}`, data.sendObj);
}

function get_total_amounts(id) {
  return http.get(`officeuser/restofficial/${id}/order/report`);
}

function send_sms_to_customer(data) {
  return http.post(`/officeuser/send/sms/patterns`, data);
}

function send_payment_to_collector(data) {
  return http.post(
    `/officeuser/restofficial/payment/tracker/${data.res_id}/send`,
    data.sendObj
  );
}

function get_ticket_types(userRole) {
  return http.get(`/officeuser/ticket_types?role=${userRole}`);
}

function send_ticket(data) {
  return http.post(`officeuser/ticket`, data);
}

function get_active_ticket_list(stayStatus, searchText, start, end) {
  return http.get(
    `officeuser/ticket/active?stay=${stayStatus}&search=${searchText}&start_date=${start}&end_date=${end}`
  );
}

function change_ticket_status(data) {
  return http.patch(`officeuser/ticket/${data.id}`, data.sendObj);
}

function ticket_detail_change(data) {
  return http.patch(`officeuser/ticket/${data.id}`, data.sendObj);
}

// * ivr data exel

function ivr_exel_upload(formData) {
  return http.post(`/officeuser/excel/ivr/upload`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

function zero_data_exel_upload(formData) {
  return http.post(`/officeuser/excel/upload`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

function ivr_send_ivr(data) {
  return http.post(`/officeuser/send/ivr`, data.sendObj);
}

function ivr_data_info() {
  return http.get(`/officeuser/send/ivr`);
}

function get_sent_tickets(searchText, start, end, page) {
  return http.get(
    `officeuser/ticket/sent?search=${searchText}&start_date=${start}&end_date=${end}&page=${page}`
  );
}

function get_completed_tickets(searchText, start, end, page) {
  return http.get(
    `officeuser/ticket/completed?search=${searchText}&start_date=${start}&end_date=${end}&page=${page}`
  );
}

function state_list() {
  return http.get(`officeuser/state/list`);
}

function change_state_history(data) {
  return http.patch(`officeuser/detail`, data.sendObj);
}
// * urgent setup

function send_urgent_setup(data) {
  return http.post(`/officeuser/application/urgent/${data.app_id}`);
}

function accept_urgent_setup(data) {
  return http.post(`/officeuser/application/urgent/${data.app_id}/accept`);
}

function get_state_history(data) {
  return http.get(
    `officeuser/state_history?start_date=${data.startDate}&end_date=${data.endDate}&officeuser=${data.userId}`
  );
}

function send_urgent_to_collector(id, stayNote) {
  return http.post(`officeuser/restofficial/payment/urgent/${id}`, {
    note: stayNote,
  });
}

function accept_urgent_collector(data) {
  return http.post(`/officeuser/restofficial/payment/urgent/${data.id}/accept`);
}

function get_state_history_daily() {
  return http.get(`officeuser/info/state_history`);
}

function collector_restaurant_list(data) {
  return http.get(
    `/officeuser/collector/restaurant/list?page=${data.page}&&search=${data.search}&&city=${data.city}&&town=${data.town}`
  );
}

function collector_restaurant_offers(data) {
  return http.get(`/officeuser/collector/restaurant/${data.id}/offers`);
}

function collector_restaurant_orders(data) {
  return http.get(`/officeuser/collector/restaurant/${data.id}/orders`);
}

function collector_restaurant_transfer() {
  return http.get(`officeuser/calculate/prim/setup`);
}

function get_setup_tracker() {
  return http.get(`officeuser/setup/tracker`);
}

function change_operation_prims(data) {
  return http.patch(`officeuser/prim/${data.id}`, data.obj);
}

function courier_tracker_demo_status_list(data) {
  return http.get(`/officeuser/restofficial/${data.user_id}/sepetfast/demo`);
}

function courier_tracker_demo_change(data) {
  return http.post(
    `/officeuser/restofficial/${data.user_id}/sepetfast/demo`,
    data.sendObj
  );
}

function courier_tracker_demo_delete(data) {
  return http.delete(`/officeuser/restofficial/${data.user_id}/sepetfast/demo`);
}

function courier_tracker_demo_test_order(data) {
  return http.post(`/officeuser/restofficial/${data.user_id}/test_order`, {
    service_type: data.service_type,
  });
}

function change_collector(data, userId) {
  return http.post(`/officeuser/collector/restaurant/${userId}/transfer`, data);
}

function get_task_list() {
  return http.get(`/officeuser/task`);
}

function new_task_create() {
  return http.post(`/officeuser/task`);
}

function task_list_detail(data) {
  return http.get(`/officeuser/task/${data.task_id}`);
}

function calendar_list() {
  return http.get(`/officeuser/calendar`);
}

function personal_template_note_list() {
  return http.get(`/officeuser/personal/note_template`);
}

function personal_template_note_create(data) {
  return http.post(`/officeuser/personal/note_template`, data);
}

function personal_template_note_delete(data) {
  return http.delete(`/officeuser/personal/note_template/${data.note_id}`);
}

function personal_template_note_sort(data) {
  return http.post(`/officeuser/personal/note_template/sort`, data);
}

function calendar_all_list() {
  return http.get(`/officeuser/calendar/all`);
}

function task_actions_list(data) {
  return http.get(`/officeuser/task/actions?type=${data.type}`);
}

function restofficial_task_action_list() {
  return http.get(`/officeuser/restofficial/note/actions`);
}

function restofficial_task_action(data) {
  return http.post(
    `/officeuser/restofficial/${data?.rest_id}/note/action`,
    data.sendObj
  );
}

function task_action(data) {
  return http.post(`/officeuser/task/${data.task_id}/action`, data.sendObj);
}

function task_revert(data) {
  return http.post(`/officeuser/task/${data.task_id}/revert`);
}

function application_data_update(id, data) {
  return http.patch(`/officeuser/application/status/${id}`, {
    ...data,
  });
}

function patch_restofficial(data) {
  return http.patch(`/officeuser/restofficial/detail/${data.id}`, data.sendObj);
}

function task_application_update(data) {
  return http.patch(
    `/officeuser/task/${data.task_id}/application/${data.app_id}`,
    data.sendObj
  );
}

function rest_prev_offer_history(data) {
  return http.get(`/officeuser/restofficial/previous_offers/${data.rest_id}`);
}

function task_dashboard(data) {
  return http.get(`/officeuser/dashboard/${data.user_id}`);
}

function task_dashboard_patch(data) {
  return http.patch(
    `officeuser/officeuser_task_type/${data.task_type_id}`,
    data.sendObj
  );
}

function task_phone_search(data) {
  return http.get(`/officeuser/task/phone/search`, {
    params: data.sendObj,
  });
}

function task_reset(data) {
  return http.post(`/officeuser/task/${data?.task_id}/reset`);
}

function restofficial_refs(data) {
  return http.get(
    `/officeuser/restofficial/refs?page=${data.page}&&city=${data.city}&&town=${data.town}&&product=${data.product}`
  );
}

function task_unlock(data) {
  return http.post(`/officeuser/task/${data?.task_id}/unlock`, data.sendObj);
}

function offer_unlock(data) {
  return http.post(`/officeuser/offer/${data?.offer_id}/unlock`, data.sendObj);
}

//* applciation Exel

function application_counts(zeroProductId, ivrProductId) {
  return http.get(
    `/officeuser/application/counts?zero_product=${zeroProductId}&ivr_product=${ivrProductId}`
  );
}

function application_deleted_count(data) {
  return http.get(
    `/officeuser/application/return/deleted?type=${data?.type}&start_time=${data?.start_time}&end_time=${data?.end_time}&deleted_count=${data?.deleted_count}&deleted_reason=${data?.deleted_reason}`
  );
}

function application_deleted(data) {
  return http.post(`/officeuser/application/return/deleted`, data.sendObj);
}

function application_history_log(page) {
  return http.get(`/officeuser/application/return/log?page=${page}`);
}

//* ys_secret_key_v2

function ys_remote_id_list(data) {
  return http.get(
    `/officeuser/yemeksepeti/remote_id?restofficial=${data.rest_id}`
  );
}

function ys_remote_id_create(data) {
  return http.post(`/officeuser/yemeksepeti/remote_id`, data.sendObj);
}

function ys_remote_id_delete(data) {
  return http.delete(`/officeuser/yemeksepeti/remote_id/${data.id} `);
}

function post_restaurant_register_tech(data) {
  return http.post(`/officeuser/application/customerservices`, data);
}

function post_restaurant_new_products(data) {
  return http.post(`/officeuser/application/product/customerservices`, data);
}

function secret_key_platform_summary(data) {
  return http.get(`/officeuser/restofficial/platforms/${data.rest_id}/summary`);
}

function secret_key_platform_info(data) {
  return http.get(
    `/officeuser/restofficial/platforms/${data.id}?secret_key=${data?.platform_id}`
  );
}

function get_active_tasks() {
  return http.get(`officeuser/tasks/active`);
}

function task_from_calendar(data) {
  return http.post(`/officeuser/calendar/${data.calendar_id}/task`);
}

function task_pull(data) {
  return http.post(`/officeuser/task/pull`, data);
}

function res_tech_pending() {
  return http.get(`/officeuser/task/pending_restaurant_tickets`);
}

function ticket_bounce_list() {
  return http.get(`/officeuser/ticket_bounce/not_defended`);
}

function ticket_bounce_defend(data) {
  return http.post(
    `/officeuser/ticket_bounce/${data.bounce_id}/defense`,
    data.sendObj
  );
}

function appointment_list(data) {
  return http.get(`/officeuser/summary?filter=${data.type}&&role=officeuser`);
}

function appointment_qcd_list(data) {
  return http.get(
    `officeuser/summary?officeuser_id=${data.user_id}&&filter=${data.type}&&role=sales_control`
  );
}

function appointment_order_list(data) {
  return http.get(`/officeuser/summary/order_counter/${data.id}`);
}

function appointment_task_back(data) {
  return http.patch(`/officeuser/calendar/${data.id}/update_time`);
}

function product_import(data) {
  return http.post(`/officeuser/product/import`, data);
}

function get_product_header_picture_template() {
  return http.get(`officeuser/product_header_picture_template`);
}

function get_product_picture_template(id) {
  return http.get(
    `officeuser/product_picture_template?product_header_picture_template=${id}`
  );
}

function save_product_picture_template(data) {
  return http.post(`officeuser/product/picture_template/link`, data);
}

function create_new_task(data) {
  return http.post(`/officeuser/task/queue`, data.sendingObj);
}

function renewal_summary_order_counter(data) {
  return http.get(`/officeuser/summary/order_counter/${data.rest_id}`);
}

function renewal_summary_previous_offer(data) {
  return http.get(`/officeuser/restofficial/previous_offers/${data.rest_id}`);
}

function offer_info(data) {
  return http.get(`/restaurant/offer?code=${data.offerCode}`);
}

function create_reminder(data) {
  return http.post(`/officeuser/reminder`, data.sendingObj);
}

function patch_reminder(data) {
  return http.patch(`/officeuser/reminder/${data?.id}`, data.sendingObj);
}

function patch_reminder_history(data) {
  return http.patch(`/officeuser/reminder/${data.id}/history`, data.sendObj);
}

function delete_product_template_header(id) {
  return http.delete(`officeuser/product_header_picture_template/${id}`);
}

function delete_product_template(id) {
  return http.delete(`officeuser/product/picture_template/${id}`);
}

function create_product_template_header(data) {
  return http.post(`officeuser/product_header_picture_template`, data);
}

function edit_product_template_header(data, id) {
  return http.patch(`officeuser/product_header_picture_template/${id}`, data);
}

function edit_product_template(data, id) {
  return http.patch(`officeuser/product/picture_template/${id}`, data);
}

function create_product_template(data) {
  return http.post(`officeuser/product_picture_template`, data);
}

function create_picture_from_task(taskId, data) {
  return http.post(`officeuser/task/${taskId}/picture`, data);
}

function get_prim_table(productId, renew) {
  return http.get(`officeuser/product/${productId}/prim/table?renew=${renew}`);
}

function get_prim_profit(type) {
  return http.get(`officeuser/profit/page?page_type=${type}`);
}

function get_prim_profile() {
  return http.get(`officeuser/profil/page`);
}

function create_restofficial_reminder(data) {
  let audioBlob;
  if (data.sendObj.audioType === "chunk") {
    audioBlob = new Blob(data.sendObj.audioChunks.current, {
      type: "audio/webm",
    });
  }
  const formData = new FormData();
  formData.append("restofficial", data.sendObj.restofficial);
  formData.append("note", data.sendObj.note);
  formData.append("task_type", data.sendObj.task_type);
  if (data.sendObj.audioType === "chunk") {
    if (data.sendObj.audioChunks.current.length !== 0) {
      formData.append("voice_note", audioBlob, "record.webm");
    }
  } else {
    formData.append("voice_note_url ", data.sendObj.audioChunks);
  }

  return http.post(`/officeuser/reminder/restofficial`, formData);
}

function patch_restofficial_reminder(data) {
  return http.patch(
    `/officeuser/reminder/restofficial/${data?.id}`,
    data.sendObj
  );
}

function create_task_reminder(data) {
  return http.post(`/officeuser/reminder/outasktype`, data.sendObj);
}

function get_lifetime_stats(id) {
  return http.get(`officeuser/restofficial/${id}/lifetime_stats`);
}

function set_new_password(data) {
  return http.post(
    `/officeuser/restofficial/${data.rest_id}/set/password`,
    data.sendObj
  );
}

function show_product_profit(data) {
  return http.post(`/officeuser/dynamic_popup/push`, data.sendObj);
}

function change_restaurant_picture(data) {
  const bodyFormData = new FormData();
  bodyFormData.append("picture", data.restaurant_picture);

  return http.patch(
    `/officeuser/restaurant/restofficial/${data.id}`,
    bodyFormData,
    {
      "Content-Type": "multipart/form-data",
    }
  );
}

function change_restaurant_video(data) {
  const bodyFormData = new FormData();
  bodyFormData.append("video", data.restaurant_video);

  return http.patch(
    `/officeuser/restaurant/restofficial/${data.id}`,
    bodyFormData,
    {
      "Content-Type": "multipart/form-data",
    }
  );
}

function partial_payment_create(data) {
  return http.post(
    `/officeuser/offer/${data.offer_id}/partial_payment`,
    data.sendObj
  );
}

function partial_payment_info(data) {
  return http.get(`/officeuser/offer/${data.offer_id}/partial_payment`);
}

function partial_payment_delete(data) {
  return http.delete(`/officeuser/offer/${data.offer_id}/partial_payment`);
}

function cdr_recording(data) {
  return http.get(`/officeuser/cdr/${data?.record_id}/recording`);
}

//* Contract Renewal

function renewal_summary_list(data) {
  return http.get(`/officeuser/contract-renewal`, {
    params: data.sendObj,
  });
}

function summary_restaurant_detail(data) {
  return http.get(
    `/officeuser/contract-renewal/${data.rest_id}?state=${data.sendObj.state}`
  );
}

function summary_renewal_assign(data) {
  return http.patch(
    `/officeuser/contract-renewal/${data.rest_id}/assign`,
    data.sendObj
  );
}

function renewal_officeusers() {
  return http.get(`/officeuser/contract-renewal/officeusers`);
}

function renewer_list() {
  return http.get(`/officeuser/contract-renewal/renewer`);
}

function renewer_increase_rate_detail(row) {
  return http.get(`/officeuser/contract-renewal/renewer/${row?.user_id}`);
}

function product_profit_data(data) {
  return http.get(
    `/officeuser/restofficial/${data.restofficial}/dynamic_popup?key=${data.key}`
  );
}

function product_profit_availability(data) {
  return http.get(
    `/officeuser/restofficial/${data.restofficial}/dynamic_popup/availability`
  );
}

function send_reference_sms(data) {
  return http.post(`/officeuser/send/reference_sms`, data.sendObj);
}

function send_tech_support(id) {
  return http.post(`officeuser/restofficial/${id}/support`);
}

function add_sepetfast_demo(id) {
  return http.post(`/officeuser/restofficial/${id}/sepetfast/setup`);
}

function get_discount_allowed(id) {
  return http.get(`officeuser/offer/${id}/discount/allowed`);
}

function change_officeuser_products(id, data) {
  return http.patch(`officeuser/detail/${id}`, data);
}

const restaurantService = {
  get_neighborhood,
  get_town,
  get_city,
  res_platforms,
  res_status,
  res_delete_printers,
  delete_office_user,
  get_office_user,
  get_food_category,
  patch_office_user,
  get_platform_list,
  get_restofficial_platforms,
  patch_restofficial_platforms,
  delete_restofficial_platforms,
  update_platforms,
  update_platforms_workstating,
  get_officeuser_restofficial_report,
  get_all_officeuser_payments,
  get_officeuser_payments,
  edit_officeuser_payments,
  get_restofficial_platforms_openclose,
  edit_officeuser_data,

  post_secret_key,
  get_application_statu_list,
  patch_application_statu,
  get_restaurant_info_data,
  updade_application_statu,

  get_myrestaurant_table_data_list,
  get_my_restaurant_tag_report,

  get_company_info,

  get_restaurant_courier_data,
  post_restaurant_courier_data,
  patch_restaurant_courier_data,

  get_restaurant_printer_data,
  delete_restaurant_printer_data,
  patch_restaurant_printer_data,

  get_restaurant_modules_data,
  patch_restaurant_modules_data,

  get_restaurant_working_hours_data,
  patch_restaurant_working_hours_data,
  post_restaurant_working_hours_data,

  get_restaurant_payment_type_list,
  get_restaurant_payment_types_data,
  post_restaurant_payment_types_data,

  post_restaurant_register,
  get_reports_data,

  get_payment_tracker,
  patch_payment_tracker,
  get_payment_tracker_with_stayed,

  get_restaurant_active_offer,
  get_restaurant_offer_data,
  patch_restaurant_offer,

  post_reset_password,
  post_restaurant_block,

  get_restaurant_menu_list,
  post_restaurant_menu_product_status,

  officeuser_list,
  patch_aplication_transfer,

  get_application_note_list,
  post_application_note,

  application_notes,
  application_notes_create,

  blocked_status_reasons,
  blocked_reason,

  stay_time,
  get_application_statu_list_stayed,
  get_tracker_notes,
  post_tracker_notes,

  get_application_statu_list_deleted,

  operation_prim,
  post_seller_restaurant_register,
  seller_my_prim,
  seller_my_prim_history,
  eft_create,
  eft_list,
  eft_change,
  get_order_repeats,
  patch_restaurant_transfer,
  get_applications_status_list,
  get_campaign_offer,
  patch_campaign_offer,
  get_notification_types,

  get_product_header,
  post_create_product_header,
  get_product_list,
  menu_product_create,
  opt_product_list,
  menu_detail,
  menu_detail_change,
  menu_product_update,
  menu_bulk_update,
  menu_header_detail_change,
  restaurant_enduser_app_list,
  menu_header_photo_upload,
  menu_photo_upload,
  send_push_notification,
  menu_bulk_update_price,
  res_detail_info,
  res_detail,
  menu_header_create,
  order_rating_list,
  order_rating_change,
  search_by_customer,
  restaurant_online_history,
  get_restaurant_online_history,

  offer_product_list,

  get_application_sector,
  offer_basket,
  get_offer_basket,
  offer_basket_change,
  create_offer,
  offer_histories_list,
  sepet_app_demo,
  offer_history_detail,
  restaurant_enduser_search,
  restaurant_enduser_detail,
  restaurant_enduser_orders,
  restaurant_enduser_cashback,
  change_offer_time,
  special_offer_patch,
  menu_header_sort,
  menu_product_sort,
  get_outbound_amount,
  get_reports_outbound,
  calculate_prim_outbound,
  get_payment_tracker_v2,
  tech_support_list,
  tech_support_detail,
  notification_list,
  notification_detail,
  call_center_rest_search,
  officeuser_list_with_role,
  patch_officeuser_detail,
  get_total_amounts,
  send_sms_to_customer,
  send_payment_to_collector,
  get_ticket_types,
  send_ticket,
  get_active_ticket_list,
  change_ticket_status,
  ticket_detail_change,
  ivr_exel_upload,
  ivr_send_ivr,
  ivr_data_info,
  get_sent_tickets,
  get_completed_tickets,
  state_list,
  change_state_history,
  send_urgent_setup,
  accept_urgent_setup,
  get_state_history,
  send_urgent_to_collector,
  accept_urgent_collector,
  patch_officeuser_detail_collector,
  get_state_history_daily,
  collector_restaurant_list,
  collector_restaurant_offers,
  collector_restaurant_orders,
  collector_restaurant_transfer,
  get_setup_tracker,
  change_operation_prims,
  courier_tracker_demo_status_list,
  courier_tracker_demo_change,
  courier_tracker_demo_delete,
  courier_tracker_demo_test_order,
  change_collector,
  get_task_list,
  new_task_create,
  task_list_detail,
  calendar_list,
  personal_template_note_list,
  personal_template_note_create,
  personal_template_note_delete,
  personal_template_note_sort,
  calendar_all_list,
  task_actions_list,
  task_action,
  task_revert,
  application_data_update,
  patch_restofficial,
  task_application_update,
  rest_prev_offer_history,
  task_dashboard,
  task_dashboard_patch,
  restofficial_task_action_list,
  restofficial_task_action,
  post_restaurant_block_v2,
  task_phone_search,
  task_reset,
  restofficial_refs,
  task_unlock,
  application_counts,
  application_deleted_count,
  application_deleted,
  application_history_log,
  ys_remote_id_list,
  ys_remote_id_create,
  ys_remote_id_delete,
  zero_data_exel_upload,
  post_restaurant_register_tech,
  get_myrestaurant_table_data_list_tech,
  post_restaurant_new_products,
  secret_key_platform_summary,
  secret_key_platform_info,
  get_active_tasks,
  task_from_calendar,
  sepet_app_demo_detail,
  sepet_app_demo_delete,
  patch_company_info,
  task_pull,
  res_tech_pending,
  ticket_bounce_list,
  ticket_bounce_defend,
  restofficial_search,
  blocked_status_reasons_v2,
  appointment_list,
  appointment_order_list,
  appointment_task_back,
  get_product_header_picture_template,
  get_product_picture_template,
  save_product_picture_template,
  create_new_task,
  product_import,
  appointment_qcd_list,
  renewal_summary_list,
  offer_info,
  create_reminder,
  patch_reminder,
  delete_product_template_header,
  delete_product_template,
  create_product_template_header,
  edit_product_template_header,
  edit_product_template,
  create_product_template,
  create_picture_from_task,
  get_prim_table,
  get_prim_profit,
  get_prim_profile,
  patch_reminder_history,
  create_restofficial_reminder,
  patch_restofficial_reminder,
  create_task_reminder,
  get_lifetime_stats,
  set_new_password,
  show_product_profit,
  change_secret_key,
  change_restaurant_picture,
  change_restaurant_video,
  partial_payment_info,
  partial_payment_create,
  partial_payment_delete,
  cdr_recording,
  renewal_summary_order_counter,
  renewal_summary_previous_offer,
  summary_restaurant_detail,
  summary_renewal_assign,
  officeuser_list_renewal,
  renewal_officeusers,
  renewer_list,
  renewer_increase_rate_detail,
  product_profit_data,
  send_reference_sms,
  product_profit_availability,
  send_tech_support,
  offer_unlock,
  add_sepetfast_demo,
  get_discount_allowed,
  change_officeuser_products,
};

export default restaurantService;
