import React, { useState, useContext, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import {
  Badge,
  Button,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  Call,
  Coffee,
  DeveloperBoard,
  Groups,
  Logout,
  NotificationsNone,
  Opacity,
  PhoneForwarded,
  PowerOff,
  School,
  Search,
  TagFaces,
} from "@mui/icons-material";
import { RestaurantContext } from "../../contexts/restaurantContext";
import { LoginContext } from "../../contexts/loginContext";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import UserLoggedModal from "../modals/userLoggedModal";
import restaurantService from "../../services/restaurantService";
import UserStatusModal from "../modals/userStatus";
import managementService from "../../services/managementService";
import moment from "moment";
import ConnectedTvIcon from "@mui/icons-material/ConnectedTv";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import makeCallComp from "../call";

import { useSnackbar } from "notistack";
import { CallContext } from "../../contexts/callContext";
import { makeStyles } from "@mui/styles";
import PuantajPopupModal from "../../pages/managment/dataManagement/components/puantajSettingsv2/modals/puantajPopup";

let resInfo = JSON.parse(localStorage.getItem("st-officeuser-info"));

const useStyles = makeStyles((theme) => ({
  input: {
    "& .MuiInputBase-input": {
      width: "100%",
      fontSize: 15,
      textAlign: "center",
    },
    "& input::placeholder": {
      fontSize: 16,
    },
  },
}));

const Header = ({ title, children }) => {
  let history = useHistory();
  const classes = useStyles();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { logoutFunc, logoutSellerFunc } = useContext(LoginContext);
  const {
    getRestaurantInfo,
    restOfficialData,
    notificationListFunc,
    notificationList,
    notificationDetailFunc,
    getStateHistory,
    userStatus,
    setUserStatus,
    statusModal,
    setStatusModal,
    setisMouseInactive,
  } = useContext(RestaurantContext);
  const {
    cdrOutboundFunc,
    cdrAnswerFunc,
    cdrHangupFunc,
    phoneNumberv2,
    cdrMissedCountFunc,
  } = useContext(CallContext);
  const [resName, setResName] = useState(null);
  const [userLocked, setUserLocked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const inputRef = useRef(null);
  const [fieldValue, setFieldValue] = useState("");
  const [puantajPopupModal, setPuantajPopupModal] = useState({
    showModal: false,
    ratingId: null,
  });

  const [disabledCallIcon, setDisabledCallIcon] = useState(false);

  const restaurantId = window.location.pathname.slice(
    13,
    window.location.pathname.length
  );

  // notification-menu
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const notifAction = (obj) => {
    setAnchorEl(null);
    let data = {
      notif_id: obj.notif_id,
      sendObj: {
        read: true,
      },
    };
    notificationDetailFunc(data);
    history.push(`/restaurants/${obj.res_id}`);
  };
  //  notification-menu

  useEffect(() => {
    notificationListFunc();
    (async () => {
      const res = await managementService.get_current_state();
      if (
        res?.state !== "active" &&
        res?.state !== "out_call" &&
        res?.state !== "setup"
      ) {
        setStatusModal(true);
      }
      setUserStatus(res?.state);
    })();
  }, []);

  useEffect(() => {
    if (title == "Restoran") {
      getRestaurantInfo(restaurantId);
    } else {
      setResName(null);
    }
  }, [title]);

  useEffect(() => {
    if (restOfficialData != null) {
      setResName(restOfficialData?.name);
    }
  }, [restOfficialData]);

  const handleUserLockedInfo = () => {
    setShowModal(true);
  };

  const handleSetUserStatus = async (status) => {
    let data = {
      sendObj: {
        state: status,
      },
    };

    await restaurantService.change_state_history(data);
    if (status === "active" || status === "out_call" || status === "setup") {
      setStatusModal(false);
    } else {
      setStatusModal(true);
    }
    let data2 = {
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      userId: JSON.parse(localStorage.getItem("st-officeuser-info")).user_id,
    };
    getStateHistory(data2);
    setUserStatus(status);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      history.push(`/restaurants/${fieldValue}`);
    }
  };

  return (
    <div
      style={{
        position: "fixed",
        backgroundColor: "black",
        height: 60,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        WebkitAppRegion: "drag",
        flexDirection: "row",
        width: "100%",
        paddingLeft: "1%",
        paddingRight: "1%",
      }}
    >
      <div
        style={{
          width: "auto",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          height: "auto",
          alignItems: "center",
          cursor: "pointer",
          padding: 12,
        }}
        onClick={() => {
          if (history.location.pathname.startsWith("/operation")) {
            history.push("/");
            setResName(null);
          } else if (history.location.pathname.startsWith("/restaurants")) {
            if (
              JSON.parse(localStorage.getItem("st-officeuser-info")).role ===
              "collector"
            ) {
              if (
                JSON.parse(localStorage.getItem("st-officeuser-info"))
                  .department === "Operasyon Yonetimi"
              ) {
                history.push("/user-dashboard");
              } else {
                history.push("/operation/tasks");
              }
            } else if (
              JSON.parse(localStorage.getItem("st-officeuser-info")).role ===
              "tech"
            ) {
              history.push("/operation/tasks");
            } else if (
              JSON.parse(localStorage.getItem("st-officeuser-info")).role ===
              "call_center"
            ) {
              history.push("/call-center");
            } else {
              history.push("/operation/tasks");
            }
            setResName(null);
          } else {
            history.push("/");
          }
        }}
      >
        <ArrowBackIosIcon
          style={{
            width: 42,
            height: 42,
            color: "#fff",
          }}
        />
        <span style={{ color: "#fff", fontSize: 26, fontFamily: "segoeuiB" }}>
          {title}{" "}
          {resName != null && title == "Restoran"
            ? `- ${resName} - ${restaurantId}`
            : ""}
        </span>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          borderRadius: 8,
        }}
      >
        <InputBase
          variant="filled"
          onKeyDown={handleKeyPress}
          style={{
            width: "30%",
            color: "black",
            marginLeft: 14,
            fontSize: 15,
            fontWeight: "400",
            fontFamily: "segoeuiRg",
            height: 45,
            backgroundColor: "#f4f4f4",
            textAlign: "center",
            borderRadius: 4,
          }}
          value={fieldValue}
          className={classes.input}
          onChange={(e) => {
            setFieldValue(e.target.value);
          }}
        />
        <Button
          style={{
            color: "white",
            backgroundColor: "#f53b62",
            textTransform: "capitalize",
            fontFamily: "SgBd",
            fontSize: 20,
            marginLeft: 30,
          }}
          onClick={() => {
            history.push(`/restaurants/${fieldValue}`);
          }}
        >
          <Search />
        </Button>
      </div>
      {children}
      <div
        style={{
          width: "25%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Tooltip title="İşe dön">
          <TagFaces
            style={{
              width: 32,
              height: 32,
              color: userStatus === "active" ? "#0ac30a" : "#fff",
              backgroundColor: "#000",
              cursor: "pointer",
            }}
            onClick={() => {
              handleSetUserStatus("active");
              setisMouseInactive(false);
            }}
          />
        </Tooltip>
        {/* <Tooltip title="Dış Arama">
          <PhoneForwarded
            style={{
              width: 32,
              height: 32,
              color: userStatus === "out_call" ? "#0ac30a" : "#fff",
              backgroundColor: "#000",
              cursor: "pointer",
            }}
            onClick={() => handleSetUserStatus("out_call")}
          />
        </Tooltip> */}
        <Tooltip title="Mola">
          <Coffee
            style={{
              width: 32,
              height: 32,
              color: userStatus === "break" ? "#c931c9" : "#fff",
              backgroundColor: "#000",
              cursor: "pointer",
            }}
            onClick={() => handleSetUserStatus("break")}
          />
        </Tooltip>
        <Tooltip title="Eğitim">
          <School
            style={{
              width: 32,
              height: 32,
              color: userStatus === "training" ? "#c931c9" : "#fff",
              backgroundColor: "#000",
              cursor: "pointer",
            }}
            onClick={() => handleSetUserStatus("training")}
          />
        </Tooltip>
        <Tooltip title="Wc">
          <Opacity
            style={{
              width: 32,
              height: 32,
              color: userStatus === "wc" ? "#c931c9" : "#fff",
              backgroundColor: "#000",
              cursor: "pointer",
            }}
            onClick={() => handleSetUserStatus("wc")}
          />
        </Tooltip>
        <Tooltip title="Toplantı">
          <Groups
            style={{
              width: 32,
              height: 32,
              color: userStatus === "meeting" ? "#c931c9" : "#fff",
              backgroundColor: "#000",
              cursor: "pointer",
            }}
            onClick={() => handleSetUserStatus("meeting")}
          />
        </Tooltip>
        <Tooltip title="Kurulum">
          <ConnectedTvIcon
            style={{
              width: 32,
              height: 32,
              color: userStatus === "setup" ? "#c931c9" : "#fff",
              backgroundColor: "#000",
              cursor: "pointer",
            }}
            onClick={() => handleSetUserStatus("setup")}
          />
        </Tooltip>
        <Tooltip title="Sözlük">
          <ImportContactsIcon
            style={{
              width: 32,
              height: 32,
              color: userStatus === "dictionary" ? "#c931c9" : "#fff",
              backgroundColor: "#000",
              cursor: "pointer",
            }}
            onClick={() => handleSetUserStatus("dictionary")}
          />
        </Tooltip>
        <Tooltip title="İşi bitir">
          <PowerOff
            style={{
              width: 32,
              height: 32,
              color: userStatus === "passive" ? "red" : "#fff",
              backgroundColor: "#000",
              cursor: "pointer",
            }}
            onClick={() => handleSetUserStatus("passive")}
          />
        </Tooltip>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        {JSON.parse(localStorage.getItem("st-officeuser-info"))
          ?.iqcell_username !== null && (
          <Call
            onClick={() => {
              setTimeout(() => {
                if (inputRef.current) {
                  inputRef.current.focus();
                }
              }, 100);

              let sendCallData = {
                callee: phoneNumberv2,
                trigger_source: "hand",
              };

              makeCallComp({
                enqueueSnackbar,
                closeSnackbar,
                inputRef,
                cdrOutboundFunc,
                cdrAnswerFunc,
                cdrHangupFunc,
                sendCallData,
                cdrMissedCountFunc,
                disabledCallIcon,
                setDisabledCallIcon,
              });
            }}
            style={{
              color: "#fff",
              width: 32,
              height: 32,
              cursor: "pointer",
              marginRight: 55,
            }}
          />
        )}
        <DeveloperBoard
          style={{
            width: 32,
            height: 32,
            color: userStatus === "passive" ? "red" : "#fff",
            backgroundColor: "#000",
            cursor: "pointer",
            marginRight: 55,
          }}
          onClick={() => {
            history.push(`/development`);
          }}
        />

        <div style={{ marginRight: 55 }} onClick={handleUserLockedInfo}>
          {userLocked ? (
            <LockIcon
              style={{
                color: "#fff",
                width: 32,
                height: 32,
                cursor: "pointer",
              }}
            />
          ) : (
            <LockOpenIcon
              style={{
                color: "#fff",
                width: 32,
                height: 32,
                cursor: "pointer",
              }}
            />
          )}
        </div>
        <div
          style={{
            width: "auto",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginRight: 55,
            }}
          >
            <Badge badgeContent={notificationList?.length} color="primary">
              <NotificationsNone
                id="notif-menu_id"
                aria-controls={open ? "notif-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                style={{
                  color: "#fff",
                  width: 32,
                  height: 32,
                  cursor: "pointer",
                }}
              />
            </Badge>
            <Menu
              id="notif-menu"
              aria-labelledby="notif-menu_id"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              {notificationList?.map((row, i) => {
                return (
                  <MenuItem
                    key={i}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                    onClick={() => {
                      if (row?.title === "CDR Puanlama") {
                        setPuantajPopupModal({
                          showModal: true,
                          ratingId: row?.cdr_rating,
                          handleClose,
                        });
                      } else {
                        let obj = {
                          res_id: row?.restofficial,
                          notif_id: row?.id,
                        };
                        notifAction(obj);
                      }
                    }}
                  >
                    <span style={{ fontFamily: "segoeuiB" }}>{row?.title}</span>
                    <span
                      style={{
                        fontFamily: "segoeuiRg",
                        marginLeft: 10,
                        fontSize: 14,
                      }}
                    >
                      {row?.content}
                    </span>
                  </MenuItem>
                );
              })}
            </Menu>
          </div>
        </div>
        <div
          style={{
            width: "auto",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginRight: 35,
            }}
          >
            <IconButton
              onClick={() => {
                if (resInfo?.type == "officeuser") {
                  logoutFunc();
                } else {
                  logoutSellerFunc();
                }
              }}
            >
              <Logout style={{ color: "#fff", width: 32, height: 32 }} />
            </IconButton>
          </div>
        </div>
      </div>

      <UserLoggedModal
        showModal={showModal}
        setShowModal={setShowModal}
        setUserLocked={setUserLocked}
      />

      <UserStatusModal statusModal={statusModal} userStatus={userStatus} />
      <PuantajPopupModal
        puantajPopupModal={puantajPopupModal}
        setPuantajPopupModal={setPuantajPopupModal}
      />
    </div>
  );
};

export default Header;
