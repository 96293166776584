import React, { useEffect, useState } from "react";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Modal,
} from "@mui/material";

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  width: "100%",
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  overflow: "auto",
  // change background colour if dragging
  background: isDragging ? "lightgreen" : "white",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  overflow: "auto",
  minWidth: 320,
});

const SortList = ({ sortListModal, setSortListModal, sortListFunc }) => {
  const [list1, setList1] = useState(null);

  useEffect(() => {
    if (sortListModal.sortList != null) {
      setList1(sortListModal.sortList);
    }
  }, [sortListModal.sortList]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const newRest = reorder(
      list1,
      result.source.index,
      result.destination.index
    );
    setList1(newRest);
  };

  return (
    <Modal
      onClose={() => {
        setSortListModal({
          showModal: false,
          parentListInfo: null,
          sortList: null,
          sortListHeader: null,
          uiInfo: null,
        });
      }}
      open={sortListModal.showModal}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid
        style={{
          background: "#f4f4f4",
          padding: 10,
          textAlign: "left",
          borderRadius: 15,
          width: sortListModal.uiInfo?.width,
          height: "auto",
        }}
      >
        <Card
          style={{
            boxShadow: "none",
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            background: "#f4f4f4",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              height: 40,
              borderRadius: 15,
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: 30,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{
                    fontSize: 20,
                    fontWeight: 700,
                    color: "royalblue",
                  }}
                >
                  {sortListModal.sortListHeader}
                </span>
              </div>
            </div>
            <div style={{ fontSize: 12, color: "gray", margin: 10 }}>
              * Sıralamak için kartın üstüne basılı tutarak sürükleyin.
            </div>
          </div>
          <CardContent
            style={{
              justifyContent: "center",
              display: "flex",
              flexDirection: "row",
              marginTop: 10,
              width: "100%",
            }}
          >
            <DragDropContext onDragEnd={onDragEnd}>
              <div
                style={{
                  display: "flex",
                  overflow: "auto",
                  height: "auto",
                  maxHeight: 400,
                  width: sortListModal.uiInfo?.width,
                }}
              >
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={{
                        ...getListStyle(snapshot.isDraggingOver),
                        width: sortListModal.uiInfo?.width,
                      }}
                    >
                      <div>
                        {list1 != null &&
                          list1?.map((item, index) => (
                            <Draggable
                              key={item.id.toString()}
                              draggableId={item.id.toString()}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                  )}
                                >
                                  {item.title}
                                </div>
                              )}
                            </Draggable>
                          ))}
                      </div>
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            </DragDropContext>
          </CardContent>
          <CardActions
            style={{
              backgroundColor: "#f4f4f4",
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 10,
            }}
          >
            <Button
              style={{ background: "#f53b62" }}
              onClick={() => {
                sortListFunc(list1, sortListModal.parentListInfo);
                setSortListModal({
                  showModal: false,
                  parentListInfo: null,
                  sortList: null,
                  sortListHeader: null,
                  uiInfo: null,
                });
              }}
              variant="contained"
            >
              Güncelle
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </Modal>
  );
};

export default SortList;
