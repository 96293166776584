import React, { useState } from "react";

import { Button, Card, Modal, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    padding: 20,
    border: "2px solid #000",
    boxShadow: 5,
    backgroundColor: "rgb(0 0 0 / 62%)",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
    maxHeight: "100vh",
  },
}));

const AddGroupPuantajModal = ({
  addGroupPuantajScreen,
  setAddGroupPuantajScreen,
  cdrRatingGroupList,
  cdrRatingGroupAddFunc,
}) => {
  const classes = useStyles();

  return (
    <>
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={addGroupPuantajScreen.showModal}
        className={classes.modal}
        onClose={() => {
          setAddGroupPuantajScreen({
            showModal: false,
            selectedTaskType: null,
          });
        }}
      >
        <Card
          style={{
            width: "45%",
            display: "flex",
            alignItems: "center",
            padding: 30,
            flexDirection: "column",
            maxHeight: "80vh",
            overflow: "auto",
          }}
        >
          <div
            style={{
              width: "100%",
              height: 40,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#fff",
              marginBottom: 20,
              fontFamily: "segoeuiBd",
              fontSize: 22,
              fontVariant: "all-petite-caps",
              letterSpacing: 3.5,
            }}
          >
            PUANTAJA GRUP EKLE
          </div>
          {addGroupPuantajScreen.showModal &&
            cdrRatingGroupList?.map((row) => {
              return (
                <Paper
                  elevation={1}
                  style={{
                    backgroundColor: "#f4f4f4",
                    width: "90%",
                    padding: 10,
                    margin: 10,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div>{row?.title}</div>
                  <Button
                    style={{
                      backgroundColor: "#f53b62",
                      color: "#fff",
                      fontFamily: "segoeuiB",
                      width: "5%",
                      display: "flex",
                      justifyContent: "center",
                      height: 20,
                    }}
                    size="small"
                    onClick={() => {
                      cdrRatingGroupAddFunc({
                        id: addGroupPuantajScreen.selectedPuantaj?.id,
                        sendObj: { group: row?.id },
                        setAddGroupPuantajScreen,
                      });
                    }}
                  >
                    Ekle
                  </Button>
                </Paper>
              );
            })}
        </Card>
      </Modal>
    </>
  );
};

export default AddGroupPuantajModal;
