import React, { useState } from "react";

import { Card, Modal, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { CheckBox } from "@mui/icons-material";
import { GreenSwitch } from "../../../../../../components/switch/greenSwitch";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    padding: 20,
    border: "2px solid #000",
    boxShadow: 5,
    backgroundColor: "rgb(0 0 0 / 62%)",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
    maxHeight: "100vh",
  },
}));

const GroupHeaderSelectModal = ({
  groupHeaderSelectModal,
  setGroupHeaderSelectModal,
  cdrRatingHeaderList,
  cdrRatingGroupList,
  setCdrRatingGroupList,
}) => {
  const classes = useStyles();

  const handleSwitchClick = (row) => {
    // Seçili grup listesini al
    const selectedGroupIndex = groupHeaderSelectModal.index;
    const selectedGroup = cdrRatingGroupList[selectedGroupIndex];

    // Güncellenmiş grup başlıklarını belirle
    const updatedRatingHeaders = selectedGroup.rating_headers.some(
      (header) => header.id === row.id
    )
      ? // Eğer başlık varsa, onu kaldır
        selectedGroup.rating_headers.filter((header) => header.id !== row.id)
      : // Eğer başlık yoksa, onu ekle
        [...selectedGroup.rating_headers, { id: row.id, title: row.title }];

    // Grup listesini güncelle
    const updatedGroupList = cdrRatingGroupList.map((group, index) =>
      index === selectedGroupIndex
        ? { ...group, rating_headers: updatedRatingHeaders }
        : group
    );

    // Güncellenmiş listeyi state'e aktar
    setCdrRatingGroupList(updatedGroupList);
  };

  return (
    <>
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={groupHeaderSelectModal.showModal}
        className={classes.modal}
        onClose={() => {
          setGroupHeaderSelectModal({
            showModal: false,
            selectedGroup: null,
            index: null,
          });
        }}
      >
        <Card
          style={{
            width: "45%",
            display: "flex",
            alignItems: "center",
            padding: 30,
            flexDirection: "column",
            maxHeight: "80vh",
            overflow: "auto",
          }}
        >
          <div
            style={{
              width: "100%",
              height: 40,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#fff",
              marginBottom: 20,
              fontFamily: "segoeuiBd",
              fontSize: 22,
              fontVariant: "all-petite-caps",
              letterSpacing: 3.5,
            }}
          >
            GRUP İÇİN BAŞLIK EKLE
          </div>
          {groupHeaderSelectModal.showModal &&
            cdrRatingHeaderList?.map((row) => {
              const isChecked = cdrRatingGroupList[
                groupHeaderSelectModal?.index
              ]?.rating_headers?.some((header) => header.id === row.id);

              return (
                <Paper
                  elevation={1}
                  style={{
                    backgroundColor: "#f4f4f4",
                    width: "90%",
                    padding: 10,
                    margin: 10,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div>{row?.title}</div>
                  <GreenSwitch
                    onClick={() => {
                      handleSwitchClick(row);
                    }}
                    checked={isChecked}
                  />
                </Paper>
              );
            })}
        </Card>
      </Modal>
    </>
  );
};

export default GroupHeaderSelectModal;
