import React, { useContext, useEffect, useRef, useState } from "react";

import {
  Box,
  Button,
  Card,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ManagementContext } from "../../../../../../contexts/managementContext";
import moment from "moment";
import CustomAudioPlayer from "../../../../../../components/audioPlayer";
import { Close, Info } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    padding: 20,
    border: "2px solid #000",
    boxShadow: 5,
    backgroundColor: "rgb(0 0 0 / 62%)",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
    maxHeight: "100vh",
  },
}));

const EducationHistoryModal = ({
  educationHistoryModal,
  setEducationHistoryModal,
}) => {
  const classes = useStyles();
  const {
    cdrRatingEducationHistoryFunc,
    educationHistoryInfo,
    cdrRatingEducationHistoryRecordFunc,
  } = useContext(ManagementContext);
  const audioRef = useRef(null);

  useEffect(() => {
    if (educationHistoryModal.showModal) {
      cdrRatingEducationHistoryFunc({
        sendObj: {
          officeuser: educationHistoryModal?.educationInfo?.officeuser?.user_id,
          task_type: educationHistoryModal?.educationInfo?.task_type?.id,
        },
      });
    }
  }, [educationHistoryModal.showModal]);

  return (
    <Modal
      disableEnforceFocus
      disableAutoFocus
      open={educationHistoryModal.showModal}
      className={classes.modal}
      onClose={() => {
        setEducationHistoryModal({
          showModal: false,
          ratingId: null,
        });
      }}
    >
      <Card
        style={{
          width: "80%",
          display: "flex",
          alignItems: "center",
          padding: 30,
          flexDirection: "column",
          maxHeight: "80vh",
          overflow: "auto",
        }}
      >
        <div
          style={{
            width: "100%",
            height: 40,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#fff",
            marginBottom: 20,
            fontFamily: "segoeuiBd",
            fontSize: 22,
            fontVariant: "all-petite-caps",
            letterSpacing: 3.5,
          }}
        >
          EĞİTİM HİSTORY -{" "}
          {educationHistoryModal?.educationInfo?.officeuser?.full_name}
        </div>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">Tarih / Saat</TableCell>
                <TableCell align="center">Eğitime Gönderen</TableCell>
                <TableCell align="center">Not</TableCell>
                <TableCell align="center">Eğitim Zamanı</TableCell>
                <TableCell align="center">Eğitim Notu</TableCell>
                <TableCell align="center">Ses dosyası</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {educationHistoryInfo?.map((row, ind) => {
                return (
                  <TableRow key={ind}>
                    <TableCell align="center">
                      {moment(row?.created_at).format("DD-MM-YYYY HH:mm:ss")}
                    </TableCell>
                    <TableCell align="center">
                      {row?.rated_by?.full_name}
                    </TableCell>
                    <TableCell align="center">
                      <Tooltip
                        title={row?.note}
                        placement="top"
                        sx={{ fontSize: 20 }}
                        arrow
                        componentsProps={{
                          tooltip: {
                            sx: {
                              fontSize: 14,
                              fontFamily: "segoeuiB",
                              padding: 1,
                              bgcolor: "#082657",
                              "& .MuiTooltip-arrow": {
                                color: "#082657",
                              },
                            },
                          },
                        }}
                      >
                        <Info
                          style={{
                            width: 24,
                            height: 24,
                            color: "#000",
                            cursor: "pointer",
                          }}
                        />
                      </Tooltip>
                    </TableCell>
                    <TableCell align="center">
                      {row?.education_completed_at !== null ? (
                        moment(row?.education_completed_at).format(
                          "DD-MM-YYYY HH:mm:ss"
                        )
                      ) : (
                        <Close />
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {row?.education_note !== null ? (
                        <Tooltip
                          title={row?.education_note}
                          placement="top"
                          sx={{ fontSize: 20 }}
                          arrow
                          componentsProps={{
                            tooltip: {
                              sx: {
                                fontSize: 14,
                                fontFamily: "segoeuiB",
                                padding: 1,
                                bgcolor: "#082657",
                                "& .MuiTooltip-arrow": {
                                  color: "#082657",
                                },
                              },
                            },
                          }}
                        >
                          <Info
                            style={{
                              width: 24,
                              height: 24,
                              color: "#000",
                              cursor: "pointer",
                            }}
                          />
                        </Tooltip>
                      ) : (
                        <Close />
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {row?.cdr?.voice ? (
                        <div style={{ width: "99%" }}>
                          <Box
                            sx={{
                              backgroundColor: "#736868",
                              color: "#fff",
                              padding: 1,
                              borderRadius: 1,
                              display: "flex",
                              flexDirection: "column",
                              gap: 1,
                              width: "90%",
                            }}
                          >
                            <div
                              style={{
                                width: "100%",
                                height: "auto",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: "#fff",
                                fontFamily: "segoeuiRg",
                                fontSize: 11,
                                color: "#000",
                                flexDirection: "column",
                              }}
                            >
                              <div>
                                {row?.cdr?.id} -{" "}
                                {moment(row?.cdr?.created_at).format(
                                  "DD-MM-YYYY HH:mm"
                                )}
                              </div>
                            </div>
                            <CustomAudioPlayer
                              audioRef={audioRef}
                              src={row?.cdr?.voice}
                            />
                          </Box>
                        </div>
                      ) : (
                        <>
                          <Button
                            style={{
                              backgroundColor: "#f53b62",
                              color: "#fff",
                              fontFamily: "segoeuiB",
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                              height: 20,
                            }}
                            size="small"
                            onClick={() => {
                              cdrRatingEducationHistoryRecordFunc({
                                record_id: row?.cdr?.id,
                              });
                            }}
                          >
                            Ses Dosyası
                          </Button>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </Modal>
  );
};

export default EducationHistoryModal;
