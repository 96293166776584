import { Avatar, Button, Divider, IconButton, Paper } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import {
  AssignmentTurnedIn,
  Coffee,
  ConnectedTv,
  Groups,
  Headphones,
  Home,
  HourglassTop,
  ImportContacts,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LocalAtm,
  MonetizationOn,
  Opacity,
  PhoneForwarded,
  PowerOff,
  School,
  TagFaces,
  TagRounded,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import moment from "moment";
import "moment/locale/tr";
import NewOrderTooltip from "../../../../components/tooltip";
import { ManagementContext } from "../../../../contexts/managementContext";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    width: 12,
    height: 12,
    borderRadius: 8,
    "&::after": {
      position: "absolute",
      top: -1,
      left: -1,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 2.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const MainScreen = ({
  setCallerFlipped,
  dashboardOfficeuserList,
  setDashboardUserInfo,
  setSelectedUser,
  stateHistoryPastListFunc,
  openToolTip,
  setOpenToolTip,
}) => {
  const [dashboardOfficeuserListObj, setDashboardOfficeuserList] = useState([]);
  const [officeuserShowList, setOfficeuserShowList] = useState([]);
  // const { cdrRatingTaskTypeCreateFunc } = useContext(ManagementContext);

  // İlk yüklemede localStorage'dan veriyi oku
  useEffect(() => {
    const storedList =
      JSON.parse(localStorage.getItem("st-dashboard-user-l")) || [];
    setOfficeuserShowList(storedList);
  }, []);

  useEffect(() => {
    if (dashboardOfficeuserList !== null) {
      setDashboardOfficeuserList(dashboardOfficeuserList);
    }
  }, [dashboardOfficeuserList]);

  const calculateActiveTime = (row) => {
    if (row?.state_info?.state_stats) {
      const totalActiveSecond =
        row?.state_info?.state_stats.active +
        row?.state_info?.state_stats.dictionary +
        row?.state_info?.state_stats.setup;

      return moment.utc(totalActiveSecond * 1000).format("mm");
    }
  };
  const calculateActiveHourTime = (row) => {
    if (row?.state_info?.state_stats) {
      const totalActiveSecond =
        row?.state_info?.state_stats.active +
        row?.state_info?.state_stats.dictionary +
        row?.state_info?.state_stats.setup;

      return moment.utc(totalActiveSecond * 1000).format("HH");
    }
  };
  const calculatePassiveTime = (row) => {
    if (row?.state_info?.state_stats) {
      const totalPassiveSecond =
        row?.state_info?.state_stats.break +
        row?.state_info?.state_stats.health +
        row?.state_info?.state_stats.meeting +
        row?.state_info?.state_stats.training +
        row?.state_info?.state_stats.wc;

      return moment.utc(totalPassiveSecond * 1000).format("mm");
    }
  };
  const calculatePassiveHourTime = (row) => {
    if (row?.state_info?.state_stats) {
      const totalPassiveSecond =
        row?.state_info?.state_stats.break +
        row?.state_info?.state_stats.health +
        row?.state_info?.state_stats.meeting +
        row?.state_info?.state_stats.training +
        row?.state_info?.state_stats.wc;

      return moment.utc(totalPassiveSecond * 1000).format("HH");
    }
  };

  const calculateSetupTime = (row) => {
    if (row?.state_info?.state_stats) {
      const totalPassiveSecond = row?.state_info?.state_stats?.setup;

      return moment.utc(totalPassiveSecond * 1000).format("mm");
    }
  };

  const calculateSetupHourTime = (row) => {
    if (row?.state_info?.state_stats) {
      const totalPassiveSecond = row?.state_info?.state_stats?.setup;

      return moment.utc(totalPassiveSecond * 1000).format("HH");
    }
  };

  const calculateTimeSpent = (row) => {
    return moment.utc(row).format("mm");
  };

  const calculateTimeSpentHour = (row) => {
    return moment.utc(row).format("HH");
  };

  const addToLocalStorageArray = (newItem) => {
    const updatedList = [...officeuserShowList, newItem];
    setOfficeuserShowList(updatedList);
    localStorage.setItem("st-dashboard-user-l", JSON.stringify(updatedList));
  };

  const removeFromLocalStorageArray = (itemToRemove) => {
    const updatedArray = officeuserShowList.filter(
      (item) => item !== itemToRemove
    );
    setOfficeuserShowList(updatedArray);
    localStorage.setItem("st-dashboard-user-l", JSON.stringify(updatedArray));
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ width: "95%", height: "98%" }}>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {dashboardOfficeuserListObj
            .filter((item) => {
              return !JSON.parse(
                localStorage.getItem("st-dashboard-user-l")
              )?.some((s) => s === item?.user_id);
            })
            .map((row, i) => {
              return (
                <Paper
                  key={i}
                  elevation={3}
                  style={{
                    width: "30%",
                    display: "flex",
                    padding: 5,
                    margin: "10px 10px",
                    backgroundColor: "#393737",
                    boxShadow: "rgb(91 91 91 / 75%) 0px 0px 9px 2px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "30%",
                      alignItems: "center",
                      justifyContent: "space-around",
                      backgroundColor: "#474545",
                    }}
                  >
                    <div style={{ width: "auto" }}>
                      <Badge
                        overlap="circular"
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        badgeContent={
                          row?.state === "active" ? (
                            <TagFaces
                              style={{
                                color: "green",
                                backgroundColor: "#f4f4f4",
                                width: 18,
                                height: 18,
                                borderRadius: 8,
                                padding: 3,
                              }}
                            />
                          ) : row?.state === "out_call" ? (
                            <PhoneForwarded
                              style={{
                                color: "green",
                                backgroundColor: "#f4f4f4",
                                width: 18,
                                height: 18,
                                borderRadius: 8,
                                padding: 3,
                              }}
                            />
                          ) : row?.state === "break" ? (
                            <Coffee
                              style={{
                                color: "purple",
                                backgroundColor: "#f4f4f4",
                                width: 18,
                                height: 18,
                                borderRadius: 8,
                                padding: 3,
                              }}
                            />
                          ) : row?.state === "training" ? (
                            <School
                              style={{
                                color: "purple",
                                backgroundColor: "#f4f4f4",
                                width: 18,
                                height: 18,
                                borderRadius: 8,
                                padding: 3,
                              }}
                            />
                          ) : row?.state === "wc" ? (
                            <Opacity
                              style={{
                                color: "purple",
                                backgroundColor: "#f4f4f4",
                                width: 18,
                                height: 18,
                                borderRadius: 8,
                                padding: 3,
                              }}
                            />
                          ) : row?.state === "meeting" ? (
                            <Groups
                              style={{
                                color: "purple",
                                backgroundColor: "#f4f4f4",
                                width: 18,
                                height: 18,
                                borderRadius: 8,
                                padding: 3,
                              }}
                            />
                          ) : row?.state === "passive" ? (
                            <PowerOff
                              style={{
                                color: "red",
                                backgroundColor: "#f4f4f4",
                                width: 18,
                                height: 18,
                                borderRadius: 8,
                                padding: 3,
                              }}
                            />
                          ) : row?.state === "setup" ? (
                            <ConnectedTv
                              style={{
                                color: "purple",
                                backgroundColor: "#f4f4f4",
                                width: 18,
                                height: 18,
                                borderRadius: 8,
                                padding: 3,
                              }}
                            />
                          ) : row?.state === "health" ? (
                            <Home
                              style={{
                                color: "red",
                                backgroundColor: "#f4f4f4",
                                width: 18,
                                height: 18,
                                borderRadius: 8,
                                padding: 3,
                              }}
                            />
                          ) : row?.state === "dictionary" ? (
                            <ImportContacts
                              style={{
                                color: "purple",
                                backgroundColor: "#f4f4f4",
                                width: 18,
                                height: 18,
                                borderRadius: 8,
                                padding: 3,
                              }}
                            />
                          ) : (
                            ""
                          )
                        }
                      >
                        <StyledBadge
                          overlap="circular"
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                          sx={{
                            "& .MuiBadge-badge": {
                              backgroundColor: row?.online ? "#44b700" : "red",
                              color: row?.online ? "#44b700" : "red",
                            },
                          }}
                          variant="dot"
                        >
                          <Avatar
                            alt="backoffice_user"
                            src={row?.picture}
                            sx={{ width: 90, height: 90, cursor: "pointer" }}
                            onClick={() => {
                              setDashboardUserInfo(row);
                              setCallerFlipped(true);
                              setSelectedUser(row);
                              // let data = {
                              //   sendObj: {
                              //     officeuser: row.user_id,
                              //     task_type: 1,
                              //   },
                              // };
                              // cdrRatingTaskTypeCreateFunc(data);
                            }}
                          />
                        </StyledBadge>
                      </Badge>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          fontFamily: "monospace",
                          fontSize: 15,
                          fontWeight: 550,
                          color: "#fff",
                        }}
                      >
                        {row?.full_name}
                      </div>
                    </div>
                    {row?.sub_department === "manager" ? (
                      ""
                    ) : (
                      <div>
                        <div style={{ color: "#fff", fontFamily: "monospace" }}>
                          TASK : {row?.task_count}
                        </div>
                        <div style={{ color: "#fff", fontFamily: "monospace" }}>
                          TAKVİM : {row?.calendar_count}
                        </div>
                      </div>
                    )}
                  </div>
                  <div
                    style={{
                      width: "70%",
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    {row?.sub_department === "manager" ? (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                        }}
                      >
                        <Paper
                          style={{
                            backgroundColor: "#494343",
                            width: 90,
                            color: "#fff",
                            padding: 1,
                            display: "flex",
                            height: 30,
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              width: "30%",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Headphones
                              style={{
                                color: "#fff",
                                backgroundColor: "transparent",
                                borderRadius: 2,
                              }}
                            />
                          </div>

                          <div
                            style={{
                              fontSize: 12,
                              fontFamily: "monospace",
                              width: "70%",
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            Çağrı
                            <div
                              style={{
                                fontSize: 13,
                                fontFamily: "monospace",
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              {row?.cdr_play_count}
                            </div>
                          </div>
                        </Paper>
                        <Paper
                          style={{
                            backgroundColor: "#494343",
                            width: 90,
                            color: "#fff",
                            padding: 1,
                            display: "flex",
                            height: 30,
                            alignItems: "center",
                            marginLeft: 10,
                          }}
                        >
                          <div
                            style={{
                              width: "30%",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <School
                              style={{
                                color: "#fff",
                                backgroundColor: "transparent",
                                borderRadius: 2,
                              }}
                            />
                          </div>

                          <div
                            style={{
                              fontSize: 12,
                              fontFamily: "monospace",
                              width: "70%",
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            Eğitim
                            <div
                              style={{
                                fontSize: 13,
                                fontFamily: "monospace",
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              {row?.education_count}
                            </div>
                          </div>
                        </Paper>
                      </div>
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Paper
                          style={{
                            backgroundColor: "#494343",
                            width: 90,
                            color: "#fff",
                            padding: 1,
                            display: "flex",
                            height: 30,
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              width: "25%",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <MonetizationOn
                              style={{
                                color: "#fff",
                                backgroundColor: "transparent",
                                borderRadius: 2,
                              }}
                            />
                          </div>

                          <div
                            style={{
                              fontSize: 12,
                              fontFamily: "monospace",
                              width: "75%",
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            Tahsilat
                            <div
                              style={{
                                fontSize: 13,
                                fontFamily: "monospace",
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              {row?.transaction_total}
                            </div>
                          </div>
                        </Paper>
                        <Paper
                          style={{
                            backgroundColor: "#494343",
                            width: 90,
                            color: "#fff",
                            padding: 1,
                            display: "flex",
                            height: 30,
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              width: "25%",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <AssignmentTurnedIn
                              style={{
                                color: "#fff",
                                backgroundColor: "transparent",
                                borderRadius: 2,
                              }}
                            />
                          </div>

                          <div
                            style={{
                              fontSize: 12,
                              fontFamily: "monospace",
                              width: "75%",
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            Kurulum
                            <div
                              style={{
                                fontSize: 13,
                                fontFamily: "monospace",
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                alignItems: "center",
                                fontWeight: 600,
                              }}
                            >
                              {row?.restofficial_count}
                            </div>
                          </div>
                        </Paper>
                        <Paper
                          style={{
                            backgroundColor: "#494343",
                            width: 90,
                            color: "#fff",
                            padding: 1,
                            display: "flex",
                            justifyContent: "space-between",
                            height: 30,
                            alignItems: "center",
                          }}
                        >
                          <HourglassTop
                            style={{
                              color: "#fff",
                              backgroundColor: "transparent",
                              borderRadius: 2,
                            }}
                          />{" "}
                          <div
                            style={{ fontSize: 10, fontFamily: "monospace" }}
                          >
                            Aktif
                            <div
                              style={{ fontSize: 13, fontFamily: "monospace" }}
                            >
                              {calculateActiveHourTime(row)}s{" "}
                              {calculateActiveTime(row)}dk
                            </div>
                          </div>
                        </Paper>
                        <Paper
                          style={{
                            backgroundColor: "#494343",
                            width: 90,
                            color: "#fff",
                            padding: 1,
                            display: "flex",
                            height: 30,
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              width: "30%",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <AssignmentTurnedIn
                              style={{
                                color: "#fff",
                                backgroundColor: "transparent",
                                borderRadius: 2,
                              }}
                            />
                          </div>

                          <div
                            style={{
                              fontSize: 12,
                              fontFamily: "monospace",
                              width: "70%",
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            Konuşma
                            <div
                              style={{
                                fontSize: 13,
                                fontFamily: "monospace",
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              {calculateTimeSpentHour(
                                row?.cdr_talk_duration * 1000
                              )}
                              s{" "}
                              {calculateTimeSpent(
                                row?.cdr_talk_duration * 1000
                              )}
                              dk
                            </div>
                          </div>
                        </Paper>
                      </div>
                    )}
                    <Divider
                      orientation="horizontal"
                      sx={{
                        backgroundColor: "gray",
                        width: "100%",
                        height: "0.1px",
                        margin: "5px 0px",
                      }}
                    />
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          width: "48%",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          style={{
                            fontFamily: "monospace",
                            fontSize: 12,
                            color: "#fff",
                            backgroundColor: "darkslategrey",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <IconButton
                            style={{
                              width: 13,
                              height: 13,
                              color:
                                moment().diff(row.current_date, "days") < 6
                                  ? "#fff"
                                  : "gray",
                            }}
                            disabled={
                              moment().diff(row.current_date, "days") < 6
                                ? false
                                : true
                            }
                            onClick={() => {
                              if (moment().diff(row.current_date, "days") < 6) {
                                row.current_date = moment(
                                  row.current_date
                                ).subtract(1, "days");
                                stateHistoryPastListFunc({
                                  sendingObj: {
                                    date: moment(row.current_date).format(
                                      "YYYY-MM-DD"
                                    ),
                                    officeuser: row?.user_id,
                                  },
                                });
                              }
                            }}
                          >
                            <KeyboardArrowLeft
                              style={{ width: 15, height: 15 }}
                            />
                          </IconButton>
                          <span>
                            {moment(row?.current_date).format("dddd")}
                          </span>
                          <IconButton
                            style={{
                              width: 13,
                              height: 13,
                              color:
                                moment().diff(row.current_date, "days") > 0
                                  ? "#fff"
                                  : "gray",
                            }}
                            disabled={
                              moment().diff(row.current_date, "days") > 0
                                ? false
                                : true
                            }
                            onClick={() => {
                              if (moment().diff(row.current_date, "days") > 0) {
                                row.current_date = moment(row.current_date).add(
                                  1,
                                  "days"
                                );

                                stateHistoryPastListFunc({
                                  sendingObj: {
                                    date: moment(row.current_date).format(
                                      "YYYY-MM-DD"
                                    ),
                                    officeuser: row?.user_id,
                                  },
                                });
                              }
                            }}
                          >
                            <KeyboardArrowRight
                              style={{ width: 15, height: 15 }}
                            />
                          </IconButton>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "space-between",
                            margin: "4px 0px",
                          }}
                        >
                          <Paper
                            style={{
                              backgroundColor: "#403836",

                              width: 90,
                              color: "#fff",
                              padding: 1,
                              display: "flex",
                              justifyContent: "center",
                              height: 30,
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                fontSize: 10,
                                fontFamily: "monospace",
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                padding: 2,
                              }}
                            >
                              İş Başlangıç
                              <div
                                style={{
                                  fontSize: 13,
                                  textAlign: "center",
                                }}
                              >
                                {row?.state_info?.active_date !== null
                                  ? moment(row?.state_info?.active_date).format(
                                      "HH:mm"
                                    )
                                  : "-"}
                              </div>
                            </div>
                          </Paper>

                          <Paper
                            style={{
                              backgroundColor: "#403836",
                              width: 90,
                              color: "#fff",
                              padding: 1,
                              display: "flex",
                              justifyContent: "center",
                              height: 30,
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                fontSize: 10,
                                fontFamily: "monospace",
                              }}
                            >
                              İş Çıkışı
                              <div
                                style={{
                                  fontSize: 13,
                                  textAlign: "center",
                                }}
                              >
                                {row?.state_info?.inactive_date !== null
                                  ? moment(
                                      row?.state_info?.inactive_date
                                    ).format("HH:mm")
                                  : "-"}
                              </div>
                            </div>
                          </Paper>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "space-between",
                            margin: "4px 0px",
                          }}
                        >
                          <Paper
                            style={{
                              backgroundColor: "#403836",
                              width: 90,
                              color: "#fff",
                              padding: 1,
                              display: "flex",
                              justifyContent: "center",
                              height: 30,
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                fontSize: 10,
                                fontFamily: "monospace",
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                textAlign: "center",
                              }}
                            >
                              Kurulum
                              <div
                                style={{
                                  fontSize: 13,
                                }}
                              >
                                {calculateSetupHourTime(row)}s{" "}
                                {calculateSetupTime(row)}dk
                              </div>
                            </div>
                          </Paper>

                          <Paper
                            style={{
                              backgroundColor: "#403836",
                              width: 90,
                              color: "#fff",
                              padding: 1,
                              display: "flex",
                              justifyContent: "center",
                              height: 30,
                              alignItems: "center",
                            }}
                          >
                            <NewOrderTooltip
                              title={
                                <div
                                  style={{
                                    overflow: "auto",
                                    padding: 2,
                                    fontSize: 12,
                                  }}
                                >
                                  <div>
                                    Mola :{" "}
                                    {moment
                                      .utc(
                                        row?.state_info?.state_stats.break *
                                          1000
                                      )
                                      .format("HH")}
                                    s{" "}
                                    {moment
                                      .utc(
                                        row?.state_info?.state_stats.break *
                                          1000
                                      )
                                      .format("mm")}
                                    dk{" "}
                                  </div>
                                  <div>
                                    Toplantı :{" "}
                                    {moment
                                      .utc(
                                        row?.state_info?.state_stats.meeting *
                                          1000
                                      )
                                      .format("HH")}
                                    s{" "}
                                    {moment
                                      .utc(
                                        row?.state_info?.state_stats.meeting *
                                          1000
                                      )
                                      .format("mm")}
                                    dk{" "}
                                  </div>
                                  <div>
                                    Eğitim :{" "}
                                    {moment
                                      .utc(
                                        row?.state_info?.state_stats.training *
                                          1000
                                      )
                                      .format("HH")}
                                    s{" "}
                                    {moment
                                      .utc(
                                        row?.state_info?.state_stats.training *
                                          1000
                                      )
                                      .format("mm")}
                                    dk{" "}
                                  </div>
                                  <div>
                                    Wc :{" "}
                                    {moment
                                      .utc(
                                        row?.state_info?.state_stats.wc * 1000
                                      )
                                      .format("HH")}
                                    s{" "}
                                    {moment
                                      .utc(
                                        row?.state_info?.state_stats.wc * 1000
                                      )
                                      .format("mm")}
                                    dk{" "}
                                  </div>
                                  <div>
                                    Sözlük :{" "}
                                    {moment
                                      .utc(
                                        row?.state_info?.state_stats
                                          .dictionary * 1000
                                      )
                                      .format("HH")}
                                    s{" "}
                                    {moment
                                      .utc(
                                        row?.state_info?.state_stats
                                          .dictionary * 1000
                                      )
                                      .format("mm")}
                                    dk{" "}
                                  </div>
                                </div>
                              }
                              placement="top"
                              open={
                                openToolTip == `open_${row?.user_id}`
                                  ? true
                                  : false
                              }
                            >
                              <div
                                style={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  fontFamily: "SgNr",
                                  cursor: "pointer",
                                  textAlign: "center",
                                }}
                                onClick={(e) => {
                                  if (openToolTip == `open_${row?.user_id}`) {
                                    setOpenToolTip(false);
                                  } else {
                                    e.stopPropagation();
                                    setOpenToolTip(`open_${row?.user_id}`);
                                  }
                                }}
                              >
                                <div
                                  style={{
                                    fontSize: 10,
                                    fontFamily: "monospace",
                                    textAlign: "center",
                                  }}
                                  onClick={(e) => {
                                    if (openToolTip == `open_${row?.user_id}`) {
                                      setOpenToolTip(false);
                                    } else {
                                      e.stopPropagation();
                                      setOpenToolTip(`open_${row?.user_id}`);
                                    }
                                  }}
                                >
                                  Pasif
                                  <div
                                    style={{
                                      fontSize: 13,
                                    }}
                                  >
                                    {calculatePassiveHourTime(row)}s{" "}
                                    {calculatePassiveTime(row)}dk
                                  </div>
                                </div>
                              </div>
                            </NewOrderTooltip>
                          </Paper>
                        </div>
                      </div>

                      {row?.sub_department === "manager" ? (
                        ""
                      ) : (
                        <>
                          <Divider
                            orientation="vertical"
                            sx={{
                              backgroundColor: "gray",
                              width: "0.1px",
                              height: "90%",
                              margin: "3px 0px",
                            }}
                          />
                          <div
                            style={{
                              width: "48%",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <div
                              style={{
                                fontFamily: "monospace",
                                fontSize: 12,
                                color: "#fff",
                                backgroundColor: "darkslategrey",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              Aramalar
                            </div>
                            <div
                              style={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "space-between",
                                margin: "4px 0px",
                              }}
                            >
                              <Paper
                                style={{
                                  backgroundColor: "#403836",

                                  width: 60,
                                  color: "#fff",
                                  padding: 1,
                                  display: "flex",
                                  justifyContent: "center",
                                  height: 30,
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    fontSize: 10,
                                    fontFamily: "monospace",
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    padding: 2,
                                  }}
                                >
                                  Gelen
                                  <div
                                    style={{
                                      fontSize: 13,
                                      textAlign: "center",
                                    }}
                                  >
                                    {row?.cdr_inbound?.total_count}
                                  </div>
                                </div>
                              </Paper>

                              <Paper
                                style={{
                                  backgroundColor: "#403836",
                                  width: 60,
                                  color: "#fff",
                                  padding: 1,
                                  display: "flex",
                                  justifyContent: "center",
                                  height: 30,
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    fontSize: 10,
                                    fontFamily: "monospace",
                                  }}
                                >
                                  Başarılı
                                  <div
                                    style={{
                                      fontSize: 13,
                                      textAlign: "center",
                                    }}
                                  >
                                    {row?.cdr_inbound?.answered_count}
                                  </div>
                                </div>
                              </Paper>
                              <Paper
                                style={{
                                  backgroundColor: "#403836",
                                  width: 60,
                                  color: "#fff",
                                  padding: 1,
                                  display: "flex",
                                  justifyContent: "center",
                                  height: 30,
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    fontSize: 10,
                                    fontFamily: "monospace",
                                  }}
                                >
                                  Başarısız
                                  <div
                                    style={{
                                      fontSize: 13,
                                      textAlign: "center",
                                    }}
                                  >
                                    {row?.cdr_inbound?.missed_count}
                                  </div>
                                </div>
                              </Paper>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "space-between",
                                margin: "4px 0px",
                              }}
                            >
                              <Paper
                                style={{
                                  backgroundColor: "#403836",

                                  width: 60,
                                  color: "#fff",
                                  padding: 1,
                                  display: "flex",
                                  justifyContent: "center",
                                  height: 30,
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    fontSize: 10,
                                    fontFamily: "monospace",
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    padding: 2,
                                  }}
                                >
                                  Giden
                                  <div
                                    style={{
                                      fontSize: 13,
                                      textAlign: "center",
                                    }}
                                  >
                                    {row?.cdr_outbound?.total_count}
                                  </div>
                                </div>
                              </Paper>

                              <Paper
                                style={{
                                  backgroundColor: "#403836",
                                  width: 60,
                                  color: "#fff",
                                  padding: 1,
                                  display: "flex",
                                  justifyContent: "center",
                                  height: 30,
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    fontSize: 10,
                                    fontFamily: "monospace",
                                  }}
                                >
                                  Başarılı
                                  <div
                                    style={{
                                      fontSize: 13,
                                      textAlign: "center",
                                    }}
                                  >
                                    {row?.cdr_outbound?.answered_count}
                                  </div>
                                </div>
                              </Paper>
                              <Paper
                                style={{
                                  backgroundColor: "#403836",
                                  width: 60,
                                  color: "#fff",
                                  padding: 1,
                                  display: "flex",
                                  justifyContent: "center",
                                  height: 30,
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    fontSize: 10,
                                    fontFamily: "monospace",
                                  }}
                                >
                                  Başarısız
                                  <div
                                    style={{
                                      fontSize: 13,
                                      textAlign: "center",
                                    }}
                                  >
                                    {row?.cdr_outbound?.total_count -
                                      row?.cdr_outbound?.answered_count}
                                  </div>
                                </div>
                              </Paper>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "end",
                      }}
                    >
                      <VisibilityOff
                        onClick={() => {
                          addToLocalStorageArray(row?.user_id);
                        }}
                        style={{
                          width: 16,
                          height: 16,
                          color: "gray",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  </div>
                </Paper>
              );
            })}
        </div>
      </div>
      <div
        style={{
          width: "5%",
          display: "flex",
          flexDirection: "column",
          height: "98%",
          alignSelf: "flex-start",
        }}
      >
        {dashboardOfficeuserListObj
          .filter((item) => {
            return JSON.parse(
              localStorage.getItem("st-dashboard-user-l")
            )?.some((s) => s === item?.user_id);
          })
          .map((row, i) => {
            return (
              <div
                style={{
                  width: "85%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  padding: 5,
                  marginTop: 10,
                }}
              >
                <div style={{ width: "auto" }}>
                  <Badge
                    overlap="circular"
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    badgeContent={
                      row?.state === "active" ? (
                        <TagFaces
                          style={{
                            color: "green",
                            backgroundColor: "#f4f4f4",
                            width: 10,
                            height: 10,
                            borderRadius: 8,
                            padding: 3,
                          }}
                        />
                      ) : row?.state === "out_call" ? (
                        <PhoneForwarded
                          style={{
                            color: "green",
                            backgroundColor: "#f4f4f4",
                            width: 10,
                            height: 10,
                            borderRadius: 8,
                            padding: 3,
                          }}
                        />
                      ) : row?.state === "break" ? (
                        <Coffee
                          style={{
                            color: "purple",
                            backgroundColor: "#f4f4f4",
                            width: 10,
                            height: 10,
                            borderRadius: 8,
                            padding: 3,
                          }}
                        />
                      ) : row?.state === "training" ? (
                        <School
                          style={{
                            color: "purple",
                            backgroundColor: "#f4f4f4",
                            width: 10,
                            height: 10,
                            borderRadius: 8,
                            padding: 3,
                          }}
                        />
                      ) : row?.state === "wc" ? (
                        <Opacity
                          style={{
                            color: "purple",
                            backgroundColor: "#f4f4f4",
                            width: 10,
                            height: 10,
                            borderRadius: 8,
                            padding: 3,
                          }}
                        />
                      ) : row?.state === "meeting" ? (
                        <Groups
                          style={{
                            color: "purple",
                            backgroundColor: "#f4f4f4",
                            width: 10,
                            height: 10,
                            borderRadius: 8,
                            padding: 3,
                          }}
                        />
                      ) : row?.state === "passive" ? (
                        <PowerOff
                          style={{
                            color: "red",
                            backgroundColor: "#f4f4f4",
                            width: 10,
                            height: 10,
                            borderRadius: 8,
                            padding: 3,
                          }}
                        />
                      ) : row?.state === "setup" ? (
                        <ConnectedTv
                          style={{
                            color: "purple",
                            backgroundColor: "#f4f4f4",
                            width: 10,
                            height: 10,
                            borderRadius: 8,
                            padding: 3,
                          }}
                        />
                      ) : row?.state === "health" ? (
                        <Home
                          style={{
                            color: "red",
                            backgroundColor: "#f4f4f4",
                            width: 10,
                            height: 10,
                            borderRadius: 8,
                            padding: 3,
                          }}
                        />
                      ) : row?.state === "dictionary" ? (
                        <ImportContacts
                          style={{
                            color: "purple",
                            backgroundColor: "#f4f4f4",
                            width: 10,
                            height: 10,
                            borderRadius: 8,
                            padding: 3,
                          }}
                        />
                      ) : (
                        ""
                      )
                    }
                  >
                    <Badge
                      overlap="circular"
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      sx={{
                        "& .MuiBadge-badge": {
                          backgroundColor: row?.online ? "#44b700" : "red",
                          color: row?.online ? "#44b700" : "red",
                          width: 6,
                          height: 8,
                          boxShadow: `0 0 0 2px #fff`,
                        },
                      }}
                      variant="dot"
                    >
                      <Avatar
                        alt="backoffice_user"
                        src={row?.picture}
                        sx={{ width: 32, height: 32, cursor: "pointer" }}
                        onClick={() => {
                          removeFromLocalStorageArray(row?.user_id);
                        }}
                      />
                    </Badge>
                  </Badge>
                </div>
              </div>
            );
          })}
        {/* <div
          style={{
            width: "85%",
            backgroundColor: "#f4f4f4",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            padding: 5,
          }}
        >
          <div
            style={{
              fontFamily: "segoeuiB",
              fontSize: 16,
              fontWeight: 500,
              textDecoration: "underline",
              textAlign: "center",
            }}
          >
            Sıralama Tipi
          </div>
          <div style={{ marginTop: 5 }}>
            <Button
              style={{
                backgroundColor: sortType === "normal" ? "green" : "#f53b62",
                color: "#fff",
                fontFamily: "segoeuiB",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                height: 20,
              }}
              size="small"
              onClick={() => {
                setSortType("normal");
              }}
            >
              Normal
            </Button>
          </div>
          <div style={{ marginTop: 5 }}>
            <Button
              style={{
                backgroundColor:
                  sortType === "transaction" ? "green" : "#f53b62",
                color: "#fff",
                fontFamily: "segoeuiB",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                height: 20,
              }}
              size="small"
              onClick={() => {
                setSortType("transaction");
              }}
            >
              Tahsilat
            </Button>
          </div>
          <div style={{ marginTop: 5 }}>
            <Button
              style={{
                backgroundColor:
                  sortType === "rest_count" ? "green" : "#f53b62",
                color: "#fff",
                fontFamily: "segoeuiB",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                height: 20,
              }}
              size="small"
              onClick={() => {
                setSortType("rest_count");
              }}
            >
              Kurulum
            </Button>
          </div>
        </div>
        <div
          style={{
            width: "85%",
            backgroundColor: "#f4f4f4",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            padding: 5,
            marginTop: 10,
          }}
        >
          <div
            style={{
              fontFamily: "segoeuiB",
              fontSize: 16,
              fontWeight: 500,
              textDecoration: "underline",
              textAlign: "center",
            }}
          >
            Olmayanı Çıkar
          </div>

          <div style={{ marginTop: 5 }}>
            <Button
              style={{
                backgroundColor: sortTypeDiff === "no" ? "green" : "#f53b62",
                color: "#fff",
                fontFamily: "segoeuiB",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                height: 20,
              }}
              size="small"
              onClick={() => {
                setSortTypeDiff("no");
              }}
            >
              Hayır
            </Button>
          </div>
          <div style={{ marginTop: 5 }}>
            <Button
              style={{
                backgroundColor: sortTypeDiff === "yes" ? "green" : "#f53b62",
                color: "#fff",
                fontFamily: "segoeuiB",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                height: 20,
              }}
              size="small"
              onClick={() => {
                setSortTypeDiff("yes");
              }}
            >
              Evet
            </Button>
          </div>
        </div>
        <div
          style={{
            width: "85%",
            backgroundColor: "#f4f4f4",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            padding: 5,
            marginTop: 10,
          }}
        >
          <div
            style={{
              fontFamily: "segoeuiB",
              fontSize: 16,
              fontWeight: 500,
              textDecoration: "underline",
              textAlign: "center",
            }}
          >
            Yönetici
          </div>

          <div style={{ marginTop: 5 }}>
            <Button
              style={{
                backgroundColor: showManager === "yes" ? "green" : "#f53b62",
                color: "#fff",
                fontFamily: "segoeuiB",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                height: 20,
              }}
              size="small"
              onClick={() => {
                setShowManager("yes");
              }}
            >
              Göster
            </Button>
          </div>
          <div style={{ marginTop: 5 }}>
            <Button
              style={{
                backgroundColor: showManager === "no" ? "green" : "#f53b62",
                color: "#fff",
                fontFamily: "segoeuiB",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                height: 20,
              }}
              size="small"
              onClick={() => {
                setShowManager("no");
              }}
            >
              Gösterme
            </Button>
          </div>
        </div>
        <div
          style={{
            width: "85%",
            backgroundColor: "#f4f4f4",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            padding: 5,
            marginTop: 10,
          }}
        >
          <div
            style={{
              fontFamily: "segoeuiB",
              fontSize: 16,
              fontWeight: 500,
              textDecoration: "underline",
              textAlign: "center",
            }}
          >
            Aktiflik
          </div>

          <div style={{ marginTop: 5 }}>
            <Button
              style={{
                backgroundColor: userStatus === "all" ? "green" : "#f53b62",
                color: "#fff",
                fontFamily: "segoeuiB",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                height: 20,
              }}
              size="small"
              onClick={() => {
                setUserStatus("all");
              }}
            >
              Tümü
            </Button>
          </div>
          <div style={{ marginTop: 5 }}>
            <Button
              style={{
                backgroundColor: userStatus === "online" ? "green" : "#f53b62",
                color: "#fff",
                fontFamily: "segoeuiB",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                height: 20,
              }}
              size="small"
              onClick={() => {
                setUserStatus("online");
              }}
            >
              Online
            </Button>
          </div>
          <div style={{ marginTop: 5 }}>
            <Button
              style={{
                backgroundColor: userStatus === "offline" ? "green" : "#f53b62",
                color: "#fff",
                fontFamily: "segoeuiB",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                height: 20,
              }}
              size="small"
              onClick={() => {
                setUserStatus("offline");
              }}
            >
              Offline
            </Button>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default MainScreen;
