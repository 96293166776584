import { makeStyles } from "@mui/styles";

import {
  Avatar,
  Box,
  Button,
  Divider,
  InputBase,
  Modal,
  Paper,
} from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { CallContext } from "../../../contexts/callContext";
import { GreenSwitch } from "../../switch/greenSwitch";
import moment from "moment";
import { ManagementContext } from "../../../contexts/managementContext";
import { useToast } from "../../snackbar";
import CustomAudioPlayer from "../../../components/audioPlayer";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    padding: 20,
    border: "2px solid #000",
    boxShadow: 5,
    backgroundColor: "rgb(0 0 0 / 30%)",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
    maxHeight: "100vh",
  },
}));

export default function PuantajVoiceModal({
  puantajVoiceModal,
  setPuantajVoiceModal,
  currentRowId,
}) {
  const classes = useStyles();
  const { alert } = useToast();
  const audioRef = useRef(null);
  const { puantajVoice } = useContext(CallContext);

  const {
    setCdrRatingInfo,
    tahsilatRatingSkipFunc,
    cdrRatingInfo,
    ratingRateSingleFunc,
  } = useContext(ManagementContext);

  const preparePayload = (array) => {
    return array.map((item) => {
      const areAllOptionsRated =
        item.options.length > 0 &&
        item.options.every((option) => option.rating !== null);

      const updatedRating = areAllOptionsRated ? true : item.rating;

      const options = item.options.map((option) => ({
        title: option.title,
        points: option.points,
        rating: option.rating,
      }));

      return {
        title: item.title,
        points: item.points,
        rating: updatedRating,
        options: options,
      };
    });
  };

  const sendNewRatingFunc = async () => {
    let data = {
      rating_id: cdrRatingInfo?.id,
      sendObj: {
        education: cdrRatingInfo?.education ? true : false,
        notification: cdrRatingInfo?.notification ? true : false,
        note: cdrRatingInfo?.note ? cdrRatingInfo?.note : "",
        ratings: preparePayload(cdrRatingInfo?.ratings),
      },
    };
    let result = await ratingRateSingleFunc(data);
    if (result) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Modal
      disableEnforceFocus
      disableAutoFocus
      open={puantajVoiceModal}
      className={classes.modal}
      onClose={() => {
        setPuantajVoiceModal(false);
      }}
    >
      <Paper
        elevation={3}
        style={{
          width: "60%",
          display: "flex",
          padding: 5,
          margin: "10px 10px",
          backgroundColor: "#393737",
          boxShadow: "#fff 0px 0px 9px 2px",
          height: 600,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "32%",
            alignItems: "center",
            justifyContent: "space-around",
            backgroundColor: "#474545",
          }}
        >
          <div style={{ width: "100%" }}>
            <Paper
              style={{
                backgroundColor: "#736868",
                width: "-webkit-fill-available",
                color: "#fff",
                padding: 1,
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  fontSize: 18,
                  fontFamily: "monospace",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {cdrRatingInfo?.task_type?.name}
              </div>
            </Paper>
            <Paper
              style={{
                backgroundColor: "#5c5caf",
                width: "-webkit-fill-available",
                color: "#fff",
                padding: 1,
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                marginTop: 2,
                cursor: "pointer",
              }}
            >
              {cdrRatingInfo?.state === "new"
                ? "Yeni"
                : cdrRatingInfo?.state === "education"
                ? "Eğitim"
                : "Tamamlandı"}
            </Paper>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                width: "40%",
                textAlign: "-webkit-center",
              }}
            >
              <Avatar
                alt="backoffice_user"
                src={cdrRatingInfo?.officeuser?.picture}
                sx={{ width: 56, height: 56, cursor: "pointer" }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "60%",
                justifyContent: "space-evenly",
              }}
            >
              <div
                style={{
                  fontFamily: "monospace",
                  fontSize: 13,
                  fontWeight: 550,
                  color: "#fff",
                  textDecoration: "underline",
                }}
              >
                Officeuser
              </div>
              <div
                style={{
                  fontFamily: "segoeuiRg",
                  fontSize: 14,
                  fontWeight: 550,
                  color: "#fff",
                }}
              >
                {cdrRatingInfo?.officeuser?.full_name}
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  fontFamily: "segoeuiRg",
                  fontSize: 11,
                  color: "#fff",
                }}
              >
                {moment(cdrRatingInfo?.created_at).format("DD-MM-YYYY HH:mm")}
              </div>
            </div>
          </div>
          {cdrRatingInfo?.rated_by !== null && (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  width: "40%",
                  textAlign: "-webkit-center",
                }}
              >
                <Avatar
                  alt="backoffice_user"
                  src={cdrRatingInfo?.rated_by?.picture}
                  sx={{ width: 56, height: 56, cursor: "pointer" }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "60%",
                  justifyContent: "space-evenly",
                }}
              >
                <div
                  style={{
                    fontFamily: "monospace",
                    fontSize: 13,
                    fontWeight: 550,
                    color: "#fff",
                    textDecoration: "underline",
                  }}
                >
                  Değerlendiren
                </div>
                <div
                  style={{
                    fontFamily: "segoeuiRg",
                    fontSize: 14,
                    fontWeight: 550,
                    color: "#fff",
                  }}
                >
                  {cdrRatingInfo?.rated_by?.full_name}
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    fontFamily: "segoeuiRg",
                    fontSize: 11,
                    color: "#fff",
                  }}
                >
                  {moment(cdrRatingInfo?.created_at).format("DD-MM-YYYY HH:mm")}
                </div>
              </div>
            </div>
          )}
          {puantajVoice && (
            <Box
              sx={{
                backgroundColor: "#736868",
                color: "#fff",
                padding: 1,
                borderRadius: 1,
                display: "flex",
                flexDirection: "column",
                gap: 1,
                width: "95%",
              }}
            >
              <CustomAudioPlayer audioRef={audioRef} src={puantajVoice} />
            </Box>
          )}
        </div>
        <div style={{ width: "68%" }}>
          <div style={{ height: "80%", overflow: "auto" }}>
            {cdrRatingInfo?.ratings?.map((rowF, indF) => (
              <Paper
                key={indF}
                style={{
                  display: "flex",
                  color: "#fff",
                  padding: 2,
                  backgroundColor: "#403836",
                  width: "95%",
                  marginTop: 10,
                  fontFamily: "segoeuiRg",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    fontSize: 15,
                    color: "#1c9532",
                  }}
                >
                  <div
                    style={{
                      marginRight: 5,
                      backgroundColor: "green",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-evenly",
                      borderRadius: 3,
                      cursor: "pointer",
                      WebkitAppRegion: "no-drag",
                      width: "auto",
                      minWidth: 30,
                      color: "#fff",
                      fontSize: 14,
                      fontFamily: "segoeuiRg",
                    }}
                  >
                    {rowF?.points}
                  </div>
                  {rowF?.title}
                </div>
                <Divider
                  orientation="vertical"
                  sx={{
                    backgroundColor: "#4a4a4a",
                    width: "100%",
                    height: "1px",
                    margin: "2px 0px",
                  }}
                />
                {rowF?.options?.length ? (
                  rowF.options.map((rowOpt, indOpt) => (
                    <div
                      key={indOpt}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          minHeight: 35,
                          alignItems: "center",
                          flexDirection: "row",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            fontSize: 14,
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              marginRight: 5,
                              backgroundColor: "#fff",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-evenly",
                              borderRadius: 3,
                              cursor: "pointer",
                              WebkitAppRegion: "no-drag",
                              width: "auto",
                              minWidth: 20,
                              color: "black",
                              fontSize: 12,
                              fontFamily: "segoeuiRg",
                              height: 15,
                            }}
                          >
                            {rowOpt?.points}
                          </div>
                          {rowOpt?.title}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            width: "30%",
                            justifyContent: "space-around",
                            alignSelf: "center",
                            marginTop: 4,
                            justifySelf: "end",
                          }}
                        >
                          <div
                            onClick={() => {
                              setCdrRatingInfo((prev) => {
                                const updated = JSON.parse(
                                  JSON.stringify(prev)
                                );
                                updated.ratings[indF].options[
                                  indOpt
                                ].rating = true;
                                return updated;
                              });
                            }}
                            style={{
                              width: 40,
                              height: 20,
                              backgroundColor:
                                rowOpt.rating === true ? "green" : "#f4f4f4",
                              borderRadius: 2,
                              color: rowOpt.rating === true ? "#fff" : "#000",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              cursor: "pointer",
                              fontSize: 13,
                            }}
                          >
                            Evet
                          </div>
                          <div
                            onClick={() => {
                              setCdrRatingInfo((prev) => {
                                const updated = JSON.parse(
                                  JSON.stringify(prev)
                                );
                                updated.ratings[indF].options[
                                  indOpt
                                ].rating = false;
                                return updated;
                              });
                            }}
                            style={{
                              width: 40,
                              height: 20,
                              backgroundColor:
                                rowOpt.rating === false ? "green" : "#f4f4f4",
                              borderRadius: 2,
                              color: rowOpt.rating === false ? "#fff" : "#000",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              cursor: "pointer",
                              fontSize: 13,
                            }}
                          >
                            Hayır
                          </div>
                        </div>
                      </div>
                      <Divider
                        orientation="vertical"
                        sx={{
                          backgroundColor: "#4a4a4a",
                          width: "100%",
                          height: "1px",
                          margin: "10px 0px",
                        }}
                      />
                    </div>
                  ))
                ) : (
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-around",
                      alignSelf: "center",
                      marginTop: 4,
                    }}
                  >
                    <div
                      onClick={() => {
                        setCdrRatingInfo((prev) => {
                          const updated = JSON.parse(JSON.stringify(prev));
                          updated.ratings[indF].rating = true;
                          return updated;
                        });
                      }}
                      style={{
                        width: 40,
                        height: 20,
                        backgroundColor:
                          rowF.rating === true ? "green" : "#f4f4f4",
                        borderRadius: 2,
                        color: rowF.rating === true ? "#fff" : "#000",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                        fontSize: 13,
                      }}
                    >
                      Evet
                    </div>
                    <div
                      onClick={() => {
                        setCdrRatingInfo((prev) => {
                          const updated = JSON.parse(JSON.stringify(prev));
                          updated.ratings[indF].rating = false;
                          return updated;
                        });
                      }}
                      style={{
                        width: 40,
                        height: 20,
                        backgroundColor:
                          rowF.rating === false ? "green" : "#f4f4f4",
                        borderRadius: 2,
                        color: rowF.rating === false ? "#fff" : "#000",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                        fontSize: 13,
                      }}
                    >
                      Hayır
                    </div>
                  </div>
                )}
              </Paper>
            ))}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              height: "20%",
              alignItems: "center",
            }}
          >
            <Paper
              style={{
                width: "75%",
                display: "flex",
                justifyContent: "space-between",
                minHeight: 42,
                backgroundColor: "#F0F0F0",
                alignSelf: "center",
                marginTop: 10,
              }}
            >
              <InputBase
                multiline
                spellCheck={false}
                style={{
                  width: "100%",
                  fontFamily: "segoeuiRg",
                  paddingLeft: 8,
                  fontSize: 14,
                }}
                rows={2}
                placeholder={"Not ekle"}
                className={classes.input}
                value={cdrRatingInfo?.note ? cdrRatingInfo?.note : ""}
                onChange={(e) => {
                  const cdrRatingInfoObj = Object.assign(
                    {},
                    JSON.parse(JSON.stringify({ ...cdrRatingInfo }))
                  );
                  cdrRatingInfoObj.note = e.target.value;
                  setCdrRatingInfo(cdrRatingInfoObj);
                }}
              />
            </Paper>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "20%",
              }}
            >
              <div
                style={{
                  height: "50%",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 3,
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#403836",
                    borderRadius: 4,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ color: "#fff", fontSize: 12 }}>Eğitim</div>
                  <GreenSwitch
                    sx={{
                      width: 54,
                      height: 33,
                      "& .MuiSwitch-thumb": {
                        width: 16,
                        height: 16,
                      },
                    }}
                    onClick={() => {
                      const cdrRatingInfoObj = Object.assign(
                        [],
                        JSON.parse(JSON.stringify({ ...cdrRatingInfo }))
                      );
                      if (cdrRatingInfoObj.education) {
                        cdrRatingInfoObj.education = !cdrRatingInfo?.education;
                      } else {
                        cdrRatingInfoObj.education = true;
                      }
                      setCdrRatingInfo(cdrRatingInfoObj);
                    }}
                    checked={cdrRatingInfo?.education ? true : false}
                  />
                </div>
                <div
                  style={{
                    backgroundColor: "#403836",
                    borderRadius: 4,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ color: "#fff", fontSize: 12 }}>Popup</div>
                  <GreenSwitch
                    sx={{
                      width: 54,
                      height: 33,
                      "& .MuiSwitch-thumb": {
                        width: 16,
                        height: 16,
                      },
                    }}
                    onClick={() => {
                      const cdrRatingInfoObj = Object.assign(
                        [],
                        JSON.parse(JSON.stringify({ ...cdrRatingInfo }))
                      );
                      if (cdrRatingInfoObj.notification) {
                        cdrRatingInfoObj.notification =
                          !cdrRatingInfo?.notification;
                      } else {
                        cdrRatingInfoObj.notification = true;
                      }
                      setCdrRatingInfo(cdrRatingInfoObj);
                    }}
                    checked={cdrRatingInfo?.notification ? true : false}
                  />
                </div>
              </div>
              <div
                style={{
                  height: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-end",
                }}
              >
                {cdrRatingInfo?.state === "new" && (
                  <Button
                    style={{
                      backgroundColor: "#f53b62",
                      color: "#fff",
                      fontFamily: "segoeuiB",
                      width: "20%",
                      display: "flex",
                      justifyContent: "center",
                      height: 20,
                    }}
                    size="small"
                    onClick={() => {
                      if (cdrRatingInfo?.note?.length > 3) {
                        sendNewRatingFunc();
                      } else {
                        alert("Not alanı minimum 3 karakter olmalı.");
                      }
                    }}
                  >
                    Kaydet
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </Paper>
    </Modal>
  );
}
