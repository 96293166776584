import React, { useContext, useEffect, useState } from "react";
import { ManagementContext } from "../../../../contexts/managementContext";
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@mui/material";
import moment from "moment";
import InfoIcon from "@mui/icons-material/Info";
import TablePaginationActions from "../../../../components/pagination/mainPagination";
import { makeStyles } from "@mui/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import RequestStatusesModal from "../../../../components/modals/requestStatuses";
import DatePicker from "react-datepicker";
import CustomDatePickerInput from "../../../../components/datePickerInput";
import { selectDefaultStyles } from "../../../../components/selectStyles/stylev3";
import Select from "react-select";

const useStyles = makeStyles({
  root: {
    "& .MuiTypography-body2": {
      fontSize: 16,
      fontFamily: "SgBd",
    },
  },
});

const statuses = [
  { value: "", label: "Hepsi" },
  { value: "created", label: "Oluşturuldu" },
  { value: "ro_rejected", label: "Restoran Reddetti" },
  { value: "ro_accepted", label: "Restoran Onay Verdi" },
  { value: "waiting_offer", label: "Teklif Bekleniyor" },
  { value: "waiting_confirm", label: "Finsansal Onay Bekleniyor" },
  { value: "unapproved", label: "Onaylanmadı" },
  { value: "confirm", label: "Onaylandı" },
  { value: "shipped", label: "Kargoya Verildi" },
  { value: "delivered", label: "Teslim Edildi" },
  { value: "checking", label: "Kontrol Ediliyor" },
  { value: "complated", label: "Tamamlandı" },
];

export default function BaskiRequestsFinance() {
  const classes = useStyles();
  const {
    getBaskiRequestsFinance,
    printingRequestList,
    getBrochurePicture,
    actionPrintingRequestFinance,
  } = useContext(ManagementContext);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [startDate, setStartDate] = useState(
    new Date(new Date().setHours(0, 0, 0, 0))
  );
  const [endDate, setEndDate] = useState(new Date());
  const selectedStartDate = moment(startDate).format("YYYY-MM-DDTHH:mm:ss");
  const selectedEndDate = moment(endDate).format("YYYY-MM-DDTHH:mm:ss");

  const [selectedStatus, setSelectedStatus] = useState({
    value: "",
    label: "Hepsi",
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    getBaskiRequestsFinance(
      page + 1,
      selectedStartDate,
      selectedEndDate,
      selectedStatus.value
    );
  }, [page, startDate, endDate, selectedStatus]);

  return (
    <div>
      <div style={{ display: "flex", marginBottom: 20 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",

            marginRight: 20,
          }}
        >
          <span
            style={{
              fontSize: 16,
              fontWeight: 600,
              marginBottom: 10,
            }}
          >
            Tarih
          </span>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <DatePicker
              selected={startDate}
              onChange={(update) => {
                setStartDate(update);
              }}
              customInput={<CustomDatePickerInput selectedWidth={170} />}
              dateFormat="dd/MM/yyyy HH:mm"
              locale="tr"
              showTimeSelect
              timeCaption="Saat"
            />
            <div style={{ marginLeft: 2 }}>
              <DatePicker
                selected={endDate}
                minDate={startDate}
                onChange={(update) => {
                  setEndDate(update);
                }}
                customInput={<CustomDatePickerInput selectedWidth={170} />}
                dateFormat="dd/MM/yyyy HH:mm"
                locale="tr"
                showTimeSelect
                timeCaption="Saat"
              />
            </div>
          </div>
        </div>

        <div>
          <span
            style={{
              fontSize: 16,
              marginBottom: 10,
              display: "block",
              fontWeight: "600",
            }}
          >
            Durum
          </span>
          <Select
            menuPosition="fixed"
            menuPortalTarget={document.body}
            selectFontSize={14}
            selectWidth={200}
            options={statuses}
            styles={selectDefaultStyles}
            isSearchable={false}
            zIndex={90}
            hoverColor={"#f53b62"}
            menuColor={"#f53b62"}
            placeholder={"Durum Seçiniz"}
            value={selectedStatus}
            getOptionValue={(option) => option.value}
            onChange={(e) => {
              setSelectedStatus(e);
            }}
            getOptionLabel={(e) => (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {e.label}
              </div>
            )}
          />
        </div>
      </div>

      <Grid container columns={12} spacing={2} sx={{ marginBottom: 4 }}>
        <Grid item xs={4}>
          <Paper elevation={3} sx={{ padding: 2 }}>
            <h3
              style={{
                margin: 0,
                marginBottom: 10,
                fontFamily: "segoeuiL",
                color: "darkslategray",
                fontSize: 18,
                textAlign: "left",
              }}
            >
              Toplam Tutar
            </h3>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                color: "darkslategray",
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  marginLeft: 5,
                  fontFamily: "segoeuiL",
                }}
              >
                {printingRequestList?.statistics?.total_price || 0} ₺
              </span>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper elevation={3} sx={{ padding: 2 }}>
            <h3
              style={{
                margin: 0,
                marginBottom: 10,
                fontFamily: "segoeuiL",
                color: "darkslategray",
                fontSize: 18,
                textAlign: "left",
              }}
            >
              Toplam Kargo Tutarı
            </h3>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                color: "darkslategray",
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  marginLeft: 5,
                  fontFamily: "segoeuiL",
                }}
              >
                {printingRequestList?.statistics?.total_cargo_price || 0} ₺
              </span>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper elevation={3} sx={{ padding: 2 }}>
            <h3
              style={{
                margin: 0,
                marginBottom: 10,
                fontFamily: "segoeuiL",
                color: "darkslategray",
                fontSize: 18,
                textAlign: "left",
              }}
            >
              Toplam Kdv Tutarı
            </h3>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                color: "darkslategray",
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  marginLeft: 5,
                  fontFamily: "segoeuiL",
                }}
              >
                {printingRequestList?.statistics?.total_vat || 0} ₺
              </span>
            </Box>
          </Paper>
        </Grid>
      </Grid>

      <span
        style={{
          fontSize: 20,
          marginBottom: 10,
          display: "block",
          fontWeight: "600",
          textAlign: "center",
        }}
      >
        Baskı İstekleri
      </span>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">Teslimat Adresi</TableCell>
              <TableCell align="center">Oluşturulma Tarihi</TableCell>
              <TableCell align="center">Restoran Adı</TableCell>
              <TableCell align="center">Oluşturan Kişi</TableCell>
              <TableCell align="center">Matbaa</TableCell>
              <TableCell align="center">Baskı Tipi</TableCell>
              <TableCell align="center">Gramaj</TableCell>
              <TableCell align="center">Boyut</TableCell>
              <TableCell align="center">Adet</TableCell>
              <TableCell align="center">Baskı Yönü</TableCell>
              <TableCell align="center">Fiyat</TableCell>
              <TableCell align="center">Kdv Oranı</TableCell>
              <TableCell align="center">Kargo Ücreti</TableCell>
              <TableCell align="center">Durum</TableCell>
              <TableCell align="center">Aksiyon</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {printingRequestList?.results?.map((row, ind) => {
              return (
                <TableRow key={ind}>
                  <TableCell align="center">
                    <Tooltip title={row.cargo_delivery_address}>
                      <InfoIcon style={{ color: "#f53b62" }} />
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">
                    {moment(row.created_at).format("DD.MM.YYYY HH:mm")}
                  </TableCell>
                  <TableCell align="center">{row.restofficial.name}</TableCell>
                  <TableCell align="center">
                    {row.officeuser.full_name}
                  </TableCell>
                  <TableCell align="center">
                    {row.printingpress.company_title}
                  </TableCell>
                  <TableCell align="center">
                    {row.printing_type.printing_type_title}
                  </TableCell>
                  <TableCell align="center">{row.grammage}</TableCell>
                  <TableCell align="center">{row.size}</TableCell>
                  <TableCell align="center">{row.quantity}</TableCell>
                  <TableCell align="center">{row.direction}</TableCell>
                  <TableCell align="center">{row.price}</TableCell>
                  <TableCell align="center">{row.vat_rate} %</TableCell>
                  <TableCell align="center">{row.cargo_price}</TableCell>
                  <TableCell align="center">
                    {row.request_status === "waiting_confirm"
                      ? "Matbaa Onay Bekliyor"
                      : row.request_status === "waiting_offer"
                      ? "Matbaa'ya yönlendirildi"
                      : row.request_status === "created"
                      ? "Baskı İsteği Oluşturuldu"
                      : row.request_status === "confirm" &&
                        row.is_document_downloaded
                      ? "PDF Baskıda"
                      : row.request_status === "confirm" &&
                        !row.is_document_downloaded
                      ? "Finans Onayladı"
                      : row.request_status === "shipped"
                      ? "Kargoya Verildi"
                      : row.request_status === "delivered"
                      ? "Teslim Edildi"
                      : row.request_status === "checking"
                      ? "Teslim Edildi"
                      : row.request_status === "complated"
                      ? "Teslim Edildi"
                      : row.request_status === "unapproved"
                      ? "Matbaa Onay Bekleniyor"
                      : ""}
                  </TableCell>
                  <TableCell align="center">
                    {row.request_status === "waiting_confirm" && (
                      <>
                        <CheckCircleIcon
                          style={{
                            color: "#f53b62",
                            cursor: "pointer",
                            marginRight: 10,
                          }}
                          onClick={() => {
                            const data = {
                              request_status: "confirm",
                            };
                            actionPrintingRequestFinance(
                              data,
                              row.id,
                              page + 1
                            );
                          }}
                        />
                        <RemoveCircleIcon
                          style={{ color: "#f53b62", cursor: "pointer" }}
                          onClick={() => {
                            const data = {
                              request_status: "unapproved",
                            };
                            actionPrintingRequestFinance(
                              data,
                              row.id,
                              page + 1
                            );
                          }}
                        />
                      </>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
            <TableRow>
              {printingRequestList?.results != null && (
                <TablePagination
                  rowsPerPageOptions={[10]}
                  labelRowsPerPage={"Sayfa Başına Kayıt"}
                  count={printingRequestList?.count}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  classes={{ root: classes.root }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              )}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
