import React, { useEffect, useReducer, useState } from "react";
//MUI
import { Button, IconButton } from "@mui/material";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
//COMPONENTS
import ServiceAreaMap from "./components/serviceAreaMap";
import { GreenSwitch } from "../../../../../components/switch/greenSwitch";
//HOOKS
import { DeleteSweep } from "@mui/icons-material";
import { useToast } from "../../../../../components/snackbar";

const marks = [];
for (let i = 0.5; i < 15; i = i + 0.5) {
  marks.push({
    value: i,
    label: `${i}`,
  });
}

function ServiceArea({ resDetailInfo, resChangeDetailFunc, id }) {
  const { alert } = useToast();
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  const [rank, setrank] = useState(0.5);
  const [latlong, setlatlong] = useState([]);
  const [areaObj, setAreaObj] = useState(null);
  const [deliveryPrice, setDeliveryPrice] = useState(null);
  const [deliveryThreshhold, setDeliveryThreshhold] = useState(null);

  const areaFunc = () => {
    let forAmountOptions = [];
    let resDetailInfoLength = resDetailInfo?.max_distance / 500;
    for (let i = 1; i <= resDetailInfoLength; i++) {
      forAmountOptions.push({
        distance: i * 0.5,
        min_price:
          resDetailInfo?.service_area?.filter(
            (row) => i * 0.5 <= row.distance / 1000
          )[0]?.min_price || 0,
      });
    }
    setAreaObj(forAmountOptions);
  };
  useEffect(() => {
    if (resDetailInfo !== null) {
      areaFunc();
      setlatlong({
        lat: resDetailInfo?.latitude,
        lng: resDetailInfo?.longitude,
      });
      setrank(resDetailInfo?.max_distance / 1000 || 1);
      setDeliveryPrice(resDetailInfo?.delivery_price);
      setDeliveryThreshhold(resDetailInfo?.free_delivery_threshold);
    }
  }, [resDetailInfo]);

  return (
    <>
      <div
        style={{
          height: 450,
          width: "99%",
          marginLeft: 10,
        }}
      >
        <ServiceAreaMap cent={latlong} rank={rank} />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: 30,
          justifyContent: "space-around",
        }}
      >
        <div
          style={{
            width: "40%",
            backgroundColor: "#fff",
            padding: 10,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h3
              style={{
                fontSize: 16,
              }}
            >
              Minimum Paket Tutarı
            </h3>
            <div>
              <IconButton
                style={{ color: "#f53b62", marginLeft: 5 }}
                onClick={() => {
                  if (areaObj?.length === 0) {
                    areaObj.push({
                      distance: 0.5,
                      min_price: 0,
                    });
                  } else {
                    areaObj.push({
                      distance: areaObj[areaObj?.length - 1].distance + 0.5,
                      min_price: 0,
                    });
                  }
                  forceUpdate();
                }}
              >
                <AddCircleOutlineRoundedIcon style={{ fontSize: 24 }} />
              </IconButton>
              <IconButton
                style={{ color: "#f53b62", marginLeft: 5 }}
                onClick={() => {
                  areaObj.pop();
                  forceUpdate();
                }}
              >
                <DeleteSweep style={{ fontSize: 24 }} />
              </IconButton>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              marginTop: 10,
            }}
          >
            {areaObj?.map((row, index) => {
              return (
                <div
                  key={index}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div
                    style={{
                      fontFamily: "SgNr",
                      fontSize: 14,
                    }}
                  >
                    {row?.distance} km ye kadar
                  </div>
                  <div>
                    <input
                      value={row?.min_price}
                      style={{
                        padding: 5,
                        border: "1px solid #000",
                        width: 50,
                        marginLeft: 10,
                        textAlign: "center",
                        borderRadius: 4,
                        fontSize: 14,
                      }}
                      onChange={(e) => {
                        let re = /^[0-9\b]+$/;
                        if (e.target.value === "" || re.test(e.target.value)) {
                          if (e.target.value === "") {
                            row.min_price = 0;
                          } else {
                            row.min_price = parseFloat(e.target.value);
                          }
                          forceUpdate();
                        }
                      }}
                    />
                    <span
                      style={{
                        marginLeft: 5,
                        fontSize: 16,
                      }}
                    >
                      ₺
                    </span>
                  </div>
                </div>
              );
            })}
            <Button
              style={{
                backgroundColor: "#f53b62",
                borderRadius: 4,
                width: "100%",
                height: 30,
                boxShadow: "2px 3px 2.5px 1px #C5C5BB",
                marginTop: 10,
                marginBottom: 10,
              }}
              variant="contained"
              onClick={() => {
                const sortedArray = Object.assign(
                  [],
                  JSON.parse(JSON.stringify({ ...areaObj }))
                );
                sortedArray.sort((a, b) => a.min_price - b.min_price);

                if (
                  areaObj.every((val, index) => {
                    if (index === areaObj.length - 1) return true;
                    return val.min_price <= areaObj[index + 1].min_price;
                  })
                ) {
                  resChangeDetailFunc({
                    service_area: areaObj?.map((row) => ({
                      ...row,
                      distance: row?.distance * 1000,
                    })),
                    restofficial_id: id,
                  });
                } else {
                  alert("Minimum paket tutarları sıralı olmalıdır.");
                }
              }}
            >
              <span
                style={{
                  fontFamily: "CoRg",
                  fontWeight: 700,
                }}
              >
                Kaydet
              </span>
            </Button>
          </div>
        </div>
        <div
          style={{
            minWidth: "20%",
            backgroundColor: "#fff",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: 20,
            height: "20%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              margin: 10,
            }}
          >
            <div
              style={{
                fontSize: 16,
                fontFamily: "SgBd",
              }}
            >
              Teslimat Ücreti
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "98%",
                marginTop: 10,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <input
                value={deliveryPrice}
                style={{
                  padding: 5,
                  border: "1px solid #000",
                  textAlign: "center",
                  borderRadius: 4,
                  fontSize: 14,
                  width: "60%",
                }}
                onChange={(e) => {
                  let re = /^[0-9\b]+$/;
                  if (e.target.value === "" || re.test(e.target.value)) {
                    if (e.target.value === "") {
                      setDeliveryPrice(0);
                    } else {
                      setDeliveryPrice(parseFloat(e.target.value));
                    }
                  }
                }}
              />
              <span
                style={{
                  fontSize: 16,
                  marginLeft: 5,
                }}
              >
                ₺
              </span>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              margin: 10,
            }}
          >
            <div
              style={{
                fontSize: 16,
                fontFamily: "SgBd",
              }}
            >
              Üstü Ücretsiz
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "98%",
                marginTop: 10,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <input
                value={deliveryThreshhold}
                style={{
                  padding: 5,
                  border: "1px solid #000",
                  textAlign: "center",
                  borderRadius: 4,
                  fontSize: 14,
                  width: "60%",
                }}
                onChange={(e) => {
                  let re = /^[0-9\b]+$/;
                  if (e.target.value === "" || re.test(e.target.value)) {
                    if (e.target.value === "") {
                      setDeliveryThreshhold(0);
                    } else {
                      setDeliveryThreshhold(parseFloat(e.target.value));
                    }
                  }
                }}
              />
              <span
                style={{
                  fontSize: 16,
                  marginLeft: 5,
                }}
              >
                ₺
              </span>
            </div>
          </div>

          <Button
            style={{
              backgroundColor: "#f53b62",
              borderRadius: 4,
              width: "100%",
              height: 30,
              boxShadow: "2px 3px 2.5px 1px #C5C5BB",
              marginTop: 10,
              marginBottom: 10,
            }}
            variant="contained"
            onClick={() => {
              resChangeDetailFunc({
                delivery_price: deliveryPrice,
                free_delivery_threshold: deliveryThreshhold,
                restofficial_id: id,
              });
            }}
          >
            <span
              style={{
                fontFamily: "CoRg",
                fontWeight: 700,
              }}
            >
              Kaydet
            </span>
          </Button>
        </div>
        <div
          style={{
            minWidth: "20%",
            backgroundColor: "#fff",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: 20,
            height: "20%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              fontFamily: "SgBd",
              fontSize: 16,
            }}
          >
            Gel Al Siparişi
            <GreenSwitch
              checked={resDetailInfo?.allow_takeaway}
              onClick={() => {
                resChangeDetailFunc({
                  allow_takeaway: !resDetailInfo?.allow_takeaway,
                  restofficial_id: id,
                });
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default ServiceArea;
