import { Button, Card, Divider, InputBase, Modal, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useContext, useEffect, useState } from "react";
import { ManagementContext } from "../../../../contexts/managementContext";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    padding: 20,
    border: "2px solid #000",
    boxShadow: 5,
    backgroundColor: "rgb(0 0 0 / 62%)",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
    maxHeight: "100vh",
  },
  paper: {
    width: "100%",
    borderRadius: 8,
  },
}));

export default function RollModal({ openRollModal, setOpenRollModal, restId }) {
  const classes = useStyles();
  const { financeOfferFunc, financeOffer, financeOfferChangeFunc } =
    useContext(ManagementContext);

  const [financeOfferObj, setFinanceOfferObj] = useState(null);

  useEffect(() => {
    financeOfferFunc({
      rest_id: restId,
    });
  }, []);

  useEffect(() => {
    if (financeOffer !== null) {
      setFinanceOfferObj(financeOffer);
    }
  }, [financeOffer]);

  return (
    <Modal
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      open={openRollModal}
      className={classes.modal}
      onClose={() => {
        setOpenRollModal(false);
      }}
    >
      <Card
        className={classes.paper}
        style={{
          width: "50%",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
          maxHeight: 600,
        }}
      >
        {financeOffer !== null && (
          <div
            style={{
              display: "flex",
              margin: "30px 0px",
              width: "100%",
              justifyContent: "space-around",
            }}
          >
            <Paper
              elevation={3}
              style={{
                padding: 10,
                backgroundColor: "#fff",
                boxShadow: "#bebebebf 0px 0px 6px 2px",
                color: "black",
                width: "30%",
                height: 100,
              }}
            >
              <div
                style={{
                  backgroundColor: "#4D4949",
                  marginBottom: 5,
                  fontSize: 15,
                  fontWeight: 600,
                  textTransform: "capitalize",
                  borderRadius: "3px",
                  color: "#fff",
                  padding: 5,
                }}
              >
                {financeOffer?.restofficial_name}
              </div>

              <div
                style={{
                  fontSize: 14,
                  marginBottom: 3,
                  fontFamily: "segoeuiRg",
                  display: "flex",
                }}
              >
                <div style={{ width: 80 }}>Toplam Fiyat</div> : ₺{" "}
                {financeOffer?.total_amount}
              </div>
              <div
                style={{
                  fontSize: 14,
                  marginBottom: 3,
                  display: "flex",
                  fontFamily: "segoeuiRg",
                }}
              >
                <div style={{ width: 80, textAlign: "start" }}>Toplam Eft</div>{" "}
                : ₺ {financeOffer?.eft_amount}
              </div>
            </Paper>
            <Paper
              elevation={3}
              style={{
                padding: 10,
                backgroundColor: "#fff",
                boxShadow: "#bebebebf 0px 0px 6px 2px",
                color: "black",
                width: "50%",
              }}
            >
              <div
                style={{
                  backgroundColor: "#4D4949",
                  textAlign: "center",
                  marginBottom: 5,
                  fontSize: 15,
                  fontWeight: 600,
                  textTransform: "capitalize",
                  borderRadius: "3px",
                  padding: 5,
                  color: "#fff",
                }}
              >
                Ürünler
              </div>

              {financeOfferObj?.products?.map((row, ind) => {
                return (
                  <div>
                    <Divider
                      orientation="horizontal"
                      sx={{
                        backgroundColor: "#f4f4f4",
                        width: "100%",
                        height: "auto",
                        margin: "10px 0px",
                      }}
                    />
                    <div
                      style={{
                        fontSize: 14,
                        marginBottom: 3,
                        fontFamily: "segoeuiRg",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ width: 120 }}>{row?.name} </div>
                      <div>₺ {financeOffer?.products[ind]?.amount} </div>
                      <div style={{ display: "flex", justifyContent: "end" }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "85%",
                          }}
                        >
                          <InputBase
                            spellCheck="false"
                            style={{
                              width: "50%",
                              fontFamily: "segoeuiRg",
                              fontSize: 12,
                              marginLeft: 8,
                              fontWeight: "bold",
                              backgroundColor: "#f4f4f4",
                            }}
                            value={row?.amount}
                            placeholder={"Yeni Fiyat"}
                            className={classes.input}
                            onChange={(e) => {
                              const financeOfferObjCopy = Object.assign(
                                [],
                                JSON.parse(
                                  JSON.stringify({ ...financeOfferObj })
                                )
                              );
                              financeOfferObjCopy.products[ind].amount =
                                e.target.value;
                              setFinanceOfferObj(financeOfferObjCopy);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
              <Divider
                orientation="horizontal"
                sx={{
                  backgroundColor: "#f4f4f4",
                  width: "100%",
                  height: "auto",
                  margin: "10px 0px",
                }}
              />
              <div
                style={{ display: "flex", justifyContent: "end", margin: 20 }}
              >
                <Button
                  style={{
                    backgroundColor: "#f53b62",
                    color: "#fff",
                    fontFamily: "segoeuiB",
                    width: 100,
                    display: "flex",
                    justifyContent: "center",
                    height: 40,
                    marginLeft: 10,
                  }}
                  size="small"
                  onClick={() => {
                    financeOfferChangeFunc({
                      rest_id: financeOfferObj?.restofficial_id,
                      sendObj: {
                        id: financeOfferObj?.id,
                        products: financeOfferObj?.products,
                      },
                    });
                    setOpenRollModal(false);
                  }}
                >
                  Onayla
                </Button>
              </div>
            </Paper>
          </div>
        )}
      </Card>
    </Modal>
  );
}
