import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  InputBase,
  Paper,
  Tooltip,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import "./puantaj.css";
import { makeStyles } from "@mui/styles";
import { GreenSwitch } from "../../../../components/switch/greenSwitch";
import { ManagementContext } from "../../../../contexts/managementContext";
import moment from "moment";

import DatePicker from "react-datepicker";
import CustomDatePickerInput from "../../../../components/datePickerInput";
import {
  Add,
  Close,
  Forward,
  Fullscreen,
  Home,
  HorizontalRule,
  Info,
  InsertChart,
  KeyboardArrowDown,
} from "@mui/icons-material";
import CustomAudioPlayer from "../../../../components/audioPlayer";
import AddGroupPuantajModal from "../../dataManagement/components/puantajSettingsv2/modals/addGroupPuantaj";
import TaskTypeStatsModal from "../../dataManagement/components/puantajSettingsv2/modals/taskTypeStats";
import { useToast } from "../../../../components/snackbar";
import Select from "react-select";
import { selectDefaultStyles } from "../../../../components/selectStyles/stylev2";
import useDebounce from "../../../../components/debounce";
import SearchWithIcon from "../../../../components/searchWithIcon";
import EducationHistoryModal from "../../dataManagement/components/puantajSettingsv2/modals/educationHistory";

const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
      borderRadius: 10,
    },
    "& .PrivateNotchedOutline-root-15.MuiOutlinedInput-notchedOutline": {
      borderColor: "gray",
      borderWidth: 0,
      borderRadius: 10,
    },
    "& .MuiOutlinedInput-input": {
      marginTop: 6,
      marginLeft: 6,
      color: "black",
      borderRadius: 10,
    },
    "& .css-1wu7ecg-MuiSvgIcon-root-MuiSelect-icon": {
      color: "black",
    },
  },
  hoverStyle: {
    position: "relative",
    "&:hover": {
      borderRadius: 4,
      backgroundColor: "gray",
    },
  },
});

const PuantajScreen = () => {
  const classes = useStyles();
  const { alert } = useToast();
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [startDate, endDate] = dateRange;
  const {
    ratingListFunc,
    ratingListNew,
    ratingListEducation,
    ratingListCompleted,
    puantajRecordFunc,
    setRatingListNew,
    setRatingListEducation,
    ratingRateFunc,
    ratingEducationRateFunc,
    cdrRatingTaskTypeCreateFunc,
    cdrRatingGrouplistFunc,
    cdrRatingGroupList,
    setCdrRatingGroupList,
    ratingStatsFunc,
    ratingStats,
    ratingTaskTypeStatsFunc,
    ratingTaskTypeStats,
    cdrRatingGroupAddFunc,
    dashboardCdrFiltersFunc,
    dashboardCdrFilterList,
    setDashboardCdrFilterList,
    cdrRatingSkipFunc,
  } = useContext(ManagementContext);
  const audioRef = useRef(null);

  const [addGroupPuantajScreen, setAddGroupPuantajScreen] = useState({
    showModal: false,
    selectedPuantaj: null,
  });
  const [taskTypeStatsModal, setTaskTypeStatsModal] = useState({
    showModal: false,
    selectedOfficeuser: null,
  });
  const [educationHistoryModal, setEducationHistoryModal] = useState({
    showModal: false,
    educationInfo: null,
  });

  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedTaskTypes, setSelectedTaskTypes] = useState(null);

  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 1000);
  const [isOpen, setIsOpen] = useState(false);

  //* container select
  const [newContainerSelect, setNewContainerSelect] = useState(false);
  const [educationContainerSelect, setEducationContainerSelect] =
    useState(false);
  const [completedContainerSelect, setCompletedContainerSelect] =
    useState(false);
  //*
  const [cardHeight, setCardHeight] = useState("72vh");

  useEffect(() => {
    cdrRatingGrouplistFunc();
    dashboardCdrFiltersFunc();
  }, []);

  useEffect(() => {
    if (endDate) {
      ratingStatsFunc({
        sendObj: {
          start_date: moment(startDate).format("YYYY-MM-DD"),
          end_date: moment(endDate).format("YYYY-MM-DD"),
          task_type:
            selectedTaskTypes !== null
              ? selectedTaskTypes?.id !== 0
                ? selectedTaskTypes?.id
                : ""
              : "",
        },
      });
      let data = {
        sendObj: {
          start_date: moment(startDate).format("YYYY-MM-DD"),
          end_date: moment(endDate).format("YYYY-MM-DD"),
          state: "new",
          officeuser:
            selectedUser !== null
              ? selectedUser?.user_id !== 0
                ? selectedUser?.user_id
                : ""
              : "",
          task_type:
            selectedTaskTypes !== null
              ? selectedTaskTypes?.id !== 0
                ? selectedTaskTypes?.id
                : ""
              : "",
          cdr: debouncedSearchTerm.length > 0 ? debouncedSearchTerm : "",
        },
      };
      let dataEdu = {
        sendObj: {
          start_date: moment(startDate).format("YYYY-MM-DD"),
          end_date: moment(endDate).format("YYYY-MM-DD"),
          state: "education",
          officeuser:
            selectedUser !== null
              ? selectedUser?.user_id !== 0
                ? selectedUser?.user_id
                : ""
              : "",
          task_type:
            selectedTaskTypes !== null
              ? selectedTaskTypes?.id !== 0
                ? selectedTaskTypes?.id
                : ""
              : "",
          cdr: debouncedSearchTerm.length > 0 ? debouncedSearchTerm : "",
        },
      };
      let dataComp = {
        sendObj: {
          start_date: moment(startDate).format("YYYY-MM-DD"),
          end_date: moment(endDate).format("YYYY-MM-DD"),
          state: "completed",
          officeuser:
            selectedUser !== null
              ? selectedUser?.user_id !== 0
                ? selectedUser?.user_id
                : ""
              : "",
          task_type:
            selectedTaskTypes !== null
              ? selectedTaskTypes?.id !== 0
                ? selectedTaskTypes?.id
                : ""
              : "",
          cdr: debouncedSearchTerm.length > 0 ? debouncedSearchTerm : "",
        },
      };
      ratingListFunc(data);
      ratingListFunc(dataEdu);
      ratingListFunc(dataComp);
    }
  }, [endDate, selectedUser, debouncedSearchTerm, selectedTaskTypes]);

  const preparePayload = (array) => {
    return array.map((item) => {
      const areAllOptionsRated =
        item.options.length > 0 &&
        item.options.every((option) => option.rating !== null);

      const updatedRating = areAllOptionsRated ? true : item.rating;

      const options = item.options.map((option) => ({
        title: option.title,
        points: option.points,
        rating: option.rating,
      }));

      return {
        title: item.title,
        points: item.points,
        rating: updatedRating,
        options: options,
      };
    });
  };

  const sendNewRatingFunc = async (row) => {
    let data = {
      rating_id: row?.id,
      sendObj: {
        education: row?.education ? true : false,
        notification: row?.notification ? true : false,
        note: row?.note ? row?.note : "",
        ratings: preparePayload(row?.ratings),
      },
      startDate,
      endDate,
    };
    let result = await ratingRateFunc(data);
    if (result) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            margin: 3,
            display: "flex",
            width: "90%",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex" }}>
            <div style={{ display: "flex", width: 230 }}>
              <DatePicker
                dateFormat="yyyy-MM-dd"
                fixedHeight="35px"
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                maxDate={new Date()}
                onChange={(update) => {
                  setDateRange(update);
                }}
                customInput={
                  <CustomDatePickerInput selectWidth={230} color={"#393737"} />
                }
                locale="tr"
              />
            </div>
            <div>
              <Select
                styles={{
                  ...selectDefaultStyles,
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                }}
                options={dashboardCdrFilterList?.officeusers}
                darkMode={true}
                responsiveHeight={"auto"}
                selectFontSize={14}
                menuColor={"#f4f4f4"}
                hoverColor={"#f53b62"}
                isSearchable={false}
                selectWidth={"250px"}
                inputColor={"#f4f4f4"}
                textColor={"black"}
                placeHolderColor={"#f53b62"}
                inputFontSize={14}
                dropdownColor={"#f53b62"}
                isClearable={false}
                closeMenuOnSelect={true}
                placeholder="Kişi"
                getOptionLabel={(option) => option.full_name}
                getOptionValue={(option) => option.user_id}
                value={selectedUser}
                onChange={(e) => setSelectedUser(e)}
              />
            </div>
            <div style={{ marginLeft: 15 }}>
              <Select
                styles={{
                  ...selectDefaultStyles,
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                }}
                options={dashboardCdrFilterList?.task_types}
                darkMode={true}
                responsiveHeight={"auto"}
                selectFontSize={14}
                menuColor={"#f4f4f4"}
                hoverColor={"#f53b62"}
                isSearchable={false}
                selectWidth={"250px"}
                inputColor={"#f4f4f4"}
                textColor={"black"}
                placeHolderColor={"#f53b62"}
                inputFontSize={14}
                dropdownColor={"#f53b62"}
                isClearable={false}
                closeMenuOnSelect={true}
                placeholder="Task Tipleri"
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                value={selectedTaskTypes}
                onChange={(e) => setSelectedTaskTypes(e)}
              />
            </div>
            <div style={{ height: 38, width: 200, marginLeft: 10 }}>
              <SearchWithIcon
                setSearchTerm={setSearchTerm}
                placeTitle={"Cdr Id"}
                searchTerm={searchTerm}
                customWidth={"100%"}
              />
            </div>
          </div>
          <div
            onClick={() => {
              if (
                newContainerSelect &&
                educationContainerSelect &&
                completedContainerSelect
              ) {
                setNewContainerSelect(false);
                setEducationContainerSelect(false);
                setCompletedContainerSelect(false);
              } else {
                setNewContainerSelect(true);
                setEducationContainerSelect(true);
                setCompletedContainerSelect(true);
              }
            }}
          >
            {newContainerSelect &&
            educationContainerSelect &&
            completedContainerSelect ? (
              <Home
                style={{
                  width: 25,
                  height: 25,
                  marginRight: 15,
                  color: "#fff",
                  cursor: "pointer",
                }}
                className={classes.hoverStyle}
              />
            ) : (
              <InsertChart
                onClick={() => {
                  ratingStatsFunc({
                    sendObj: {
                      start_date: moment(startDate).format("YYYY-MM-DD"),
                      end_date: moment(endDate).format("YYYY-MM-DD"),
                      task_type:
                        selectedTaskTypes !== null
                          ? selectedTaskTypes?.id !== 0
                            ? selectedTaskTypes?.id
                            : ""
                          : "",
                    },
                  });
                }}
                style={{
                  width: 25,
                  height: 25,
                  marginRight: 15,
                  color: "#fff",
                  cursor: "pointer",
                }}
                className={classes.hoverStyle}
              />
            )}
          </div>
        </div>

        <div style={{ display: "flex", flexDirection: "row" }}>
          {!newContainerSelect ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                height: "80vh",
                // transition: "width 0.3s ease",
              }}
            >
              <div
                style={{
                  width: "100%",
                  backgroundColor: "#ffb818",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  fontFamily: "segoeuiB",
                  fontSize: 13,
                  padding: "5px 0px",
                  color: "#fff",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    marginLeft: 15,
                    backgroundColor: "white",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                    borderRadius: 3,
                    cursor: "pointer",
                    WebkitAppRegion: "no-drag",
                    width: "auto",
                    minWidth: 30,
                  }}
                >
                  <div
                    style={{
                      color: "black",
                      fontSize: 14,
                      fontFamily: "segoeuiRg",
                    }}
                  >
                    {ratingListNew?.length}
                  </div>
                </div>
                <div>YENİ</div>
                <HorizontalRule
                  style={{
                    width: 18,
                    height: 18,
                    marginRight: 15,
                    color: "#fff",
                    cursor: "pointer",
                  }}
                  className={classes.hoverStyle}
                  onClick={() => {
                    setNewContainerSelect(true);
                  }}
                />
              </div>
              <div style={{ overflow: "auto" }}>
                {ratingListNew?.map((row, ind) => {
                  return (
                    <Paper
                      key={ind}
                      elevation={3}
                      style={{
                        width: "93%",
                        display: "flex",
                        padding: 5,
                        margin: "10px 10px",
                        backgroundColor: "#393737",
                        boxShadow: "rgb(91 91 91 / 75%) 0px 0px 9px 2px",
                        height: cardHeight,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "32%",
                          alignItems: "center",
                          justifyContent: "space-between",
                          backgroundColor: "#474545",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "end",
                            }}
                          >
                            <Add
                              style={{
                                width: 18,
                                height: 18,
                                marginRight: 15,
                                color: "#fff",
                                cursor: "pointer",
                              }}
                              className={classes.hoverStyle}
                              onClick={async () => {
                                let result = await sendNewRatingFunc(row);
                                if (result) {
                                  setAddGroupPuantajScreen({
                                    showModal: true,
                                    selectedPuantaj: row,
                                  });
                                }
                              }}
                            />
                            <Fullscreen
                              style={{
                                width: 18,
                                height: 18,
                                marginRight: 15,
                                color: "#fff",
                                cursor: "pointer",
                              }}
                              className={classes.hoverStyle}
                              onClick={() => {
                                setCardHeight(
                                  cardHeight === "72vh" ? 380 : "72vh"
                                );
                              }}
                            />
                          </div>
                          <Paper
                            style={{
                              backgroundColor: "#736868",
                              width: "-webkit-fill-available",
                              color: "#fff",
                              padding: 1,
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                fontSize: 18,
                                fontFamily: "segoeuiRg",
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              {row?.task_type?.name}
                            </div>
                          </Paper>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <div
                            style={{
                              width: "40%",
                              textAlign: "-webkit-center",
                            }}
                          >
                            <Avatar
                              alt="backoffice_user"
                              src={row?.officeuser?.picture}
                              sx={{ width: 56, height: 56, cursor: "pointer" }}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "60%",
                              justifyContent: "space-evenly",
                            }}
                          >
                            <div
                              style={{
                                fontFamily: "segoeuiRg",
                                fontSize: 14,
                                fontWeight: 550,
                                color: "#fff",
                              }}
                            >
                              {row?.officeuser?.full_name}
                            </div>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                fontFamily: "segoeuiRg",
                                fontSize: 11,
                                color: "#fff",
                              }}
                            >
                              {moment(row?.created_at).format(
                                "DD-MM-YYYY HH:mm"
                              )}
                            </div>
                          </div>
                        </div>
                        <Divider
                          orientation="vertical"
                          sx={{
                            backgroundColor: "gray",
                            height: "1px",
                            width: "100%",
                          }}
                        />
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <div>
                            <IconButton
                              onClick={() => {
                                cdrRatingSkipFunc({
                                  id: row?.id,
                                  row,
                                  times: { startDate, endDate },
                                });
                              }}
                            >
                              <Forward style={{ color: "#fff" }} />
                            </IconButton>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                              textAlign: "center",
                            }}
                          >
                            <div
                              style={{
                                fontFamily: "segoeuiRg",
                                fontSize: 13,
                                fontWeight: 550,
                                color: "#fff",
                              }}
                            >
                              <div
                                style={{
                                  cursor: "pointer",
                                  textDecoration:
                                    row?.cdr?.restofficial !== null
                                      ? "underline"
                                      : "",
                                  color: "#bfbfbf",
                                }}
                                onClick={() => {
                                  if (row?.cdr?.restofficial !== null) {
                                    window.open(
                                      `/restaurants/${row?.cdr?.restofficial?.user_id}`,
                                      "_blank"
                                    );
                                  }
                                }}
                              >
                                {row?.cdr?.restofficial !== null
                                  ? row?.cdr?.restofficial?.name
                                  : "Restoranla ilişkilendirilemedi."}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            width: "90%",
                          }}
                        >
                          {row?.cdr?.voice ? (
                            <div style={{ width: "99%" }}>
                              <Box
                                sx={{
                                  backgroundColor: "#736868",
                                  color: "#fff",
                                  padding: 1,
                                  borderRadius: 1,
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: 1,
                                  width: "90%",
                                }}
                              >
                                <div
                                  style={{
                                    width: "100%",
                                    height: "auto",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    backgroundColor: "#fff",
                                    fontFamily: "segoeuiRg",
                                    fontSize: 11,
                                    color: "#000",
                                    flexDirection: "column",
                                  }}
                                >
                                  <div>
                                    {row?.cdr?.id} -{" "}
                                    {moment(row?.cdr?.created_at).format(
                                      "DD-MM-YYYY HH:mm"
                                    )}
                                  </div>
                                </div>
                                <CustomAudioPlayer
                                  audioRef={audioRef}
                                  src={row?.cdr?.voice}
                                />
                              </Box>
                            </div>
                          ) : (
                            <>
                              <Button
                                style={{
                                  backgroundColor: "#f53b62",
                                  color: "#fff",
                                  fontFamily: "segoeuiB",
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "center",
                                  height: 20,
                                }}
                                size="small"
                                onClick={() => {
                                  puantajRecordFunc({
                                    record_id: row?.cdr?.id,
                                    list: "new",
                                  });
                                }}
                              >
                                Ses Dosyası
                              </Button>
                            </>
                          )}
                        </div>
                      </div>
                      <div style={{ width: "68%" }}>
                        <div style={{ height: "70%", overflow: "auto" }}>
                          {row?.ratings?.map((rowF, indF) => {
                            return (
                              <Paper
                                style={{
                                  display: "flex",
                                  color: "#fff",
                                  padding: 2,
                                  backgroundColor: "#403836",
                                  width: "95%",
                                  marginTop: 10,
                                  fontFamily: "segoeuiRg",
                                  flexDirection: "column",
                                }}
                              >
                                <div
                                  style={{
                                    width: "100%",
                                    display: "flex",
                                    fontSize: 15,
                                    color: "#1c9532",
                                  }}
                                >
                                  <div
                                    style={{
                                      marginRight: 5,
                                      backgroundColor: "green",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-evenly",
                                      borderRadius: 3,
                                      cursor: "pointer",
                                      WebkitAppRegion: "no-drag",
                                      width: "auto",
                                      minWidth: 30,
                                      color: "#fff",
                                      fontSize: 14,
                                      fontFamily: "segoeuiRg",
                                    }}
                                  >
                                    {rowF?.points}
                                  </div>
                                  {rowF?.title}
                                </div>
                                <Divider
                                  orientation="vertical"
                                  sx={{
                                    backgroundColor: "#4a4a4a",
                                    width: "100%",
                                    height: "1px",
                                    margin: "2px 0px",
                                  }}
                                />
                                {rowF?.options?.length !== 0 ? (
                                  rowF?.options?.map((rowOpt, indOpt) => {
                                    return (
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            minHeight: 35,
                                            alignItems: "center",
                                            flexDirection: "row",
                                          }}
                                        >
                                          <div
                                            style={{
                                              width: "100%",
                                              display: "flex",
                                              fontSize: 14,
                                              alignItems: "center",
                                            }}
                                          >
                                            <div
                                              style={{
                                                marginRight: 5,
                                                backgroundColor: "#fff",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-evenly",
                                                borderRadius: 3,
                                                cursor: "pointer",
                                                WebkitAppRegion: "no-drag",
                                                width: "auto",
                                                minWidth: 20,
                                                color: "black",
                                                fontSize: 12,
                                                fontFamily: "segoeuiRg",
                                                height: 15,
                                              }}
                                            >
                                              {rowOpt?.points}
                                            </div>
                                            {rowOpt?.title}
                                          </div>
                                          <div
                                            style={{
                                              display: "flex",
                                              width: "30%",
                                              justifyContent: "space-around",
                                              alignSelf: "center",
                                              marginTop: 4,
                                              justifySelf: "end",
                                            }}
                                          >
                                            <div
                                              onClick={() => {
                                                const ratingListNewObj = [
                                                  ...ratingListNew,
                                                ];
                                                ratingListNewObj[ind].ratings[
                                                  indF
                                                ].options[indOpt].rating = true;
                                                setRatingListNew(
                                                  ratingListNewObj
                                                );
                                              }}
                                              style={{
                                                width: 40,
                                                height: 20,
                                                backgroundColor:
                                                  rowOpt.rating === true
                                                    ? "green"
                                                    : "#f4f4f4",
                                                borderRadius: 2,
                                                color:
                                                  rowOpt.rating === true
                                                    ? "#fff"
                                                    : "#000",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                cursor: "pointer",
                                                fontSize: 13,
                                              }}
                                            >
                                              Evet
                                            </div>
                                            <div
                                              onClick={() => {
                                                const ratingListNewObj = [
                                                  ...ratingListNew,
                                                ];
                                                ratingListNewObj[ind].ratings[
                                                  indF
                                                ].options[
                                                  indOpt
                                                ].rating = false;
                                                setRatingListNew(
                                                  ratingListNewObj
                                                );
                                              }}
                                              style={{
                                                width: 40,
                                                height: 20,
                                                backgroundColor:
                                                  rowOpt.rating === false
                                                    ? "green"
                                                    : "#f4f4f4",
                                                borderRadius: 2,
                                                color:
                                                  rowOpt.rating === false
                                                    ? "#fff"
                                                    : "#000",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                cursor: "pointer",
                                                fontSize: 13,
                                              }}
                                            >
                                              Hayır
                                            </div>
                                          </div>
                                        </div>
                                        <Divider
                                          orientation="vertical"
                                          sx={{
                                            backgroundColor: "#4a4a4a",
                                            width: "100%",
                                            height: "1px",
                                            margin: "10px 0px",
                                          }}
                                        />
                                      </div>
                                    );
                                  })
                                ) : (
                                  <div
                                    style={{
                                      display: "flex",
                                      width: "100%",
                                      justifyContent: "space-around",
                                      alignSelf: "center",
                                      marginTop: 4,
                                    }}
                                  >
                                    <div
                                      onClick={() => {
                                        const ratingListNewObj = [
                                          ...ratingListNew,
                                        ];
                                        ratingListNewObj[ind].ratings[
                                          indF
                                        ].rating = true;
                                        setRatingListNew(ratingListNewObj);
                                      }}
                                      style={{
                                        width: 40,
                                        height: 20,
                                        backgroundColor:
                                          rowF.rating === true
                                            ? "green"
                                            : "#f4f4f4",
                                        borderRadius: 2,
                                        color:
                                          rowF.rating === true
                                            ? "#fff"
                                            : "#000",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        cursor: "pointer",
                                        fontSize: 13,
                                      }}
                                    >
                                      Evet
                                    </div>
                                    <div
                                      onClick={() => {
                                        const ratingListNewObj = [
                                          ...ratingListNew,
                                        ];
                                        ratingListNewObj[ind].ratings[
                                          indF
                                        ].rating = false;
                                        setRatingListNew(ratingListNewObj);
                                      }}
                                      style={{
                                        width: 40,
                                        height: 20,
                                        backgroundColor:
                                          rowF.rating === false
                                            ? "green"
                                            : "#f4f4f4",
                                        borderRadius: 2,
                                        color:
                                          rowF.rating === false
                                            ? "#fff"
                                            : "#000",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        cursor: "pointer",
                                        fontSize: 13,
                                      }}
                                    >
                                      Hayır
                                    </div>
                                  </div>
                                )}
                              </Paper>
                            );
                          })}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            height: "30%",
                            alignItems: "center",
                          }}
                        >
                          <Paper
                            style={{
                              width: "70%",
                              display: "flex",
                              justifyContent: "space-between",
                              minHeight: 42,
                              backgroundColor: "#F0F0F0",
                              alignSelf: "center",
                              marginTop: 10,
                              height: "70%", // Paper'ın height'ı ayarlandı
                            }}
                          >
                            <InputBase
                              multiline
                              spellCheck={false}
                              style={{
                                width: "100%",
                                fontFamily: "segoeuiRg",
                                paddingLeft: 8,
                                fontSize: 14,
                                height: "100%",
                                display: "flex",
                                alignItems: "center",
                              }}
                              sx={{
                                "& .MuiInputBase-input": {
                                  height: "100% !important",
                                  boxSizing: "border-box",
                                  lineHeight: "normal",
                                },
                              }}
                              rows={2}
                              placeholder={"Not ekle"}
                              className={classes.input}
                              value={row?.note ? row?.note : ""}
                              onChange={(e) => {
                                const ratingListNewObj = Object.assign(
                                  [],
                                  JSON.parse(
                                    JSON.stringify({ ...ratingListNew })
                                  )
                                );
                                ratingListNewObj[ind].note = e.target.value;
                                setRatingListNew(ratingListNewObj);
                              }}
                            />
                          </Paper>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "20%",
                              height: "100%",
                              justifyContent: "space-evenly",
                            }}
                          >
                            <div
                              style={{
                                height: "50%",
                                display: "flex",
                                justifyContent: "center",
                                marginTop: 3,
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: "#403836",
                                  borderRadius: 4,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <div style={{ color: "#fff", fontSize: 12 }}>
                                  Eğitim
                                </div>
                                <GreenSwitch
                                  sx={{
                                    width: 54,
                                    height: 33,
                                    "& .MuiSwitch-thumb": {
                                      width: 16,
                                      height: 16,
                                    },
                                  }}
                                  onClick={() => {
                                    const ratingListNewObj = Object.assign(
                                      [],
                                      JSON.parse(
                                        JSON.stringify({ ...ratingListNew })
                                      )
                                    );
                                    if (ratingListNewObj[ind].education) {
                                      ratingListNewObj[ind].education =
                                        !row?.education;
                                    } else {
                                      ratingListNewObj[ind].education = true;
                                    }
                                    setRatingListNew(ratingListNewObj);
                                  }}
                                  checked={row?.education ? true : false}
                                />
                              </div>
                              <div
                                style={{
                                  backgroundColor: "#403836",
                                  borderRadius: 4,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <div style={{ color: "#fff", fontSize: 12 }}>
                                  Popup
                                </div>
                                <GreenSwitch
                                  sx={{
                                    width: 54,
                                    height: 33,
                                    "& .MuiSwitch-thumb": {
                                      width: 16,
                                      height: 16,
                                    },
                                  }}
                                  onClick={() => {
                                    const ratingListNewObj = Object.assign(
                                      [],
                                      JSON.parse(
                                        JSON.stringify({ ...ratingListNew })
                                      )
                                    );
                                    if (ratingListNewObj[ind].notification) {
                                      ratingListNewObj[ind].notification =
                                        !row?.notification;
                                    } else {
                                      ratingListNewObj[ind].notification = true;
                                    }
                                    setRatingListNew(ratingListNewObj);
                                  }}
                                  checked={row?.notification ? true : false}
                                />
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "flex-end",
                              }}
                            >
                              <Button
                                style={{
                                  backgroundColor: "#f53b62",
                                  color: "#fff",
                                  fontFamily: "segoeuiB",
                                  width: "20%",
                                  display: "flex",
                                  justifyContent: "center",
                                  height: 20,
                                }}
                                size="small"
                                onClick={() => {
                                  if (row?.note?.length > 3) {
                                    sendNewRatingFunc(row);
                                  } else {
                                    alert(
                                      "Not alanı minimum 3 karakter olmalı."
                                    );
                                  }
                                }}
                              >
                                Kaydet
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Paper>
                  );
                })}
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "3%",
                height: "80vh",
                // transition: "width 0.3s ease",
              }}
            >
              <div
                style={{
                  width: "100%",
                  backgroundColor: "#ffb818",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  fontFamily: "segoeuiB",
                  fontSize: 13,
                  padding: "5px 0px",
                  color: "#fff",
                  position: "relative",
                  writingMode: "vertical-rl", // Yazı yönü aniden değişir
                  transform: "rotate(180deg)", // Döndürme aniden uygulanır
                  height: "100%",
                }}
              >
                <div></div>
                <div>YENİ</div>

                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{
                      backgroundColor: "white",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-evenly",
                      borderRadius: 3,
                      cursor: "pointer",
                      WebkitAppRegion: "no-drag",
                      width: "auto",
                      minWidth: 30,
                      writingMode: "horizontal-tb",
                      transform: "rotate(180deg)",
                    }}
                  >
                    <div
                      style={{
                        color: "black",
                        fontSize: 14,
                        fontFamily: "segoeuiRg",
                      }}
                    >
                      {ratingListNew?.length}
                    </div>
                  </div>
                  <Fullscreen
                    style={{
                      width: 18,
                      height: 18,
                      marginTop: 15,
                      color: "#fff",
                      cursor: "pointer",
                    }}
                    className={classes.hoverStyle}
                    onClick={() => {
                      setNewContainerSelect(false);
                    }}
                  />
                </div>
              </div>
            </div>
          )}
          <Divider
            orientation="vertical"
            sx={{
              backgroundColor: "gray",
              width: "1px",
              height: "80vh",
            }}
          />
          {!educationContainerSelect ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                height: "80vh",
                // transition: "width 0.3s ease",
              }}
            >
              <div
                style={{
                  width: "100%",
                  backgroundColor: "#6aa1ff",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  fontFamily: "segoeuiB",
                  fontSize: 13,
                  padding: "5px 0px",
                  color: "#fff",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    marginLeft: 15,
                    backgroundColor: "white",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                    borderRadius: 3,
                    cursor: "pointer",
                    WebkitAppRegion: "no-drag",
                    width: "auto",
                    minWidth: 30,
                  }}
                >
                  <div
                    style={{
                      color: "black",
                      fontSize: 14,
                      fontFamily: "segoeuiRg",
                    }}
                  >
                    {ratingListEducation?.length}
                  </div>
                </div>
                <div>EĞİTİM</div>
                <HorizontalRule
                  style={{
                    width: 18,
                    height: 18,
                    marginRight: 15,
                    color: "#fff",
                    cursor: "pointer",
                  }}
                  className={classes.hoverStyle}
                  onClick={() => {
                    setEducationContainerSelect(true);
                  }}
                />
              </div>

              <div style={{ overflow: "auto" }}>
                {ratingListEducation?.map((row, ind) => {
                  return (
                    <Paper
                      key={ind}
                      elevation={3}
                      style={{
                        width: "93%",
                        display: "flex",
                        padding: 5,
                        margin: "10px 10px",
                        backgroundColor: "#393737",
                        boxShadow: `${
                          row?.notification &&
                          !row?.notification_read &&
                          moment().diff(moment(row?.notification_at), "days") >
                            2
                            ? "red"
                            : "rgb(91 91 91 / 75%)"
                        } 0px 0px 9px 2px`,
                        height: cardHeight,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "32%",
                          alignItems: "center",
                          justifyContent: "space-between",
                          backgroundColor: "#474545",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "end",
                            }}
                          >
                            <Fullscreen
                              style={{
                                width: 18,
                                height: 18,
                                marginRight: 15,
                                color: "#fff",
                                cursor: "pointer",
                              }}
                              className={classes.hoverStyle}
                              onClick={() => {
                                setCardHeight(
                                  cardHeight === "72vh" ? 380 : "72vh"
                                );
                              }}
                            />
                          </div>
                          <Paper
                            style={{
                              backgroundColor: "#736868",
                              width: "-webkit-fill-available",
                              color: "#fff",
                              padding: 1,
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                fontSize: 18,
                                fontFamily: "segoeuiRg",
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              {row?.task_type?.name}
                            </div>
                          </Paper>
                          <Paper
                            onClick={() => {
                              setEducationHistoryModal({
                                showModal: true,
                                educationInfo: row,
                              });
                            }}
                            style={{
                              backgroundColor: "#5c5caf",
                              width: "-webkit-fill-available",
                              color: "#fff",
                              padding: 1,
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                              marginTop: 2,
                              cursor: "pointer",
                            }}
                          >
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                fontFamily: "segoeuiRg",
                                fontSize: 12,
                                color: "#fff",
                                fontVariant: "all-petite-caps",
                                letterSpacing: 1.5,
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: "#fff",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-evenly",
                                  borderRadius: 3,
                                  cursor: "pointer",
                                  WebkitAppRegion: "no-drag",
                                  width: "auto",
                                  minWidth: 20,
                                  color: "black",
                                  fontSize: 12,
                                  fontFamily: "segoeuiRg",
                                  height: 15,
                                }}
                              >
                                {row?.education_count}
                              </div>

                              {row?.notification ? "POPUP" : "EĞİTİM"}
                              <div style={{ minWidth: 30 }}></div>
                            </div>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                fontFamily: "segoeuiRg",
                                fontSize: 11,
                                color: "#fff",
                                fontVariant: "all-petite-caps",
                                letterSpacing: 1.5,
                                justifyContent: "center",
                              }}
                            >
                              {row?.notification
                                ? moment(row?.notification_at).format(
                                    "DD-MM-YYYY HH:mm"
                                  )
                                : ""}
                            </div>
                          </Paper>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <div
                            style={{
                              width: "40%",
                              textAlign: "-webkit-center",
                            }}
                          >
                            <Avatar
                              alt="backoffice_user"
                              src={row?.officeuser?.picture}
                              sx={{ width: 56, height: 56, cursor: "pointer" }}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "60%",
                              justifyContent: "space-evenly",
                            }}
                          >
                            <div
                              style={{
                                fontFamily: "monospace",
                                fontSize: 13,
                                fontWeight: 550,
                                color: "#fff",
                                textDecoration: "underline",
                              }}
                            >
                              Officeuser
                            </div>
                            <div
                              style={{
                                fontFamily: "segoeuiRg",
                                fontSize: 14,
                                fontWeight: 550,
                                color: "#fff",
                              }}
                            >
                              {row?.officeuser?.full_name}
                            </div>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                fontFamily: "segoeuiRg",
                                fontSize: 11,
                                color: "#fff",
                              }}
                            >
                              {moment(row?.created_at).format(
                                "DD-MM-YYYY HH:mm"
                              )}
                            </div>
                          </div>
                        </div>
                        <Divider
                          orientation="vertical"
                          sx={{
                            backgroundColor: "gray",
                            height: "1px",
                            width: "100%",
                          }}
                        />
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <div
                            style={{
                              width: "40%",
                              textAlign: "-webkit-center",
                            }}
                          >
                            <Avatar
                              alt="backoffice_user"
                              src={row?.rated_by?.picture}
                              sx={{ width: 56, height: 56, cursor: "pointer" }}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "60%",
                              justifyContent: "space-evenly",
                            }}
                          >
                            <div
                              style={{
                                fontFamily: "monospace",
                                fontSize: 13,
                                fontWeight: 550,
                                color: "#fff",
                                textDecoration: "underline",
                              }}
                            >
                              Değerlendiren
                            </div>
                            <div
                              style={{
                                fontFamily: "segoeuiRg",
                                fontSize: 14,
                                fontWeight: 550,
                                color: "#fff",
                              }}
                            >
                              {row?.rated_by?.full_name}
                            </div>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                fontFamily: "segoeuiRg",
                                fontSize: 11,
                                color: "#fff",
                              }}
                            >
                              {moment(row?.created_at).format(
                                "DD-MM-YYYY HH:mm"
                              )}
                            </div>
                          </div>
                        </div>

                        <Divider
                          orientation="vertical"
                          sx={{
                            backgroundColor: "gray",
                            height: "1px",
                            width: "100%",
                          }}
                        />
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                              textAlign: "center",
                            }}
                          >
                            <div
                              style={{
                                fontFamily: "segoeuiRg",
                                fontSize: 13,
                                fontWeight: 550,
                                color: "#fff",
                              }}
                            >
                              <div
                                style={{
                                  cursor: "pointer",
                                  textDecoration:
                                    row?.cdr?.restofficial !== null
                                      ? "underline"
                                      : "",
                                  color: "#bfbfbf",
                                }}
                                onClick={() => {
                                  if (row?.cdr?.restofficial !== null) {
                                    window.open(
                                      `/restaurants/${row?.cdr?.restofficial?.user_id}`,
                                      "_blank"
                                    );
                                  }
                                }}
                              >
                                {row?.cdr?.restofficial !== null
                                  ? row?.cdr?.restofficial?.name
                                  : "Restoranla ilişkilendirilemedi."}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            width: "90%",
                          }}
                        >
                          {row?.cdr?.voice ? (
                            <div style={{ width: "99%" }}>
                              <Box
                                sx={{
                                  backgroundColor: "#736868",
                                  color: "#fff",
                                  padding: 1,
                                  borderRadius: 1,
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: 1,
                                  width: "90%",
                                }}
                              >
                                <div
                                  style={{
                                    width: "100%",
                                    height: "auto",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    backgroundColor: "#fff",
                                    fontFamily: "segoeuiRg",
                                    fontSize: 11,
                                    color: "#000",
                                    flexDirection: "column",
                                  }}
                                >
                                  <div>
                                    {row?.cdr?.id} -{" "}
                                    {moment(row?.cdr?.created_at).format(
                                      "DD-MM-YYYY HH:mm"
                                    )}
                                  </div>
                                </div>
                                <CustomAudioPlayer
                                  audioRef={audioRef}
                                  src={row?.cdr?.voice}
                                />
                              </Box>
                            </div>
                          ) : (
                            <>
                              <Button
                                style={{
                                  backgroundColor: "#f53b62",
                                  color: "#fff",
                                  fontFamily: "segoeuiB",
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "center",
                                  height: 20,
                                }}
                                size="small"
                                onClick={() => {
                                  puantajRecordFunc({
                                    record_id: row?.cdr?.id,
                                    list: "education",
                                  });
                                }}
                              >
                                Ses Dosyası
                              </Button>
                            </>
                          )}
                        </div>
                      </div>
                      <div style={{ width: "68%" }}>
                        <div style={{ height: "70%", overflow: "auto" }}>
                          {row?.ratings?.map((rowF, indF) => {
                            return (
                              <Paper
                                style={{
                                  display: "flex",
                                  color: "#fff",
                                  padding: 2,
                                  backgroundColor: "#403836",
                                  width: "95%",
                                  marginTop: 10,
                                  fontFamily: "segoeuiRg",
                                  flexDirection: "column",
                                }}
                              >
                                <div
                                  style={{
                                    width: "100%",
                                    display: "flex",
                                    fontSize: 15,
                                    color: "#1c9532",
                                  }}
                                >
                                  <div
                                    style={{
                                      marginRight: 5,
                                      backgroundColor: "green",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-evenly",
                                      borderRadius: 3,
                                      cursor: "pointer",
                                      WebkitAppRegion: "no-drag",
                                      width: "auto",
                                      minWidth: 30,
                                      color: "#fff",
                                      fontSize: 14,
                                      fontFamily: "segoeuiRg",
                                    }}
                                  >
                                    {rowF?.points}
                                  </div>
                                  {rowF?.title}
                                </div>
                                <Divider
                                  orientation="vertical"
                                  sx={{
                                    backgroundColor: "#4a4a4a",
                                    width: "100%",
                                    height: "1px",
                                    margin: "2px 0px",
                                  }}
                                />
                                {rowF?.options?.length !== 0 ? (
                                  rowF?.options?.map((rowOpt, indOpt) => {
                                    return (
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            minHeight: 35,
                                            alignItems: "center",
                                            flexDirection: "row",
                                          }}
                                        >
                                          <div
                                            style={{
                                              width: "100%",
                                              display: "flex",
                                              fontSize: 14,
                                              alignItems: "center",
                                            }}
                                          >
                                            <div
                                              style={{
                                                marginRight: 5,
                                                backgroundColor: "#fff",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-evenly",
                                                borderRadius: 3,
                                                cursor: "pointer",
                                                WebkitAppRegion: "no-drag",
                                                width: "auto",
                                                minWidth: 20,
                                                color: "black",
                                                fontSize: 12,
                                                fontFamily: "segoeuiRg",
                                                height: 15,
                                              }}
                                            >
                                              {rowOpt?.points}
                                            </div>
                                            {rowOpt?.title}
                                          </div>
                                          <div
                                            style={{
                                              display: "flex",
                                              width: "30%",
                                              justifyContent: "space-around",
                                              alignSelf: "center",
                                              marginTop: 4,
                                            }}
                                          >
                                            <div
                                              style={{
                                                width: 40,
                                                height: 20,
                                                backgroundColor:
                                                  rowOpt.rating === true
                                                    ? "green"
                                                    : "#f4f4f4",
                                                borderRadius: 2,
                                                color:
                                                  rowOpt.rating === true
                                                    ? "#fff"
                                                    : "#000",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                cursor: "pointer",
                                                fontSize: 13,
                                              }}
                                            >
                                              Evet
                                            </div>
                                            <div
                                              style={{
                                                width: 40,
                                                height: 20,
                                                backgroundColor:
                                                  rowOpt.rating === false
                                                    ? "green"
                                                    : "#f4f4f4",
                                                borderRadius: 2,
                                                color:
                                                  rowOpt.rating === false
                                                    ? "#fff"
                                                    : "#000",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                cursor: "pointer",
                                                fontSize: 13,
                                              }}
                                            >
                                              Hayır
                                            </div>
                                          </div>
                                        </div>
                                        <Divider
                                          orientation="vertical"
                                          sx={{
                                            backgroundColor: "#4a4a4a",
                                            width: "100%",
                                            height: "1px",
                                            margin: "10px 0px",
                                          }}
                                        />
                                      </div>
                                    );
                                  })
                                ) : (
                                  <div
                                    style={{
                                      display: "flex",
                                      width: "100%",
                                      justifyContent: "space-around",
                                      alignSelf: "center",
                                      marginTop: 4,
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: 40,
                                        height: 20,
                                        backgroundColor:
                                          rowF.rating === true
                                            ? "green"
                                            : "#f4f4f4",
                                        borderRadius: 2,
                                        color:
                                          rowF.rating === true
                                            ? "#fff"
                                            : "#000",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        cursor: "pointer",
                                        fontSize: 13,
                                      }}
                                    >
                                      Evet
                                    </div>
                                    <div
                                      style={{
                                        width: 40,
                                        height: 20,
                                        backgroundColor:
                                          rowF.rating === false
                                            ? "green"
                                            : "#f4f4f4",
                                        borderRadius: 2,
                                        color:
                                          rowF.rating === false
                                            ? "#fff"
                                            : "#000",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        cursor: "pointer",
                                        fontSize: 13,
                                      }}
                                    >
                                      Hayır
                                    </div>
                                  </div>
                                )}
                              </Paper>
                            );
                          })}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            height: "30%",
                            alignItems: "center",
                          }}
                        >
                          <Paper
                            style={{
                              width: "70%",
                              display: "flex",
                              justifyContent: "space-between",
                              minHeight: 42,
                              backgroundColor: "#F0F0F0",
                              alignSelf: "center",
                              marginTop: 10,
                              height: "70%", // Paper'ın height'ı ayarlandı
                            }}
                          >
                            <InputBase
                              multiline
                              spellCheck={false}
                              style={{
                                width: "100%",
                                fontFamily: "segoeuiRg",
                                paddingLeft: 8,
                                fontSize: 14,
                                height: "100%",
                                display: "flex",
                                alignItems: "center",
                              }}
                              sx={{
                                "& .MuiInputBase-input": {
                                  height: "100% !important",
                                  boxSizing: "border-box",
                                  lineHeight: "normal",
                                },
                              }}
                              rows={2}
                              placeholder={"Not ekle"}
                              className={classes.input}
                              value={
                                row?.education_note ? row?.education_note : ""
                              }
                              onChange={(e) => {
                                const ratingListEducationObj = Object.assign(
                                  [],
                                  JSON.parse(
                                    JSON.stringify({ ...ratingListEducation })
                                  )
                                );
                                ratingListEducationObj[ind].education_note =
                                  e.target.value;
                                setRatingListEducation(ratingListEducationObj);
                              }}
                            />
                          </Paper>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "20%",
                              height: "100%",
                              justifyContent: "space-evenly",
                              alignItems: "center",
                            }}
                          >
                            <Tooltip
                              title={row?.note}
                              placement="top"
                              sx={{ fontSize: 20 }}
                              arrow
                              componentsProps={{
                                tooltip: {
                                  sx: {
                                    fontSize: 14,
                                    fontFamily: "segoeuiB",
                                    padding: 1,
                                    bgcolor: "#082657",
                                    "& .MuiTooltip-arrow": {
                                      color: "#082657",
                                    },
                                  },
                                },
                              }}
                            >
                              <Info
                                style={{
                                  width: 24,
                                  height: 24,
                                  color: "#fff",
                                  cursor: "pointer",
                                }}
                              />
                            </Tooltip>
                            <Button
                              style={{
                                backgroundColor: "#f53b62",
                                color: "#fff",
                                fontFamily: "segoeuiB",
                                width: "20%",
                                display: "flex",
                                justifyContent: "center",
                                height: 20,
                              }}
                              size="small"
                              onClick={() => {
                                let data = {
                                  rating_id: row?.id,
                                  sendObj: {
                                    education_note: row?.education_note
                                      ? row?.education_note
                                      : "",
                                  },
                                  startDate,
                                  endDate,
                                };
                                ratingEducationRateFunc(data);
                              }}
                            >
                              Kaydet
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Paper>
                  );
                })}
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "3%",
                height: "80vh",
                // transition: "width 0.3s ease",
              }}
            >
              <div
                style={{
                  width: "100%",
                  backgroundColor: "#6aa1ff",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  fontFamily: "segoeuiB",
                  fontSize: 13,
                  padding: "5px 0px",
                  color: "#fff",
                  position: "relative",
                  writingMode: "vertical-rl",
                  transform: "rotate(180deg)",
                  height: "100%",
                }}
              >
                <div></div>
                <div>EĞİTİM</div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{
                      backgroundColor: "white",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-evenly",
                      borderRadius: 3,
                      cursor: "pointer",
                      WebkitAppRegion: "no-drag",
                      width: "auto",
                      minWidth: 30,
                      writingMode: "horizontal-tb",
                      transform: "rotate(180deg)",
                    }}
                  >
                    <div
                      style={{
                        color: "black",
                        fontSize: 14,
                        fontFamily: "segoeuiRg",
                      }}
                    >
                      {ratingListEducation?.length}
                    </div>
                  </div>
                  <Fullscreen
                    style={{
                      width: 18,
                      height: 18,
                      marginTop: 15,
                      color: "#fff",
                      cursor: "pointer",
                    }}
                    className={classes.hoverStyle}
                    onClick={() => {
                      setEducationContainerSelect(false);
                    }}
                  />
                </div>
              </div>
            </div>
          )}
          <Divider
            orientation="vertical"
            sx={{
              backgroundColor: "gray",
              width: "1px",
              height: "80vh",
            }}
          />{" "}
          {!completedContainerSelect ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                height: "80vh",
                // transition: "width 0.5s ease",
              }}
            >
              <div
                style={{
                  width: "100%",
                  backgroundColor: "green",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  fontFamily: "segoeuiB",
                  fontSize: 13,
                  padding: "5px 0px",
                  color: "#fff",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    marginLeft: 15,
                    backgroundColor: "white",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                    borderRadius: 3,
                    cursor: "pointer",
                    WebkitAppRegion: "no-drag",
                    width: "auto",
                    minWidth: 30,
                  }}
                >
                  <div
                    style={{
                      color: "black",
                      fontSize: 14,
                      fontFamily: "segoeuiRg",
                    }}
                  >
                    {ratingListCompleted?.length}
                  </div>
                </div>

                <div>TAMAMLANAN</div>
                <HorizontalRule
                  style={{
                    width: 18,
                    height: 18,
                    marginRight: 15,
                    color: "#fff",
                    cursor: "pointer",
                  }}
                  className={classes.hoverStyle}
                  onClick={() => {
                    setCompletedContainerSelect(true);
                  }}
                />
              </div>
              <div style={{ overflow: "auto" }}>
                {ratingListCompleted?.map((row, ind) => {
                  return (
                    <Paper
                      key={ind}
                      elevation={3}
                      style={{
                        width: "93%",
                        display: "flex",
                        padding: 5,
                        margin: "10px 10px",
                        backgroundColor: "#393737",
                        boxShadow: "rgb(91 91 91 / 75%) 0px 0px 9px 2px",
                        height: cardHeight,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "32%",
                          alignItems: "center",
                          justifyContent: "space-between",
                          backgroundColor: "#474545",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "end",
                            }}
                          >
                            <Fullscreen
                              style={{
                                width: 18,
                                height: 18,
                                marginRight: 15,
                                color: "#fff",
                                cursor: "pointer",
                              }}
                              className={classes.hoverStyle}
                              onClick={() => {
                                setCardHeight(
                                  cardHeight === "72vh" ? 380 : "72vh"
                                );
                              }}
                            />
                          </div>
                          <Paper
                            style={{
                              backgroundColor: "#736868",
                              width: "-webkit-fill-available",
                              color: "#fff",
                              padding: 1,
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                fontSize: 18,
                                fontFamily: "segoeuiRg",
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              {row?.task_type?.name}
                            </div>
                          </Paper>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <div
                            style={{
                              width: "40%",
                              textAlign: "-webkit-center",
                            }}
                          >
                            <Avatar
                              alt="backoffice_user"
                              src={row?.officeuser?.picture}
                              sx={{ width: 56, height: 56, cursor: "pointer" }}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "60%",
                              justifyContent: "space-evenly",
                            }}
                          >
                            <div
                              style={{
                                fontFamily: "monospace",
                                fontSize: 13,
                                fontWeight: 550,
                                color: "#fff",
                                textDecoration: "underline",
                              }}
                            >
                              Officeuser
                            </div>
                            <div
                              style={{
                                fontFamily: "segoeuiRg",
                                fontSize: 14,
                                fontWeight: 550,
                                color: "#fff",
                              }}
                            >
                              {row?.officeuser?.full_name}
                            </div>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                fontFamily: "segoeuiRg",
                                fontSize: 11,
                                color: "#fff",
                              }}
                            >
                              {moment(row?.created_at).format(
                                "DD-MM-YYYY HH:mm"
                              )}
                            </div>
                          </div>
                        </div>
                        <Divider
                          orientation="vertical"
                          sx={{
                            backgroundColor: "gray",
                            height: "1px",
                            width: "100%",
                          }}
                        />
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <div
                            style={{
                              width: "40%",
                              textAlign: "-webkit-center",
                            }}
                          >
                            <Avatar
                              alt="backoffice_user"
                              src={row?.rated_by?.picture}
                              sx={{ width: 56, height: 56, cursor: "pointer" }}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "60%",
                              justifyContent: "space-evenly",
                            }}
                          >
                            <div
                              style={{
                                fontFamily: "monospace",
                                fontSize: 13,
                                fontWeight: 550,
                                color: "#fff",
                                textDecoration: "underline",
                              }}
                            >
                              Değerlendiren
                            </div>
                            <div
                              style={{
                                fontFamily: "segoeuiRg",
                                fontSize: 14,
                                fontWeight: 550,
                                color: "#fff",
                              }}
                            >
                              {row?.rated_by?.full_name}
                            </div>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                fontFamily: "segoeuiRg",
                                fontSize: 11,
                                color: "#fff",
                              }}
                            >
                              {moment(row?.created_at).format(
                                "DD-MM-YYYY HH:mm"
                              )}
                            </div>
                          </div>
                        </div>
                        <Divider
                          orientation="vertical"
                          sx={{
                            backgroundColor: "gray",
                            height: "1px",
                            width: "100%",
                          }}
                        />
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                              textAlign: "center",
                            }}
                          >
                            <div
                              style={{
                                fontFamily: "segoeuiRg",
                                fontSize: 13,
                                fontWeight: 550,
                                color: "#fff",
                              }}
                            >
                              <div
                                style={{
                                  cursor: "pointer",
                                  textDecoration:
                                    row?.cdr?.restofficial !== null
                                      ? "underline"
                                      : "",
                                  color: "#bfbfbf",
                                }}
                                onClick={() => {
                                  if (row?.cdr?.restofficial !== null) {
                                    window.open(
                                      `/restaurants/${row?.cdr?.restofficial?.user_id}`,
                                      "_blank"
                                    );
                                  }
                                }}
                              >
                                {row?.cdr?.restofficial !== null
                                  ? row?.cdr?.restofficial?.name
                                  : "Restoranla ilişkilendirilemedi."}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            width: "90%",
                          }}
                        >
                          {row?.cdr?.voice ? (
                            <div style={{ width: "99%" }}>
                              <Box
                                sx={{
                                  backgroundColor: "#736868",
                                  color: "#fff",
                                  padding: 1,
                                  borderRadius: 1,
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: 1,
                                  width: "90%",
                                }}
                              >
                                <div
                                  style={{
                                    width: "100%",
                                    height: "auto",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    backgroundColor: "#fff",
                                    fontFamily: "segoeuiRg",
                                    fontSize: 11,
                                    color: "#000",
                                    flexDirection: "column",
                                  }}
                                >
                                  <div>
                                    {row?.cdr?.id} -{" "}
                                    {moment(row?.cdr?.created_at).format(
                                      "DD-MM-YYYY HH:mm"
                                    )}
                                  </div>
                                </div>
                                <CustomAudioPlayer
                                  audioRef={audioRef}
                                  src={row?.cdr?.voice}
                                />
                              </Box>
                            </div>
                          ) : (
                            <>
                              <Button
                                style={{
                                  backgroundColor: "#f53b62",
                                  color: "#fff",
                                  fontFamily: "segoeuiB",
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "center",
                                  height: 20,
                                }}
                                size="small"
                                onClick={() => {
                                  puantajRecordFunc({
                                    record_id: row?.cdr?.id,
                                    list: "completed",
                                  });
                                }}
                              >
                                Ses Dosyası
                              </Button>
                            </>
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          width: "68%",
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                          overflow: "auto",
                        }}
                      >
                        {!isOpen && (
                          <>
                            <Paper
                              elevation={2}
                              style={{
                                width: "90%",
                                height: "auto",
                                display: "flex",
                                flexDirection: "column",
                                backgroundColor: "#403836",
                                padding: 10,
                              }}
                            >
                              {row?.education ? (
                                <>
                                  <div
                                    style={{
                                      color: "#5c5caf",
                                      fontFamily: "segoeuiB",
                                    }}
                                  >
                                    Eğitim
                                  </div>
                                  <div
                                    style={{
                                      color: "#fff",
                                      fontFamily: "segoeuiRg",
                                      fontSize: 14,
                                    }}
                                  >
                                    {row?.education_note}
                                  </div>
                                </>
                              ) : (
                                <div
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    minHeight: 40,
                                    fontVariant: "all-petite-caps",
                                    color: "#5c5caf",
                                    fontSize: 18,
                                    letterSpacing: 1.5,
                                    fontFamily: "segoeuiB",
                                  }}
                                >
                                  {row?.notification ? (
                                    "POPUP"
                                  ) : (
                                    <Close style={{ color: "#fff" }} />
                                  )}
                                </div>
                              )}
                            </Paper>
                            <Paper
                              elevation={2}
                              style={{
                                width: "90%",
                                height: "auto",
                                display: "flex",
                                flexDirection: "column",
                                backgroundColor: "#403836",
                                marginTop: 15,
                                padding: 10,
                              }}
                            >
                              <div
                                style={{
                                  color: "#5c5caf",
                                  fontFamily: "segoeuiB",
                                  fontVariant: "all-petite-caps",
                                }}
                              >
                                Not
                              </div>
                              <div
                                style={{
                                  color: "#fff",
                                  fontFamily: "segoeuiRg",
                                  fontSize: 14,
                                }}
                              >
                                {row?.note}
                              </div>
                            </Paper>
                          </>
                        )}
                        <Paper
                          elevation={3}
                          style={{
                            width: "92%",
                            display: "flex",
                            padding: 5,
                            backgroundColor: "#393737",
                            boxShadow: "rgb(91 91 91 / 75%) 0px 0px 1px 1px",
                            flexDirection: "column",
                            height: isOpen ? "85%" : "5%",
                            margin: "15px 0px",
                          }}
                        >
                          <div className="accordion">
                            <div
                              className="accordion-header"
                              style={{
                                display: "flex",
                                width: "100%",
                                alignItems: "center",
                                justifyContent: "space-between",
                                backgroundColor: row?.reminder
                                  ? "#54735c"
                                  : "#474545",
                                color: "#f4f4f4",
                                fontFamily: "segoeuiB",
                              }}
                            >
                              <div style={{ display: "flex" }}>
                                <Button
                                  style={{
                                    width: "20%",
                                    height: 20,
                                    backgroundColor: "#474545",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    color: "#f4f4f4",
                                  }}
                                  onClick={() => {
                                    if (isOpen === row?.id) {
                                      setIsOpen(null);
                                    } else {
                                      setIsOpen(row?.id);
                                    }
                                  }}
                                >
                                  <KeyboardArrowDown />
                                </Button>
                              </div>
                              <div> Puanlamayı aç</div>
                              <div style={{ width: "20%" }}></div>
                            </div>
                          </div>
                          {isOpen === row?.id && (
                            <div style={{ height: "95%" }}>
                              <div style={{ height: "85%", overflow: "auto" }}>
                                {row?.ratings?.map((rowF, indF) => {
                                  return (
                                    <Paper
                                      style={{
                                        display: "flex",
                                        color: "#fff",
                                        padding: 2,
                                        backgroundColor: "#403836",
                                        width: "95%",
                                        marginTop: 10,
                                        fontFamily: "segoeuiRg",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div
                                        style={{
                                          width: "100%",
                                          display: "flex",
                                          fontSize: 15,
                                          color: "#1c9532",
                                        }}
                                      >
                                        <div
                                          style={{
                                            marginRight: 5,
                                            backgroundColor: "green",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-evenly",
                                            borderRadius: 3,
                                            cursor: "pointer",
                                            WebkitAppRegion: "no-drag",
                                            width: "auto",
                                            minWidth: 30,
                                            color: "#fff",
                                            fontSize: 14,
                                            fontFamily: "segoeuiRg",
                                          }}
                                        >
                                          {rowF?.points}
                                        </div>
                                        {rowF?.title}
                                      </div>
                                      <Divider
                                        orientation="vertical"
                                        sx={{
                                          backgroundColor: "#4a4a4a",
                                          width: "100%",
                                          height: "1px",
                                          margin: "2px 0px",
                                        }}
                                      />
                                      {rowF?.options?.length !== 0 ? (
                                        rowF?.options?.map((rowOpt, indOpt) => {
                                          return (
                                            <div
                                              style={{
                                                display: "flex",
                                                flexDirection: "column",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  display: "flex",
                                                  minHeight: 35,
                                                  alignItems: "center",
                                                  flexDirection: "row",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    width: "100%",
                                                    display: "flex",
                                                    fontSize: 14,
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  {" "}
                                                  <div
                                                    style={{
                                                      marginRight: 5,
                                                      backgroundColor: "#fff",
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent:
                                                        "space-evenly",
                                                      borderRadius: 3,
                                                      cursor: "pointer",
                                                      WebkitAppRegion:
                                                        "no-drag",
                                                      width: "auto",
                                                      minWidth: 20,
                                                      color: "black",
                                                      fontSize: 12,
                                                      fontFamily: "segoeuiRg",
                                                      height: 15,
                                                    }}
                                                  >
                                                    {rowOpt?.points}
                                                  </div>
                                                  {rowOpt?.title}
                                                </div>
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    width: "30%",
                                                    justifyContent:
                                                      "space-around",
                                                    alignSelf: "center",
                                                    marginTop: 4,
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      width: 40,
                                                      height: 20,
                                                      backgroundColor:
                                                        rowOpt.rating === true
                                                          ? "green"
                                                          : "#f4f4f4",
                                                      borderRadius: 2,
                                                      color:
                                                        rowOpt.rating === true
                                                          ? "#fff"
                                                          : "#000",
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                      cursor: "pointer",
                                                      fontSize: 13,
                                                    }}
                                                  >
                                                    Evet
                                                  </div>
                                                  <div
                                                    style={{
                                                      width: 40,
                                                      height: 20,
                                                      backgroundColor:
                                                        rowOpt.rating === false
                                                          ? "green"
                                                          : "#f4f4f4",
                                                      borderRadius: 2,
                                                      color:
                                                        rowOpt.rating === false
                                                          ? "#fff"
                                                          : "#000",
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                      cursor: "pointer",
                                                      fontSize: 13,
                                                    }}
                                                  >
                                                    Hayır
                                                  </div>
                                                </div>
                                              </div>
                                              <Divider
                                                orientation="vertical"
                                                sx={{
                                                  backgroundColor: "#4a4a4a",
                                                  width: "100%",
                                                  height: "1px",
                                                  margin: "10px 0px",
                                                }}
                                              />
                                            </div>
                                          );
                                        })
                                      ) : (
                                        <div
                                          style={{
                                            display: "flex",
                                            width: "100%",
                                            justifyContent: "space-around",
                                            alignSelf: "center",
                                            marginTop: 4,
                                          }}
                                        >
                                          <div
                                            style={{
                                              width: 40,
                                              height: 20,
                                              backgroundColor:
                                                rowF.rating === true
                                                  ? "green"
                                                  : "#f4f4f4",
                                              borderRadius: 2,
                                              color:
                                                rowF.rating === true
                                                  ? "#fff"
                                                  : "#000",
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                              cursor: "pointer",
                                              fontSize: 13,
                                            }}
                                          >
                                            Evet
                                          </div>
                                          <div
                                            style={{
                                              width: 40,
                                              height: 20,
                                              backgroundColor:
                                                rowF.rating === false
                                                  ? "green"
                                                  : "#f4f4f4",
                                              borderRadius: 2,
                                              color:
                                                rowF.rating === false
                                                  ? "#fff"
                                                  : "#000",
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                              cursor: "pointer",
                                              fontSize: 13,
                                            }}
                                          >
                                            Hayır
                                          </div>
                                        </div>
                                      )}
                                    </Paper>
                                  );
                                })}
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-evenly",
                                  height: "15%",
                                  alignItems: "center",
                                }}
                              >
                                {row?.education_note !== null ? (
                                  <Tooltip
                                    title={row?.education_note}
                                    placement="top"
                                    sx={{ fontSize: 20 }}
                                    arrow
                                    componentsProps={{
                                      tooltip: {
                                        sx: {
                                          fontSize: 14,
                                          fontFamily: "segoeuiB",
                                          padding: 1,
                                          bgcolor: "#082657",
                                          "& .MuiTooltip-arrow": {
                                            color: "#082657",
                                          },
                                        },
                                      },
                                    }}
                                  >
                                    <Info
                                      style={{
                                        width: 24,
                                        height: 24,
                                        color: "#fff",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </Tooltip>
                                ) : (
                                  <Info
                                    style={{
                                      width: 24,
                                      height: 24,
                                      color: "gray",
                                    }}
                                  />
                                )}
                                <Tooltip
                                  title={row?.note}
                                  placement="top"
                                  sx={{ fontSize: 20 }}
                                  arrow
                                  componentsProps={{
                                    tooltip: {
                                      sx: {
                                        fontSize: 14,
                                        fontFamily: "segoeuiB",
                                        padding: 1,
                                        bgcolor: "#082657",
                                        "& .MuiTooltip-arrow": {
                                          color: "#082657",
                                        },
                                      },
                                    },
                                  }}
                                >
                                  <Info
                                    style={{
                                      width: 24,
                                      height: 24,
                                      color: "#fff",
                                      cursor: "pointer",
                                    }}
                                  />
                                </Tooltip>
                              </div>
                            </div>
                          )}
                        </Paper>
                      </div>
                    </Paper>
                  );
                })}
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "3%",
                height: "80vh",
                // transition: "width 0.5s ease",
              }}
            >
              <div
                style={{
                  width: "100%",
                  backgroundColor: "green",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  fontFamily: "segoeuiB",
                  fontSize: 13,
                  padding: "5px 0px",
                  color: "#fff",
                  position: "relative",
                  writingMode: "vertical-rl",
                  transform: "rotate(180deg)",
                  height: "100%",
                }}
              >
                <div></div>
                <div>TAMAMLANAN</div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{
                      backgroundColor: "white",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-evenly",
                      borderRadius: 3,
                      cursor: "pointer",
                      WebkitAppRegion: "no-drag",
                      width: "auto",
                      minWidth: 30,
                      writingMode: "horizontal-tb",
                      transform: "rotate(180deg)",
                    }}
                  >
                    <div
                      style={{
                        color: "black",
                        fontSize: 14,
                        fontFamily: "segoeuiRg",
                      }}
                    >
                      {ratingListCompleted?.length}
                    </div>
                  </div>
                  <Fullscreen
                    style={{
                      width: 18,
                      height: 18,
                      marginTop: 15,
                      color: "#fff",
                      cursor: "pointer",
                    }}
                    className={classes.hoverStyle}
                    onClick={() => {
                      setCompletedContainerSelect(false);
                    }}
                  />
                </div>
              </div>
            </div>
          )}
          <Divider
            orientation="vertical"
            sx={{
              backgroundColor: "gray",
              width: "1px",
              height: "80vh",
            }}
          />
          {newContainerSelect &&
            educationContainerSelect &&
            completedContainerSelect && (
              <div
                style={{
                  width: "80%",
                  display: "flex",
                  background: "gray",
                  borderRadius: 2,
                  backgroundColor: "#494343",
                  flexWrap: "wrap",
                  marginLeft: 100,
                  gap: "10px",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  padding: 20,
                  height: "90%",
                  overflow: "auto",
                  maxHeight: "75vh",
                }}
              >
                {ratingStats?.map((item, index) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#332e2d",
                        borderRadius: 4,
                        padding: 4,
                      }}
                    >
                      <Avatar
                        alt="backoffice_user"
                        src={item?.officeuser?.picture}
                        sx={{
                          width: 28,
                          height: 28,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setTaskTypeStatsModal({
                            showModal: true,
                            selectedOfficeuser: item,
                          });
                        }}
                      />

                      <Paper
                        style={{
                          backgroundColor: "#403836",
                          marginLeft: 10,
                          width: 80,
                          color: "#fff",
                          padding: 1,
                          display: "flex",
                          justifyContent: "center",
                          height: 30,
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            fontSize: 10,
                            fontFamily: "segoeuiRg",
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            padding: 2,
                          }}
                        >
                          Yeni
                          <div
                            style={{
                              fontSize: 13,
                              textAlign: "center",
                            }}
                          >
                            {item?.new_count}
                          </div>
                        </div>
                      </Paper>
                      <Paper
                        style={{
                          backgroundColor: "#403836",
                          marginLeft: 10,
                          width: 80,
                          color: "#fff",
                          padding: 1,
                          display: "flex",
                          justifyContent: "center",
                          height: 30,
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            fontSize: 10,
                            fontFamily: "segoeuiRg",
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            padding: 2,
                          }}
                        >
                          Eğitim
                          <div
                            style={{
                              fontSize: 13,
                              textAlign: "center",
                            }}
                          >
                            {item?.education_count}
                          </div>
                        </div>
                      </Paper>
                      <Paper
                        style={{
                          backgroundColor: "#403836",
                          marginLeft: 10,
                          width: 80,
                          color: "#fff",
                          padding: 1,
                          display: "flex",
                          justifyContent: "center",
                          height: 30,
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            fontSize: 10,
                            fontFamily: "segoeuiRg",
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            padding: 2,
                          }}
                        >
                          Tamamlanan
                          <div
                            style={{
                              fontSize: 13,
                              textAlign: "center",
                            }}
                          >
                            {item?.completed_count}
                          </div>
                        </div>
                      </Paper>
                      <Paper
                        style={{
                          backgroundColor: "#403836",
                          marginLeft: 10,
                          width: 80,
                          color: "#fff",
                          padding: 1,
                          display: "flex",
                          justifyContent: "center",
                          height: 30,
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            fontSize: 10,
                            fontFamily: "segoeuiRg",
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            padding: 2,
                          }}
                        >
                          Puan Ort.
                          <div
                            style={{
                              fontSize: 13,
                              textAlign: "center",
                            }}
                          >
                            %{item?.average_percentage?.toFixed(0)}
                          </div>
                        </div>
                      </Paper>
                    </div>
                  );
                })}
              </div>
            )}
        </div>
      </div>
      <AddGroupPuantajModal
        addGroupPuantajScreen={addGroupPuantajScreen}
        setAddGroupPuantajScreen={setAddGroupPuantajScreen}
        cdrRatingGroupList={cdrRatingGroupList}
        setCdrRatingGroupList={setCdrRatingGroupList}
        cdrRatingGroupAddFunc={cdrRatingGroupAddFunc}
      />
      <TaskTypeStatsModal
        taskTypeStatsModal={taskTypeStatsModal}
        setTaskTypeStatsModal={setTaskTypeStatsModal}
        startDate={startDate}
        endDate={endDate}
        ratingTaskTypeStatsFunc={ratingTaskTypeStatsFunc}
        ratingTaskTypeStats={ratingTaskTypeStats}
        cdrRatingTaskTypeCreateFunc={cdrRatingTaskTypeCreateFunc}
      />
      <EducationHistoryModal
        educationHistoryModal={educationHistoryModal}
        setEducationHistoryModal={setEducationHistoryModal}
      />
    </>
  );
};

export default PuantajScreen;
