import React, { useContext, useEffect, useState } from "react";
import { Add, AddCircleOutline, Delete, Save, Sort } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  InputBase,
  Paper,
  Radio,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { ManagementContext } from "../../../../contexts/managementContext";
import Select from "react-select";
import { selectDefaultStyles } from "../../../../components/selectStyles/stylev2";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import GroupHeaderSelectModal from "./puantajSettingsv2/modals/groupHeaderSelect";
import SettingsHeaderSelectModal from "./puantajSettingsv2/modals/settingsHeaderSelect";
import SortList from "../../../../components/modals/sortList";
import { zeroToHundred } from "../../../../utils/constants/arrays";

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"div"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
      borderRadius: 10,
    },
    "& .PrivateNotchedOutline-root-15.MuiOutlinedInput-notchedOutline": {
      borderColor: "gray",
      borderWidth: 0,
      borderRadius: 10,
    },
    "& .MuiOutlinedInput-input": {
      marginTop: 6,
      marginLeft: 6,
      color: "black",
      borderRadius: 10,
    },
    "& .css-1wu7ecg-MuiSvgIcon-root-MuiSelect-icon": {
      color: "black",
    },
  },
  tabs: {
    "& .MuiTabs-flexContainer": {
      justifyContent: "center",
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "#f53f62",
    },
    "& .MuiTab-root": {
      fontFamily: "segoeuiB",
    },
    "& .MuiTab-root.Mui-selected": {
      color: "#f53f62",
    },
  },
});

const PuantajSettings = () => {
  const classes = useStyles();
  const [createNewRatingSetting, setCreateNewRatingSetting] = useState(null);
  const [ratingSettingListObj, setRatingSettingListObj] = useState([]);
  const [groupHeaderSelectModal, setGroupHeaderSelectModal] = useState({
    showModal: false,
    selectedGroup: null,
    index: null,
  });
  const [settingsHeaderSelectModal, setSettingsHeaderSelectModal] = useState({
    showModal: false,
    selectedTaskType: null,
    index: null,
  });
  const [value, setValue] = useState(0);

  const [sortListModal, setSortListModal] = useState({
    showModal: false,
    parentListInfo: null,
    sortList: null,
    sortListHeader: null,
    uiInfo: null,
  });

  const {
    ratingSettingsListFunc,
    ratingSettingsList,
    dashboardCdrFiltersFunc,
    dashboardCdrFilterList,
    cdrRatingHeaderlistFunc,
    cdrRatingHeaderList,
    setCdrRatingHeaderList,
    cdrRatingHeaderUpdateFunc,
    cdrRatingHeaderCreateFunc,
    cdrRatingGrouplistFunc,
    cdrRatingGroupList,
    setCdrRatingGroupList,
    cdrRatingGroupUpdateFunc,
    cdrRatingGroupCreateFunc,
    ratingSettingsCreateFunc,
    ratingSettingsHeaderFunc,
    cdrHeaderDeleteFunc,
    cdrGroupDeleteFunc,
    cdrRatingSettingsPatchFunc,
  } = useContext(ManagementContext);

  useEffect(() => {
    cdrRatingHeaderlistFunc();
    cdrRatingGrouplistFunc();

    ratingSettingsListFunc();
    dashboardCdrFiltersFunc();
  }, []);

  useEffect(() => {
    if (ratingSettingsList !== null) {
      setRatingSettingListObj(ratingSettingsList);
    }
  }, [ratingSettingsList]);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const calculateTotalPoint = (row) => {
    let total = 0;
    if (row?.options?.length !== 0) {
      row?.options?.map((row) => {
        total = total + row?.points;
      });
    } else {
      total = row?.points;
    }
    return total;
  };

  const sortListFunc = (list, parentList) => {
    const updatedParentList = {
      ...parentList,
      options: list,
    };

    const data = {
      id: updatedParentList.id,
      sendObj: updatedParentList,
    };

    cdrRatingHeaderUpdateFunc(data);
  };

  return (
    <>
      <div
        style={{
          width: "100%",
        }}
      >
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            display: "flex",
            justifyContent: "center",
            width: "100%",
            alignItems: "center",
            backgroundColor: "#fff",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChangeTab}
            className={classes.tabs}
            variant="scrollable"
            scrollButtons="auto"
            sx={{
              backgroundColor: "#fff",
              borderRadius: 1,
              minWidth: 500,
              width: "100%",
              margin: "10px 0px",
            }}
          >
            <Tab
              label="Task"
              {...a11yProps(0)}
              sx={{
                backgroundColor: "#f4f4f4",
                width: "50%",
              }}
            />

            <Tab
              label="Başlıklar"
              {...a11yProps(1)}
              sx={{
                backgroundColor: "#f4f4f4",
                width: "50%",
                borderLeftStyle: "solid",
                borderLeftColor: "#f53b62",
                borderLeftWidth: 1,
              }}
            />

            <Tab
              label="Gruplar"
              {...a11yProps(2)}
              sx={{
                backgroundColor: "#f4f4f4",
                width: "50%",
                borderLeftStyle: "solid",
                borderLeftColor: "#f53b62",
                borderLeftWidth: 1,
              }}
            />
          </Tabs>
        </Box>
        <div
          style={{
            backgroundColor: "#fff",
          }}
        >
          <TabPanel
            value={value}
            index={0}
            style={{
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                width: "100%",
              }}
            >
              {ratingSettingListObj?.map((row, index) => {
                return (
                  <Paper
                    elevation={3}
                    style={{
                      width: "48%",
                      display: "flex",
                      padding: 5,
                      margin: "10px 10px",
                      backgroundColor: "#f4f4f4",
                      boxShadow: `${
                        row?.count === 0 ? "red" : "rgb(91 91 91 / 75%)"
                      } 0px 0px 9px 2px`,
                      height: 300,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "25%",
                        alignItems: "center",
                        justifyContent: "space-evenly",
                        backgroundColor: "#dfdfdf",
                      }}
                    >
                      <div style={{ width: "100%" }}>
                        <Paper
                          style={{
                            backgroundColor: "#736868",
                            width: "-webkit-fill-available",
                            color: "#fff",
                            padding: 1,
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              fontSize: 18,
                              fontFamily: "monospace",
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            Task Tipi Seç
                          </div>
                        </Paper>
                        <div
                          style={{
                            textAlign: "--webkit-center",
                            marginTop: 10,
                            width: "100%",
                          }}
                        >
                          <Select
                            options={dashboardCdrFilterList?.task_types}
                            styles={selectDefaultStyles}
                            isSearchable={true}
                            selectWidth={"100%"}
                            hoverColor={"black"}
                            menuColor={"#f53b62"}
                            placeHolderColor={"#fff"}
                            placeholder={"Task Tipi"}
                            dynamiczIndex={3}
                            value={{
                              id: row?.task_type?.id,
                              name: dashboardCdrFilterList?.task_types?.find(
                                (item) => item.id === row?.task_type?.id
                              )?.name,
                            }}
                            getOptionValue={(option) => option.id}
                            onChange={(e) => {
                              const ratingSettingListObjv2 = Object.assign(
                                [],
                                JSON.parse(
                                  JSON.stringify({
                                    ...ratingSettingListObj,
                                  })
                                )
                              );
                              ratingSettingListObjv2[index].task_type.id = e.id;
                              setRatingSettingListObj(ratingSettingListObjv2);
                            }}
                            getOptionLabel={(e) => (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <span>{e.name}</span>
                              </div>
                            )}
                          />
                        </div>
                      </div>
                      <div style={{ width: "100%" }}>
                        <Paper
                          style={{
                            backgroundColor: "#736868",
                            width: "-webkit-fill-available",
                            color: "#fff",
                            padding: 1,
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              fontSize: 18,
                              fontFamily: "monospace",
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            Adet
                          </div>
                        </Paper>
                        <div
                          style={{
                            textAlign: "--webkit-center",
                            marginTop: 10,
                          }}
                        >
                          <Select
                            options={zeroToHundred}
                            styles={selectDefaultStyles}
                            isSearchable={true}
                            selectWidth={"100%"}
                            hoverColor={"black"}
                            menuColor={"#f53b62"}
                            placeHolderColor={"#fff"}
                            placeholder={"Adet"}
                            dynamiczIndex={3}
                            value={{
                              value: row?.count,
                              label: row?.count,
                            }}
                            getOptionValue={(option) => option.value}
                            onChange={(e) => {
                              cdrRatingSettingsPatchFunc({
                                id: row?.id,
                                sendObj: {
                                  count: e.value,
                                },
                              });
                              // const ratingSettingListObjv2 = Object.assign(
                              //   [],
                              //   JSON.parse(
                              //     JSON.stringify({
                              //       ...ratingSettingListObj,
                              //     })
                              //   )
                              // );
                              // ratingSettingListObjv2[index].count = e.value;
                              // setRatingSettingListObj(ratingSettingListObjv2);
                            }}
                            getOptionLabel={(e) => (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <span>{e.label}</span>
                              </div>
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <div style={{ width: "75%" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          height: "10%",
                        }}
                      >
                        <IconButton
                          onClick={() => {
                            setSettingsHeaderSelectModal({
                              showModal: true,
                              selectedTaskType: row,
                              index,
                            });
                          }}
                        >
                          <Add />
                        </IconButton>
                      </div>
                      <div style={{ height: "90%", overflow: "auto" }}>
                        {row?.headers?.map((row2) => {
                          return (
                            <Paper
                              elevation={1}
                              style={{
                                padding: "5px 15px",
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: 10,
                              }}
                            >
                              <div style={{ width: "90%" }}>{row2?.title}</div>
                              <div
                                style={{
                                  width: "10%",
                                  backgroundColor: "white",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-evenly",
                                  borderRadius: 3,
                                  cursor: "pointer",
                                  width: "auto",
                                  minWidth: 35,
                                  boxShadow: "darkslategray 0px 0px 2px 2px",
                                }}
                              >
                                {row2?.points}
                              </div>
                            </Paper>
                          );
                        })}
                      </div>
                    </div>
                  </Paper>
                );
              })}
              <div
                style={{
                  width: "48%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Paper
                  elevation={3}
                  style={{
                    width: "40%",
                    display: "flex",
                    padding: 5,
                    margin: "10px 10px",
                    backgroundColor: "#f4f4f4",
                    boxShadow: "green 0px 0px 9px 2px",
                    height: 300,
                  }}
                >
                  {createNewRatingSetting !== null ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                          alignItems: "center",
                          justifyContent: "space-evenly",
                          backgroundColor: "#dfdfdf",
                        }}
                      >
                        <div style={{ width: "100%" }}>
                          <Paper
                            style={{
                              backgroundColor: "#736868",
                              width: "-webkit-fill-available",
                              color: "#fff",
                              padding: 1,
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                fontSize: 18,
                                fontFamily: "monospace",
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              Task Tipi Seç
                            </div>
                          </Paper>
                          <div
                            style={{
                              textAlign: "--webkit-center",
                              marginTop: 10,
                            }}
                          >
                            <Select
                              options={dashboardCdrFilterList?.task_types}
                              styles={selectDefaultStyles}
                              isSearchable={true}
                              selectWidth={"100%"}
                              hoverColor={"black"}
                              menuColor={"#f53b62"}
                              placeHolderColor={"#fff"}
                              placeholder={"Adet"}
                              dynamiczIndex={3}
                              value={{
                                id: createNewRatingSetting?.task_type,
                                name: dashboardCdrFilterList?.task_types?.find(
                                  (item) =>
                                    item.id ===
                                    createNewRatingSetting?.task_type
                                )?.name,
                              }}
                              getOptionValue={(option) => option.id}
                              onChange={(e) => {
                                //   setSelectedQuantity(e);
                                const createNewRatingSettingObj = Object.assign(
                                  {},
                                  JSON.parse(
                                    JSON.stringify({
                                      ...createNewRatingSetting,
                                    })
                                  )
                                );
                                createNewRatingSettingObj.task_type = e.id;
                                setCreateNewRatingSetting(
                                  createNewRatingSettingObj
                                );
                              }}
                              getOptionLabel={(e) => (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <span>{e.name}</span>
                                </div>
                              )}
                            />
                          </div>
                        </div>
                        <div style={{ width: "100%" }}>
                          <Paper
                            style={{
                              backgroundColor: "#736868",
                              width: "-webkit-fill-available",
                              color: "#fff",
                              padding: 1,
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                fontSize: 18,
                                fontFamily: "monospace",
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              Adet
                            </div>
                          </Paper>
                          <div
                            style={{
                              textAlign: "--webkit-center",
                              marginTop: 10,
                            }}
                          >
                            <Select
                              options={zeroToHundred}
                              styles={selectDefaultStyles}
                              isSearchable={true}
                              selectWidth={100}
                              hoverColor={"black"}
                              menuColor={"#f53b62"}
                              placeHolderColor={"#fff"}
                              placeholder={"Adet"}
                              dynamiczIndex={3}
                              value={{
                                value: createNewRatingSetting?.count,
                                label: createNewRatingSetting?.count,
                              }}
                              getOptionValue={(option) => option.value}
                              onChange={(e) => {
                                const createNewRatingSettingObj = Object.assign(
                                  {},
                                  JSON.parse(
                                    JSON.stringify({
                                      ...createNewRatingSetting,
                                    })
                                  )
                                );
                                createNewRatingSettingObj.count = e.value;
                                setCreateNewRatingSetting(
                                  createNewRatingSettingObj
                                );
                              }}
                              getOptionLabel={(e) => (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <span>{e.label}</span>
                                </div>
                              )}
                            />
                          </div>
                        </div>
                        <Button
                          style={{
                            backgroundColor: "green",
                            color: "#fff",
                            fontFamily: "segoeuiB",
                            width: "40%",
                            display: "flex",
                            justifyContent: "center",
                            height: 30,
                          }}
                          size="small"
                          onClick={() => {
                            let data = {
                              sendObj: {
                                task_type: createNewRatingSetting?.task_type,
                                count: createNewRatingSetting?.count,
                              },
                              setCreateNewRatingSetting,
                            };
                            ratingSettingsCreateFunc(data);
                          }}
                        >
                          Oluştur
                        </Button>
                      </div>
                    </>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <AddCircleOutline
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setCreateNewRatingSetting({
                            task_type: "",
                            count: "",
                          });
                        }}
                      />
                    </div>
                  )}
                </Paper>
              </div>
            </div>
          </TabPanel>
          <TabPanel value={value} index={1} style={{ width: "100%" }}>
            <div>
              <Paper
                elevation={1}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div style={{ alignSelf: "end" }}>
                  <IconButton
                    onClick={() => {
                      const cdrRatingHeaderListObj = Object.assign(
                        [],
                        JSON.parse(
                          JSON.stringify({
                            ...cdrRatingHeaderList,
                          })
                        )
                      );
                      cdrRatingHeaderListObj?.push({
                        title: "",
                        points: 1,
                        options: [],
                      });
                      setCdrRatingHeaderList(cdrRatingHeaderListObj);
                    }}
                  >
                    <Add />
                  </IconButton>
                </div>
                {cdrRatingHeaderList?.map((row, index) => {
                  return (
                    <Paper
                      elevation={1}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "80%",
                        backgroundColor: "#f4f4f4",
                        padding: "5px 15px 10px",
                        marginTop: 15,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "space-betweens",
                          marginBottom: 10,
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            height: 30,
                            display: "flex",
                            backgroundColor: "#f4f4f4",
                            fontFamily: "MnBd",
                            fontSize: 20,
                            fontVariant: "all-petite-caps",
                            letterSpacing: 1.5,
                          }}
                        >
                          Başlık
                        </div>
                        <IconButton
                          onClick={() => {
                            cdrHeaderDeleteFunc({ id: row.id });
                          }}
                        >
                          <Delete style={{ color: "black" }} />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            let data;
                            if (row.hasOwnProperty("id")) {
                              data = {
                                id: row?.id,
                                sendObj: row,
                              };
                              cdrRatingHeaderUpdateFunc(data);
                            } else {
                              data = {
                                sendObj: row,
                              };
                              cdrRatingHeaderCreateFunc(data);
                            }
                          }}
                        >
                          <Save />
                        </IconButton>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "white",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-evenly",
                            borderRadius: 3,
                            cursor: "pointer",
                            width: "auto",
                            minWidth: 35,
                            height: 35,
                            boxShadow: "darkslategray 0px 0px 2px 2px",
                          }}
                        >
                          <div
                            style={{
                              color: "black",
                              fontSize: 14,
                              fontFamily: "segoeuiRg",
                            }}
                          >
                            {calculateTotalPoint(row)}
                          </div>
                        </div>
                        <InputBase
                          spellCheck="false"
                          value={row?.title}
                          sx={{
                            fontSize: 15,
                            backgroundColor: "#fff",
                            width: "70%",
                            padding: "0px 4px",
                            height: 36,
                            fontFamily: "segoeuiRg",
                            "& input": {
                              textAlign: "center",
                              MozAppearance: "textfield",
                              "&::-webkit-outer-spin-button": {
                                display: "none",
                              },
                              "&::-webkit-inner-spin-button": {
                                display: "none",
                              },
                            },
                          }}
                          placeholder={""}
                          onChange={(e) => {
                            const cdrRatingHeaderListObj = Object.assign(
                              [],
                              JSON.parse(
                                JSON.stringify({
                                  ...cdrRatingHeaderList,
                                })
                              )
                            );
                            cdrRatingHeaderListObj[index].title =
                              e.target.value;
                            setCdrRatingHeaderList(cdrRatingHeaderListObj);
                          }}
                        />

                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-evenly",
                            width: "20%",
                          }}
                        >
                          <FormControlLabel
                            labelPlacement="left"
                            control={
                              <Checkbox
                                checked={
                                  row?.options?.length !== 0 ? true : false
                                }
                                onChange={(e) => {
                                  const cdrRatingHeaderListObj = Object.assign(
                                    [],
                                    JSON.parse(
                                      JSON.stringify({
                                        ...cdrRatingHeaderList,
                                      })
                                    )
                                  );
                                  cdrRatingHeaderListObj[index].options?.push({
                                    title: "",
                                    points: 0,
                                  });
                                  setCdrRatingHeaderList(
                                    cdrRatingHeaderListObj
                                  );
                                }}
                                sx={{
                                  color: "#f53b62",
                                  "&.Mui-checked": {
                                    color: "#f53b62",
                                  },
                                }}
                              />
                            }
                            style={{
                              textAlign: "center",
                              backgroundColor: "#fff",
                              borderRadius: 4,
                              minWidth: 110,
                              height: 36,
                            }}
                            label={
                              <span
                                style={{
                                  fontSize: 14,
                                }}
                              >
                                Alt Başlık
                              </span>
                            }
                          />
                          <FormControlLabel
                            labelPlacement="left"
                            control={
                              <Checkbox
                                checked={
                                  row?.options?.length === 0 ? true : false
                                }
                                onChange={(e) => {
                                  const cdrRatingHeaderListObj = Object.assign(
                                    [],
                                    JSON.parse(
                                      JSON.stringify({
                                        ...cdrRatingHeaderList,
                                      })
                                    )
                                  );
                                  cdrRatingHeaderListObj[index].options = [];
                                  setCdrRatingHeaderList(
                                    cdrRatingHeaderListObj
                                  );
                                }}
                                sx={{
                                  color: "#f53b62",
                                  "&.Mui-checked": {
                                    color: "#f53b62",
                                  },
                                }}
                              />
                            }
                            style={{
                              textAlign: "center",
                              backgroundColor: "#fff",
                              borderRadius: 4,
                              minWidth: 110,
                              height: 36,
                            }}
                            label={
                              <span
                                style={{
                                  fontSize: 14,
                                }}
                              >
                                Puan
                              </span>
                            }
                          />
                        </div>
                        {row?.options?.length === 0 && (
                          <div
                            style={{
                              backgroundColor: "white",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-evenly",
                              borderRadius: 3,
                              cursor: "pointer",
                              width: "auto",
                              minWidth: 35,
                              height: 35,
                            }}
                          >
                            <InputBase
                              type="number"
                              spellCheck="false"
                              value={row?.points}
                              sx={{
                                fontSize: 14,
                                backgroundColor: "#fff",
                                minWidth: 35,
                                width: 50,
                                padding: "0px 4px",
                                height: 36,
                                fontFamily: "segoeuiRg",
                                "& input": {
                                  textAlign: "center",
                                  MozAppearance: "textfield",
                                  "&::-webkit-outer-spin-button": {
                                    display: "none",
                                  },
                                  "&::-webkit-inner-spin-button": {
                                    display: "none",
                                  },
                                },
                              }}
                              placeholder={""}
                              onChange={(e) => {
                                const cdrRatingHeaderListObj = Object.assign(
                                  [],
                                  JSON.parse(
                                    JSON.stringify({
                                      ...cdrRatingHeaderList,
                                    })
                                  )
                                );
                                cdrRatingHeaderListObj[index].points = parseInt(
                                  e.target.value
                                );
                                setCdrRatingHeaderList(cdrRatingHeaderListObj);
                              }}
                            />
                          </div>
                        )}
                      </div>
                      {row?.options.length !== 0 && (
                        <>
                          <Divider
                            orientation="vertical"
                            sx={{
                              backgroundColor: "#d3d3d3",
                              width: "100%",
                              height: "1px",
                              margin: "15px 0px 0px 0px",
                            }}
                          />
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: "95%",
                              flexDirection: "column",
                              alignSelf: "end",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "space-betweens",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  width: "100%",
                                  height: 30,
                                  display: "flex",
                                  backgroundColor: "#f4f4f4",
                                  fontFamily: "MnBd",
                                  fontSize: 20,
                                  fontVariant: "all-petite-caps",
                                  letterSpacing: 1.5,
                                }}
                              >
                                Alt Başlık
                              </div>
                              <IconButton
                                onClick={() => {
                                  setSortListModal({
                                    showModal: true,
                                    parentListInfo: row,
                                    sortList: row?.options,
                                    sortListHeader: "Alt Başlık Sıralama",
                                    uiInfo: {
                                      width: 900,
                                    },
                                  });
                                }}
                              >
                                <Sort />
                              </IconButton>
                              <IconButton
                                onClick={() => {
                                  const cdrRatingHeaderListObj = Object.assign(
                                    [],
                                    JSON.parse(
                                      JSON.stringify({
                                        ...cdrRatingHeaderList,
                                      })
                                    )
                                  );
                                  cdrRatingHeaderListObj[index].options?.push({
                                    title: "",
                                    points: 0,
                                  });
                                  setCdrRatingHeaderList(
                                    cdrRatingHeaderListObj
                                  );
                                }}
                              >
                                <Add />
                              </IconButton>
                            </div>
                            {row?.options?.map((rowSub, indRowSub) => {
                              return (
                                <div
                                  style={{
                                    display: "flex",
                                    width: "100%",
                                    marginTop: 10,
                                    alignItems: "center",
                                  }}
                                >
                                  <InputBase
                                    spellCheck="false"
                                    value={rowSub?.title}
                                    sx={{
                                      fontSize: 15,
                                      backgroundColor: "#fff",
                                      width: "70%",
                                      padding: "0px 4px",
                                      height: "100%",
                                      fontFamily: "segoeuiRg",
                                      marginLeft: 10,
                                      "& input": {
                                        textAlign: "center",
                                        MozAppearance: "textfield",
                                        "&::-webkit-outer-spin-button": {
                                          display: "none",
                                        },
                                        "&::-webkit-inner-spin-button": {
                                          display: "none",
                                        },
                                      },
                                    }}
                                    placeholder={""}
                                    onChange={(e) => {
                                      const cdrRatingHeaderListObj =
                                        Object.assign(
                                          [],
                                          JSON.parse(
                                            JSON.stringify({
                                              ...cdrRatingHeaderList,
                                            })
                                          )
                                        );
                                      cdrRatingHeaderListObj[index].options[
                                        indRowSub
                                      ].title = e.target.value;
                                      setCdrRatingHeaderList(
                                        cdrRatingHeaderListObj
                                      );
                                    }}
                                  />
                                  <InputBase
                                    type="number"
                                    spellCheck="false"
                                    value={rowSub?.points}
                                    sx={{
                                      fontSize: 14,
                                      backgroundColor: "#fff",
                                      minWidth: 35,
                                      width: 50,
                                      padding: "0px 4px",
                                      height: 36,
                                      marginLeft: 10,
                                      fontFamily: "segoeuiRg",
                                      "& input": {
                                        textAlign: "center",
                                        MozAppearance: "textfield",
                                        "&::-webkit-outer-spin-button": {
                                          display: "none",
                                        },
                                        "&::-webkit-inner-spin-button": {
                                          display: "none",
                                        },
                                      },
                                    }}
                                    placeholder={""}
                                    onChange={(e) => {
                                      const cdrRatingHeaderListObj =
                                        Object.assign(
                                          [],
                                          JSON.parse(
                                            JSON.stringify({
                                              ...cdrRatingHeaderList,
                                            })
                                          )
                                        );
                                      cdrRatingHeaderListObj[index].options[
                                        indRowSub
                                      ].points = parseInt(e.target.value);
                                      setCdrRatingHeaderList(
                                        cdrRatingHeaderListObj
                                      );
                                    }}
                                  />
                                  <IconButton
                                    onClick={() => {
                                      const updatedList =
                                        cdrRatingHeaderList.map(
                                          (item, parentIndex) => {
                                            if (parentIndex === index) {
                                              // Sadece parent'ın options'ını güncelle
                                              return {
                                                ...item,
                                                options: item.options.filter(
                                                  (_, optIndex) =>
                                                    optIndex !== indRowSub
                                                ),
                                              };
                                            }
                                            return item;
                                          }
                                        );

                                      setCdrRatingHeaderList(updatedList);
                                    }}
                                    style={{ marginLeft: 30 }}
                                  >
                                    <Delete style={{ color: "black" }} />
                                  </IconButton>
                                </div>
                              );
                            })}
                          </div>
                        </>
                      )}
                    </Paper>
                  );
                })}
              </Paper>
            </div>
          </TabPanel>
          <TabPanel value={value} index={2} style={{ width: "100%" }}>
            <div>
              <Paper
                elevation={1}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div style={{ alignSelf: "end" }}>
                  <IconButton
                    onClick={() => {
                      const cdrRatingGroupListObj = Object.assign(
                        [],
                        JSON.parse(
                          JSON.stringify({
                            ...cdrRatingGroupList,
                          })
                        )
                      );
                      cdrRatingGroupListObj?.push({
                        title: "",
                        rating_headers: [],
                      });
                      setCdrRatingGroupList(cdrRatingGroupListObj);
                    }}
                  >
                    <Add />
                  </IconButton>
                </div>
                {cdrRatingGroupList?.map((row, index) => {
                  return (
                    <Paper
                      elevation={1}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "80%",
                        backgroundColor: "#f4f4f4",
                        padding: "5px 15px 10px",
                        marginTop: 15,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "space-betweens",
                          marginBottom: 10,
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            height: 30,
                            display: "flex",
                            backgroundColor: "#f4f4f4",
                            fontFamily: "MnBd",
                            fontSize: 20,
                            fontVariant: "all-petite-caps",
                            letterSpacing: 1.5,
                          }}
                        >
                          Başlık
                        </div>
                        <IconButton
                          onClick={() => {
                            cdrGroupDeleteFunc({ id: row.id });
                          }}
                        >
                          <Delete style={{ color: "black" }} />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            let data;
                            if (row.hasOwnProperty("id")) {
                              data = {
                                id: row?.id,
                                sendObj: {
                                  title: row.title,
                                  rating_headers: row.rating_headers.map(
                                    (header) => header.id
                                  ),
                                },
                              };
                              cdrRatingGroupUpdateFunc(data);
                            } else {
                              data = {
                                sendObj: {
                                  title: row.title,
                                  rating_headers: row.rating_headers.map(
                                    (header) => header.id
                                  ),
                                },
                              };

                              cdrRatingGroupCreateFunc(data);
                            }
                          }}
                        >
                          <Save />
                        </IconButton>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <InputBase
                          spellCheck="false"
                          value={row?.title}
                          sx={{
                            fontSize: 15,
                            backgroundColor: "#fff",
                            width: "70%",
                            padding: "0px 4px",
                            height: 36,
                            fontFamily: "segoeuiRg",
                            "& input": {
                              textAlign: "center",
                              MozAppearance: "textfield",
                              "&::-webkit-outer-spin-button": {
                                display: "none",
                              },
                              "&::-webkit-inner-spin-button": {
                                display: "none",
                              },
                            },
                          }}
                          placeholder={""}
                          onChange={(e) => {
                            const cdrRatingGroupListObj = Object.assign(
                              [],
                              JSON.parse(
                                JSON.stringify({
                                  ...cdrRatingGroupList,
                                })
                              )
                            );
                            cdrRatingGroupListObj[index].title = e.target.value;
                            setCdrRatingGroupList(cdrRatingGroupListObj);
                          }}
                        />
                      </div>
                      <div
                        style={{
                          marginTop: 10,
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "space-betweens",
                            marginBottom: 10,
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                              height: 30,
                              display: "flex",
                              backgroundColor: "#f4f4f4",
                              fontFamily: "MnBd",
                              fontSize: 20,
                              fontVariant: "all-petite-caps",
                              letterSpacing: 1.5,
                            }}
                          >
                            GRUPTA EKLENMİŞ HEADERLAR
                          </div>
                          <IconButton
                            onClick={() => {
                              setGroupHeaderSelectModal({
                                showModal: true,
                                selectedGroup: row,
                                index,
                              });
                            }}
                          >
                            <Add />
                          </IconButton>
                        </div>
                        {row?.rating_headers?.map((row2) => {
                          return (
                            <Paper
                              elevation={1}
                              style={{
                                backgroundColor: "#fff",
                                width: "60%",
                                padding: 10,
                                margin: 10,
                                display: "flex",
                                justifyContent: "space-between",
                                fontFamily: "segoeuiRg",
                              }}
                            >
                              <div>{row2?.title}</div>
                            </Paper>
                          );
                        })}
                      </div>
                    </Paper>
                  );
                })}
              </Paper>
            </div>
          </TabPanel>
        </div>
      </div>
      <GroupHeaderSelectModal
        groupHeaderSelectModal={groupHeaderSelectModal}
        setGroupHeaderSelectModal={setGroupHeaderSelectModal}
        cdrRatingHeaderList={cdrRatingHeaderList}
        cdrRatingGroupList={cdrRatingGroupList}
        setCdrRatingGroupList={setCdrRatingGroupList}
        cdrRatingGroupCreateFunc={cdrRatingGroupCreateFunc}
        cdrRatingGroupUpdateFunc={cdrRatingGroupUpdateFunc}
      />
      <SettingsHeaderSelectModal
        settingsHeaderSelectModal={settingsHeaderSelectModal}
        setSettingsHeaderSelectModal={setSettingsHeaderSelectModal}
        cdrRatingHeaderList={cdrRatingHeaderList}
        ratingSettingsHeaderFunc={ratingSettingsHeaderFunc}
      />

      <SortList
        sortListModal={sortListModal}
        setSortListModal={setSortListModal}
        sortListFunc={sortListFunc}
      />
    </>
  );
};

export default PuantajSettings;
