import React, { useContext, useEffect, useState } from "react";

import { Button, Card, Divider, Modal, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ManagementContext } from "../../../../../../contexts/managementContext";
import { RestaurantContext } from "../../../../../../contexts/restaurantContext";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    padding: 20,
    border: "2px solid #000",
    boxShadow: 5,
    backgroundColor: "rgb(0 0 0 / 62%)",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
    maxHeight: "100vh",
  },
}));

const PuantajPopupModal = ({ puantajPopupModal, setPuantajPopupModal }) => {
  const classes = useStyles();
  const {
    cdrRatingDetailv2Func,
    cdrRatingDetail,
    cdrRatingNotificationReadFunc,
  } = useContext(ManagementContext);
  const { notificationListFunc } = useContext(RestaurantContext);

  useEffect(() => {
    if (puantajPopupModal.showModal) {
      cdrRatingDetailv2Func({ id: puantajPopupModal.ratingId });
    }
  }, [puantajPopupModal.showModal]);

  return (
    <Modal
      disableEnforceFocus
      disableAutoFocus
      open={puantajPopupModal.showModal}
      className={classes.modal}
      onClose={() => {
        setPuantajPopupModal({
          showModal: false,
          ratingId: null,
        });
      }}
    >
      <Card
        style={{
          width: "45%",
          display: "flex",
          alignItems: "center",
          padding: 30,
          flexDirection: "column",
          maxHeight: "80vh",
          overflow: "auto",
        }}
      >
        <div
          style={{
            width: "100%",
            height: 40,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#fff",
            marginBottom: 20,
            fontFamily: "segoeuiBd",
            fontSize: 22,
            fontVariant: "all-petite-caps",
            letterSpacing: 3.5,
          }}
        >
          PUANTAJ POPUP
        </div>
        <Paper
          elevation={2}
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontFamily: "segoeuiRg",
            padding: 10,
          }}
        >
          {cdrRatingDetail?.note}
        </Paper>
        <div
          style={{
            height: "100%",
            overflow: "auto",
            marginTop: 10,
            width: "100%",
          }}
        >
          {cdrRatingDetail?.ratings?.map((rowF, indF) => {
            return (
              <Paper
                style={{
                  display: "flex",
                  color: "#fff",
                  padding: 2,
                  backgroundColor: "#403836",
                  width: "95%",
                  marginTop: 10,
                  fontFamily: "segoeuiRg",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    fontSize: 15,
                    color: "#1c9532",
                  }}
                >
                  {rowF?.title}
                </div>
                <Divider
                  orientation="vertical"
                  sx={{
                    backgroundColor: "#4a4a4a",
                    width: "100%",
                    height: "1px",
                    margin: "2px 0px",
                  }}
                />
                {rowF?.options?.length !== 0 ? (
                  rowF?.options?.map((rowOpt, indOpt) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            minHeight: 35,
                            alignItems: "center",
                            flexDirection: "row",
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              fontSize: 14,
                              alignItems: "center",
                            }}
                          >
                            {rowOpt?.title}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              width: "30%",
                              justifyContent: "space-around",
                              alignSelf: "center",
                              marginTop: 4,
                              justifySelf: "end",
                            }}
                          >
                            <div
                              style={{
                                width: 40,
                                height: 20,
                                backgroundColor:
                                  rowOpt.rating === true ? "green" : "#f4f4f4",
                                borderRadius: 2,
                                color: rowOpt.rating === true ? "#fff" : "#000",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                cursor: "pointer",
                                fontSize: 13,
                              }}
                            >
                              Evet
                            </div>
                            <div
                              style={{
                                width: 40,
                                height: 20,
                                backgroundColor:
                                  rowOpt.rating === false ? "green" : "#f4f4f4",
                                borderRadius: 2,
                                color:
                                  rowOpt.rating === false ? "#fff" : "#000",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                cursor: "pointer",
                                fontSize: 13,
                              }}
                            >
                              Hayır
                            </div>
                          </div>
                        </div>
                        <Divider
                          orientation="vertical"
                          sx={{
                            backgroundColor: "#4a4a4a",
                            width: "100%",
                            height: "1px",
                            margin: "10px 0px",
                          }}
                        />
                      </div>
                    );
                  })
                ) : (
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-around",
                      alignSelf: "center",
                      marginTop: 4,
                    }}
                  >
                    <div
                      style={{
                        width: 40,
                        height: 20,
                        backgroundColor:
                          rowF.rating === true ? "green" : "#f4f4f4",
                        borderRadius: 2,
                        color: rowF.rating === true ? "#fff" : "#000",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                        fontSize: 13,
                      }}
                    >
                      Evet
                    </div>
                    <div
                      style={{
                        width: 40,
                        height: 20,
                        backgroundColor:
                          rowF.rating === false ? "green" : "#f4f4f4",
                        borderRadius: 2,
                        color: rowF.rating === false ? "#fff" : "#000",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                        fontSize: 13,
                      }}
                    >
                      Hayır
                    </div>
                  </div>
                )}
              </Paper>
            );
          })}
        </div>
        <Button
          style={{
            color: "white",
            backgroundColor: "#f53b62",
            padding: "5px 20px",
            textTransform: "capitalize",
            fontFamily: "SgBd",
            fontSize: 18,
            marginTop: 10,
            width: "100%",
          }}
          onClick={() => {
            cdrRatingNotificationReadFunc({ id: puantajPopupModal.ratingId });
            setPuantajPopupModal({
              showModal: false,
              ratingId: null,
            });
            puantajPopupModal.handleClose();
            setTimeout(() => {
              notificationListFunc();
            }, 1500);
          }}
        >
          Okudum / Anladım
        </Button>
      </Card>
    </Modal>
  );
};

export default PuantajPopupModal;
